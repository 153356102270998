import { auth } from "../firebase/index";
import { push } from "connected-react-router";
import { AppThunk } from "../app/store";
import { Address } from "../Types/userType";
import { addressDocument, addressesCollection } from "../firebasePaths";
import {
  deleteDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { logError } from "../lib/logError";

const timestamp = serverTimestamp();

export const updateMainAddress = (
  address: Omit<Address, "uid">[]
): AppThunk => {
  return async (): Promise<void> => {
    const uid = auth.currentUser!.uid;
    address.forEach((addr) => {
      const addressRef = addressDocument(uid, addr.aid);

      setDoc(
        addressRef,
        { mainAddress: addr.mainAddress },
        { merge: true }
      ).catch((e) => {
        logError(e);
      });
    });
  };
};

export const sendAllFalse = async (address: Omit<Address, "uid" | "aid">) => {
  const uid = auth.currentUser!.uid;
  const addressesRef = addressesCollection(uid);
  const addressResponse = await getDocs(addressesRef);
  const addressArray: Address[] = [];
  const fetchAddressPromise = addressResponse.docs.map(async (addressDoc) => {
    const addressData = addressDoc.data() as Address;
    addressArray.push(addressData);
  });
  await Promise.all(fetchAddressPromise);
  if (addressArray.length === 0) {
    const { mainAddress, ...omit } = address;
    await sendAddress({
      mainAddress: true,
      ...omit,
    });

    return;
  }
  if (address.mainAddress) {
    const addressPromise = addressArray.map(async (add) => {
      const allFalse: Pick<Address, "mainAddress"> = {
        mainAddress: false,
      };
      setDoc(addressDocument(uid, add.aid), allFalse, {
        merge: true,
      });
    });
    await Promise.all(addressPromise);
  }
  sendAddress(address);
};

export const sendAddress = async (address: Omit<Address, "uid" | "aid">) => {
  const uid = auth.currentUser!.uid;

  const addressesRef = addressDocument(uid);
  const addressData = {
    aid: addressesRef.id,
    lastName: address.lastName,
    firstName: address.firstName,
    lastNameKana: address.lastNameKana,
    firstNameKana: address.firstNameKana,
    zipcode: address.zipcode,
    prefecture: address.prefecture,
    city: address.city,
    town: address.town,
    address: address.address,
    buildingName: address.buildingName,
    phoneNumber: address.phoneNumber,
    mainAddress: address.mainAddress,
    uid: uid,
    createdAt: timestamp,
  };

  setDoc(addressesRef, addressData);
};

export const isMainAddressCheck = async (address: Omit<Address, "uid">) => {
  const uid = auth.currentUser!.uid;
  const addressesRef = addressesCollection(uid);
  if (address.mainAddress) {
    getDocs(addressesRef)
      .then((doc) => {
        doc.forEach(async (data) => {
          const addressData = data.data();
          if (address.aid !== data.id) {
            const allFalse: Pick<Address, "mainAddress"> = {
              mainAddress: false,
            };
            await setDoc(addressDocument(uid, addressData.aid), allFalse, {
              merge: true,
            });
          }
        });
      })
      .then(async () => {
        await updateAddress(address);
        return;
      });
  }
  await updateAddress(address);
};

const updateAddress = async (address: Omit<Address, "uid">) => {
  const uid = auth.currentUser!.uid;
  const addressRef = addressDocument(uid, address.aid);
  const updateAddressData = {
    lastName: address.lastName,
    firstName: address.firstName,
    lastNameKana: address.lastNameKana,
    firstNameKana: address.firstNameKana,
    zipcode: address.zipcode,
    prefecture: address.prefecture,
    city: address.city,
    town: address.town,
    address: address.address,
    buildingName: address.buildingName,
    phoneNumber: address.phoneNumber,
    mainAddress: address.mainAddress,
  };
  await setDoc(addressRef, updateAddressData, { merge: true });
};

export const fetchAddress = (uid: string, setAddress: Function): AppThunk => {
  return async (): Promise<void> => {
    const addressesRef = addressesCollection(uid);
    const addressResponse = await getDocs(addressesRef);
    const addressArray: Address[] = [];

    const addressPromise = addressResponse.docs.map((addDoc, index) => {
      const address = addDoc.data();
      const newBody: Address = {
        zipcode: address.zipcode,
        prefecture: address.prefecture,
        city: address.city,
        town: address.town,
        address: address.address,
        buildingName: address.buildingName,
        firstName: address.firstName,
        lastName: address.lastName,
        firstNameKana: address.firstNameKana,
        lastNameKana: address.lastNameKana,
        phoneNumber: address.phoneNumber,
        mainAddress: address.mainAddress!,
        aid: address.aid,
        addressIdx: String(index + 1),
        uid: address.uid,
      };
      addressArray.push(newBody);
    });
    await Promise.all(addressPromise);
    setAddress(addressArray);
  };
};

export const addressLengthCheck = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const addressesRef = addressesCollection(uid);
    const addressLength = (await getDocs(addressesRef)).size;
    if (addressLength >= 5) {
      alert(`住所登録は5つまでです。`);
    } else {
      dispatch(push("/address-registration/" + uid));
    }
  };
};

export const deleteAddress = (aid: string): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const addressRef = addressDocument(uid, aid);
    await deleteDoc(addressRef);
  };
};

export const fetchDeliveryAddress = (setAddress: Function): AppThunk => {
  return async (): Promise<void> => {
    let unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const addressesRef = addressesCollection(user.uid);
        const addressResponse = await getDocs(addressesRef);

        const addressArray: Address[] = [];
        const addressPromise = addressResponse.docs.map((addDoc) => {
          const data = addDoc.data() as Address;
          addressArray.push(data);
        });
        await Promise.all(addressPromise);

        const mainAddress = addressArray.find((address) => address.mainAddress);
        if (mainAddress) {
          setAddress(mainAddress);
          return;
        }
        if (!Array.isArray(addressArray)) setAddress(addressArray[0]);
      }
      unsubscribe();
    });
  };
};

export const fetchAddressChange = (
  uid: string,
  setChangeAddress: Function
): AppThunk => {
  return async (): Promise<void> => {
    const addressesRef = addressesCollection(uid);
    const addressBox: any[] = [];
    getDocs(addressesRef)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          addressBox.push(data);
        });
      })
      .then(() => {
        setChangeAddress(addressBox);
      });
  };
};
