import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import { CreatorList } from "../../Types/userType";
import { Name } from "../UIKit/Name";
import { UserIcon } from "../UIKit/UserIcon";
import { selectUser } from "../../features/userSlice";
import { CreatorListSkeleton } from "../CreatorList/CreatorListSkeleton";
type Props = {
  loading: boolean;
  userData: CreatorList[];
};
const UserInfoMap = (props: Props) => {
  const { loading, userData } = props;
  const useStyles = makeStyles({
    memerList: {
      marginTop: 30,
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "white",
    },
    memer: {
      width: "33.333333%",
    },
  });
  const classes = useStyles();
  const skeletonLength: number[] = Array(9).fill(0);
  const { push } = useHistory();
  const clientUser = useSelector(selectUser);
  return (
    <div className={classes.memerList}>
      {skeletonLength.map(
        (_, index) => loading && <CreatorListSkeleton key={index} />
      )}
      {userData.map((user, idx) => (
        <div key={idx} className={classes.memer}>
          <UserIcon
            photoUrl={user.photoUrl}
            width={"98px"}
            height={"98px"}
            margin={"0 auto"}
            frameWidth={"98px"}
            onClick={() => push(`/profile/${user.uid}/${clientUser.uid}`)}
          />
          <Name
            displayName={user.displayName}
            marginTop={"5px"}
            fontSize={"12px"}
          />
        </div>
      ))}
    </div>
  );
};

export default UserInfoMap;
