import Right from "../../../assets/icon/rightArrow.png";
import { Typography, makeStyles } from "@material-ui/core";

type Props = {
  label: string;
  onClick?: () => void;
};
const userStyles = makeStyles({
  myPageMenu: {
    display: "flex",
    borderTop: "1px solid rgb(234,234,234)",
    cursor: "pointer",
    transition: "all 0.2s",
    "& p": {
      fontSize: 15,
      fontWeight: "lighter",
      flex: 5,
      margin: "6px 0 6px 15px",
      color: "rgb(151,151,151)",
    },
    "& img": {
      width: 9,
      height: 18,
      margin: "9px 15px 6px 15px",
    },

    "&:hover": {
      backgroundColor: "rgb(222,222,222)",
      transition: "all 0.2s",
    },
  },
});

export const MenuButton = (props: Props) => {
  const { label, onClick } = props;
  const classes = userStyles();
  return (
    <div className={classes.myPageMenu} onClick={onClick}>
      <Typography>{label}</Typography>
      <img src={Right} alt="rightIcon" />
    </div>
  );
};
