import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { deleteImage } from "../../features/imageOperation";
import { selectPost } from "../../features/postSlice";
import { selectProjectPost } from "../../features/projectPostSlice";
import { PostImages } from "../../Types/projectType";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import GridLayout from "react-grid-layout";
import { useWindowDimensions } from "../../hooks/windowDimensions";

export const QuiltedOneImages: React.FC<PostImages> = ({
  images,
  setImages,
  currentWidth,
}) => {
  const useStyles = makeStyles({
    root: {},
    mainImage: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    gridListLeft: {
      margin: "0 auto",
    },
    imageArea: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
  });

  const classes = useStyles();
  const postSelector = useSelector(selectPost);
  // 通常ポストのストアに格納された画像
  const postImg: { path: string; id: string }[] = postSelector.images;
  const projectPostSelector = useSelector(selectProjectPost);
  // プロジェクトポストのストアに格納された画像
  const projectPostImg: { path: string; id: string }[] =
    projectPostSelector.images;
  // 長いので比較演算しの時に使うIDを変数に入れてる
  const projectID = projectPostSelector.projectID;
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();
  return (
    <div className={classes.root}>
      <div className={classes.mainImage}>
        {/* projectIDの文字列が空じゃなければプロジェクトの画像を表示
        空であれば通常投稿の画像を表示 */}

        {projectID !== ""
          ? projectPostImg.length >= 1 && (
              <GridLayout
                className="layout"
                isDraggable={false}
                isResizable={false}
                cols={20}
                rowHeight={width > 499 ? 100 : 75}
                width={width > 499 ? 500 : currentWidth}
                margin={[1, 1]}
              >
                <div
                  key={0}
                  data-grid={{
                    x: 0,
                    y: 0,
                    w: 20,
                    h: 5.545,
                    i: "0",
                  }}
                >
                  <img
                    src={projectPostImg[0].path}
                    className={classes.imageArea}
                    onClick={() =>
                      dispatch(
                        deleteImage(projectPostImg[0].id, images, setImages)
                      )
                    }
                    alt="postImage"
                  />
                </div>
              </GridLayout>
            )
          : postImg.length >= 1 && (
              <GridLayout
                className="layout"
                isDraggable={false}
                isResizable={false}
                cols={20}
                rowHeight={width > 499 ? 100 : 75}
                width={width > 499 ? 500 : currentWidth}
                margin={[1, 1]}
              >
                <div
                  key={0}
                  data-grid={{
                    x: 0,
                    y: 0,
                    w: 20,
                    h: 6,
                    i: "0",
                  }}
                >
                  <img
                    src={postImg[0].path}
                    className={classes.imageArea}
                    onClick={() =>
                      dispatch(deleteImage(postImg[0].id, images, setImages))
                    }
                    alt="postImage"
                  />
                </div>
              </GridLayout>
            )}
      </div>
    </div>
  );
};
