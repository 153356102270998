import { CreatorList } from "../Types/userType";
import { usersCollection } from "../firebasePaths";
import {
  DocumentData,
  Query,
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { AppThunk } from "../app/store";
import { push } from "connected-react-router";
import { creatorHopeEmail } from "../function/cloudFunctions";
import { doc, setDoc } from "firebase/firestore";
import { creatorHopeCollection } from "../firebasePaths";
import { timeout } from "./helpers/timeHelper";
import { FirebaseTimestampType, db } from "../firebase";
import { ReservedProductInquiryType } from "../Types/creatorType";
import { logError } from "../lib/logError";

export const fetchCreatorList = async () => {
  let creatorList: CreatorList[] = [];
  const creatorListRef = usersCollection();
  const q = query(
    creatorListRef,
    where("role", "==", "memer"),
    orderBy("createdAt", "desc"),
    limit(9)
  );

  try {
    // Firestoreのクエリとタイムアウトを競わせる
    const creatorListResponse: any = await Promise.race([
      getDocs(q),
      timeout(3000), // 3秒でタイムアウト
    ]);

    creatorListResponse.forEach((doc: any) => {
      const userData = doc.data();
      const newData = {
        photoUrl: userData.photoUrl,
        displayName: userData.displayName,
        uid: userData.uid,
        createdAt: userData.createdAt,
      };
      creatorList.push(newData);
    });

    if (creatorList.length === 0) {
      throw new Error("No creators found");
    }
  } catch (error) {
    console.error("Failed to fetch creator list or timeout occurred:", error);
    throw error; // エラーを再スローするか、または適切なエラー処理を行います。
  }

  const response = {
    lastLeadCreator: creatorList[creatorList.length - 1].createdAt,
    firstRadCreator: creatorList[0].createdAt,
    lastId: creatorList[creatorList.length - 1].uid,
    firstId: creatorList[0].uid,
    creatorList,
  };
  return response;
};

export const fetchFirstOrLastId = async (sortOrder: "asc" | "desc") => {
  let returnId = "";
  const creatorListRef = usersCollection();
  const q = query(
    creatorListRef,
    where("role", "==", "memer"),
    orderBy("createdAt", sortOrder),
    limit(1)
  );

  const response = await getDocs(q);
  response.forEach((doc) => {
    const data = doc.data();
    const id = data.uid;
    returnId = id;
  });
  return returnId;
};

export const fetchSortCreatorList = async (
  targetTime: Date,
  action: "next" | "prev"
) => {
  let creatorList: CreatorList[] = [];
  let q: Query<DocumentData, DocumentData>;
  const creatorListRef = usersCollection();
  if (action === "prev") {
    q = query(
      creatorListRef,
      where("role", "==", "memer"),
      orderBy("createdAt", "desc"),
      limitToLast(9),
      endBefore(targetTime)
    );
  } else {
    q = query(
      creatorListRef,
      where("role", "==", "memer"),
      orderBy("createdAt", "desc"),
      limit(9),
      startAfter(targetTime)
    );
  }
  const queryResponse = await getDocs(q);
  queryResponse.forEach((doc) => {
    const userData = doc.data();
    const newData = {
      photoUrl: userData.photoUrl,
      displayName: userData.displayName,
      uid: userData.uid,
      createdAt: userData.createdAt,
    };
    creatorList.push(newData);
  });

  const response = {
    lastLeadCreator: creatorList[creatorList.length - 1].createdAt,
    firstRadCreator: creatorList[0].createdAt,
    lastId: creatorList[creatorList.length - 1].uid,
    firstId: creatorList[0].uid,
    creatorList,
  };

  return response;
};

type CreatorData = {
  appealPoint: string;
  creatorConcept: string;
  email: string;
  instagram: string;
  name: string;
  phoneNumber: string;
  selfInfo: string;
  tiktok: string;
  twitter: string;
  userName: string;
};

export const sendCreatorHope = (creatorData: CreatorData): AppThunk => {
  return async (dispatch): Promise<void> => {
    const creatorHopeRef = creatorHopeCollection();
    setDoc(doc(creatorHopeRef), { ...creatorData })
      .then(() => {
        creatorHopeEmail({ ...creatorData })
          .then((res) => {})
          .catch((err) => {
            logError(err);
          });
        dispatch(push("/creatorhope/done"));
      })
      .catch(() => {
        throw new Error();
      });
  };
};

type OrderMetadata = {
  price: number;
  originalPrice: number;
  projectName: string;
  productNumber: string;
};

type OrderData = {
  createdAt: FirebaseTimestampType;
  metadata: OrderMetadata;
  uid: string;
  reservedProducts: Product[];
};

// Productの型定義
type Product = {
  color: string;
  detail: Detail[];
};

// Detailの型定義
type Detail = {
  size: string;
  selectedQuantity: number;
};

const fetchPaymentAuthorizations = async (path: string) => {
  const snapshot = await getDocs(
    collection(db, `${path}/paymentAuthorizations`)
  );
  const authorizations = snapshot.docs.map((doc) => doc.data());
  return authorizations;
};

const getAllPaymentAuthorizationsForUser = async (
  userId: string
): Promise<string[]> => {
  const projectsSnapshot = await getDocs(
    collection(db, `users/${userId}/projects`)
  );
  const projects: string[] = projectsSnapshot.docs.map(
    (doc) => `users/${userId}/projects/${doc.id}`
  );

  return projects;
};

const createFlatDataForProduct = (
  product: Product,
  detail: Detail,
  orderData: OrderData
) => {
  const salesPrice = detail.selectedQuantity * orderData.metadata.price;
  const originalPrice = orderData.metadata.originalPrice;

  return {
    createdAt: orderData.createdAt.toDate(),
    projectName: orderData.metadata.projectName,
    productNumber: orderData.metadata.productNumber,
    salesPrice,
    originalPrice,
    uid: orderData.uid,
    color: product.color,
    size: detail.size,
    productQuantity: detail.selectedQuantity,
  };
};

const flattenOrders = (ordersData: OrderData[]) => {
  return ordersData.flatMap((orderData) =>
    orderData.reservedProducts.flatMap((product) =>
      product.detail.map((det) =>
        createFlatDataForProduct(product, det, orderData)
      )
    )
  );
};

export const fetchAllReservedData = async (userId: string) => {
  const paymentAuthorizations = await getAllPaymentAuthorizationsForUser(
    userId
  );

  const allReservedData = [];

  for (const p of paymentAuthorizations) {
    const projectSalesData = (await fetchPaymentAuthorizations(
      p
    )) as OrderData[];
    const data = flattenOrders(projectSalesData);
    allReservedData.push(data);
  }

  const result = allReservedData.flat();

  return result;
};
