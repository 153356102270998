import { useState } from "react";
import { FirebaseTimestampType } from "../../firebase";
import { Backdrop, Fade, Modal, makeStyles } from "@material-ui/core";
import { relativeDate } from "../../function/common";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import Selection from "../../components/selection/Selection";
import garbageBox from "../../assets/icon/garbageBox2.png";
import { postDeleteError } from "../../lib/errorText";
import { toast } from "react-toastify";
import { logError } from "../../lib/logError";
import { Post } from "../../Types/userType";
import { deletePost } from "../../function/cloudFunctions";

type Props = {
  seconds: FirebaseTimestampType;
  post: Post;
  fetchPost?: Function;
  setDeleteLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PostTime = (props: Props) => {
  const { seconds, post, fetchPost, setDeleteLoading } = props;
  const { uid } = useSelector(selectUser);
  const useStyles = makeStyles({
    createdDate: {
      margin: "0 auto 10px",
      width: "96%",
      "& p": {
        fontSize: 12,
        color: "gray",
      },
    },
    garbageBoxIcon: {
      float: "right",
      cursor: "pointer",
      width: 20,
      filter: "opacity(55%)",
      marginTop: 2,
    },
    timeText: {
      fontWeight: 100,
      fontSize: 12,
      color: "gray",
    },

    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  const relativeDateText = relativeDate(seconds);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClose = () => {
    setModalIsOpen(false);
  };

  const isOpenModal = () => {
    setModalIsOpen(true);
  };

  const postDelete = async () => {
    try {
      setModalIsOpen(false);
      setDeleteLoading && setDeleteLoading(true);
      const response = await deletePost({ post });

      if (!Array.isArray(response.data)) throw Error(postDeleteError.notArray);

      toast.success("投稿の削除に成功しました。");
      if (fetchPost) {
        await fetchPost(true);
        setDeleteLoading!(false);
      }
    } catch (error) {
      logError(error);
      if (error instanceof Error) {
        if (postDeleteError.notArray) {
          toast.error(
            "投稿の削除に失敗しました、時間を置いてもう一度お試しください"
          );
          setDeleteLoading && setDeleteLoading!(false);
          return;
        }
      }
    }
  };

  return (
    <div className={classes.createdDate}>
      <span className={classes.timeText}>{relativeDateText}</span>
      {uid === post.uid && (
        <img
          src={garbageBox}
          onClick={() => isOpenModal()}
          className={classes.garbageBoxIcon}
          alt="garbageBox"
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalIsOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalIsOpen}>
          <Selection
            rightText="キャンセル"
            leftText="削除"
            onClick={() => postDelete()}
            onCancel={() => handleClose()}
            description={`削除後は元に戻せません。\n本当に投稿を削除しますか？`}
          />
        </Fade>
      </Modal>
    </div>
  );
};
