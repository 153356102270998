import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { RegAddress } from "./RegAddress";
import { GrayBack } from "../MyPage/GrayBack";
import { addressLengthCheck } from "../../features/addressOperation";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { SpaceBox } from "../UIKit/SpaceBox";

const useStyles = makeStyles({
  container: {},
  link: {
    textDecoration: "none",
  },
});

export const DeliveryReg = (props: RouteComponentProps) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.container}>
      <BrowserBackHeader label="配送先一覧" browserBack={history} />
      <GrayBack height={15} label="住所一覧" fontSize="12px" />
      <GrayBack height={1} isDark />
      <RegAddress />
      <SpaceBox height={50} />
      <Center>
        <GrayButton width={250} onClick={() => dispatch(addressLengthCheck())}>
          ＋ 新しい配送先を登録
        </GrayButton>
      </Center>
      <SpaceBox height={50} />
    </div>
  );
};
