import { useEffect, useState } from "react";
import { ProjectPost } from "../ProjectPost";
import { RouteComponentProps } from "react-router-dom";
import { fetchProject } from "../../../features/projectPostOperation";
import { Project } from "../../../Types/projectType";
import { BrowserBackHeader } from "../../../components/Header/BrowserBackHeader";
import { toast } from "react-toastify";

export const WantedProjectPosts = (
  props: RouteComponentProps<{ creatorUid: string; projectId: string }>
) => {
  const { match, history } = props;
  const creatorUid = match.params.creatorUid;
  const projectId = match.params.projectId;

  const projectRef = `users/${creatorUid}/projects/${projectId}`;
  const [project, setProject] = useState<Project | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchProject(projectRef);
        setProject(res);
      } catch (error) {
        toast.error("投稿の取得に失敗しました。");
      }
    })();
  }, []);

  return (
    <div>
      <BrowserBackHeader
        label={
          project?.projectName && project.projectName.length > 13
            ? `${project.projectName.substring(0, 11)}...`
            : `${project?.projectName ?? ""}`
        }
        browserBack={history}
      />
      <ProjectPost projectRef={projectRef} />
    </div>
  );
};
