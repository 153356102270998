export const timeout = (ms: number) => {
  return new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Timeout")), ms)
  );
};

export const firebaseTimestampToDate = (createdAt: {
  seconds: number;
  nanoseconds: number;
}) => {
  return new Date(createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000);
};

export const firebaseTimestampToJapaneseDate = (createdAt: {
  seconds: number;
  nanoseconds: number;
}) => {
  const date = new Date(
    createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000
  );
  return date.toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};
