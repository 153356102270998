import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core'

const GrayBorder = () => {
  const useStyles = makeStyles({
    border: {
      borderBottom: '1px solid rgb(112,112,112)'
    }
  })
  const classes = useStyles()
  return <div className={classes.border}></div>
}

export default React.memo(GrayBorder)
