import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles, Typography } from "@material-ui/core";

interface PROPS {
  detaile: {
    price: string;
    salesDate: string;
  };
}

const SalesDetailes = (props: PROPS) => {
  const useStyles = makeStyles({
    salesdetailArea: {
      display: "flex",
      marginBottom: 10,
    },
    left: {
      flex: 1,
      textAlign: "center",
    },
    right: {
      flex: 1,
      textAlign: "center",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.salesdetailArea}>
      <div className={classes.left}>
        <Typography>{props.detaile.salesDate}</Typography>
      </div>
      <div className={classes.right}>
        <Typography>{props.detaile.price}</Typography>
      </div>
    </div>
  );
};

export default SalesDetailes;
