import { useCallback, useEffect, useState } from "react";
import { Review as ReviewType } from "../../Types/reviewType";
import { Text } from "../atoms/Text/Text";
import { Review } from "./Review";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { fetchCreatorName, getReviews } from "../../features/reviewHelper";
import { DocumentSnapshot } from "firebase/firestore";
import { toast } from "react-toastify";

type Props = {
  creatorUid: string;
};

export const ReviewList = ({ creatorUid }: Props) => {
  const [reviews, setReviews] = useState<(ReviewType & { id: string })[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState<DocumentSnapshot | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [creatorName, setCreatorName] = useState("");

  const useStyles = makeStyles({
    titleContainer: {
      padding: "40px 0",
    },
    reviewBorder: {
      borderBottom: "1px solid #ccc",
    },
    moreButton: {
      width: "100%",
      marginTop: 20,
      height: 40,
      display: hasMore && !isLoading && reviews ? "flex" : "none",
      justifyContent: "center",
      alignItems: "center",
    },
    reviewFooterBorder: {
      borderBottom: "1px solid #ccc",
      marginTop: hasMore ? 20 : 40,
    },
  });

  const classes = useStyles();

  const fetchReviews = useCallback(async () => {
    setIsLoading(true);
    if (!hasMore) return;
    const { data, lastDoc } = await getReviews(creatorUid, lastVisible);
    setReviews((prevReviews) => [...prevReviews, ...data]);
    setHasMore(data.length > 0);
    setLastVisible(lastDoc);
    setIsLoading(false);
  }, [hasMore, creatorUid, lastVisible]);

  useEffect(() => {
    try {
      fetchReviews();
      (async () => {
        setCreatorName(await fetchCreatorName(creatorUid));
      })();
    } catch (error) {
      console.error(error);
      toast.error("データの取得に失敗しました。");
    }
  }, []);

  return (
    <div>
      {reviews.length > 0 && (
        <>
          <div className={classes.reviewBorder} />
          <div className={classes.titleContainer}>
            <Text
              text={`${creatorName} product reviews`}
              fontSize={20}
              isDark
              className={{ textAlign: "center" }}
            />
          </div>
          {reviews.map((review) => (
            <Review key={review.id} review={review} />
          ))}
          {isLoading && (
            <CircularProgress
              size={40}
              color="primary"
              thickness={2.5}
              style={{ margin: "0 auto", display: "block", padding: 20 }}
            />
          )}
          <div className={classes.moreButton} onClick={fetchReviews}>
            <Text
              text="もっと見る"
              isDark
              className={{
                cursor: "pointer",
              }}
              align="center"
            />
          </div>
          <div className={classes.reviewFooterBorder} />
        </>
      )}
    </div>
  );
};
