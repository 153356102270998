import TopSkeletonIcon from "./TopSkeletonIcon";
import TopSkeletonImg from "./TopSkeletonImg";
import TopSkeletonText from "./TopSkeletonText";
const TopSkeleton = () => {
  return (
    <div>
      <TopSkeletonIcon />
      <TopSkeletonImg />
      <TopSkeletonText />
    </div>
  );
};

export default TopSkeleton;
