import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";
import DeliveryUserInfo from "./DeliveryUserInfo";
import { auth } from "../../firebase";
import { Address } from "../../Types/userType";
import { GrayBack } from "../MyPage/GrayBack";
import Radio from "@material-ui/core/Radio";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import {
  fetchAddress,
  updateMainAddress,
} from "../../features/addressOperation";
import { EditModal } from "./EditModal";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Text } from "../atoms/Text/Text";
import { toast } from "react-toastify";

export const RegAddress = (): JSX.Element => {
  const [address, setAddress] = useState<Omit<Address, "uid">[]>([]);
  const [addressEdit, setAddressEdit] = useState<Omit<Address, "uid">>();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [indexNumber, setIndexNumber] = useState();
  const dispatch = useDispatch();
  const handleOpenEdit = (index: number) => {
    setOpenEdit(true);
    setAddressEdit(address[index]);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpen = (event: any) => {
    setOpen(true);
    setIndexNumber(event);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const yesHandleClose = () => {
    setOpen(false);
    address.filter((addr) => {
      if (addr.addressIdx === indexNumber) {
        addr.mainAddress = true;
      } else {
        addr.mainAddress = false;
      }
      return addr;
    });
    dispatch(updateMainAddress(address));
  };

  const useStyles = makeStyles({
    addressInfoArea: {
      padding: 10,
      "&:hover": {
        backgroundColor: "rgb(201,201,201)",
        transition: "all 0.3s",
      },
    },
    addressTitle: {
      height: 20,
      marginBottom: 5,
      "& p": {
        margin: "5px 0 0 5px",
        fontSize: 14,
      },
    },
    addressArea: {
      display: "flex",
    },
    addressCheckArea: {
      flex: 1,
    },
    addressInfo: {
      flex: 7,
      cursor: "pointer",
    },
    deliveryInfo: {
      marginLeft: 20,
    },
    deliveryUserInfo: {},
    postCode: {},
    radio: {
      margin: "25px auto 0",
      display: "block",
    },
    modalArea: {
      padding: 20,
      backgroundColor: "white",
      zIndex: 100,
    },
    modalInfo: {
      marginBottom: 20,
    },
    modalInfoEdit: {
      height: 50,
    },
    modalAddress: {
      height: 100,
      marginLeft: 150,
      "@media screen and (max-width: 499px)": {
        marginLeft: 100,
      },
      "& p": {
        fontSize: 12,
        margin: "0 0",
      },
    },
    modalBtnArea: {
      alignItems: "center",
      display: "flex",
    },
    btnLeft: {
      flex: 1,
    },
    btnRight: {
      flex: 1,
    },
    btnCenter: {
      flex: 1,
    },
    selectBtn: {
      margin: "0 auto",
      display: "block",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    link: {
      textDecoration: "none",
    },
  });
  const uid = auth.currentUser?.uid;
  const classes = useStyles();
  const fetchAddressData = () => {
    try {
      dispatch(fetchAddress(uid!, setAddress));
    } catch (error) {
      toast.error("住所の取得に失敗しました。");
    }
  };
  useEffect(() => {
    fetchAddressData();
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modalArea}>
            <div className={classes.modalInfo}>
              <Text
                text="メインの住所へ変更しますか？"
                fontSize={14}
                align="center"
              />
            </div>
            <div className={classes.modalBtnArea}>
              <Center
                className={{
                  flex: 1,
                }}
              >
                <GrayButton width={80} onClick={() => yesHandleClose()}>
                  はい
                </GrayButton>
              </Center>
              <Center
                className={{
                  flex: 1,
                }}
              >
                <GrayButton width={80} onClick={() => handleClose()}>
                  いいえ
                </GrayButton>
              </Center>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={handleCloseEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={openEdit}>
          <EditModal
            fetchAddressData={fetchAddressData}
            addressEdit={addressEdit!}
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
          />
        </Fade>
      </Modal>

      {address &&
        address.map((addr, index) => (
          <div key={addr.aid}>
            <div className={classes.addressInfoArea}>
              <div className={classes.addressTitle}>
                <Typography>住所{index + 1}</Typography>
              </div>
              <div className={classes.addressArea}>
                <div className={classes.addressCheckArea}>
                  <Radio
                    checked={addr.mainAddress}
                    onChange={(e) => handleOpen(e.target.value)}
                    value={index + 1}
                    color="default"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "1" }}
                    className={classes.radio}
                  />
                </div>
                <div
                  className={classes.addressInfo}
                  onClick={() => handleOpenEdit(index)}
                >
                  <DeliveryUserInfo
                    label={
                      addr.lastName +
                      "  " +
                      addr.firstName +
                      "  " +
                      "(" +
                      addr.lastNameKana +
                      "  " +
                      addr.firstNameKana +
                      ")"
                    }
                  />
                  <DeliveryUserInfo label={"〒 " + addr.zipcode} />
                  <DeliveryUserInfo
                    label={
                      addr.prefecture + addr.city + addr.town + addr.address
                    }
                  />
                  <DeliveryUserInfo label={addr.buildingName} />
                  <DeliveryUserInfo label={"TEL " + addr.phoneNumber} />
                </div>
              </div>
            </div>
            <GrayBack height={1} isDark />
          </div>
        ))}
    </div>
  );
};
