import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";

type Props = {
  follow: number;
  follower: number;
  post: number;
  uid: string;
};

export const UserFollowInfo = (props: Props) => {
  const { follow, follower, post, uid } = props;
  const useStyles = makeStyles({
    followArea: {
      backgroundColor: "white",
      display: "flex",
    },
    followBlock: {
      flex: 1,
      cursor: "pointer",
    },
    postBlock: {
      flex: 1,
    },
    number: {
      fontSize: 18,
      color: "rgb(122,122,122)",
      margin: "0 0 0 0 ",
      textAlign: "center",
      fontWeight: "bold",
      "@media screen and (max-width: 499px)": {
        fontSize: 16,
      },
    },
    text: {
      fontSize: 10,
      color: "rgb(122,122,122)",
      margin: "0 0 10px 0 ",
      textAlign: "center",
    },
  });
  const classes = useStyles();
  const { push } = useHistory();

  return (
    <div className={classes.followArea}>
      <div className={classes.postBlock}>
        <p className={classes.number}>{post === undefined ? 0 : post}</p>
        <p className={classes.text}>投稿</p>
      </div>
      {/* <div
        className={classes.followBlock}
        onClick={() => push(`/follower/${uid}`)}
      >
        <p className={classes.number}>
          {follower === undefined ? 0 : follower}
        </p>
        <p className={classes.text}>フォロワー</p>
      </div>
      <div
        className={classes.followBlock}
        onClick={() => push(`/follow/${uid}`)}
      >
        <p className={classes.number}>{follow === undefined ? 0 : follow}</p>
        <p className={classes.text}>フォロー中</p>
      </div> */}
    </div>
  );
};
