import { makeStyles } from "@material-ui/core";
import { SpaceBox } from "../../../components/UIKit/SpaceBox";
import { ProductThreeDAnother } from "./ProductThreeDAnother";
import { ProductThreeDImages } from "./ProductThreeDImages";
import { SalesProject } from "../../../Types/productType";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { MyPageHeader } from "../../../components/MyPage/MypageHeader";
import { Text } from "../../../components/atoms/Text/Text";

type Props = {
  project: SalesProject;
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ThreeDAvatar = (props: Props) => {
  const { project, modalIsOpen, setModalIsOpen } = props;
  const useStyles = makeStyles({
    closeButtonArea: {
      float: "right",
    },
    box: {
      height: "100%",
      backgroundColor: "white",
      width: "100%",
      margin: "10px auto",
      overflow: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    buttonArea: {
      marginTop: 60,
      marginRight: 15,
      marginBottom: 5,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      "@media screen and (max-width: 499px)": {
        marginTop: 40,
        marginBottom: 2,
      },
    },
    closeBtn: {
      width: 30,
      height: 30,
      color: "rgb(151,151,151)",
    },
  });
  const classes = useStyles();

  const handleClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div className={classes.closeButtonArea}>
        <div className={classes.buttonArea} onClick={handleClose}>
          <HighlightOffIcon className={classes.closeBtn} fontSize="small" />
          <Text
            text="3D Avatarを閉じる"
            className={{ marginLeft: 5, marginTop: 1 }}
          />
        </div>
      </div>
      <div className={classes.box}>
        <ProductThreeDAnother project={project} />
        <SpaceBox height={100} />
        <ProductThreeDImages images={project.imageDescriptions ?? []} />
        <SpaceBox height={100} />
      </div>
    </>
  );
};
