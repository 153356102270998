import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { ControllerRenderProps } from "react-hook-form";
import { SpaceBox } from "../../UIKit/SpaceBox";

type Props = {
  label: string;
  field?: ControllerRenderProps<any, any>;
  value?: string;
};

export const InquiryInput = (props: Props) => {
  const { label, field, value } = props;
  const useStyles = makeStyles({
    inputArea: {
      margin: "0 20px",
    },
    input: {
      width: "100%",
    },
    title: {
      marginLeft: 5,
      fontSize: 12,
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.inputArea}>
      <Typography className={classes.title}>{label}</Typography>
      <TextField
        className={classes.input}
        required
        autoComplete="given-name"
        fullWidth
        {...field}
        defaultValue={value}
      />
      <SpaceBox height={30} />
    </div>
  );
};
