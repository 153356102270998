import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Comment, CommentMolding } from "../../Types/userType";
import { AddComment } from "./PostUIKit/AddComment";
import { ReplyCommentField } from "./PostUIKit/ReplyCommentField";
import { SpaceBox } from "../../components/UIKit/SpaceBox";

type Props = {
  comments: Comment[];
  setCommentLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReplyComments = (props: Props) => {
  const { comments, setCommentLoading } = props;
  const useStyles = makeStyles({
    commentBorder: {
      width: "95%",
      borderBottom: "1px solid rgb(151,151,151)",
      margin: "0 auto",
    },
  });
  const classes = useStyles();

  let commentsMoldingBox: CommentMolding[] = [];

  const commentsMoldingClose = () => {
    commentsMoldingBox = [];
    comments.forEach((comment: Comment, index: number) => {
      const commentMolding: CommentMolding = {
        ...comment,
        index: index,
        toggle: false,
        textToggle: false,
      };
      commentsMoldingBox.push(commentMolding);
    });

    return commentsMoldingBox;
  };

  const commentsMoldingOpen = (pushNumber: number) => {
    commentsMoldingBox = [];
    comments.forEach((comment: Comment, index: number) => {
      const commentMolding: CommentMolding = {
        ...comment,
        index: index,
        toggle: index === pushNumber ? true : false,
        textToggle: index === pushNumber ? true : false,
      };
      commentsMoldingBox.push(commentMolding);
    });

    return commentsMoldingBox;
  };

  const [modifyComments, setModifyComments] = useState(commentsMoldingClose());

  useEffect(() => {
    setModifyComments(commentsMoldingClose());
  }, [comments]);

  return (
    <div>
      {modifyComments.length >= 1 &&
        modifyComments.map((com: CommentMolding, idx: number) => (
          <div key={idx}>
            <AddComment
              comment={com}
              index={idx}
              textToggle={com.textToggle}
              commentsMoldingOpen={commentsMoldingOpen}
              commentsMoldingClose={commentsMoldingClose}
              setModifyComments={setModifyComments}
              setCommentLoading={setCommentLoading}
            />
            {com.toggle && (
              <ReplyCommentField
                comment={com}
                setCommentLoading={setCommentLoading}
              />
            )}
            <div className={classes.commentBorder} />
          </div>
        ))}
      <SpaceBox height={250} />
    </div>
  );
};
