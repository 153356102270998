import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import {
  Link,
  RouteComponentProps,
  useLocation,
  useParams,
} from "react-router-dom";
import { Text } from "../atoms/Text/Text";
import { SpaceBox } from "../UIKit/SpaceBox";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Center } from "../atoms/Layout/Center";
import { useEffect, useState } from "react";
import { fetchReviewProject, postReview } from "../../features/reviewHelper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { Skeleton } from "@material-ui/lab";
import { auth } from "../../firebase";

type FormValues = {
  reviewComment: string;
  reviewScore: number;
};

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "20px",
  },
  input: {
    marginBottom: "20px",
  },
  textContainer: {
    padding: 20,
  },
  projectContainer: {
    display: "flex",
    padding: "20px 20px 0 20px",
    gap: 20,
  },
  projectImageContainer: {},
  projectImage: {
    width: 120,
    height: 160,
  },
  projectNameContainer: {},
  loginLink: {
    textAlign: "center",
    color: "skyblue",
    cursor: "pointer",
    textDecoration: "underline",
  },
});

export const ReviewForm = (props: RouteComponentProps) => {
  const { history } = props;
  const user = useSelector(selectUser);
  const location = useLocation();
  const currentPath = location.pathname;
  const [projectImage, setProjectImage] = useState("");
  const [projectName, setProjectName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const { creator_uid, project_id, purchase_history_id } = useParams<{
    creator_uid: string;
    project_id: string;
    purchase_history_id: string;
  }>();
  const methods = useForm<FormValues>({
    defaultValues: {
      reviewScore: 0,
    },
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: FormValues) => {
    try {
      await postReview(
        creator_uid,
        data.reviewComment,
        data.reviewScore,
        user.username,
        user.uid,
        project_id,
        purchase_history_id,
        projectName,
        projectImage
      );
      toast.success(`レビューありがとうございます！\n投稿に成功しました。`);
      history.push("/review-done");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(
          "レビュー投稿に失敗しました。ログインをしていない場合はログインをしてください"
        );
      }
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (!authUser) {
        if (
          window.confirm(
            "レビューを投稿するにはログインが必要です。\nログインページに移動しますか？"
          )
        ) {
          history.push(`/signin?from=${currentPath}`);
        }
      } else {
        (async () => {
          try {
            const project = await fetchReviewProject(creator_uid, project_id);
            setProjectImage(project.images[0]);
            setProjectName(project.projectName);
            setIsLoading(false);
          } catch (error) {
            toast.error("データの取得に失敗しました");
          }
        })();
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
      <FormProvider {...methods}>
        <BrowserBackHeader label="レビュー投稿" browserBack={history} />
        <div className={classes.textContainer}>
          <Text
            text="この度はご購入ありがとうございました！"
            isDark
            fontSize={14}
          />
          <Text
            text={`クリエイターおよびtomeme運営としても励みになりますので、\nぜひレビューをお願いします。`}
            isDark
            fontSize={14}
          />
        </div>
        <SpaceBox height={1} backgroundColor="rgba(0,0,0, 0.2)" />
        <div className={classes.projectContainer}>
          {isLoading ? (
            <>
              <Skeleton variant="rect" width={120} height={160} />
              <Skeleton variant="text" width={210} height={30} />
            </>
          ) : (
            <>
              <div className={classes.projectImageContainer}>
                <img
                  src={projectImage}
                  alt="projectImage"
                  className={classes.projectImage}
                />
              </div>
              <div className={classes.projectNameContainer}>
                <Text text={projectName} isDark fontSize={14} />
              </div>
            </>
          )}
        </div>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="reviewScore"
            control={control}
            rules={{ required: true, min: 1 }}
            render={({ field }) => (
              <div className={classes.input}>
                <Text text="満足度" isDark fontSize={14} />
                <Rating
                  {...field}
                  value={Number(field.value)}
                  onChange={(_, value) => field.onChange(value)}
                />
                {errors.reviewScore && (
                  <Text text="*スコアは必須です。" color="red" />
                )}
              </div>
            )}
          />
          <Controller
            name="reviewComment"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="レビュー内容"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                error={!!errors.reviewComment}
                helperText={errors.reviewComment?.message}
              />
            )}
          />
          <Center className={{ marginTop: 20 }}>
            <GrayButton width={250} type="submit">
              レビューを投稿
            </GrayButton>
          </Center>
        </form>
      </FormProvider>
      <Center
        className={{
          marginTop: 40,
        }}
      >
        <Link to={`/signin?from=${currentPath}`} className={classes.loginLink}>
          ログインはこちら
        </Link>
      </Center>
    </div>
  );
};
