import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  getAuth,
} from "firebase/auth";
import {
  Timestamp,
  collection,
  doc,
  initializeFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage, ref } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

let firebaseConfig = {};

if (process.env.REACT_APP_ENV === "development") {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_DEV_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_DEV_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DEV_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID,
    measurementId: "G-G6Z2BXM3MP",
  };
} else {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: "tomeme.jp",
    // authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: "G-FV31VJTDYP",
  };
}

// firebaseConfigを元にfirebaseを初期化
// firebase.initializeApp(firebaseConfig);
// if (process.env.NODE_ENV == "production") {
//   const analytics = firebase.analytics();
// }
const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
// Firebase Analyticsのインスタンスを取得

export const analyticsCustomLogEvent = (
  eventName: string,
  params: Object = {}
): void => {
  if (Object.keys(params).length > 0) {
    logEvent(analytics, eventName, params);
  } else {
    logEvent(analytics, eventName);
  }
};

export const functions = getFunctions(firebaseApp);
// export const functions = firebase.functions().useEmulator("localhost", 5001); // TODO: あとで
export const auth = getAuth();
export const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});

export const createStorageReference = (directory: string, fileName: string) => {
  const path = `${directory}/${fileName}`;
  return ref(getStorage(firebaseApp), path);
};

export type FirebaseTimestampType = Timestamp;
export type FirebaseFieldValueTime = typeof serverTimestamp;
export type FirebaseDocRefType = ReturnType<typeof doc>;
export type FirebaseCollectionRefType = ReturnType<typeof collection>;

// export const FirebaseFieldValue = firebase.firestore.FieldValue;
// export const FirebaseTimestamp = firebase.firestore.Timestamp;
// // export type FirebaseServerTimestamp = FilteringStyledOptions.
// export type FirebaseTimestampType = firebase.firestore.Timestamp;
// export type FirebaseFieldValueTime = firebase.firestore.FieldValue;
// export type FirebaseDocRefType = firebase.firestore.DocumentReference;
// export type FirebaseCollectionRefType = firebase.firestore.CollectionReference;
export const provider = new GoogleAuthProvider();
export const twitterProvider = new TwitterAuthProvider();
