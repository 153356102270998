import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import { selectChoiceProduct } from "../../../features/productSlice";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { selectUser } from "../../../features/userSlice";
import { ReserveOrPurchaseConfirm } from "../../Purchase/ReserveOrPurchaseConfirm";
import { logError } from "../../../lib/logError";
import { fireStoreBatchError, stripeError } from "../../../lib/errorText";
import { toast } from "react-toastify";
import {
  deliveryOrderNumber,
  fetchDeliveryLocationData,
} from "../../../features/helpers/deliveryHelper";
import { Project } from "../../../Types/projectType";
import { SlackSendData } from "../../../Types/slackType";
import {
  slackNotice,
  sendReserveProduct,
  sendBuyProduct,
  stripeRetrievePaymentIntent,
  stripeIntentPaymentMethod,
} from "../../../function/cloudFunctions";
import { getDocumentRef } from "../../../firebasePaths";
import { collection, getDoc, getDocs } from "firebase/firestore";
import { GrayButton } from "../../../components/atoms/GrayButton/GrayButton";
import { Center } from "../../../components/atoms/Layout/Center";
import { Address } from "../../../Types/deliveryType";
import { Credit } from "../../../Types/paymentType";
import { useDebounce } from "../../../hooks/useDebounce";
import { analyticsCustomLogEvent } from "../../../firebase";
import { Label, Products } from "../../../Types/productType";

type Props = {
  deliveryAddress: Address;
  useCredit: Credit;
  customerID?: string;
  setSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reserveId?: string;
  isNotExist?: boolean;
  paymentAmount: number;
  discountedPrice: number;
  discountRate: number;
  prevLocation?: string;
  creatorInfo: Label;
};
export const BuySubmitButton = (props: Props) => {
  const {
    deliveryAddress,
    useCredit,
    customerID,
    setSubmitLoading,
    reserveId,
    isNotExist,
    paymentAmount,
    discountedPrice,
    discountRate,
    prevLocation,
    creatorInfo,
  } = props;

  const useStyles = makeStyles({
    grayBack: {},
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  const choiceProductStore = useSelector(selectChoiceProduct);
  const { underReservation, inProduction, sale } =
    choiceProductStore.salesStatus;

  const quantity = choiceProductStore.selectedQuantityTotal;
  const user = useSelector(selectUser);

  const [open, setOpen] = useState(false);
  const [isBuy, setIsBuy] = useState(false);
  const { push } = useHistory();

  const deliveryAndCreditSelectCheck = () => {
    if (useCredit.pmID !== "" && deliveryAddress.aid !== "") {
      return false;
    } else {
      return true;
    }
  };
  const debounce = useDebounce(200);

  // 予約
  const reserveSubmit = async () => {
    setOpen(false);
    setSubmitLoading(true);

    const { uid } = user;

    // CFから伝票番号発行
    let orderNumber: string;
    try {
      orderNumber = await deliveryOrderNumber();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      toast.error(
        "購入処理に失敗しました。\nもう一度最初からやり直してください。"
      );
      return;
    }

    const { address, zipcode, phoneNumber, name } =
      await fetchDeliveryLocationData(uid, deliveryAddress.aid);
    const productData = JSON.stringify(choiceProductStore.reserveProducts);
    let pid;
    try {
      const projectRef = getDocumentRef(choiceProductStore.projectRef);
      const productRef = collection(projectRef, "products");
      const reserveCheck: boolean[] = [];
      let productNumber = "";

      const reserveStatusLimitCheck = (
        targetProject: Project,
        products: Products[]
      ) => {
        const limitCheckProduct = targetProject.colors?.map(
          ({ value, limit, label }) => {
            const reserveSum = products
              .filter((item) => item.color === label)
              .reduce((acc, { reserve }) => acc + reserve, 0);
            return { value, limit, label, reserve: reserveSum };
          }
        );

        choiceProductStore.reserveProducts.forEach((item1) => {
          const matchingItem2 = limitCheckProduct?.find(
            (item2) => item2.label === item1.color
          );
          if (matchingItem2) {
            const totalReserved = item1.detail.reduce(
              (sum, detailItem) => sum + detailItem.selectedQuantity,
              0
            );
            const availableLimit = matchingItem2.limit - matchingItem2.reserve;

            if (totalReserved > availableLimit) {
              toast.error(
                `予約制限を超えたため、${item1.color}の商品の予約はできません。`
              );
              reserveCheck.push(false);

              return;
            }
            reserveCheck.push(true);
          }
        });
      };

      const inProductionLimitCheck = (products: Products[]) => {
        const limitCheckProduct = products.map(
          ({ reserveLimit, color, reserve, size }) => {
            return {
              reserveLimit: reserveLimit ?? 0,
              reserve,
              label: color,
              size,
            };
          }
        );

        choiceProductStore.reserveProducts.forEach((reserveProduct) => {
          reserveProduct.detail.forEach((detail) => {
            const matchingItem = limitCheckProduct?.find(
              (product) =>
                product.label === reserveProduct.color &&
                product.size === detail.size
            );
            if (matchingItem) {
              const { selectedQuantity } = detail;
              const availableLimit =
                matchingItem.reserveLimit - matchingItem.reserve;
              if (selectedQuantity > availableLimit) {
                toast.error(
                  `予約制限を超えたため、${reserveProduct.color}の${detail.size}の商品の予約はできません。`
                );
                reserveCheck.push(false);
              }
            }
          });

          // const matchingItem2 = limitCheckProduct?.find(
          //   (item2) => item2.label === item1.color
          // );
          // if (matchingItem2) {
          //   const totalReserved = item1.detail.reduce(
          //     (sum, detailItem) => sum + detailItem.selectedQuantity,
          //     0
          //   );
          //   const availableLimit =
          //     matchingItem2.reserveLimit - matchingItem2.reserve;

          //   if (totalReserved > availableLimit) {
          //     toast.error(
          //       `予約制限を超えたため、${item1.color}の商品の予約はできません。`
          //     );
          //     reserveCheck.push(false);

          //     return;
          //   }
          //   reserveCheck.push(true);
          // }
        });
      };

      const fetchProductsLimitCheck = async () => {
        const projectData = await getDoc(projectRef);
        const productSnapshot = await getDocs(productRef);
        const project = projectData.data() as Project;
        productNumber = project.productNumber;
        const products: any[] = [];
        productSnapshot.forEach((doc) => {
          const product = doc.data();
          products.push(product);
        });

        if (inProduction || sale) {
          inProductionLimitCheck(products);
        }
        if (underReservation) {
          reserveStatusLimitCheck(project, products);
        }
      };

      await fetchProductsLimitCheck();
      if (reserveCheck.includes(false)) {
        setSubmitLoading(false);
        return;
      }

      const intentMetadata = {
        aid: deliveryAddress.aid,
        quantity: choiceProductStore.selectedQuantityTotal,
        price: Math.floor(paymentAmount),
        postage: 0, // キャンペーン中
        projectRef: choiceProductStore.projectRef,
        uid,
        zipcode,
        address,
        phoneNumber,
        name,
        orderNumber,
        projectName: choiceProductStore.projectName,
        productNumber,
        reserveId,
        originalPrice: choiceProductStore.retailPrice,
        discountRate,
      };
      const reserveData = {
        productData,
        pid,
        customerID: customerID,
        paymentMethodID: useCredit.pmID,
        intentMetadata,
        ...intentMetadata,
      };
      await sendReserveProduct(reserveData);
    } catch (error) {
      logError(error);
      if (error instanceof Error) {
        if (error.message === stripeError.payment.authorization) {
          toast.error("購入に失敗しました、もう一度お試しください。");
          setSubmitLoading(false);
          return;
        }
        if (true) {
          const data: SlackSendData = {
            scene: "error",
            channel: "#error_notice",
            id: pid,
            description: {
              error: {
                message: `ID:${pid}を使用してStripeから予約履歴のmetaDataをFirestoreへ保存してください`,
              },
            },
          };
          slackNotice(data);
          setSubmitLoading(false);
          return;
        }
      }
    }
    analyticsCustomLogEvent("begin_checkout");
    setSubmitLoading(false);
    const shareData = {
      prevLocation,
      creatorUid: creatorInfo.value,
      image: choiceProductStore.image,
    };
    push("/reservedone", shareData);
  };

  const reserveDebounce = () => {
    debounce(() => {
      reserveSubmit();
    });
  };

  // 購入
  const buySubmit = async () => {
    setIsBuy(true);
    setOpen(false);
    setSubmitLoading(true);

    const uid = user.uid;

    const orderNumber = await deliveryOrderNumber();
    const { address, zipcode, phoneNumber, name } =
      await fetchDeliveryLocationData(uid, deliveryAddress.aid);

    const productData = {
      price: choiceProductStore.retailPrice,
      // quantity: choiceProductStore.purchaseQuantity,
      // productRef: choiceProductStore.productRef,
      projectRef: choiceProductStore.projectRef,
    };
    const shipFee = 0; //今は適当

    const intentMetadata = {
      aid: deliveryAddress.aid,
      postage: 500,
      productData: JSON.stringify([productData]),
      uid,
      zipcode,
      address,
      phoneNumber,
      name,
      orderNumber,
      // totalAmount: total,
    };
    const intentData = {
      // amount: total + shipFee,
      customerID: customerID,
      paymentMethodID: useCredit.pmID,
      productData: [productData],
      metadata: intentMetadata,
    };
    let pid;
    try {
      const paymentIntentResponse = await stripeIntentPaymentMethod(intentData);
      pid = paymentIntentResponse.data.id;
      // statusCodeがあったらエラー
      if (paymentIntentResponse.data.statusCode)
        throw Error(stripeError.payment.authorization);

      const buyData = {
        // totalAmount: total,
        aid: deliveryAddress.aid,
        pid,
        postage: shipFee,
        productData: [productData],
        uid,
        orderNumber,
      };
      const buyRes = await sendBuyProduct(buyData);
      // batchでcommitした数の配列が返ってくることを期待しているので、配列がなければエラー
      if (!Array.isArray(buyRes.data))
        throw Error(fireStoreBatchError.notArray);
    } catch (error) {
      logError(error);
      if (error instanceof Error) {
        if (error.message === stripeError.payment.authorization) {
          toast.error("購入に失敗しました、もう一度お試しください。");
          setSubmitLoading(false);
          return;
        }
        if (error.message === fireStoreBatchError.notArray) {
          const retrieveRes = await stripeRetrievePaymentIntent({
            pid,
          });

          const rebornData = retrieveRes.data;
          const {
            postage,
            orderNumber,
            productData,
            totalAmount,
            ...omitData
          } = rebornData;

          const buyData = {
            pid,
            postage: parseInt(postage),
            productData: JSON.parse(productData ?? ""),
            orderNumber: parseInt(orderNumber),
            totalAmount: parseInt(totalAmount ?? "0"),
            ...omitData,
          };
          const buyRes = await sendBuyProduct(buyData);
          // TODO:方向性決まったら書き換える
          if (!Array.isArray(buyRes.data)) {
            const data: SlackSendData = {
              scene: "error",
              channel: "#error_notice",
              id: pid,
              description: {
                error: {
                  message: `ID:${pid}を使用してStripeから予約履歴のmetaDataをFirestoreへ保存してください`,
                },
              },
            };
            slackNotice(data);
            return;
          }
        }
      }
    }
    analyticsCustomLogEvent("purchase");
    setSubmitLoading(false);
    setIsBuy(false);
    push("/buydone");
    // pushして画面変える
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.grayBack}>
      {(underReservation || inProduction || sale) && (
        <Center>
          <GrayButton
            onClick={() => handleOpen()}
            disabled={deliveryAndCreditSelectCheck()}
            width={300}
          >
            予約を確定する
          </GrayButton>
        </Center>
      )}
      {/* {sale && (
        <Center>
          <GrayButton
            onClick={() => handleOpen()}
            disabled={deliveryAndCreditSelectCheck()}
            width={300}
          >
            購入する
          </GrayButton>
        </Center>
      )} */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ReserveOrPurchaseConfirm
            reserveDebounce={reserveDebounce}
            buySubmit={buySubmit}
            handleClose={handleClose}
            isBuy={isBuy}
            isNotExist={isNotExist}
            paymentAmount={paymentAmount}
            discountedPrice={discountedPrice}
            discountRate={discountRate}
            quantity={quantity}
          />
        </Fade>
      </Modal>
    </div>
  );
};
