import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { makeStyles, Typography } from '@material-ui/core'

interface PROPS {
  saleText: string
  rewardAmount: string
}

const SaleBox = (props: PROPS) => {
  const useStyles = makeStyles({
    saleBox: {
      padding: '0 15px',
    },
    flexBox: {
      display: 'flex',
      border: '1px solid black',
      marginBottom: 20,
    },
    left: {
      flex: 1,
      borderRight: '1px solid black',
    },
    right: {
      flex: 2,
    },
    text: {
      padding: '25px 0',
    },
  })
  const classes = useStyles()
  return (
    <div className={classes.saleBox}>
      <div className={classes.flexBox}>
        <div className={classes.left}>
          <Typography align='center' className={classes.text}>
            {props.saleText}
          </Typography>
        </div>
        <div className={classes.right}>
          <Typography align='center' className={classes.text}>
            {props.rewardAmount}円
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default SaleBox
