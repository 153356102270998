import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { auth } from "../../firebase";
import { toast } from "react-toastify";

export const FooterButton = () => {
  const useStyles = makeStyles({
    grayArea: {
      backgroundColor: "rgb(245,245,245)",
    },
    button: {
      backgroundColor: "white",
      margin: " 0 auto",
      display: "block",
      width: 150,
      color: "rgb(151,151,151)",
      border: "none",
      borderRadius: 0,
    },
  });
  const classes = useStyles();
  const { push } = useHistory();
  const loginUser = auth.currentUser;

  const logout = () => {
    try {
      auth.signOut();
      push("/signin");
    } catch (error) {
      toast.error("ログアウトに失敗しました");
      return;
    }
  };

  const signIn = () => {
    push("/signin");
  };

  return (
    <div className={classes.grayArea}>
      {loginUser === null ? (
        <Button
          variant="outlined"
          className={classes.button}
          onClick={() => signIn()}
        >
          サインイン
        </Button>
      ) : (
        <Button
          variant="outlined"
          className={classes.button}
          onClick={() => logout()}
        >
          ログアウト
        </Button>
      )}
    </div>
  );
};
