import { Button, makeStyles } from "@material-ui/core";
import ReserveInfo from "../Product/ReserveParts/ReserveInfo";
import { ReservePriceTotal } from "../Product/ReserveParts/ReservePriceTotal";
import { ReserveProduct } from "../Product/ReserveParts/ReserveProduct";
import { useSelector } from "react-redux";
import { selectChoiceProduct } from "../../features/productSlice";
import { SpaceBox } from "../../components/UIKit/SpaceBox";

type Props = {
  reserveDebounce: () => void;
  buySubmit: Function;
  handleClose: Function;
  isBuy: boolean;
  isNotExist?: boolean;
  paymentAmount: number;
  discountedPrice: number;
  discountRate: number;
  quantity: number;
};

export const ReserveOrPurchaseConfirm = (props: Props) => {
  const {
    reserveDebounce,
    buySubmit,
    handleClose,
    isBuy,
    isNotExist,
    paymentAmount,
    discountedPrice,
    discountRate,
    quantity,
  } = props;
  const useStyles = makeStyles({
    grayBack: {
      backgroundColor: "rgb(234,234,234)",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    reserveBox: {
      maxHeight: "calc(100vh - 50px)", // 画面の高さから50pxを引いた値を最大高さとする
      overflowY: "auto",
      width: 498,
      paddingTop: 10,
      border: "1px solid black",
      margin: "0 auto",
      backgroundColor: "white",
      "@media screen and (max-width: 499px)": {
        width: 373,
      },
    },
    reserveConfirm: {
      height: 170,
      display: "flex",
    },
    left: {
      width: 180,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 135,
      },
    },
    right: {
      width: 320,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 240,
      },
    },
    imageBox: {
      margin: "0 auto",
      width: 120,
    },
    image: {
      width: 120,
      height: 150,
      objectFit: "cover",
    },
    buttonArea: {
      height: 50,
      display: "flex",
    },
    leftBtn: {
      // height: 20,
      width: "50%",
      margin: "0 5px 0 10px",
    },
    rightBtn: {
      width: "50%",
      margin: "0 10px 0 5px",
    },
    reserveBtn: {},
  });
  const classes = useStyles();
  const choiceProduct = useSelector(selectChoiceProduct);
  const underReservation = choiceProduct.salesStatus.underReservation;
  const { inProduction, sale } = choiceProduct.salesStatus;

  return (
    <div className={classes.reserveBox}>
      <ReserveInfo bgc="white" border="1px solid gray" />
      {choiceProduct.reserveProducts.map((product) =>
        product.detail.map((detail) => {
          const productData = {
            color: product.color,
            value: product.value,
          };
          return (
            <ReserveProduct
              productData={productData}
              productDetail={detail}
              image={choiceProduct.image}
              projectName={choiceProduct.projectName}
              retailPrice={choiceProduct.retailPrice}
              itemName=""
            />
          );
        })
      )}
      <ReservePriceTotal
        paymentAmount={paymentAmount}
        discountedPrice={discountedPrice}
        discountRate={discountRate}
        isNotExist={isNotExist}
        quantity={quantity}
      />
      <SpaceBox height={10} />
      <div className={classes.buttonArea}>
        <div className={classes.leftBtn}>
          {(underReservation || inProduction || sale) && (
            <Button
              className={classes.reserveBtn}
              variant="outlined"
              fullWidth={true}
              onClick={() => reserveDebounce()}
              disabled={isBuy}
            >
              予約を確定する
            </Button>
          )}
          {/* {sale && (
            <Button
              className={classes.reserveBtn}
              variant="outlined"
              fullWidth={true}
              onClick={() => buySubmit()}
            >
              購入する
            </Button>
          )} */}
        </div>
        <div className={classes.rightBtn}>
          <Button
            className={classes.reserveBtn}
            variant="outlined"
            fullWidth={true}
            onClick={() => handleClose()}
          >
            閉じる
          </Button>
        </div>
      </div>
    </div>
  );
};
