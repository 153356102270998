import {
  db,
  auth,
  FirebaseDocRefType,
  analyticsCustomLogEvent,
} from "../firebase";
import * as Path from "../refPath";
import { Info, MentionUserData, Post, User, UserInfo } from "../Types/userType";
import { AppThunk } from "../app/store";
import { fetchPost } from "./postSlice";
import {
  followerAddApi,
  projectPostUnWanted,
  projectPostWanted,
} from "../function/cloudFunctions";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  commentedPostDocument,
  commentsDocument,
  followersCollection,
  followsCollection,
  getDocumentRef,
  likedPostDocument,
  likedUserDocument,
  postDocument,
  projectLikedPostsDocument,
  socialInfosCollection,
  userDocument,
  usersCollection,
  wantedProjectDocument,
} from "../firebasePaths";
import { logError } from "../lib/logError";

const timeStamp = serverTimestamp();

export const switchLiked = (
  liked: boolean,
  post: Post,
  setLikeCounter: Function,
  setLikeState: Function
) => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    alert("ログインしてください");
    return;
  }

  setLikeState(!liked);

  if (!liked) {
    likePost(post);
    setLikeCounter((prevState: number) => prevState + 1);
  } else {
    unLikePost(post);
    setLikeCounter((prevState: number) => prevState - 1);
  }
};

const likePost = (post: Post) => {
  const batch = writeBatch(db);
  const anotherUser = auth.currentUser!.uid;
  const postRef = Path.userPost(post.userInfo.uid, post.postID ?? "");

  let likedPostsRef: FirebaseDocRefType;

  let authorID: string;
  let projectID: string = "";
  if (post.projectPostRef) {
    likedPostsRef = projectLikedPostsDocument(anotherUser, post.postID);
    const uidSplit = post.projectPostRef.split("/");
    authorID = uidSplit[1];
    projectID = uidSplit[3];
  } else {
    likedPostsRef = likedPostDocument(anotherUser, post.postID);
    const uidSplit = postRef.split("/");
    authorID = uidSplit[1];
  }

  batch.set(
    likedPostsRef,
    {
      id: likedPostsRef.id,
      postRef: post.projectPostRef ? post.projectPostRef : postRef,
      author: authorID,
      createdAt: serverTimestamp(),
      pid: post.projectPostRef ? projectID : "no data",
    },
    { merge: true }
  );

  let postLikedRef;

  if (post.projectPostRef) {
    postLikedRef = doc(
      collection(getDocumentRef(post.projectPostRef), "likedUsers"),
      anotherUser
    );
  } else {
    postLikedRef = likedUserDocument(
      post.userInfo.uid,
      post.postID ?? "",
      anotherUser
    );
  }
  batch.set(
    postLikedRef,
    {
      id: anotherUser,
      createdAt: serverTimestamp(),
    },
    { merge: true }
  );

  batch.commit();
};

const unLikePost = (post: Post) => {
  const anotherUser = auth.currentUser!.uid;

  const batch = writeBatch(db);
  let likedPostRef: FirebaseDocRefType;

  if (post.projectPostRef) {
    const projectPostLikedUsersRef = doc(
      collection(getDocumentRef(post.projectPostRef), "likedUsers"),
      anotherUser
    );

    batch.delete(projectPostLikedUsersRef);
    likedPostRef = projectLikedPostsDocument(anotherUser, post.postID);
  } else {
    const likedUsersRef = likedUserDocument(
      post.userInfo.uid,
      post.postID ?? "",
      anotherUser
    );
    batch.delete(likedUsersRef);
    likedPostRef = likedPostDocument(anotherUser, post.postID ?? "");
  }
  batch.delete(likedPostRef);
  batch.commit();
};

export const setPostComment = (
  post: Post,
  commentBody: string,
  resetCommentBody: Function
): AppThunk => {
  return async (_, getState): Promise<void> => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      alert("ログインしてください");
      return;
    }
    const batch = writeBatch(db);
    const anotherUser = auth.currentUser!.uid;
    const user = getState().user.user;
    const clientUserInfo = {
      displayName: user.displayName,
      uid: user.uid,
      photoUrl: user.photoUrl,
    };
    const timeStamp = serverTimestamp();
    const comment = {
      userInfo: clientUserInfo,
      comment: commentBody,
      createdAt: timeStamp,
      replyCount: 0,
    };

    if (post.projectPostRef) {
      const commentedRef = doc(
        collection(getDocumentRef(post.projectPostRef), "comments"),
        anotherUser
      );
      const commentedRefID = commentedRef.id;
      const commentedPostRef = commentedPostDocument(
        clientUserInfo.uid,
        commentedRefID
      );
      const postRef = getDocumentRef(post.projectPostRef);
      batch.set(commentedRef, { ...comment, commentRef: commentedRef.path });
      batch.set(commentedPostRef, {
        createdAt: timeStamp,
        postID: post.postID,
        commentRef: commentedRef.path,
      });
      batch.set(postRef, { commentCount: increment(1) }, { merge: true });

      batch.commit().then(() => {
        userInfoComment(post, anotherUser);
      });
    } else {
      const commentedRef = commentsDocument(
        post.userInfo.uid,
        post.postID ?? ""
      );
      const commentedRefID = commentedRef.id;
      const commentedPostRef = commentedPostDocument(
        clientUserInfo.uid,
        commentedRefID
      );
      const postRef = postDocument(post.uid, post.postID ?? "");

      batch.set(commentedRef, { ...comment, commentRef: commentedRef.path });
      batch.set(commentedPostRef, {
        createdAt: timeStamp,
        postID: post.postID,
        commentRef: commentedRef.path,
      });
      batch.set(postRef, { commentCount: increment(1) }, { merge: true });

      batch.commit().then(() => {});
    }

    resetCommentBody();
  };
};

export const setReplyComment = (
  comment: any,
  replyBody: string,
  commentUser: UserInfo,
  resetReply: Function
): AppThunk => {
  return async (): Promise<void> => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert("ログインしてください");
      return;
    }
    const batch = writeBatch(db);
    const timeStamp = serverTimestamp();
    const commentRef = getDocumentRef(comment.commentRef);
    const replyRef = doc(
      collection(getDocumentRef(comment.commentRef), "replyComments")
    );

    const newReply = {
      reply: replyBody,
      createdAt: timeStamp,
      userInfo: commentUser,
      replyRef: replyRef.path,
    };
    batch.set(replyRef, newReply);
    batch.set(commentRef, { replyCount: increment(1) }, { merge: true });
    batch.commit();
    resetReply();
  };
};

export const deletePostComment = (commentRefPath: string): AppThunk => {
  return async (_, getState): Promise<void> => {
    const batch = writeBatch(db);
    const user = getState().user.user;
    const commentID = commentRefPath.split("/").slice(-1)[0];
    const postPath = commentRefPath.split("/").slice(0, -2).join("/");

    const commentRef = getDocumentRef(commentRefPath);
    const commentedPostRef = commentedPostDocument(user.uid, commentID);
    const postRef = getDocumentRef(postPath);

    batch.delete(commentRef);
    batch.delete(commentedPostRef);
    batch.set(
      postRef,
      {
        commentCount: increment(-1),
      },
      { merge: true }
    );
    batch.commit();
  };
};

export const switchWanted = (
  wanted: boolean,
  post: Post,
  setWantCounter: React.Dispatch<React.SetStateAction<number>>,
  setWantState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { currentUser } = auth;

  if (!currentUser) {
    alert("ログインしてください");
    return;
  }
  if (currentUser!.uid !== null) {
    setWantState(!wanted);
    if (!wanted) {
      wantPost(post);
      setWantCounter((prev: number) => prev + 1);
    } else {
      unWantPost(post);
      setWantCounter((prev: number) => prev - 1);
    }
  } else {
    alert("ログインしてください");
  }
};

const wantPost = (post: Post) => {
  const uid = auth.currentUser!.uid;

  const batch = writeBatch(db);
  const uidSplit = post.projectPostRef!.split("/");
  const postUserID = uidSplit[1];
  const projectID = uidSplit[3];
  const wantedProjectRef = wantedProjectDocument(uid, projectID);
  batch.set(
    wantedProjectRef,
    {
      projectRef: `users/${postUserID}/projects/${projectID}`,
      author: postUserID,
      pid: projectID,
      createdAt: serverTimestamp(),
    },
    { merge: true }
  );

  const wantedUserRef = doc(
    collection(getDocumentRef(post.projectRef), "wantedUsers"),
    uid
  );

  batch.set(
    wantedUserRef,
    {
      wantedUserUid: uid,
      createdAt: serverTimestamp(),
    },
    { merge: true }
  );

  batch.commit().then(() => {
    const data = {
      post,
      uid,
    };
    projectPostWanted(data)
      .then(() => {
        analyticsCustomLogEvent("want_clicked");
      })
      .catch((err) => {
        logError(err);
      });
  });
};

const unWantPost = (post: Post) => {
  const uid = auth.currentUser!.uid;
  const uidSplit = post.projectPostRef!.split("/");
  const projectID = uidSplit[3];
  const batch = writeBatch(db);

  const wantedUserRef = doc(
    collection(getDocumentRef(post.projectRef), "wantedUsers"),
    uid
  );
  const wantedPostRef = wantedProjectDocument(uid, projectID);

  batch.delete(wantedUserRef);
  batch.delete(wantedPostRef);
  batch
    .commit()
    .then(() => {
      const data = {
        post: post,
      };
      projectPostUnWanted(data)
        .then(() => {
          analyticsCustomLogEvent("unwant_clicked");
        })
        .catch((err) => {
          logError(err);
        });
    })
    .catch((err) => {
      logError(err);
    });
};

export const switchFollow = (
  followed: boolean,
  post: any,
  postsData: any, //storeデータ
  setFollowState: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const newArray = JSON.parse(JSON.stringify(postsData));
    const postID = post.postID;
    const followedUser = newArray.map((p: any) => {
      if (p.postID === postID) {
        p.userFollowed = !followed;
      }
      return p;
    });
    setFollowState(!followed);
    dispatch(fetchPost(followedUser));
    if (!followed) {
      follow(post.userInfo.uid);
    } else {
      unFollow(post.userInfo.uid);
    }
  };
};

export const follow = (followerUID: string, setIsFollowed?: Function) => {
  if (!auth.currentUser) {
    return;
  }

  const uid = auth.currentUser!.uid;
  const batch = writeBatch(db);
  const timeStamp = serverTimestamp();
  const myUserFollowsRef = doc(followsCollection(uid), followerUID);

  followerAddApi({ userID: uid, followID: followerUID })
    .then(() => {})
    .catch(() => {});

  batch.set(
    myUserFollowsRef,
    {
      uid: uid,
      followerUid: followerUID,
      createdAt: timeStamp,
    },
    { merge: true }
  );
  batch.commit().then(() => {});
  setIsFollowed && setIsFollowed(true); //mypageからのフォロー時に使用
};
export const unFollow = (followerUID: string, setIsFollowed?: Function) => {
  if (!auth.currentUser) {
    return;
  }
  const uid = auth.currentUser!.uid;
  const myUserFollowsRef = doc(followsCollection(uid), followerUID);

  deleteDoc(myUserFollowsRef);
  setIsFollowed && setIsFollowed(false); //mypageからのフォロー時に使用
};

export const fetchMentionUser = (
  setUsersList: React.Dispatch<React.SetStateAction<User[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setLoadingOfLoading: React.Dispatch<React.SetStateAction<boolean>>
): AppThunk => {
  return async (_, state): Promise<void> => {
    setLoadingOfLoading(true);
    const userListBox: User[] = [];
    const creatorRef = query(usersCollection(), where("role", "==", "memer"));

    await getDocs(creatorRef).then(async (snapshot) => {
      await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          const newData = {
            photoUrl: data?.photoUrl,
            displayName: data?.displayName,
            uid: data?.uid,
            selected: false,
          };
          userListBox.push(newData);
        })
      );
    });
    setUsersList(userListBox);
    setLoading(false);
  };
};

export const updateMpageUserFollowed = (
  followUid: string,
  setFollowState: Function
) => {
  if (!auth.currentUser) {
    return;
  }
  const uid = auth.currentUser!.uid;
  const followsRef = followsCollection(uid);
  onSnapshot(followsRef, (snapshot) => {
    snapshot.forEach((doc) => {
      if (doc.data().uid === followUid) {
        setFollowState(true);
      }
    });
  });
};

// コメントのお知らせ
const userInfoComment = (post: Post, anotherUid: string) => {
  const userRef = userDocument(post.uid);
  const anotherUserRef = userDocument(anotherUid);
  const userInfoRef = doc(socialInfosCollection(post.uid));

  getDoc(userRef).then((doc) => {
    const data = doc.data();

    if (data && data.followInfo) {
      getDoc(anotherUserRef).then((doc) => {
        const userData = doc.data();
        if (userData) {
          const newInfo = {
            actionUser: {
              uid: userData.uid,
              image: userData.photoUrl,
              displayName: userData.displayName,
            },
            kinds: "comment",
            alreadyRead: false,
            image: post.images[0].path,
            createdAt: timeStamp,
            postRef: post.projectPostRef
              ? post.projectPostRef
              : `${post.author}/posts/${post.postID}`,
            infoRef: userInfoRef.id,
          };
          setDoc(userInfoRef, { ...newInfo });
        }
      });
    }
  });
};

export const userInfoMention = (
  post: any,
  uid: string,
  postRef?: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    post.mentionTo.forEach((m: MentionUserData) => {
      const userRef = userDocument(m.uid);
      const anotherUserRef = userDocument(uid);
      const userInfoRef = doc(socialInfosCollection(m.uid));
      getDoc(userRef).then((doc) => {
        const data = doc.data();
        if (data && data.mentionInfo) {
          getDoc(anotherUserRef).then((doc) => {
            const userData = doc.data();
            if (userData) {
              const newInfo = {
                actionUser: {
                  uid: userData.uid,
                  image: userData.photoUrl,
                  displayName: userData.displayName,
                },
                kinds: "mention",
                alreadyRead: false,
                image: post.images[0].path,
                createdAt: timeStamp,
                postRef: post.projectPostRef ? post.projectPostRef : postRef,
                infoRef: userInfoRef.id,
              };
              setDoc(userInfoRef, { ...newInfo });
            }
          });
        }
      });
    });
  };
};

export const infoAlreadyRead = (info: Info[]): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const infosRef = socialInfosCollection(uid);

    onSnapshot(infosRef, (snapshot) => {
      snapshot.forEach((infoDoc) => {
        const infoData = infoDoc.data();
        const infoRef = doc(infosRef, infoData.infoRef);

        !infoData.alreadyRead &&
          setDoc(infoRef, { alreadyRead: true }, { merge: true });
      });
    });
  };
};
