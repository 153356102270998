import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Backdrop,
  Button,
  Fade,
  MenuItem,
  Modal,
  Select,
  makeStyles,
} from "@material-ui/core";
import { InquiryInput } from "./InquiryInput";
import Typography from "@material-ui/core/Typography";
import InquiryNote from "./InquiryNote";
import { InquiryTextArea } from "./InquiryTextArea";
import { InquiryAttach } from "./InquiryAttach";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { getUserSelector } from "../../../features/userSlice";
import {
  fetchUnpaidReserve,
  sendInquiry,
} from "../../../features/userOperation";
import { BrowserBackHeader } from "../../Header/BrowserBackHeader";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { SpaceBox } from "../../UIKit/SpaceBox";
import { toast } from "react-toastify";
import { GrayButton } from "../../atoms/GrayButton/GrayButton";
import { Center } from "../../atoms/Layout/Center";
import { UnpaidReserve } from "../../../Types/userType";
import { Loading } from "../../atoms/Loading/Loading";
import {
  INQUIRY_KIND,
  InquirySelectValue,
  generateInquiryText,
} from "../../../features/helpers/inquiryHelper";

type FormValues = {
  address: string;
  username: string;
  subject: string;
  inquiry: string;
  inquiryKind: string;
};

export const InquiryForm = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    btn: {
      display: "none",
    },
    textRed: {
      color: "red",
      fontSize: 12,
      marginLeft: 25,
    },
    inputArea: {
      margin: "0 20px",
    },
    title: {
      marginLeft: 5,
      marginBottom: 10,
      display: "block",
      fontSize: 12,
    },
    textArea: {
      width: 417,
      padding: 14,
      resize: "none",
      borderRadius: 5,
      borderColor: "rgb(196,196,196)",
      "@media screen and (max-width: 499px)": {
        width: 292,
      },
    },
    imgArea: {
      height: 506.25,
      width: "100vw",
      display: "block",
      margin: "0 auto",
    },
    attcImg: {
      objectFit: "cover",
      height: 506.25,
      width: "100%",
    },
    inquiryScroll: {
      height: "100%",
      position: "fixed",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "white",
      width: 500,
      margin: "0 auto",
      overflow: "scroll",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    inquiryKind: {
      margin: "0 20px",
    },
    inquiryMenu: {
      width: "100%",
    },
    buttonContainer: {
      margin: "0 20px",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    reserveBox: {
      maxHeight: "calc(100vh - 50px)", // 画面の高さから50pxを引いた値を最大高さとする
      overflowY: "auto",
      width: 498,
      padding: 10,
      border: "1px solid black",
      margin: "0 auto",
      backgroundColor: "white",
      borderRadius: 10,
      "@media screen and (max-width: 499px)": {
        width: 373,
      },
    },
    reserveContainer: {
      cursor: "pointer",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.05)", // Very light gray on hover
      },
    },
    noReserve: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      fontSize: 12,
    },
    unpaidContainer: {
      display: "flex",
      gap: 10,
      marginTop: 10,
    },
    unpaidImage: {
      width: 75,
      height: 100,
      objectFit: "cover",
    },
    image: {
      width: 75,
      height: 100,
      borderRadius: 10,
    },
    unpaidText: {
      fontSize: 12,
    },
    unpaidData: {},
    border: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      margin: "10px auto",
    },
    reserveProductInfoTitle: {
      fontSize: 12,
    },
    modalButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  const user = useSelector(getUserSelector);
  const classes = useStyles();
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const [attachment, setAttachment] = useState("");
  const [image, setImage] = useState({ photoUrl: "" });
  const [openAppSelectBox, setOpenAppSelectBox] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFeting, setIsFeting] = useState(false);
  const [unpaidReserves, setUnpaidReserves] = useState<
    (UnpaidReserve & { projectImage: string })[]
  >([]);
  const [unpaidReserve, setUnpaidReserve] = useState<
    UnpaidReserve & { projectImage: string }
  >();
  const [inquiryTheme, setInquiryTheme] = useState<InquirySelectValue>("app");

  const methods = useForm<FormValues>({
    defaultValues: {
      subject: INQUIRY_KIND[0].value, // Set the default value for subject
      username: user.username,
    },
  });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  const { push } = useHistory();

  const onSubmit = async (data: FormValues) => {
    try {
      await sendInquiry(
        {
          ...data,
          photoUrl: image.photoUrl,
          username: user.username,
        },
        user.uid,
        unpaidReserve?.paymentRef
      );
      push("/inquirydone");
    } catch (error) {
      toast.error("問い合わせの投稿に失敗しました。");
    }
  };

  const btnClick = () => {
    const realBtn = document.getElementById("noneBtn");
    realBtn?.click();
  };

  const openSelectBox = (inquiryKind: string) => {
    if (
      inquiryKind === "coupon" ||
      inquiryKind === "productDeliveryChange" ||
      inquiryKind === "reservationSizeOrColorChange"
    ) {
      setOpenAppSelectBox(true);
      return;
    }
    setOpenAppSelectBox(false);
  };

  const fetchUnpaid = async () => {
    setIsFeting(true);
    const res = await fetchUnpaidReserve(user.uid);
    setUnpaidReserves(res);
    setIsFeting(false);
    setIsOpen(true);
  };

  const selectProduct = (data: UnpaidReserve & { projectImage: string }) => {
    setIsOpen(false);
    setUnpaidReserve(data);
    const enteredText = getValues("inquiry");
    const mergeText = enteredText
      ? `${enteredText}\n\n${generateInquiryText(data, inquiryTheme)}`
      : generateInquiryText(data, inquiryTheme);

    setValue("inquiry", mergeText ?? "");
  };

  return (
    <>
      <FormProvider {...methods}>
        {isFeting && <Loading text="取得中" />}
        <BrowserBackHeader label="お問い合わせ" browserBack={history} />
        <div className={classes.inquiryScroll}>
          <SpaceBox height={80} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {errors.address && (
              <Typography className={classes.textRed}>
                {errors.address.message}
              </Typography>
            )}
            <Controller
              name="address"
              control={control}
              rules={{
                required: "※必須です",
                pattern: {
                  value: regex,
                  message: "メールアドレスの形式で入力してください",
                },
              }}
              render={({ field }) => (
                <InquiryInput label="メールアドレス" field={field} />
              )}
            />
            <InquiryNote
              label={
                "※メールアドレスの入力誤り、メールの設定で受信拒否されてしまいますと、連絡が取れない可能性がありますのでご注意ください。"
              }
            />
            <SpaceBox height={25} />
            <Controller
              name="username"
              control={control}
              render={() => (
                <InquiryInput label="ユーザー名" value={user.username} />
              )}
            />
            <SpaceBox height={25} />
            <div className={classes.inquiryKind}>
              <Typography className={classes.title}>件名</Typography>
              <Controller
                name="subject"
                control={control}
                rules={{
                  required: "※必須です",
                }}
                render={({ field }) => (
                  <Select
                    className={classes.inquiryMenu}
                    {...field}
                    label="問い合わせ内容"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      openSelectBox(e.target.value as InquirySelectValue);
                      setInquiryTheme(e.target.value as InquirySelectValue);
                    }}
                  >
                    {INQUIRY_KIND.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
            <SpaceBox height={25} />
            {openAppSelectBox && (
              <div className={classes.buttonContainer}>
                <SpaceBox height={25} />
                <Button variant="outlined" onClick={() => fetchUnpaid()}>
                  予約した商品を選択する
                </Button>
                <SpaceBox height={25} />
              </div>
            )}
            {errors.inquiry && (
              <Typography className={classes.textRed}>
                {errors.inquiry.message}
              </Typography>
            )}
            <Controller
              name="inquiry"
              control={control}
              rules={{
                required: "※必須です",
              }}
              render={({ field }) => (
                <InquiryTextArea label="お問い合わせ内容" field={field} />
              )}
            />
            <SpaceBox height={30} />
            <InquiryAttach
              label="添付ファイル"
              setAttachment={setAttachment}
              setImage={setImage}
            />
            {attachment && (
              <div className={classes.imgArea}>
                <img
                  src={attachment}
                  className={classes.attcImg}
                  alt="attachment"
                />
              </div>
            )}
            <SpaceBox height={70} />
            <button id="noneBtn" className={classes.btn}>
              submit
            </button>
            <Center>
              <GrayButton onClick={btnClick}>送信</GrayButton>
            </Center>
          </form>
          <SpaceBox height={70} />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={isOpen}>
              <div className={classes.reserveBox}>
                {unpaidReserves.length > 0 ? (
                  <>
                    <Typography
                      align="center"
                      className={classes.reserveProductInfoTitle}
                    >
                      対象の商品を選択してください
                    </Typography>
                    {unpaidReserves.map((reserve) => (
                      <>
                        <div className={classes.border} />
                        <div
                          key={reserve.reserveId}
                          className={classes.reserveContainer}
                          onClick={() => selectProduct(reserve)}
                        >
                          <div className={classes.unpaidContainer}>
                            <div className={classes.unpaidImage}>
                              <img
                                src={reserve.projectImage}
                                alt="projectImage"
                                className={classes.image}
                              />
                            </div>
                            <div className={classes.unpaidData}>
                              <Typography className={classes.unpaidText}>
                                商品名: {reserve.projectName}
                              </Typography>
                              <Typography className={classes.unpaidText}>
                                型番: {reserve.productNumber}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <div className={classes.noReserve}>
                    <Typography
                      align="center"
                      className={classes.reserveProductInfoTitle}
                    >
                      現在変更可能な予約商品がありません
                    </Typography>
                  </div>
                )}
                <div className={classes.border} />

                <div className={classes.modalButtonContainer}>
                  <Button variant="outlined" onClick={() => setIsOpen(false)}>
                    閉じる
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      </FormProvider>
    </>
  );
};
