import { Typography, makeStyles } from "@material-ui/core";
import tomemeLogo from "../../../assets/icon/tomemeLogo.png";
import { User } from "../../../Types/userType";

type Props = {
  mentionTos: User[];
  adminMention: () => {
    displayName: string;
    photoUrl: string;
    selected: boolean;
    uid: string;
    isAdmin: boolean;
  };
  setMentionTos: React.Dispatch<React.SetStateAction<User[]>>;
};

export const AdminMentionTo = (props: Props) => {
  const { mentionTos, adminMention, setMentionTos } = props;
  const useStyles = makeStyles({
    card: {
      height: 70,
      cursor: "pointer",
    },
    userInfoBar: {
      height: 70,
      display: "flex",
    },
    Name: {
      lineHeight: 6,
      fontSize: 12,
    },
    userIcon: {
      width: 120,
      height: 60,
      marginTop: 6,
    },
    iconBox: {
      display: "grid",
      borderRadius: 10,
      width: 58,
      height: 58,
      backgroundColor: "rgb(239,239,239)",
      marginLeft: 40,
      textAlign: "center",
      justifyContent: "center",
      alignContent: "center",
    },
    Icon: {
      width: 54,
      objectFit: "fill",
    },
    checked: {
      backgroundColor: "rgb(210, 210, 210)",
      height: "100%",
    },
    unChecked: {
      height: "100%",
    },
  });
  const classes = useStyles();
  const isSelectAdmin = () => !!mentionTos.find((mention) => mention.isAdmin);

  const setAdmin = () => {
    const adminUser = adminMention();
    setMentionTos((prev) => {
      if (prev.length === 0) {
        return [adminUser];
      }
      const findAdmin = prev.find((v) => v.isAdmin);
      if (!findAdmin) {
        return [...prev, adminUser];
      }
      const exceptAdmin = prev.filter((v) => !v.isAdmin);
      return exceptAdmin;
    });
  };
  return (
    <div className={classes.card}>
      <div className={isSelectAdmin() ? classes.checked : classes.unChecked}>
        <div onClick={() => setAdmin()}>
          <div className={classes.userInfoBar}>
            <div className={classes.userIcon}>
              <div className={classes.iconBox}>
                <img src={tomemeLogo} className={classes.Icon} alt="icon" />
              </div>
            </div>
            <Typography className={classes.Name}>tomeme運営</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
