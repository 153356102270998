import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { selectPost } from "../../features/postSlice";
import { deleteImage } from "../../features/imageOperation";
import { selectProjectPost } from "../../features/projectPostSlice";
import { PostImages } from "../../Types/projectType";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import GridLayout from "react-grid-layout";
import { useWindowDimensions } from "../../hooks/windowDimensions";

export const QuiltedFourImages: React.FC<PostImages> = ({
  images,
  setImages,
  currentWidth,
}) => {
  const postSelector = useSelector(selectPost);
  // 通常ポストのストアに格納された画像
  const postImg: { path: string; id: string }[] = postSelector.images;
  const projectPostSelector = useSelector(selectProjectPost);
  // プロジェクトポストのストアに格納された画像
  const projectPostImg: { path: string; id: string }[] =
    projectPostSelector.images;
  // 長いので比較演算しの時に使うIDを変数に入れてる
  const projectID = projectPostSelector.projectID;
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme: any) => ({
    root: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    leftMainImage: {
      width: 359,
      display: "inline-block",
      "@media screen and (max-width: 499px)": {
        width: 272.8,
      },
    },
    gridListLeft: {},
    imageArea: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
    rightImage: {
      width: 140,
      display: "inline-block",
      float: "right",
      "@media screen and (max-width: 499px)": {
        width: 106.4,
      },
    },
  }));

  const classes = useStyles();
  const { width } = useWindowDimensions();
  return (
    <div className={classes.root}>
      {projectID !== "" ? (
        <>
          <GridLayout
            className="layout"
            isDraggable={false}
            isResizable={false}
            cols={20}
            rowHeight={width > 499 ? 100 : 75}
            width={width > 499 ? 500 : currentWidth}
            margin={[1, 1]}
          >
            <div
              key={0}
              data-grid={{ x: 0, y: 0, w: 14, h: 6, i: "0" }}
              onClick={() =>
                dispatch(deleteImage(projectPostImg[0].id, images, setImages))
              }
            >
              <img
                src={projectPostImg[0].path}
                className={classes.imageArea}
                alt="postImage"
              />
            </div>
            <div
              key={1}
              data-grid={{ x: 14, y: 0, w: 6, h: 2, i: "1" }}
              onClick={() =>
                dispatch(deleteImage(projectPostImg[1].id, images, setImages))
              }
            >
              <img
                src={projectPostImg[1].path}
                className={classes.imageArea}
                alt="postImage"
              />
            </div>
            <div
              key={2}
              data-grid={{ x: 14, y: 1, w: 6, h: 2, i: "2" }}
              onClick={() =>
                dispatch(deleteImage(projectPostImg[2].id, images, setImages))
              }
            >
              <img
                src={projectPostImg[2].path}
                className={classes.imageArea}
                alt="postImage"
              />
            </div>
            {projectPostImg.length > 3 && (
              <div
                key={3}
                data-grid={{ x: 14, y: 2, w: 6, h: 2, i: "3" }}
                onClick={() =>
                  dispatch(deleteImage(projectPostImg[3].id, images, setImages))
                }
              >
                <img
                  src={projectPostImg[3].path}
                  alt="postImage"
                  className={classes.imageArea}
                />
              </div>
            )}
          </GridLayout>
        </>
      ) : (
        <>
          <GridLayout
            className="layout"
            isDraggable={false}
            isResizable={false}
            cols={20}
            rowHeight={width > 499 ? 100 : 75}
            width={width > 499 ? 500 : currentWidth}
            margin={[1, 1]}
          >
            <div
              key={0}
              data-grid={{ x: 0, y: 0, w: 14, h: 6, i: "0" }}
              onClick={() =>
                dispatch(deleteImage(postImg[0].id, images, setImages))
              }
            >
              <img
                src={postImg[0].path}
                className={classes.imageArea}
                alt="postImage"
              />
            </div>
            <div
              key={1}
              data-grid={{ x: 14, y: 0, w: 6, h: 2, i: "1" }}
              onClick={() =>
                dispatch(deleteImage(postImg[1].id, images, setImages))
              }
            >
              <img
                src={postImg[1].path}
                className={classes.imageArea}
                alt="postImage"
              />
            </div>
            <div
              key={2}
              data-grid={{ x: 14, y: 1, w: 6, h: 2, i: "2" }}
              onClick={() =>
                dispatch(deleteImage(postImg[2].id, images, setImages))
              }
            >
              <img
                src={postImg[2].path}
                className={classes.imageArea}
                alt="postImage"
              />
            </div>
            {postImg.length > 3 && (
              <div
                key={3}
                data-grid={{ x: 14, y: 2, w: 6, h: 2, i: "3" }}
                onClick={() =>
                  dispatch(deleteImage(postImg[3].id, images, setImages))
                }
              >
                <img
                  src={postImg[3].path}
                  className={classes.imageArea}
                  alt="postImage"
                />
              </div>
            )}
          </GridLayout>
        </>
      )}
    </div>
  );
};
