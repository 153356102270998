import React, { useCallback, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import styled from "styled-components";

interface PROPS {
  isActive: boolean;
  setActive?: Function;
  text?: string;
}

const StyledCircle = styled(LoadingOverlay)`
  .MyLoader_overlay {
    margin: 0 auto;
    width: 500px;
    height: ${window.innerHeight}px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(145, 145, 145, 0.9);
    z-index: 10;
    @media screen and (max-width: 499px) {
      width: 375px;
    }
  }
  .MyLoader_spinner {
    width: 70px;
  }
  .MyLoader_wrapper {
  }
  .MyLoader_content {
  }
`;
const FullWindowCircleLoading = (props: PROPS) => {
  return (
    <div>
      <StyledCircle
        active={props.isActive}
        spinner
        classNamePrefix="MyLoader_"
        text={props.text}
      ></StyledCircle>
    </div>
  );
};

export default FullWindowCircleLoading;
