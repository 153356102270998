import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
type Props = {
  align?: "inherit" | "left" | "center" | "right" | "justify";
  fontSize?: string | number;
  text: string;
  color?: string;
  className?: object;
  isDark?: boolean;
};

export const Text = (props: Props) => {
  const {
    align = "left",
    fontSize = 12,
    text,
    color = "rgb(144, 144, 144)",
    className,
    isDark,
  } = props;
  const useStyles = makeStyles({
    text: {
      fontSize: fontSize,
      color: isDark ? "rgb(80, 80, 80)" : color,
      whiteSpace: "pre-line", // CSS property to handle \n for line breaks
      ...className,
    },
  });
  const classes = useStyles();
  return (
    <Typography align={align} className={classes.text}>
      {text}
    </Typography>
  );
};
