import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import userReducer from '../features/userSlice'
import postReducer from '../features/postSlice'
import projectPostReducer from '../features/projectPostSlice'
import controlBoolReducer, { selectModal } from '../features/controlBoolSlice'
import productReducer from '../features/productSlice'
import locationReducer from '../features/locationSlice'
import purchaseReducer from '../features/purchaseSlice'
// import productReducer from '../features/productSlice'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const reducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  post: postReducer,
  projectPost: projectPostReducer,
  controlBool: controlBoolReducer,
  product: productReducer,
  location: locationReducer,
  purchase: purchaseReducer,
})

export const store = configureStore({
  reducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(routerMiddleware(history))
  },
})
export type RootState = ReturnType<typeof reducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
//
