import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";
import { postId } from "../../features/postUtil";
import {
  fetchFirstSortPosts,
  fetchSortPosts,
  sortLastIdGet,
} from "../../features/postOperation";
import TopSkeleton from "../TopSkeleton";
import { Post } from "../../Types/userType";
import InfiniteScroll from "react-infinite-scroller";
import { ProjectTitle } from "./ProjectPost/ProjectTitle";
import { MentionPost } from "../../components/UIKit/MentionPost";
import { PostText } from "./PostText";
import { ProjectPostFooter } from "./ProjectPostFooter";
import { ProjectPostStatus } from "./ProjectPost/ProjectPostStatus";
import { PostTime } from "./PostTime";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { UserInfo } from "../../components/Header/UserInfo";
import { PropsSwitchQuiltedImages } from "../../components/PostImage/PropsSwitchQuiltedImages";
import { toast } from "react-toastify";

type Props = {
  uid: string;
};

export const SortPost = (props: Props) => {
  const { uid } = props;
  const useStyles = makeStyles({
    separation: {
      height: 0.5,
      backgroundColor: "rgb(235, 232, 232)",
    },
    statusArea: {
      marginTop: 9,
      marginLeft: -9,
      position: "relative",
    },
    projectNameArea: {
      paddingLeft: 10,
      paddingTop: 15,
    },
    projectNameText: {
      fontWeight: "bold",
      fontSize: 12,
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [postsData, setPostsData] = useState<Post[]>([]);
  const [checkPostId, setCheckPostId] = useState(postId);
  const [lastDate, setLastDate] = useState<Post>();
  const [oldestId, setOldestId] = useState("");
  const [oneLoadState, setOneLoadState] = useState<Number>(0);
  // 読み込んでるPOST
  const inputPostsData = useCallback((post) => {
    setPostsData(post);
  }, []);

  const moreRead = () => {
    if (oldestId !== checkPostId[checkPostId.length - 1]) {
      try {
        dispatch(
          fetchSortPosts(
            lastDate,
            postsData,
            oldestId,
            uid,
            setCheckPostId,
            inputPostsData,
            setLastDate
          )
        );
      } catch (error) {
        toast.error("投稿の取得に失敗しました。\nリロードしてください");
      }
    }
  };
  const loader = (
    <div key={0}>
      <TopSkeleton />
    </div>
  );

  const hasMoreReturn = () => {
    if (postsData.length === 0) {
      return false;
    } else {
      return oldestId !== checkPostId[checkPostId.length - 1];
    }
  };

  useEffect(() => {
    setOneLoadState(1);
    try {
      dispatch(
        fetchFirstSortPosts(uid, setCheckPostId, inputPostsData, setLastDate)
      );
      dispatch(sortLastIdGet(uid, setOldestId));
    } catch (error) {
      toast.error("投稿の取得に失敗しました。\nリロードしてください");
    }
  }, [oneLoadState]);
  return (
    <div>
      <InfiniteScroll
        loadMore={moreRead}
        hasMore={hasMoreReturn()}
        loader={loader}
      >
        {postsData.map((post: Post, idx) => (
          <div key={idx}>
            <UserInfo post={post} posts={postsData} />

            <div className={classes.statusArea}>
              {post.salesStatus && (
                <ProjectPostStatus status={post.salesStatus} />
              )}
              {post.projectRef ? (
                <PropsSwitchQuiltedImages
                  images={post.images}
                  projectRef={post.projectRef}
                  salesStatus={post.salesStatus}
                />
              ) : (
                <PropsSwitchQuiltedImages images={post.images} />
              )}
            </div>
            <SpaceBox height={20} />
            {post.title !== "" && <ProjectTitle title={post.title} />}
            {post.mentionTo.length > 0 && (
              <MentionPost users={post.mentionTo} />
            )}
            <PostText label={post.body ?? ""} />
            <ProjectPostFooter post={post} />
            {post.projectName && (
              <div className={classes.projectNameArea}>
                <Typography className={classes.projectNameText}>
                  プロジェクト名　{post.projectName}
                </Typography>
                <PostText label={post.projectDescription ?? ""} />
              </div>
            )}
            <PostTime seconds={post.createdAt.seconds} post={post} />
            <div className={classes.separation} />
          </div>
        ))}
      </InfiniteScroll>
      <SpaceBox height={50} />
    </div>
  );
};
