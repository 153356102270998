import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../vertical.css";
import "../../../assets/style.css";
import Modal from "react-modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

type Props = {
  images: string[];
};
export const ArrayImages = (props: Props) => {
  const { images } = props;
  const useStyles = makeStyles({
    miniImageArea: {
      marginTop: 8,
    },
    imgMini: {
      width: 80,
      height: 100,
      margin: 1,
      objectFit: "cover",
      cursor: "pointer",
      "@media screen and (max-width: 499px)": {
        width: 60,
        height: 75,
        margin: 1,
      },
    },
    miniArea: {
      width: 80,
      height: 100,
      display: "inline-block",
      margin: 1,
      "@media screen and (max-width: 499px)": {
        width: 60,
        height: 75,
      },
    },
    imageModal: {
      width: 500,
      height: 625,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
        height: 468.75,
      },
      outline: "none",
    },
    modalImage: {
      width: 500,
      height: 625,
      margin: "10px auto 0",
      display: "block",
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
        height: 468.75,
      },
      "&amp;:focus": {
        outline: "none",
      },
      "&:active": {
        outline: "none",
      },
    },
    closeBtn: {
      width: 30,
      height: 30,
      marginTop: 100,
      color: "rgb(151,151,151)",
      cursor: "pointer",
      float: "right",
    },
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  let i: number;
  const clickImage = (index: number) => {
    i = index;
    setOpen(true);
    setImage(images[i]);
  };

  return (
    <div className={classes.miniImageArea}>
      {images.map(
        (img, index: number) =>
          img !== "" && (
            <div key={index} className={classes.miniArea}>
              <img
                src={img}
                className={classes.imgMini}
                onClick={() => {
                  clickImage(index);
                }}
                alt="projectImage"
              />
            </div>
          )
      )}
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        className={classes.imageModal}
        overlayClassName={{
          base: "modalImage-base",
          afterOpen: "modalImage-after",
          beforeClose: "modalImage-before",
        }}
        closeTimeoutMS={300}
      >
        <HighlightOffIcon
          className={classes.closeBtn}
          fontSize="small"
          onClick={handleClose}
        />
        <img
          src={image}
          className={classes.modalImage}
          alt="modalProjectImage"
        />
      </Modal>
    </div>
  );
};
