// done

import "../../vertical.css";
import { makeStyles } from "@material-ui/core";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { SwitchMenu } from "../feature/Menu/SwitchMenu";

const userStyles = makeStyles({
  myPageContainer: {
    width: 500,
    margin: "0 auto",
    "@media screen and (max-width: 499px)": {
      width: "100vw",
    },
  },
  myPageDummyAreaBorder: {
    height: 10,
    borderTop: "0.5px solid rgb(234,234,234)",
  },
  myPageDummyAreaBorder2: {
    height: 300,
    borderTop: "0.5px solid rgb(234,234,234)",
  },
  overlayProfile: {
    margin: "0 auto",
    width: 500,
    backgroundColor: "white",
    "@media screen and (max-width: 499px)": {
      width: "100vw",
    },
  },
  overlay: {
    margin: "0 auto",
    width: 500,
    backgroundColor: "white",
    "@media screen and (max-width: 499px)": {
      width: "100vw",
    },
  },
});

export const Menu = (props: RouteComponentProps) => {
  const { history } = props;
  const classes = userStyles();

  return (
    <div className={classes.myPageContainer} id="MyPageContainer">
      <BrowserBackHeader label="マイページ" browserBack={history} />
      <SwitchMenu />
    </div>
  );
};
