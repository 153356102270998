import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const CreatorListSkeleton = () => {
  const useStyles = makeStyles({
    memer: {
      width: "33.333333%",
    },
    nameSkeleton: {
      margin: "0 auto",
    },
    loadingIcon: {
      borderRadius: 15,
      margin: "0 auto",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.memer}>
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.loadingIcon}
        width={98}
        height={98}
      />

      <Skeleton
        animation="wave"
        height={10}
        width={88}
        style={{ marginTop: 5, marginBottom: 12 }}
        className={classes.nameSkeleton}
      />
    </div>
  );
};
