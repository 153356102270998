import { db, auth, provider, analyticsCustomLogEvent } from "../firebase/index";
import { push } from "connected-react-router";
import { AppThunk } from "../app/store";
import { isValidRequiredInput, isValidEmailFormat } from "../function/common";
import { updateUserProfile } from "./userSlice";
import {
  Inquiry,
  CreatorList,
  SignUpFormValue,
  User,
  UserPrivate,
  UserProfileEditType,
  UnpaidReserve,
} from "../Types/userType";
import { editInfo } from "../features/userSlice";
import {
  initEmailVerified,
  inquiryAdminNotice,
} from "../function/cloudFunctions";
import {
  UserCredential,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  getDocumentRef,
  paymentAuthorizationsCollection,
  projectsCollection,
  unpaidReservesCollection,
  userDocument,
  userPrivate,
  usersCollection,
} from "../firebasePaths";
import { dateOfBirthValidate } from "../lib/userHelper";
import { Dispatch } from "redux";
import { Project } from "../Types/projectType";
import { logError } from "../lib/logError";

const timestamp = serverTimestamp();

export const accountExist = (email: string): AppThunk => {
  return async (dispatch): Promise<void | boolean> => {
    return fetchSignInMethodsForEmail(auth, email).then((result) => {
      if (0 < result.length) {
        // 登録されている
        alert("すでに登録されています");
        return false;
      }
    });
  };
};
export const signUp = (signUpData: SignUpFormValue): AppThunk => {
  return async (dispatch): Promise<void | boolean> => {
    const {
      email,
      password,
      confirmPassword,
      displayName,
      birthDay,
      birthMonth,
      birthYear,
      gender,
    } = signUpData;

    if (!isValidRequiredInput(displayName, email, password, confirmPassword)) {
      alert("必須項目が未入力です。");
      return false;
    }
    if (!isValidEmailFormat(email)) {
      alert("メールアドレスの形式が不正です。もう1度お試しください");
      return false;
    }
    if (password !== confirmPassword) {
      alert("パスワードが一致しません。もう1度お試しください。");
      return false;
    }
    if (password.length < 6) {
      alert("パスワードは6文字以上で入力してください。");
      return false;
    }
    dispatch(accountExist(email));
    return createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        const user = result.user;
        if (user) {
          const uid = user.uid;

          const userInitialData = {
            uid,
            displayName,
            username: displayName,
            role: "customer",
            followCount: 0,
            followerCount: 0,
            postCount: 0,
            updatedAt: timestamp,
            createdAt: timestamp,
            photoUrl:
              "https://firebasestorage.googleapis.com/v0/b/meme-project-f3275.appspot.com/o/null_images%2Fei-person.png?alt=media&token=53a26ce0-1552-4766-823f-dcd26959b558",
            selfIntroduction: "",
            notice: true,
          };

          const dateOfBirth = dateOfBirthValidate(
            String(birthYear),
            String(birthMonth),
            String(birthDay)
          );

          const userPrivateData = {
            email,
            likeInfo: true,
            wantInfo: true,
            followInfo: true,
            mentionInfo: true,
            commentInfo: true,
            dateOfBirth,
            gender,
          };

          const batch = writeBatch(db);
          const docRef = doc(usersCollection(), uid);
          const userPrivateRef = userPrivate(uid);
          batch.set(docRef, userInitialData);
          batch.set(userPrivateRef, userPrivateData);
          batch
            .commit()
            .then(async () => {
              analyticsCustomLogEvent("signup");
              dispatch(push("/signup/confirm"));
              auth.signOut();
            })
            .catch((error) => {
              logError(error);
              throw error;
            });
        }
      })
      .catch((error) => {
        logError(error);
        throw error;
      });
  };
};

export const signIn = (
  email: string,
  password: string,
  redirectTo: string
): AppThunk => {
  return async (dispatch): Promise<string | UserCredential> => {
    if (!isValidRequiredInput(email, password)) {
      return Promise.resolve("メールアドレスかパスワードが未入力です。");
    }
    if (!isValidEmailFormat(email)) {
      return Promise.resolve("メールアドレスの形式が不正です");
    }

    return signInWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        const userState = result.user;
        if (!userState) {
          throw new Error("ユーザーIDを取得できません。");
        }
        if (!userState.emailVerified) {
          return initEmailVerified({ email: email })
            .then(() => {
              auth.signOut();
              return Promise.resolve(
                `認証がされていないため、認証してください。\n登録されたメールアドレス宛に認証用リンクを送信しました。`
              );
            })
            .catch((err: any) => {
              console.error(err);
              throw err;
            });
        } else {
          dispatch(push(redirectTo));
          return Promise.resolve(result);
        }
      })
      .catch((error) => {
        console.error(error);
        return Promise.resolve(
          "メールアドレスもしくはパスワードが正しくありません。"
        );
      });
  };
};
export const logout = (): AppThunk => {
  return async (dispatch): Promise<void> => {};
};

export const signInGoogle = (
  routerHistory: any,
  targetUrl?: string,
  redirectTo?: string
): AppThunk | any => {
  signInWithPopup(auth, provider).then((result) => {
    const user = result.user;
    logError(user);
    if (user) {
      const uid = user.uid;
      const timestamp = serverTimestamp();
      if (user?.metadata.creationTime === user?.metadata.lastSignInTime) {
        const userInitialData = {
          uid: uid,
          username: user.displayName,
          role: "customer",
          followCount: 0,
          followerCount: 0,
          postCount: 0,
          updatedAt: timestamp,
          createdAt: timestamp,
          displayName: user.displayName,
          photoUrl: user.photoURL,
          selfIntroduction: "",
          notice: true,
        };
        const userPrivateData = {
          email: user.email,
          likeInfo: true,
          wantInfo: true,
          followInfo: true,
          mentionInfo: true,
          commentInfo: true,
          gender: "empty",
          dateOfBirth: "",
        };
        const batch = writeBatch(db);
        const docRef = doc(usersCollection(), uid);
        const userPrivateRef = userPrivate(uid);
        batch.set(docRef, userInitialData);
        batch.set(userPrivateRef, userPrivateData);
        batch.commit().then(() => {
          routerHistory.push(targetUrl ? targetUrl : redirectTo ?? "/");
        });
        analyticsCustomLogEvent("sign_up");
      }

      routerHistory.push(targetUrl ? targetUrl : redirectTo ?? "/");
    }
  });
  return async (dispatch: any): Promise<void> => {
    // TODO: ここ書かないとエラーが出るため書いている
    return;
  };
};

// export const signInTwitter = (dispatch: any): AppThunk | any => {
//   // TODO: もっとスマートな書き方があるかもしれないのでその時がきたら直す
//   signInWithPopup(auth, twitterProvider).then(async (result) => {
//     const user = result.user;
//     if (user) {
//       const uid = user.uid;
//       const timestamp = serverTimestamp();
//       const userInitialData = {
//         uid: uid,
//         username: user?.displayName,
//         email: user.email,
//         role: "customer",
//         likeInfo: true,
//         wantInfo: true,
//         followInfo: true,
//         mentionInfo: true,
//         commentInfo: true,
//         notice: true,
//         followCount: 0,
//         followerCount: 0,
//         postCount: 0,
//         updatedAt: timestamp,
//         createdAt: timestamp,
//         displayName: user?.displayName,
//         photoUrl: user.photoURL,
//       };
//       // logError(uid);
//       const docRef = doc(usersCollection(), uid);
//       setDoc(docRef, userInitialData).then(() => {
//         dispatch.push("/");
//       });
//     }

//     await dispatch.push("/");
//   });
//   return async (dispatch: any): Promise<void> => {
//     // TODO: ここ書かないとエラーが出るため書いている
//     return;
//   };
// };

export const setPhotoUrl = (props: any): AppThunk => {
  return async (dispatch): Promise<void> => {
    // 写真未設定の場合この写真が採用される

    const isNullphotoUrl =
      "https://firebasestorage.googleapis.com/v0/b/meme-project-f3275.appspot.com/o/null_images%2Fei-person.png?alt=media&token=53a26ce0-1552-4766-823f-dcd26959b558";
    const photoUrlPath = props.path ? props.path : isNullphotoUrl;
    const uid = auth.currentUser!.uid;
    const updateProfile = { photoUrl: photoUrlPath, update_at: timestamp };

    const docRef = doc(usersCollection(), uid);
    setDoc(docRef, updateProfile, { merge: true })
      .then(() => {
        dispatch(push("/signup/confirmation", props));
      })
      .catch((error: any) => {
        logError(error);
      });
  };
};

export const updateProfile = async (
  data: UserProfileEditType,
  uid: string,
  dispatch: Dispatch<any>
) => {
  const {
    displayName,
    selfIntroduction,
    notice,
    birthDay,
    birthMonth,
    birthYear,
    gender,
  } = data;

  const profileData = {
    username: displayName,
    displayName,
    selfIntroduction: selfIntroduction ?? "",
    notice: notice ?? false,
  };

  const dateOfBirth = dateOfBirthValidate(
    String(birthYear),
    String(birthMonth),
    String(birthDay)
  );

  const privateData = {
    gender,
    dateOfBirth,
  };
  const docRef = doc(usersCollection(), uid);
  const userPrivateRef = userPrivate(uid);
  try {
    await runTransaction(db, async (transaction) => {
      transaction.set(docRef, profileData, { merge: true });
      transaction.set(userPrivateRef, privateData, { merge: true });
    });

    dispatch(
      setUpdateUserData({
        username: displayName,
        displayName,
        selfIntroduction,
        notice,
      })
    );
  } catch (error) {
    throw error;
  }
};

export const setUpdateUserData = (data: {
  selfIntroduction: string;
  displayName: string;
  username: string;
  notice: boolean;
}): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(updateUserProfile(data));
  };
};

export const sendInquiry = async (
  inquiry: Inquiry,
  uid: string,
  connectionRef?: string
) => {
  let nextInquiryNum = 0;
  const inquiryRef = collection(db, "inquiry");
  const inquiryCountRef = query(
    inquiryRef,
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const querySnapshot = await getDocs(inquiryCountRef);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const inquiryLastNumber = data.inquiryNo;
    nextInquiryNum = inquiryLastNumber + 1;
  });
  await setDoc(doc(inquiryRef), {
    ...inquiry,
    uid: uid,
    inquiryNo: nextInquiryNum,
    createdAt: timestamp,
  });
  const inquiryData = {
    ...inquiry,
    uid: uid,
    inquiryNo: nextInquiryNum,
    connectionRef: connectionRef ?? "",
  };
  await inquiryAdminNotice(inquiryData);
};

interface CreatorProfile {
  topProfile: string;
  bottomProfile: string;
}

export const creatorProfileEdit = (
  uid: string,
  data: CreatorProfile
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const usersCollection = collection(db, "users");
    const creatorRef = doc(usersCollection, uid);
    setDoc(creatorRef, data, { merge: true }).then(() => {
      alert("プロフィールを更新しました。");
    });
  };
};

export const infoSetting = (
  followConf: boolean,
  likeConf: boolean,
  wantConf: boolean,
  commentConf: boolean,
  mentionConf: boolean,
  setMessage: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;

    const docRef = userPrivate(uid);
    logError(docRef);
    setDoc(
      docRef,
      {
        followInfo: followConf,
        likeInfo: likeConf,
        wantInfo: wantConf,
        commentInfo: commentConf,
        mentionInfo: mentionConf,
      },
      { merge: true }
    ).then(() => {
      dispatch(
        editInfo({
          followInfo: followConf,
          likeInfo: likeConf,
          wantInfo: wantConf,
          commentInfo: commentConf,
          mentionInfo: mentionConf,
        })
      );
      setMessage("更新しました");
      setTimeout(() => setMessage(""), 3000);
    });
  };
};

export const fetchFollower = (
  setFollowerUserData: Function,
  setLoading: Function,
  setSelectUserData: Function,
  uid: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uidBox: string[] = [];
    const followerRef = collection(doc(db, "users", uid), "followers");
    await getDocs(followerRef)
      .then((snapshot: any) => {
        snapshot.forEach((doc: any) => {
          const data = doc.data();
          uidBox.push(data.followerUid);
        });
      })
      .then(() => {
        uidBox.forEach((ub) => {
          const followerUserRef = doc(db, "users", ub);
          getDoc(followerUserRef).then((user: any) => {
            const userData = user.data();
            const newData = {
              photoUrl: userData.photoUrl,
              displayName: userData.displayName,
              uid: userData.uid,
              createdAt: userData.createdAt,
            };
            setFollowerUserData((prev: CreatorList[]) => [...prev, newData]);
          });
        });
        setLoading(false);
      });

    const userRef = userDocument(uid);
    getDoc(userRef).then((user: { data: () => any }) => {
      const userData = user.data();
      setSelectUserData(userData);
    });
  };
};

export const fetchFollow = (
  setFollowUserData: Function,
  setLoading: Function,
  setSelectUserData: Function,
  uid: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const followRef = collection(doc(db, "users", uid), "follows");
    await getDocs(followRef).then((snapshot) => {
      snapshot.forEach((docSnap) => {
        const data = docSnap.data();
        const followerUserRef = doc(db, "users", data.followerUid);
        getDoc(followerUserRef).then((user) => {
          const userData = user.data() as User;
          const newData = {
            photoUrl: userData.photoUrl,
            displayName: userData.displayName,
            uid: userData.uid,
            createdAt: userData.createdAt,
          };
          setFollowUserData((prev: CreatorList[]) => [...prev, newData]);
        });
      });
    });
    setLoading(false);

    const userRef = userDocument(uid);

    getDoc(userRef).then((user: any) => {
      const userData = user.data();
      setSelectUserData(userData);
    });
  };
};

export const memerReward = (
  startMonth: Date,
  endMonth: Date,
  setTotalSales: React.Dispatch<React.SetStateAction<number>>,
  setReward: React.Dispatch<React.SetStateAction<number>>,
  uid: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const projectRef = projectsCollection(uid);
    getDocs(projectRef).then((snapshot: any) => {
      snapshot.forEach((doc: any) => {
        const reductionRate = doc.data().reductionRate / 100;
        const projectID = doc.id;

        const q = query(
          paymentAuthorizationsCollection(uid, projectID),
          where("createdAt", ">=", startMonth),
          where("createdAt", "<=", endMonth)
        );

        getDocs(q).then((paySnapshot: any) => {
          paySnapshot.forEach((payDoc: any) => {
            const payDocData = payDoc.data();
            const totalSales = payDocData.price * payDocData.quantity;
            const memerReward = Math.round(
              payDocData.price * reductionRate * payDocData.quantity
            );
            setTotalSales((prev: number) => prev + totalSales);
            setReward((prev: number) => prev + memerReward);
          });
        });
      });
    });
  };
};

export const fetchSingleProjectData = (
  setNumberOfSales: Function,
  projectRef: string,
  setDailySalesDetail: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const ref = collection(getDocumentRef(projectRef), "paymentIntents");
    getDocs(ref).then((snapshot: any) => {
      snapshot.forEach((doc: any) => {
        const data = doc.data();
        setNumberOfSales((prev: number) => prev + data.quantity);

        const releaseDate = new Date(data.createdAt.toDate());
        const month = releaseDate.getMonth() + 1;
        const day = releaseDate.getDate();

        const { price } = data.price;
        const totalPrice = price * data.quantity;
        const totalPriceString = totalPrice.toLocaleString();

        const arrayPushData = {
          salesDate: `${month}/${day}`,
          price: totalPriceString,
        };

        setDailySalesDetail((prev: { salesDate: string; price: string }[]) => [
          ...prev,
          arrayPushData,
        ]);
      });
    });
  };
};

export const fetchXAccount = async (
  uid: string,
  setState: React.Dispatch<React.SetStateAction<string>>
) => {
  await getDoc(userDocument(uid)).then((user: { data: () => any }) => {
    const userData = user.data() as User;
    setState(userData.xAccountName ?? "");
  });
};

export const fetchUserPrivate = async (uid: string) => {
  const snapshot = await getDoc(userPrivate(uid));
  const userPrivateData = snapshot.data() as UserPrivate;

  return userPrivateData;
};

export const fetchUnpaidReserve = async (uid: string) => {
  const snapshot = await getDocs(
    query(unpaidReservesCollection(uid), where("isPaid", "==", false))
  );
  const data = snapshot.docs.map((doc) => doc.data() as UnpaidReserve);

  const mergeUnpaidByProjectImage = await Promise.all(
    data.map(async (reserve) => {
      const projectRef = getDocumentRef(reserve.projectRef);
      const projectSnapshot = await getDoc(projectRef);
      const projectData = projectSnapshot.data() as Project;
      return { ...reserve, projectImage: projectData.images[0] };
    })
  );
  return mergeUnpaidByProjectImage;
};
