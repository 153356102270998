import { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";

type Props = {
  price: string;
  quantity: number;
};

export const ReservePrice = (props: Props) => {
  const { price, quantity } = props;
  const useStyles = makeStyles({
    priceBox: {
      display: "flex",
      width: 300,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: 225,
      },
    },
    left: {
      flex: 1,
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    right: {
      flex: 1.5,
      textAlign: "center",
    },
    price: {
      paddingLeft: 10,
      lineHeight: 2.5,
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    number: {
      lineHeight: 2.5,
      cursor: "pointer",
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    total: {
      width: 300,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: 225,
      },
    },
    subTotal: {
      paddingLeft: 10,
      fontSize: 14,
    },
  });

  const [subTotal, setSubTotal] = useState(false);
  const classes = useStyles();
  const switchSubTotal = () => {
    setSubTotal(!subTotal);
  };

  return (
    <>
      <div className={classes.priceBox}>
        <div className={classes.left}>
          <Typography align="center" className={classes.price}>
            {price}
          </Typography>
        </div>
        <div className={classes.right}>
          <Typography
            align="center"
            className={classes.number}
            onClick={() => switchSubTotal()}
          >
            {quantity}点
          </Typography>
          {/* {subTotal ? (
            <Typography
              className={classes.number}
              onClick={() => switchSubTotal()}
            >
              {`${quantity} ▼`}
            </Typography>
          ) : (
            <Typography
              className={classes.number}
              onClick={() => switchSubTotal()}
            >
              {`${quantity} ▲`}
            </Typography>
          )} */}
        </div>
      </div>
    </>
  );
};
