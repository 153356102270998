import { toast } from "react-toastify";

export const imageTypeCheck = (file: File) => {
  const fileType = file.type;
  if (
    fileType !== "image/jpeg" &&
    fileType !== "image/png" &&
    fileType !== "image/webp"
  ) {
    toast.error(
      "受付できない拡張子です。\nJPEG、PNG、および WebP ファイルのみが受け入れられます。"
    );
    throw Error();
  }
};
