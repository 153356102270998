import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const InquiryNote = (props: any) => {
  const useStyles = makeStyles({
    noteArea: {
      margin: '0 20px'
    },
    noteText: {
      color: 'rgb(151,151,151)',
      fontSize: 13
    }
  })
  const classes = useStyles()
  return (
    <div className={classes.noteArea}>
      <Typography className={classes.noteText}>{props.label}</Typography>
    </div>
  )
}

export default InquiryNote
