import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";

interface PROPS {
  leftKind: string;
  detaile: string;
}

const ProductInfo = (props: PROPS) => {
  const useStyles = makeStyles({
    tableText: {
      margin: "0 auto 10px",
      width: "90%",
      borderBottom: "1px solid black",
      display: "flex",
    },
    left: {
      textAlign: "center",
      flex: 1,
    },
    right: {
      textAlign: "center",
      flex: 1,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.tableText}>
      <div className={classes.left}>{props.leftKind}</div>
      <div className={classes.right}>{props.detaile}</div>
    </div>
  );
};

export default ProductInfo;
