import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectUser } from "../../../features/userSlice";
import { auth } from "../../../firebase";
import { MenuButton } from "./MenuButton";
import { FooterButton } from "../../MyPage/FooterButton";
import { UserMyPage } from "./UserMyPage";
import { GrayBack } from "../../MyPage/GrayBack";

export const SwitchMenu = () => {
  const { push } = useHistory();
  const user = useSelector(selectUser);
  const isLogin = auth.currentUser;
  return (
    <div>
      {isLogin && (
        <>
          <UserMyPage />
          <GrayBack label="ユーザー情報" height={29} />
          <MenuButton
            label="プロフィール編集"
            onClick={() => push(`/mypage/${user.uid}/profileedit`)}
          />
          <MenuButton
            label="配送先一覧"
            onClick={() => push("/mypage/delivery-list")}
          />
          <MenuButton
            label="クレジットカード情報"
            onClick={() => push(`/mypage/${user.uid}/creditlist`)}
          />
          <MenuButton
            label="ほしいを押したプロジェクト"
            onClick={() => push(`/wanted-project/${user.uid}`)}
          />
          <MenuButton
            label="商品予約履歴"
            onClick={() => push("/mypage/reservelist")}
          />
          <MenuButton
            label="注文履歴、配送状況確認"
            onClick={() => push("/mypage/history")}
          />
          {/* <MenuButton
            label="アプリ内通知設定"
            onClick={() => push("/info/setting")}
          /> */}
          <MenuButton
            label="パスワード再設定"
            onClick={() => push("/passwordreset")}
          />

          {user.role === "memer" && (
            <>
              <GrayBack label="creator情報" height={29} />
              <MenuButton
                label="creator プロフィール編集"
                onClick={() => push(`/mypage/creator/profile/${user.uid}/edit`)}
              />
              <MenuButton
                label="creator PR PHOTO編集"
                onClick={() =>
                  push(`/mypage/creator/image-layout/${user.uid}/edit`)
                }
              />
              <MenuButton
                label="creator 予約確認(β)"
                onClick={() => push(`/mypage/creator/reserved/${user.uid}`)}
              />
              <MenuButton
                label="creator 売上確認"
                onClick={() =>
                  push(`/mypage/creator/sale-confirmation/${user.uid}`)
                }
              />
            </>
          )}
        </>
      )}

      <GrayBack label="サポート" height={isLogin ? 29 : 60} />
      <a
        href="https://meme-corporate.web.app/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <MenuButton label="はじめての方" />
      </a>
      <a
        href="https://meme-corporate.web.app/news/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <MenuButton label="お知らせ" />
      </a>
      <a
        href="https://meme-corporate.web.app/inquiry/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <MenuButton label="よくある質問" />
      </a>
      <MenuButton label="利用規約" onClick={() => push("/terms/service")} />
      <MenuButton
        label="プライバシーポリシー"
        onClick={() => push("/privacypolicy")}
      />
      <MenuButton
        label="特定商取引法に基づく表記"
        onClick={() => push("/terms")}
      />
      <MenuButton
        label="tomemeクリエイター希望登録"
        onClick={() => push("/creatorhope")}
      />
      {isLogin && (
        <MenuButton label="お問い合わせ" onClick={() => push("/inquiry")} />
      )}
      <GrayBack height={90} />
      <FooterButton />
      <GrayBack height={90} />
    </div>
  );
};
