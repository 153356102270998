import { SalesStatus } from "../../Types/productType";

export const projectRouterChoice = (
  status: SalesStatus | undefined,
  projectRef: string
) => {
  if (!status) return "";
  const projectRefArray = projectRef && projectRef.split("/");
  const projectID = projectRef && projectRefArray[3];
  const memerUID = projectRef && projectRefArray[1];

  const productUrl = `/product/${memerUID}/${projectID}`;
  const postUrl = `/project-post/${memerUID}/${projectID}`;
  if (status.sale) return productUrl;
  if (status.underReservation) return productUrl;
  if (status.inProduction) return productUrl;
  return postUrl;
};

export const isProjectLink = (
  salesStatus?: SalesStatus,
  projectRef?: string
) => {
  if (!salesStatus) return false;
  if (projectRef) {
    if (
      salesStatus.underReservation ||
      salesStatus.sale ||
      salesStatus.production ||
      salesStatus.inProduction
    )
      return true;
    return false;
  }
  return false;
};
