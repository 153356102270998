import React from "react";
import Left from "../../assets/icon/leftArrow.png";
import { Typography, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

type Props = {
  label: string;
  transitionLocation: string;
};

export const Header = (props: Props) => {
  const { label, transitionLocation } = props;
  const userStyles = makeStyles({
    headerLeft: {
      width: 15,
      height: 30,
      marginTop: 12,
      cursor: "pointer",
      outline: "none",
      "@media screen and (max-width: 499px)": {
        marginTop: 8,
        marginLeft: 5,
        width: 12,
        height: 24,
      },
    },
    myPageHeader: {
      zIndex: 20,
      display: "flex",
      borderBottom: "0.5px solid rgba(0,0,0, 0.2)",
      borderWidth: "thin",
      height: 54,
      backgroundColor: "white",
      "@media screen and (max-width: 499px)": {
        height: 38,
      },
    },
    myPageReturn: {
      flex: 1,
    },
    myPageTitle: {
      flex: 3,
      textAlign: "center",
      cursor: "default",
      "& p": {
        fontSize: 20,
        marginBottom: 5,
        marginTop: 10,
        "@media screen and (max-width: 499px)": {
          fontSize: 18,
        },
      },
    },
    myPageDummy: {
      flex: 1,
    },
    headerTitle: {
      flex: 3,
      textAlign: "center",
      cursor: "default",
      fontSize: 18,
      lineHeight: 3.3,
      "@media screen and (max-width: 499px)": {
        lineHeight: 2.8,
        fontSize: 16,
      },
    },
  });
  const classes = userStyles();
  const { push } = useHistory();

  return (
    <div className={classes.myPageHeader}>
      <div className={classes.myPageReturn}>
        <img
          src={Left}
          className={classes.headerLeft}
          onClick={() => push(transitionLocation)}
          alt="leftArrow"
        />
      </div>
      <Typography className={classes.headerTitle}>{label}</Typography>
      <div className={classes.myPageDummy}></div>
    </div>
  );
};
