import { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import { fetchAllReservedData } from "../../../features/creatorOperation";
import { ReservedProductInquiryType } from "../../../Types/creatorType";
import { RouteComponentProps } from "react-router-dom";

const ReservedProductInquiry = (
  props: RouteComponentProps<{ uid: string }>
) => {
  const { history, match } = props;
  const uid = match.params.uid;
  const [data, setData] = useState<ReservedProductInquiryType[]>([]);
  const [projectNames, setProjectNames] = useState<string[]>(["All"]);
  const [selectedProjectName, setSelectedProjectName] = useState<string>("All");
  const [yearMonthOptions, setYearMonthOptions] = useState<string[]>(["All"]);
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>("All");

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    header: {
      backgroundColor: "#f0f0f0",
      color: "#000",
      fontWeight: "bold",
    },
    row: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#fafafa",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#f5f5f5",
      },
    },
    total: {
      fontWeight: "bold",
      backgroundColor: "#ddd",
      "& > td": {
        fontWeight: "bold",
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = (await fetchAllReservedData(
          uid
        )) as ReservedProductInquiryType[];

        const dataWithDates = fetchedData.map((item) => ({
          ...item,
          createdAt: item.createdAt, // TimestampをDateに変換
        }));

        setData(dataWithDates);

        const uniqueProjectNames = [
          "All",
          ...Array.from(new Set(dataWithDates.map((item) => item.projectName))),
        ];
        setProjectNames(uniqueProjectNames);

        const uniqueYearMonths = [
          "All",
          ...Array.from(
            new Set(
              dataWithDates.map((item) => format(item.createdAt, "yyyy-MM"))
            )
          ),
        ];
        setYearMonthOptions(uniqueYearMonths);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, []);

  const filteredAndSortedData = data
    .filter(
      (item) =>
        (selectedProjectName === "All" ||
          item.projectName === selectedProjectName) &&
        (selectedYearMonth === "All" ||
          format(item.createdAt, "yyyy-MM") === selectedYearMonth)
    )
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  const totalSalesPrice = filteredAndSortedData.reduce(
    (sum, item) => sum + item.salesPrice,
    0
  );
  const totalQuantity = filteredAndSortedData.reduce(
    (sum, item) => sum + item.productQuantity,
    0
  );

  return (
    <div>
      <h1>予約済一覧</h1>
      <Select
        value={selectedProjectName}
        onChange={(event) =>
          setSelectedProjectName(event.target.value as string)
        }
      >
        {projectNames.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <Select
        value={selectedYearMonth}
        onChange={(event) => setSelectedYearMonth(event.target.value as string)}
      >
        {yearMonthOptions.map((ym) => (
          <MenuItem key={ym} value={ym}>
            {ym}
          </MenuItem>
        ))}
      </Select>
      {/* <p>予約金額合計: {totalSalesPrice}</p> */}
      <p>予約個数合計: {totalQuantity}</p>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>予約日</TableCell>
            <TableCell className={classes.header}>プロジェクト名</TableCell>
            <TableCell className={classes.header}>プロダクトNo.</TableCell>
            {/* <TableCell className={classes.header}>予約価格</TableCell>
            <TableCell className={classes.header}>オリジナル価格</TableCell> */}
            <TableCell className={classes.header}>カラー</TableCell>
            <TableCell className={classes.header}>サイズ</TableCell>
            <TableCell className={classes.header}>個数</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAndSortedData.map((item, index) => (
            <TableRow key={index} className={classes.row}>
              <TableCell>{format(item.createdAt, "yyyy-MM-dd")}</TableCell>
              <TableCell>{item.projectName}</TableCell>
              <TableCell>{item.productNumber}</TableCell>
              {/* <TableCell>{item.salesPrice}</TableCell>
              <TableCell>{item.originalPrice}</TableCell> */}
              <TableCell>{item.color}</TableCell>
              <TableCell>{item.size}</TableCell>
              <TableCell>{item.productQuantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ReservedProductInquiry;
