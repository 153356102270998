import React from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { deleteImage } from "../../features/imageOperation";
import { selectPost } from "../../features/postSlice";
import { selectProjectPost } from "../../features/projectPostSlice";
import { PostImages } from "../../Types/projectType";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import GridLayout from "react-grid-layout";
import { useWindowDimensions } from "../../hooks/windowDimensions";

export const QuiltedFiveImages: React.FC<PostImages> = ({
  images,
  setImages,
  currentWidth,
}) => {
  const postSelector = useSelector(selectPost);
  // 通常ポストのストアに格納された画像
  const postImg: { path: string; id: string }[] = postSelector.images;
  const projectPostSelector = useSelector(selectProjectPost);
  // プロジェクトポストのストアに格納された画像
  const projectPostImg: { path: string; id: string }[] =
    projectPostSelector.images;
  // 長いので比較演算しの時に使うIDを変数に入れてる
  const projectID = projectPostSelector.projectID;
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme: any) => ({
    root: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
    imageArea: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
    test: {},
  }));

  const classes = useStyles();
  const { width } = useWindowDimensions();
  return (
    <div className={classes.root}>
      <div className={classes.test}>
        {projectID !== "" ? (
          <>
            <GridLayout
              className="layout"
              isDraggable={false}
              isResizable={false}
              cols={20}
              rowHeight={width > 499 ? 100 : 75}
              width={width > 499 ? 500 : currentWidth}
              margin={[1, 1]}
            >
              <div
                key={0}
                data-grid={{ x: 0, y: 0, w: 13, h: 3, i: "0" }}
                onClick={() =>
                  dispatch(deleteImage(projectPostImg[0].id, images, setImages))
                }
              >
                <img
                  src={projectPostImg[0].path}
                  className={classes.imageArea}
                  alt="postImage"
                />
              </div>

              <div
                key={1}
                data-grid={{ x: 0, y: 1, w: 13, h: 3, i: "1" }}
                onClick={() =>
                  dispatch(deleteImage(projectPostImg[1].id, images, setImages))
                }
              >
                <img
                  src={projectPostImg[1].path}
                  className={classes.imageArea}
                  alt="postImage"
                />
              </div>

              <div
                key={2}
                data-grid={{ x: 14, y: 0, w: 7, h: 2, i: "2" }}
                onClick={() =>
                  dispatch(deleteImage(projectPostImg[2].id, images, setImages))
                }
              >
                <img
                  src={projectPostImg[2].path}
                  className={classes.imageArea}
                  alt="postImage"
                />
              </div>

              <div
                key={3}
                data-grid={{ x: 14, y: 1, w: 7, h: 2, i: "3" }}
                onClick={() =>
                  dispatch(deleteImage(projectPostImg[3].id, images, setImages))
                }
              >
                <img
                  src={projectPostImg[3].path}
                  alt="postImage"
                  className={classes.imageArea}
                />
              </div>
              {projectPostImg.length > 4 && (
                <div
                  key={4}
                  data-grid={{ x: 14, y: 2, w: 7, h: 2, i: "4" }}
                  onClick={() =>
                    dispatch(
                      deleteImage(projectPostImg[4].id, images, setImages)
                    )
                  }
                >
                  <img
                    src={projectPostImg[4].path}
                    alt="postImage"
                    className={classes.imageArea}
                  />
                </div>
              )}
            </GridLayout>
          </>
        ) : (
          <>
            <GridLayout
              className="layout"
              isDraggable={false}
              isResizable={false}
              cols={20}
              rowHeight={width > 499 ? 100 : 75}
              width={width > 499 ? 500 : currentWidth}
              margin={[1, 1]}
            >
              <div
                key={0}
                data-grid={{ x: 0, y: 0, w: 13, h: 3, i: "0" }}
                onClick={() =>
                  dispatch(deleteImage(postImg[0].id, images, setImages))
                }
              >
                <img
                  src={postImg[0].path}
                  className={classes.imageArea}
                  alt="postImage"
                />
              </div>
              <div
                key={1}
                data-grid={{ x: 0, y: 1, w: 13, h: 3, i: "1" }}
                onClick={() =>
                  dispatch(deleteImage(postImg[1].id, images, setImages))
                }
              >
                <img
                  src={postImg[1].path}
                  className={classes.imageArea}
                  alt="postImage"
                />
              </div>
              <div
                key={2}
                data-grid={{ x: 14, y: 0, w: 7, h: 2, i: "2" }}
                onClick={() =>
                  dispatch(deleteImage(postImg[2].id, images, setImages))
                }
              >
                <img
                  src={postImg[2].path}
                  className={classes.imageArea}
                  alt="postImage"
                />
              </div>

              <div
                key={3}
                data-grid={{ x: 14, y: 1, w: 7, h: 2, i: "3" }}
                onClick={() =>
                  dispatch(deleteImage(postImg[3].id, images, setImages))
                }
              >
                <img
                  src={postImg[3].path}
                  className={classes.imageArea}
                  alt="postImage"
                />
              </div>
              {postImg.length > 4 && (
                <div
                  key={4}
                  data-grid={{ x: 14, y: 2, w: 7, h: 2, i: "4" }}
                  onClick={() =>
                    dispatch(deleteImage(postImg[4].id, images, setImages))
                  }
                >
                  <img
                    src={postImg[4].path}
                    className={classes.imageArea}
                    alt="postImage"
                  />
                </div>
              )}
            </GridLayout>
          </>
        )}
      </div>
    </div>
  );
};
