import React from "react";
import Left from "../../assets/icon/leftArrow.png";
import { Typography, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { History as BrowserHistory } from "history";
import home from "../../assets/icon/home.png";
import Headroom from "react-headroom";

type Props = {
  label: string;
  browserBack: BrowserHistory;
};

export const BrowserBackHeader = React.memo((props: Props) => {
  const { label, browserBack } = props;

  const userStyles = makeStyles({
    headerLeft: {
      width: 15,
      height: 30,
      marginTop: 12,
      cursor: "pointer",
      outline: "none",
      "@media screen and (max-width: 499px)": {
        marginTop: 8,
        marginLeft: 5,
        width: 12,
        height: 24,
      },
    },
    headerHome: {
      width: 30,
      height: 30,
      cursor: "pointer",
      outline: "none",
      float: "right",
      marginTop: 14,
      marginRight: 10,
      "@media screen and (max-width: 499px)": {
        marginTop: 8,
        width: 25,
        height: 25,
      },
    },
    header: {
      zIndex: 20,
      display: "flex",
      borderBottom: "0.5px solid rgba(0,0,0, 0.2)",
      borderWidth: "thin",
      height: 54,
      backgroundColor: "white",
      "@media screen and (max-width: 499px)": {
        height: 38,
      },
    },
    headerIcon: {
      marginLeft: 10,
      flex: 1,
    },
    headerTitle: {
      flex: 3,
      textAlign: "center",
      cursor: "default",
      fontSize: 14,
      lineHeight: 4.4,
      "@media screen and (max-width: 499px)": {
        lineHeight: 3.6,
        fontSize: 12,
      },
    },
  });

  const classes = userStyles();

  const { push } = useHistory();

  return (
    <Headroom style={{ zIndex: 100, maxWidth: 500, margin: "0 auto" }}>
      <div className={classes.header}>
        <div className={classes.headerIcon}>
          <img
            src={Left}
            className={classes.headerLeft}
            onClick={() => browserBack.goBack()}
            alt="arrow"
          />
        </div>
        <Typography className={classes.headerTitle}>{label}</Typography>
        <div className={classes.headerIcon}>
          <img
            src={home}
            className={classes.headerHome}
            onClick={() => push("/")}
            alt="arrow"
          />
        </div>
      </div>
    </Headroom>
  );
});
