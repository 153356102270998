import { useState } from "react";
import { useDispatch } from "react-redux";
import { Switch, Typography, makeStyles } from "@material-ui/core";
import { GrayBack } from "../MyPage/GrayBack";
import { DeliveryInput } from "./DeliveryInput";
import { CityInput } from "./CityInput";
import { Zipcode } from "./Zipcode";
import { sendAllFalse } from "../../features/addressOperation";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { PrefectureArray } from "../../features/prefectures";
import TownInput from "./TownInput";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { Prefecture } from "../../Types/deliveryType";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Center } from "../atoms/Layout/Center";
import { Text } from "../atoms/Text/Text";
import { toast } from "react-toastify";

export const DeliveryAddressReg = (props: RouteComponentProps) => {
  const { history } = props;

  const salesProject = history.location.state;
  const useStyles = makeStyles({
    checked: {
      width: "100%",
      height: 50,
      backgroundColor: "lightcyan",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    unChecked: {
      width: "100%",
      height: 50,
      backgroundColor: "white",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    container: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": { width: "100vw" },
    },
    checkInput: {
      display: "none",
    },
    buttonArea: {
      height: "100%",
      backgroundColor: "rgb(245,245,245)",
    },
    btn: {
      display: "none",
    },
    prefecturesContainer: {
      display: "flex",
    },
    prefecturesLeft: {
      flex: 6,
      "& p": {
        fontSize: 13,
        margin: "13px 0 8px 5px",
      },
    },
    prefecturesRight: {
      flex: 4,
    },
    labelRed: {
      color: "red",
    },
    prefecturesSelect: {
      width: "100%",
      marginTop: 7,
    },
    selectBox: {
      width: 200,
      height: 45,
      fontSize: 16,
      border: "none",
      borderBottom: "1px solid rgb(151,151,151)",
      "&:disabled": {
        color: "black",
      },
    },
    inputArea: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });
  const classes = useStyles();
  const { push } = useHistory();
  const [mainAddress, setMainAddress] = useState(false);

  // 数字のみの正規表現変数
  const numberRegExp = /^0\d{9,10}$/;

  // カタカナの正規表現
  const katakana = /^[ァ-ヶー　]+$/;

  const switchCheck = () => {
    setMainAddress(!mainAddress);
  };
  const methods = useForm();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      await sendAllFalse({
        lastName: data.lastName,
        firstName: data.firstName,
        lastNameKana: data.lastNameKana,
        firstNameKana: data.firstNameKana,
        zipcode: data.zipcode,
        prefecture: data.pref,
        city: data.city,
        town: data.town,
        address: data.address,
        buildingName: data.buildingName ? data.buildingName : "",
        phoneNumber: data.phoneNumber,
        mainAddress: mainAddress,
      });

      toast.success("住所を保存しました。");
      reset();
    } catch (error) {
      toast.error("住所の保存に失敗しました。\n再度お試しください。");
    }
  };

  const btnClick = () => {
    const realBtn = document.getElementById("noneBtn");
    realBtn?.click();
  };

  const switchText = () => {
    if (mainAddress) return "ON";
    return "OFF";
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className={classes.container}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BrowserBackHeader label="配送先登録" browserBack={history} />
            <GrayBack
              height={40}
              label="<氏名を入力してください>"
              fontSize="12px"
              color="gray"
            />
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: "　入力必須です",
              }}
              render={({ field }) => (
                <DeliveryInput
                  id="inputLastName"
                  infoLabel=" 姓"
                  labelRed="　※必須"
                  place=" 例) 渋谷"
                  field={field}
                  error={errors.lastName}
                />
              )}
            />
            <GrayBack height={3} />
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: "　入力必須です",
              }}
              render={({ field }) => (
                <DeliveryInput
                  id="inputFirstName"
                  infoLabel=" 名"
                  labelRed="　※必須"
                  place=" 例) 渋子"
                  field={field}
                  error={errors.firstName}
                />
              )}
            />
            <GrayBack height={3} />
            <Controller
              name="lastNameKana"
              control={control}
              rules={{
                required: "　入力必須です",
                pattern: {
                  value: katakana,
                  message: "　カタカナで入力してください",
                },
              }}
              render={({ field }) => (
                <DeliveryInput
                  id="inputLastNameKana"
                  infoLabel=" セイ"
                  labelRed="　※必須"
                  place=" 例) シブヤ"
                  field={field}
                  error={errors.lastNameKana}
                />
              )}
            />
            <GrayBack height={3} />
            <Controller
              name="firstNameKana"
              control={control}
              rules={{
                required: "　入力必須です",
                pattern: {
                  value: katakana,
                  message: "　カタカナで入力してください",
                },
              }}
              render={({ field }) => (
                <DeliveryInput
                  id="inputFirstNameKana"
                  infoLabel=" メイ"
                  labelRed="　※必須"
                  place=" 例) シブコ"
                  field={field}
                  error={errors.firstNameKana}
                />
              )}
            />
            <GrayBack
              height={20}
              label="<郵便番号で入力してください>"
              color="gray"
              fontSize="12px"
            />
            <Controller
              name="zipcode"
              control={control}
              rules={{
                required: "　入力必須です",
                minLength: {
                  value: 7,
                  message: "　7文字で入力してください",
                },
                maxLength: {
                  value: 7,
                  message: "　7文字で入力してください",
                },
              }}
              render={({ field }) => (
                <Zipcode
                  id="inputZipcode"
                  infoLabel="郵便番号（ハイフンなし）"
                  labelRed="※必須"
                  place=" 例) 1500001"
                  field={field}
                  error={errors.zipcode}
                  setValue={setValue}
                />
              )}
            />
            <GrayBack
              height={20}
              label="<郵便番号入力で都道府県は自動で入力されます>"
              color="gray"
              fontSize="12px"
            />
            <div className={classes.prefecturesContainer}>
              <div className={classes.prefecturesLeft}>
                <Typography>
                  都道府県
                  <span className={classes.labelRed}>　※必須</span>
                  {errors.pref && (
                    <span className={classes.labelRed}>
                      {errors.pref.message}
                    </span>
                  )}
                </Typography>
              </div>
              <div className={classes.prefecturesRight}>
                <select
                  className={classes.selectBox}
                  disabled
                  id=""
                  {...register("pref", { required: "　必須です。" })}
                >
                  {PrefectureArray.map((pref: Prefecture, index: number) => (
                    <option key={index} value={pref.value}>
                      {pref.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <GrayBack height={3} />
            <Controller
              name={"city"}
              control={control}
              rules={{
                required: "　入力必須です",
              }}
              render={({ field }) => (
                <CityInput
                  id="inputCity"
                  infoLabel=" 市区町村"
                  labelRed="※必須"
                  place=" 例 渋谷区渋谷"
                  field={field}
                  error={errors.city}
                  disabled
                />
              )}
            />
            <Controller
              name={"town"}
              control={control}
              rules={{
                required: "　入力必須です",
              }}
              render={({ field }) => (
                <CityInput
                  id="inputTown"
                  error={errors.town}
                  infoLabel=" 町名"
                  labelRed="※必須"
                  place=" 例 渋谷"
                  field={field}
                  disabled
                />
              )}
            />
            <GrayBack
              height={20}
              label="<番地、建物名、部屋番号を入力してください>"
              color="gray"
              fontSize="12px"
            />
            <Controller
              name={"address"}
              control={control}
              rules={{
                required: "　入力必須です",
              }}
              render={({ field }) => (
                <DeliveryInput
                  id="inputAddress"
                  infoLabel=" 番地"
                  labelRed="　※必須"
                  place=" 例) 1-23-20"
                  field={field}
                  error={errors.address}
                />
              )}
            />
            <GrayBack height={3} />
            <Controller
              name={"buildingName"}
              control={control}
              render={({ field }) => (
                <DeliveryInput
                  infoLabel=" 建物名・部屋番号"
                  place=" 例) 松本ビル601"
                  field={field}
                />
              )}
            />
            <GrayBack
              height={20}
              label="<電話番号を入力してください>"
              color="gray"
              fontSize="12px"
            />
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: "　入力必須です",
                pattern: {
                  value: numberRegExp,
                  message: "　入力に誤りがあります",
                },
                maxLength: {
                  value: 11,
                  message: "　桁が多いです",
                },
                minLength: {
                  value: 10,
                  message: "　桁が足りません",
                },
              }}
              render={({ field }) => (
                <DeliveryInput
                  id="inputPhoneNumber"
                  infoLabel=" 電話番号（ハイフンなし）"
                  labelRed="※必須"
                  place=" 例) 09011112222"
                  field={field}
                  error={errors.phoneNumber}
                />
              )}
            />
            <GrayBack height={60} />
            <div className={classes.inputArea}>
              <Text
                text={`メインの住所に設定する(${switchText()})`}
                className={{
                  TextAlign: "center",
                }}
              />
              <Switch
                onChange={() => {
                  switchCheck();
                }}
                color="primary"
                value={mainAddress}
              />
            </div>
            <GrayBack height={60} />
            <div className={classes.buttonArea}>
              <button id="noneBtn" className={classes.btn}>
                submit
              </button>
              <Center>
                <GrayButton width={250} onClick={() => btnClick()}>
                  登録する
                </GrayButton>
              </Center>
              {salesProject && (
                <Center
                  className={{
                    marginTop: 20,
                  }}
                >
                  <GrayButton
                    width={250}
                    onClick={() => push("/product/reserve", salesProject)}
                  >
                    購入画面に戻る
                  </GrayButton>
                </Center>
              )}
            </div>
            <GrayBack height={150} />
          </form>
        </div>
      </FormProvider>
    </>
  );
};
