import React from "react";
import { useHistory } from "react-router";
import { Typography, makeStyles } from "@material-ui/core";
import CommentIcon from "../../../assets/icon/comment.png";
import { Post } from "../../../Types/userType";

type Props = {
  post: Post;
};

export const Comment = React.memo((props: Props) => {
  const { post } = props;
  const { push } = useHistory();
  const useStyles = makeStyles({
    comment: {
      flex: 1,
    },
    commentArea: {
      margin: "0 auto",
      display: "flex",
      cursor: "pointer",
    },
    commentTextArea: {
      marginLeft: 7,
    },
    commentText: {
      lineHeight: 3.5,
      fontSize: 12,
      marginLeft: 4,
    },
    commentIcon: {
      width: 20,
      height: 20,
      marginTop: 10,
    },
    commentLength: {
      marginLeft: 7,
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.comment}>
      {/* pushの時にpostをpropsで渡す. ローディングした時にpost情報ちゃんと渡るのか確認する */}
      <div
        className={classes.commentArea}
        onClick={() => push("/comment", post)}
      >
        <img src={CommentIcon} className={classes.commentIcon} alt="comment" />
        <div className={classes.commentTextArea}>
          <Typography className={classes.commentText}>コメント</Typography>
        </div>
        {/* <div className={classes.commentLength}>
          <Typography className={classes.commentText}>
            {post.commentCount}
          </Typography>
        </div> */}
      </div>
    </div>
  );
});
