import { AppThunk } from "../app/store";
import { previewImage } from "../features/userSlice";
import { setPhotoUrl } from "./userOperation";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import { Images } from "../Types/imageType";
import { createStorageReference } from "../firebase";
import {
  StorageReference,
  deleteObject,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import {
  DocumentData,
  DocumentReference,
  doc,
  setDoc,
} from "firebase/firestore";
import { usersCollection } from "../firebasePaths";
import { getCroppedImg, urlToBlob } from "./helpers/imageHelper";
import { Area } from "react-easy-crop";
import { logError } from "../lib/logError";

const naming = () => {
  // Generate random 16 digits strings
  const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const N = 16;
  const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N)))
    .map((n) => S[n % S.length])
    .join("");

  return fileName;
};

const uploadFile = async (storageRef: StorageReference, file: File) => {
  try {
    return await uploadBytes(storageRef, file);
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error; // re-throw the error to be handled by the caller
  }
};

const getUploadedFileURL = async (storageRef: StorageReference) => {
  try {
    const downloadURL = await getDownloadURL(storageRef);
    if (!downloadURL) {
      throw new Error("Download URL is undefined");
    }
    return downloadURL;
  } catch (error) {
    console.error("Error getting download URL:", error);
    throw error; // re-throw the error to be handled by the caller
  }
};

export const compressOption = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1024,
};

const handleUpload = async (
  directory: string,
  fileName: string,
  file: File
) => {
  const uploadRef = createStorageReference(directory, fileName);
  try {
    const uploadResult = await uploadFile(uploadRef, file);
    const downloadURL = await getUploadedFileURL(uploadResult.ref);

    return downloadURL;
  } catch (error) {
    console.error("Error handling upload:", error);
  }
};

export const uploadImage = (
  e: React.ChangeEvent<HTMLInputElement>,
  setImage: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const file = e.target.files![0];
    const compressFile = await imageCompression(file, compressOption);
    const name = naming();

    const downloadURL = await handleUpload("images", name, compressFile);
    if (downloadURL) {
      const newImage = { id: name, path: downloadURL };
      const preview = { photoUrl: downloadURL };
      setImage(newImage);
      dispatch(previewImage(preview));
      dispatch(setPhotoUrl(newImage));
    }

    // const uploadRef = createStorageReference("images", name);

    // const downloadURL = (await handleUpload(uploadRef, compressFile)) as string;

    // const newImage = { id: name, path: downloadURL };
    // const preview = { photoUrl: downloadURL };
    // setImage(newImage);
    // dispatch(previewImage(preview));
    // dispatch(setPhotoUrl(newImage));

    // const uploadTask = uploadBytes(uploadRef, compressFile);
    // await uploadTask
    //   .then((): void => {
    //     // Handle successful uploads on complete
    //     uploadTask.snapshot.ref
    //       .getDownloadURL()
    //       .then((downloadURL: string): void => {
    //         const newImage = { id: name, path: downloadURL };
    //         const preview = { photoUrl: downloadURL };
    //         setImage(newImage);
    //         dispatch(previewImage(preview));
    //         dispatch(setPhotoUrl(newImage));
    //       });
  };
};
export const uploadInquiryImage = (
  e: React.ChangeEvent<HTMLInputElement>,
  setImage: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const file = e.target.files![0];
    const compressFile = await imageCompression(file, compressOption);

    const downloadURL = await handleUpload(
      "inquiry_images",
      naming(),
      compressFile
    );

    if (downloadURL) {
      const preview = { photoUrl: downloadURL };
      setImage(preview);
    }
    // const uploadRef = storage.ref("inquiry_images").child(naming());
    // const uploadTask = uploadRef.put(compressFile);
    // await uploadTask
    //   .then((): void => {
    //     // Handle successful uploads on complete
    //     uploadTask.snapshot.ref
    //       .getDownloadURL()
    //       .then((downloadURL: string): void => {
    //         const preview = { photoUrl: downloadURL };
    //         setImage(preview);
    //       });
    //   })
    // .catch((): void => {
    //   // dispatch(hideLoadingAction())
    // });
  };
};

export const uploadMemerLayoutImage = async (
  e: React.ChangeEvent<HTMLInputElement>,
  userRef: DocumentReference,
  index: number,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
  images: string[],
  setImage: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const file = e.target.files![0];
  const compressFile = await imageCompression(file, compressOption);

  const downloadURL = await handleUpload("images", naming(), compressFile);
  if (downloadURL) {
    const newImages = images.map((image, idx) => {
      if (index === idx) return downloadURL;
      return image;
    });
    setImage(newImages);
    setDoc(userRef, { images: newImages }, { merge: true })
      .then(async () => {
        setLoadingState(false);
      })
      .catch((err: Error) => {
        logError(err);
        setLoadingState(false);
      });
  }
};

export const uploadProfileImage = (
  e: React.ChangeEvent<HTMLInputElement>,
  uid: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const file = e.target.files![0];
    if (!file) return;
    const compressFile = await imageCompression(file, compressOption);
    const name = naming();
    setLoading(true);
    const downloadURL = await handleUpload("images", name, compressFile);
    if (downloadURL) {
      const preview = { photoUrl: downloadURL };
      const docRef = doc(usersCollection(), uid);
      await setDoc(docRef, preview, { merge: true });
      dispatch(previewImage(preview));
    } else {
      toast.error("アイコンの設定に失敗しました");
    }
    setLoading(false);

    // const uploadRef = storage.ref("images").child(name);
    // const uploadTask = uploadRef.put(compressFile);
    // uploadTask
    //   .then((): void => {
    //     // Handle successful uploads on complete
    //     uploadTask.snapshot.ref
    //       .getDownloadURL()
    //       .then((downloadURL: string): void => {
    //         const preview = { photoUrl: downloadURL };
    //         dispatch(previewImage(preview));
    //       });
    //   })
    //     .catch((): void => {
    //       toast.error("アイコンの設定に失敗しました");
    //     });
    // };
  };
};

export const threeDImageDetailUpload = (
  e: React.ChangeEvent<HTMLInputElement>,
  setThreeDImages: Function,
  index: number,
  newImages: any,
  length: number,
  setLength: Function,
  setValue: Function // FormにnewImagesをSetするために追記
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const file = e.target.files![0];
    const compressFile = await imageCompression(file, compressOption);

    const downloadURL = await handleUpload(
      "project_images",
      naming(),
      compressFile
    );

    if (downloadURL) {
      let previewImages = [...newImages];
      previewImages[index] = downloadURL;
      setThreeDImages(previewImages);
      setValue("imageDescriptions", previewImages);
      setLength(length + 1);
    }

    // const uploadRef = storage.ref("project_images").child(naming());
    // const uploadTask = uploadRef.put(compressFile);
    // uploadTask
    //   .then((): void => {
    //     uploadTask.snapshot.ref
    //       .getDownloadURL()
    //       .then((downloadURL: string): void => {
    //         let previewImages = [...newImages];
    //         previewImages[index] = downloadURL;
    //         setThreeDImages(previewImages);
    //         setValue("imageDescriptions", previewImages);
    //         setLength(length + 1);
    //       });
    //   })
    //   .catch((): void => {
    //     // dispatch(hideLoadingAction())
    //   });
  };
};

export const measuringImageUpload = (
  e: React.ChangeEvent<HTMLInputElement>,
  setImage: Function,
  length: number,
  setLength: Function,
  setMeasuringImage: Function,
  setValue: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const file = e.target.files![0];
    const compressFile = await imageCompression(file, compressOption);

    const downloadURL = await handleUpload(
      "project_images",
      naming(),
      compressFile
    );
    if (downloadURL) {
      setImage(downloadURL);
      setMeasuringImage(downloadURL);
      setValue("measuringImage", downloadURL);
      setLength(length + 1);
    }

    // const uploadRef = storage.ref("project_images").child(naming());
    // const uploadTask = uploadRef.put(compressFile);
    // uploadTask
    //   .then((): void => {
    //     uploadTask.snapshot.ref
    //       .getDownloadURL()
    //       .then((downloadURL: string): void => {
    //         setImage(downloadURL);
    //         setMeasuringImage(downloadURL);
    //         setValue("measuringImage", downloadURL);
    //         setLength(length + 1);
    //       });
    //   })
    //   .catch((): void => {});
  };
};

export const projectMultipleUploadImage = (
  e: React.ChangeEvent<HTMLInputElement>,
  setPreviewImages: Function,
  index: number,
  newImages: any,
  length: number,
  setLength: Function,
  setValue: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const file = e.target.files![0];
    // const uploadRef = storage.ref("project_images").child(naming());
    const downloadURL = await handleUpload("project_images", naming(), file);

    if (downloadURL) {
      let previewImages = [...newImages];
      previewImages[index] = downloadURL;
      setPreviewImages(previewImages);
      setValue("images", previewImages);
      setLength(length + 1);
    }
    // const uploadTask = uploadRef.put(file);
    // uploadTask
    //   .then((): void => {
    //     // Handle successful uploads on complete
    //     uploadTask.snapshot.ref
    //       .getDownloadURL()
    //       .then((downloadURL: string): void => {
    //         let previewImages = [...newImages];
    //         previewImages[index] = downloadURL;
    //         setPreviewImages(previewImages);
    //         setValue("images", previewImages);
    //         setLength(length + 1);
    //       });
    //   })
    //   .catch((): void => {
    //     // dispatch(hideLoadingAction())
    //   });
  };
};

export const multipleUploadImage = async (
  image: string,
  croppedAreaPixels: Area | undefined,
  setImages: React.Dispatch<React.SetStateAction<Images[]>>,
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!croppedAreaPixels) return;
  const name = naming();
  const croppedImageBlobUrl = await getCroppedImg(image, croppedAreaPixels);
  const croppedImageBlob = await urlToBlob(croppedImageBlobUrl);
  const croppedImageFile = new File([croppedImageBlob], "croppedImage.jpeg", {
    type: "image/jpeg",
  });
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  const compressedFile = await imageCompression(croppedImageFile, options);
  const imageRef = createStorageReference("project_images", name);
  const upLoadTask = uploadBytesResumable(imageRef, compressedFile);

  upLoadTask.on(
    "state_changed",
    (snapshot) => {},
    (error) => {
      setModalIsOpen(false);
    },
    async () => {
      try {
        const downloadURL = await getDownloadURL(imageRef);
        const newImage = { id: name, path: downloadURL };
        setImages((prev: any) => [...prev, newImage]);
        setModalIsOpen(false);
      } catch (error) {
        console.error("Error getting download URL: ", error);
      }
    }
  );
};

export const deleteImage = (
  id: string,
  images: any,
  setImages: any
): AppThunk => {
  return async (dispatch): Promise<void | boolean> => {
    const ret = window.confirm("この画像を削除しますか？");
    if (!ret) {
      return false;
    } else {
      const newImages = images.filter((image: any) => image.id !== id);
      setImages(newImages);
      return deleteObject(createStorageReference("images", id)); // storage.ref("images").child(id).delete();
    }
  };
};

export const imageUpload = async (file: File) => {
  try {
    const downloadURL = await handleUpload("project_images", naming(), file);
    if (downloadURL) {
      return downloadURL;
    }
  } catch (error) {
    logError(error);
  }
};
