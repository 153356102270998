import { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { animateScroll as scroll } from "react-scroll";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { SpaceBox } from "../UIKit/SpaceBox";

export const Terms = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    termsArea: {
      padding: "0 10px",
    },
    termsTitle: {
      margin: "30px auto",
    },
    oneBlock: {
      marginBottom: 30,
    },
    textBold: {
      fontSize: 14,
      fontWeight: "bold",
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    text: {
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    textBorder: {
      borderBottom: "1px solid black",
    },
  });
  const classes = useStyles();

  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 0 });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      {/* <Header label={'特定商取引法に基づく表記'} transitionLocation={'/mypage'} /> */}
      <BrowserBackHeader
        label="特定商取引法に基づく表記"
        browserBack={history}
      />
      <SpaceBox height={45} />
      <div className={classes.termsArea}>
        <div className={classes.termsTitle}>
          <Typography align="center">特定商取引に基づく表記</Typography>
        </div>
        <div className={classes.oneBlock}>
          <Typography className={classes.textBold}>1.事業者の名称:</Typography>
          <Typography className={classes.text}>株式会社bodaijyu</Typography>
          <br />
          <Typography className={classes.textBold}>2.代表者の氏名:</Typography>
          <Typography className={classes.text}>代表取締役 石原雄三</Typography>
          <br />
          <Typography className={classes.textBold}>3.住所:</Typography>
          <Typography className={classes.text}>
            東京都渋谷区渋谷 1-23-20 松本ビル 6 階
          </Typography>
          <br />
          <Typography className={classes.textBold}>4.電話番号:</Typography>
          <Typography className={classes.text}>
            「サポート →
            お問い合わせ」からお問い合わせください。お客様ご指定のメールアドレス
            に当社の電話番号を遅滞なく通知させていただきます。
            <br />
            <br />※ ※
            お取引やサービスについてのお問い合わせは、[下記メールアドレス/「お問い合わせ
            窓口」の問い合わせフォーム]にて受け付けており、電話では受け付けておりません。
          </Typography>
          <br />
          <Typography className={classes.textBold}>
            5.メールアドレス:
          </Typography>
          <Typography className={classes.text}>info@tomeme.jp</Typography>
          <br />
          <Typography className={classes.textBold}>6.サイト URL:</Typography>
          <Typography className={classes.text}>https://tomeme.jp</Typography>
          <br />
          <Typography className={classes.textBold}>
            7.商品の販売価格の対価:
          </Typography>
          <Typography className={classes.text}>
            各商品のご購入ページにて表示する価格
          </Typography>
          <Typography className={classes.textBold}>
            <br />
            8.対価以外に必要となる費用:
          </Typography>
          <Typography className={classes.text}>
            配送料:<span className={classes.textBorder}>全国一律 600 円</span>
            <br />
            ※なお、インターネット接続料金その他の電気通信回線の通信に関する費用はお客様にて
            別途ご負担いただく必要があります(金額は、お客様が契約した各事業者が定めるとおり)。
          </Typography>
          <Typography className={classes.textBold}>
            <br />
            9.支払方法:
          </Typography>
          <Typography className={classes.text}>
            ・ クレジットカード決済 (Visa、Mastercard、American
            Express、Discover、Diners Club、JCB)
          </Typography>
          <br />
          <Typography className={classes.textBold}>
            10.代金の支払時期:
          </Typography>
          <Typography className={classes.text}>
            (1) クレジットカード
            <br />
            ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会
            社までお問い合わせください。
          </Typography>
          <br />
          <Typography className={classes.textBold}>
            11.商品引渡しの時期:
          </Typography>
          <Typography className={classes.text}>
            (1) 通常商品
            <br />
            (a) クレジットカード
            <br />
            クレジットカード利用の承認が下りた後、3~4営業日以内に発送します。
            <br />
            <br />
            お客様の購入申請に対し、当社が承諾の通知を行った後、3~4営業日に商品を発送します。
            <br />
            <br />
            (b) コンビニ決済
            <br />
            代金入金確認次第、３〜４営業日以内に商品を発送します。
            <br />
            (c)代金引換え
            <br />
            お客様の購入申請に対し、当社が承諾の通知を行った後、３〜４営業日に商品を発送します。
            <br />
            <br />
            ※
            ただし、注文の混雑状況や天候等の影響により、3~4営業日以上お待ちいただく場合
            もございます。予めご了承お願いいたします。商品の発送状況はご注文履歴からご確認
            いただけます。
            <br />
            <br />
            (2) 予約商品
            <br />
            各商品ページに記載の発送時期に発送します。
            <br />
            <br />
            ※
            ただし、注文の混雑状況や天候等の影響により、商品のお届けに時間がかかる場合もご
            ざいます。予めご了承お願いいたします。商品の発送状況はご注文履歴からご確認いた
            だけます。
            <br />
            ※
            予約商品の場合、原材料の都合により受注後に生産中止となる場合もございます。予め
            ご了承お願いいたします。
            <br />
          </Typography>
          <br />
          <Typography className={classes.textBold}>
            12.返品・キャンセル等に関する特約:
          </Typography>
          <Typography className={classes.text}>
            本サイトで販売する商品については、購入手続完了後のお客様都合による返品及び返金、交
            換並びにキャンセルを原則としてお受けいたしません。なお、商品に欠陥や不良等がある場
            合は、利用規約の定めに従って対応します。
          </Typography>
        </div>
      </div>
      <SpaceBox height={30} />
    </div>
  );
};
