import { makeStyles, TextField } from "@material-ui/core";
import { SpaceBox } from "../../UIKit/SpaceBox";

interface PROPS {
  notice: string;
  label: string;
  error?: string;
  place?: string;
  displayName?: string;
  field: object;
  id: string;
}

const Input = (props: PROPS) => {
  const useStyles = makeStyles({
    inputContainer: {
      padding: "0 10px 0 10px",
    },
    textInfo: {
      height: 20,
    },
    labelBlack: {
      color: "block",
      fontSize: 12,
    },
    labelRed: {
      color: "red",
      fontSize: 12,
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.inputContainer}>
      <div className={classes.textInfo}>
        <p>
          <span className={classes.labelBlack}>{props.label}</span>
          <span className={classes.labelRed}>※必須</span>
          {props.error && (
            <span className={classes.labelRed}>{props.error}</span>
          )}
        </p>
      </div>
      <SpaceBox height={10} />
      <TextField
        placeholder={props.place}
        defaultValue={props.displayName}
        value={props.displayName}
        fullWidth
        {...props.field}
        id={props.id}
        label={props.label}
      />
    </div>
  );
};

export default Input;
