import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { CreatorList, User } from "../../Types/userType";
import UserInfoMap from "./UserInfoMap";
import { fetchFollow } from "../../features/userOperation";
import { SpaceBox } from "../UIKit/SpaceBox";

export const FollowList = (
  props: RouteComponentProps<{ id: string; uid: string }>
) => {
  const { history, match } = props;

  const dispatch = useDispatch();
  const [followUserData, setFollowUserData] = useState<CreatorList[]>([]);
  const [selectUserData, setSelectUserData] = useState<User>();
  const [loading, setLoading] = useState<boolean>(true); // skeleton
  const uid = match.params.id;

  useEffect(() => {
    dispatch(
      fetchFollow(setFollowUserData, setLoading, setSelectUserData, uid)
    );
  }, []);
  return (
    <div>
      <BrowserBackHeader
        label={`${selectUserData?.displayName} フォローリスト`}
        browserBack={history}
      />
      <UserInfoMap userData={followUserData} loading={loading} />
      <SpaceBox height={20} />
    </div>
  );
};
