import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { makeStyles } from "@material-ui/core";
import { selectUser } from "../../features/userSlice";

type Props = {
  uid: string;
};

export const MyFollowInfo = (props: Props) => {
  const { uid } = props;
  const useStyles = makeStyles({
    followArea: {
      backgroundColor: "white",
      display: "flex",
    },
    followBlock: {
      flex: 1,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgb(234,234,234)",
      },
    },
    postBlock: {
      flex: 1,
      cursor: "pointer",
    },
    number: {
      fontSize: 18,
      color: "rgb(122,122,122)",
      margin: 0,
      textAlign: "center",
      fontWeight: "bold",
      "@media screen and (max-width: 499px)": {
        fontSize: 16,
      },
    },
    text: {
      fontSize: 10,
      color: "rgb(122,122,122)",
      margin: "0 0 10px 0 ",
      textAlign: "center",
    },
  });
  const classes = useStyles();
  const selector = useSelector(selectUser);
  const { push } = useHistory();

  return (
    <div className={classes.followArea}>
      <div className={classes.postBlock}>
        <p className={classes.number}>{selector.postCount}</p>
        <p className={classes.text}>投稿</p>
      </div>
      {/* <div
        className={classes.followBlock}
        onClick={() => push(`/follower/${uid}`)}
      >
        <p className={classes.number}>{selector.followerCount}</p>
        <p className={classes.text}>フォロワー</p>
      </div>
      <div
        className={classes.followBlock}
        onClick={() => push(`/follow/${uid}`)}
      >
        <p className={classes.number}>{selector.followCount}</p>
        <p className={classes.text}>フォロー中</p>
      </div> */}
    </div>
  );
};
