import React, { useState } from "react";
import { selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Comment } from "../../../Types/userType";
import { sendReplayComment } from "../../../function/cloudFunctions";
import { GrayButton } from "../../../components/atoms/GrayButton/GrayButton";
import { LoginInfo } from "../../../components/feature/LoginInfo/LoginInfo";
import { logError } from "../../../lib/logError";

type Props = {
  comment: Comment;
  setCommentLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

type CommentForm = {
  reply: string;
};

export const ReplyCommentField = React.memo((props: Props) => {
  const { comment, setCommentLoading } = props;
  const useStyles = makeStyles({
    textArea: {
      marginTop: 20,
    },
    textInput: {
      padding: "10px 15px",
      width: 400,
      borderRadius: 10,
      border: "1px solid rgb(223, 223, 223)",
      resize: "none",
      color: "rgb(90,90,90)",
      "@media screen and (max-width: 499px)": {
        width: 280,
      },
      marginBottom: 15,
      fontSize: 12,
      "&::placeholder": {
        fontSize: 12,
        color: "gray",
      },
      outline: "none",
    },
    submitArea: {
      width: "100%",
      display: "grid",
      itemAlign: "center",
      justifyItems: "center",
      marginBottom: 15,
    },
    submitBtn: {
      height: 20,
    },
    submitDummyArea: {
      display: "flex",
    },
    dummyArea: {
      width: 80,
    },
    dummyArea2: {
      width: 93,
    },
    btnArea: {
      display: "flex",
    },
    btn: {
      display: "none",
    },
    errorText: {
      fontSize: 12,
      color: "red",
    },
  });

  const classes = useStyles();
  const user = useSelector(selectUser);
  const isLogin = user.isSignedIn;
  const commentUser = {
    displayName: user.displayName,
    uid: user.uid,
    photoUrl: user.photoUrl,
  };

  const [commentActive, setCommentActive] = useState(true);
  const [isShow, setIsShow] = useState(false);

  const defaultValues = {
    reply: "",
  };

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const btnClick = () => {
    const realBtn = document.getElementById("noneBtn");
    realBtn?.click();
  };

  const commentApi = (data: CommentForm) => {
    if (isLogin) {
      setCommentActive(false);
      setCommentLoading(true);
      const replayComment = { comment, reply: data.reply, commentUser };
      sendReplayComment(replayComment)
        .then(() => {
          setCommentLoading(false);
          setCommentActive(true);
          setValue("reply", "");
        })
        .catch((err) => {
          logError(err);
        });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <LoginInfo isShow={isShow} setState={setIsShow} />
        <form onSubmit={handleSubmit(commentApi)}>
          <div className={classes.submitDummyArea}>
            <div className={classes.dummyArea}></div>
            <div className={classes.textArea}>
              {errors.reply && (
                <Typography align="center" className={classes.errorText}>
                  {errors.reply.message}
                </Typography>
              )}
              <Controller
                name={"reply"}
                control={control}
                rules={{
                  required: "0文字でコメントはできません",
                }}
                render={({ field }) => (
                  <TextareaAutosize
                    maxRows={30}
                    className={`${classes.textInput} my-custom-textarea`}
                    placeholder="コメントを返信する"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className={classes.btnArea}>
            <div className={classes.dummyArea2} />
            <div className={classes.submitArea}>
              <GrayButton
                disabled={!commentActive}
                onClick={btnClick}
                width="50%"
              >
                返信する
              </GrayButton>
            </div>
          </div>
          <button id="noneBtn" className={classes.btn}>
            submit
          </button>
        </form>
      </FormProvider>
    </>
  );
});
