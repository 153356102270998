import { AppThunk } from "../app/store";
import { openLoading } from "../features/controlBoolSlice";
import { push } from "connected-react-router";

export const refreshLoading = (path: string, path2?: string): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(openLoading());
    if (path2) {
      dispatch(push(path, path2));
    } else {
      dispatch(push(path));
    }
  };
};
