export const convertPeriodDate = (
  startPeriodDate: string,
  endPeriodDate: string
) => {
  const start = new Date(startPeriodDate);
  const end = new Date(endPeriodDate);
  end.setDate(end.getDate() - 1);
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}年${month}月${day}日`;
  };

  return `${formatDate(start)}〜${formatDate(end)}まで割引中`;
};

export const isWithinPeriod = (
  startDiscountPeriod?: string | Date,
  endDiscountPeriod?: string | Date,
  periodDiscountRate?: number
): boolean => {
  if (!startDiscountPeriod || !endDiscountPeriod || !periodDiscountRate)
    return false;
  const now = new Date();
  const start = new Date(startDiscountPeriod);
  const end = new Date(endDiscountPeriod);

  return now >= start && now <= end;
};

export const calculationPrice = (
  price: string,
  startDiscountPeriod?: string | Date,
  endDiscountPeriod?: string | Date,
  periodDiscountRate?: number
): number => {
  const castPrice = Number(price);
  if (!periodDiscountRate) return castPrice;

  if (
    isWithinPeriod(startDiscountPeriod, endDiscountPeriod, periodDiscountRate)
  ) {
    const discountAmount = Math.ceil(castPrice * (periodDiscountRate / 100));
    const discountedPrice = castPrice - discountAmount;
    return discountedPrice;
  }
  return castPrice;
};
