import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    product: {
      memer: { label: "", value: "" },
      category: { label: "", value: "" },
      colors: { label: "", value: "" },
      detailSize: ["", "", "", "", "", "", ""],
      gender: { label: "", value: "" },
      imageDescriptions: ["", "", "", "", "", "", "", "", ""],
      images: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      imageDescription: "",
      materialAttention: "",
      measuringImage: "",
      orderDeadline: null,
      productNumber: "",
      projectName: "",
      projectRef: "",
      reservationDate: null,
      retailPrice: 0,
      memerComment: "",
      scheduleDate: null,
      size: [{}],
      sizeDataTable: [
        {
          id: 0,
          name: "",
          col_1: 0,
          col_2: 0,
          col_3: 0,
          col_4: 0,
          col_5: 0,
          col_6: 0,
        },
      ],
      threeDimages: {
        XS: [
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
        ],
        S: [
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
        ],
        M: [
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
        ],
        L: [
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
        ],
        XL: [
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
        ],
        XXL: [
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
          { photoUrl: "", length: 0 },
        ],
      },
      toBeShipDate: null,
      numberToShip: 0,
      reservations: 0,
    },
    products: [
      {
        color: "",
        size: "",
        retailPrice: 0,
        productRef: "",
        image: "",
        projectName: "",
        request: 0,
        reserve: 0,
        stock: 0,
        colorIndex: 0,
        sizeIndex: 0,
      },
    ],
    selectSizeColor: {
      size: "",
      color: "",
    },
    choiceProduct: {
      reserveProducts: [
        {
          color: "",
          value: "",
          detail: [{ size: "", ref: "", selectedQuantity: 0 }],
        },
      ],
      image: "",
      projectName: "",
      calculate: 0,
      retailPrice: 0,
      request: 0,
      reserve: 0,
      stock: 0,
      selectedQuantityTotal: 0,
      projectRef: "",
      toBeShipDate: "",
      salesStatus: {
        production: false,
        underReservation: false,
        sale: false,
        endOfSale: false,
        cancel: false,
        inProduction: false,
        official: false,
      },
      itemName: "",
    },
  },
  reducers: {
    setProductStore: (state, action) => {
      state.product = action.payload;
    },
    resetProductStore: (state) => {
      state.product = {
        memer: { label: "", value: "" },
        category: { label: "", value: "" },
        colors: { label: "", value: "" },
        detailSize: ["", "", "", "", "", "", ""],
        gender: { label: "", value: "" },
        imageDescriptions: ["", "", "", "", "", "", "", "", ""],
        images: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        imageDescription: "",
        materialAttention: "",
        measuringImage: "",
        orderDeadline: null,
        productNumber: "",
        projectName: "",
        projectRef: "",
        memerComment: "",
        reservationDate: null,
        retailPrice: 0,
        scheduleDate: null,
        size: [{}],
        sizeDataTable: [
          {
            id: 0,
            name: "",
            col_1: 0,
            col_2: 0,
            col_3: 0,
            col_4: 0,
            col_5: 0,
            col_6: 0,
          },
        ],
        threeDimages: {
          XS: [
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
          ],
          S: [
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
          ],
          M: [
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
          ],
          L: [
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
          ],
          XL: [
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
          ],
          XXL: [
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
            { photoUrl: "", length: 0 },
          ],
        },
        toBeShipDate: null,
        numberToShip: 0,
        reservations: 0,
      };
    },
    setProductsStore: (state, action) => {
      state.products = action.payload;
    },
    resetProductsStore: (state) => {
      state.products = [
        {
          color: "",
          size: "",
          retailPrice: 0,
          productRef: "",
          image: "",
          projectName: "",
          request: 0,
          reserve: 0,
          stock: 0,
          colorIndex: 0,
          sizeIndex: 0,
        },
      ];
    },
    setSelectSizeColorStore: (state, action) => {
      state.selectSizeColor = action.payload;
    },
    resetSelectSizeColorStore: (state) => {
      state.selectSizeColor = {
        size: "",
        color: "",
      };
    },
    setReserveProductStore: (state, action) => {
      state.choiceProduct = action.payload;
    },
    resetChoiceProductStore: (state) => {
      state.choiceProduct = {
        reserveProducts: [
          {
            color: "",
            value: "",
            detail: [{ size: "", ref: "", selectedQuantity: 0 }],
          },
        ],
        calculate: 0,
        retailPrice: 0,
        image: "",
        projectName: "",
        request: 0,
        reserve: 0,
        stock: 0,
        selectedQuantityTotal: 0,
        projectRef: "",
        toBeShipDate: "",
        salesStatus: {
          production: false,
          underReservation: false,
          sale: false,
          endOfSale: false,
          cancel: false,
          inProduction: false,
          official: false,
        },
        itemName: "",
      };
    },
  },
});

export const {
  setProductStore,
  resetProductStore,
  setProductsStore,
  resetProductsStore,
  setReserveProductStore,
  resetChoiceProductStore,
  setSelectSizeColorStore,
  resetSelectSizeColorStore,
} = productSlice.actions;
export const selectProduct = (state: RootState) => state.product.product;
export const selectProducts = (state: RootState) => state.product.products;
export const selectSizeColorProduct = (state: RootState) =>
  state.product.selectSizeColor;
export const selectChoiceProduct = (state: RootState) =>
  state.product.choiceProduct;

export default productSlice.reducer;
