import { Header } from "../../components/Header/Header";
import { Done } from "./Done";

const RequestDone = () => {
  return (
    <div>
      <Header label="ありがとうございます！" transitionLocation="/?" />
      <Done info="リクエストありがとうございます。" linkText="TOPページへ" />
    </div>
  );
};

export default RequestDone;
