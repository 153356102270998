import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import { selectUser } from "../../features/userSlice";
import { UserFollowInfo } from "./UserFollowInfo";
import { UserIcon } from "../UIKit/UserIcon";
import { Name } from "../UIKit/Name";
import { SelfIntroduction } from "./SelfIntroduction";
import { TopFooter } from "../Footer/TopFooter";
import { MyPageFollowBtn } from "../UIKit/MyPageFollowBtn";
import { SortPost } from "../../templates/Post/SortPost";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { UserProfileType } from "../../Types/userType";
import { useEffect, useState } from "react";

type Props = {
  userInfo?: UserProfileType;
  uid?: string;
};

export const UserProfile = (props: Props) => {
  const { userInfo, uid } = props;
  const history = useHistory();
  const myProfileUid = useSelector(selectUser).uid;
  const [followCount, setFollowCount] = useState(userInfo?.followCount ?? 0);
  const [followerCount, setFollowerCount] = useState(
    userInfo?.followerCount ?? 0
  );
  const useStyles = makeStyles({
    container: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    userProfile: {
      marginTop: 30,
      display: "flex",
    },
    userProfileLeft: {
      flex: 1,
    },
    userProfileRight: {
      flex: 2,
    },
  });
  const classes = useStyles();

  useEffect(() => {
    setFollowCount(userInfo?.followCount ?? 0);
    setFollowerCount(userInfo?.followerCount ?? 0);
  }, [userInfo?.followerCount, userInfo?.followCount]);
  return (
    <div className={classes.container}>
      {userInfo && (
        <div>
          <BrowserBackHeader
            label={userInfo.displayName ?? ""}
            browserBack={history}
          />
          <TopFooter effect="mypage" />
          <div className={classes.userProfile}>
            <div className={classes.userProfileLeft}>
              <UserIcon
                photoUrl={userInfo?.photoUrl}
                width="98px"
                height="98px"
                margin="0 0 0 20px"
              />
            </div>
            <div className={classes.userProfileRight}>
              <Name
                displayName={userInfo.displayName}
                margin={"10px 0 10px 0"}
              />
              <UserFollowInfo
                follow={followCount}
                follower={followerCount}
                post={userInfo.postCount}
                uid={userInfo.uid}
              />
            </div>
          </div>

          <SelfIntroduction bottomProfile={userInfo.selfIntroduction} />
          {/* 
          userInfo(今見てるユーザーページのユーザーのuid)をMyPageFollowBtnに渡す
          */}
          {/* {myProfileUid !== userInfo.uid && (
            <MyPageFollowBtn
              anotherUser={userInfo.uid}
              uid={uid}
              setFollowerCount={setFollowerCount}
            />
          )} */}
          <SortPost uid={userInfo.uid} />
        </div>
      )}
    </div>
  );
};
