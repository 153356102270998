import React from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import { Text } from "../../components/atoms/Text/Text";

type Props = {
  info: string;
  linkText: string;
};

export const Done = (props: Props) => {
  const { info, linkText } = props;
  const { push } = useHistory();
  const useStyles = makeStyles({
    doneArea: {
      padding: "80px 0",
    },
    info: {
      height: 200,
      "& p": {
        display: "block",
        margin: "40px auto 0",
        textAlign: "center",
      },
    },
    link: {
      color: "rgb(63,101,227)",
      cursor: "pointer",
      fontSize: 16,
      textAlign: "center",
      marginTop: 0,
    },
  });

  const classes = useStyles();

  const infoText = info
    .split(/(\n)/)
    .map((item, index) => (
      <React.Fragment key={index}>
        {item.match(/\n/) ? <br /> : <Text text={item} fontSize={14} isDark />}
      </React.Fragment>
    ));

  return (
    <div className={classes.doneArea}>
      <div className={classes.info}>{infoText}</div>
      <p className={classes.link} onClick={() => push("/?")}>
        {linkText}
      </p>
    </div>
  );
};
