import { makeStyles, Typography } from "@material-ui/core";
import { SpaceBox } from "../../../components/UIKit/SpaceBox";

export const ReserveSend = (props: any) => {
  const { shipSeason } = props;
  const useStyles = makeStyles({
    shipInfo: {
      marginTop: 50,
    },
    sendSchedule: {
      fontSize: 14,
      fontWeight: "bold",
      color: "rgb(95,95,95)",
    },
    sendDate: {
      marginTop: 10,
      fontSize: 20,
      fontWeight: "bold",
      color: "rgb(95,95,95)",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.shipInfo}>
      <div>
        <Typography align="center" className={classes.sendSchedule}>
          発送予定日
        </Typography>
      </div>
      <div>
        <Typography align="center" className={classes.sendDate}>
          {shipSeason}
        </Typography>
      </div>
      <SpaceBox height={20} />
    </div>
  );
};
