import { RouteComponentProps } from "react-router-dom";
import { ProjectMap } from "./ProjectMap";
import { BrowserBackHeader } from "../../../components/Header/BrowserBackHeader";

export const ProjectsCard = (props: RouteComponentProps<{ id: string }>) => {
  const { match, history } = props;
  const uid = match.params.id;
  const status = "newPost";

  return (
    <div>
      <BrowserBackHeader label="プロジェクト選択" browserBack={history} />
      <ProjectMap uid={uid} status={status} />
    </div>
  );
};
