import { Typography, makeStyles, Grid } from "@material-ui/core";
import { LatestColorSalesInfo, SalesProject } from "../../../Types/productType";

type Props = {
  salesDetailData: LatestColorSalesInfo[];
  setSalesDetailData: Function;
  project: SalesProject;
};

export const ProductColorSize = (props: Props) => {
  const { salesDetailData, setSalesDetailData, project } = props;
  const useStyles = makeStyles({
    itemBox: {
      marginTop: 40,
    },
    detailBox: {
      display: "flex",
      margin: "10px 0",
    },
    colorCircleBox: {
      display: "flex", // flexに変更
      alignItems: "center", // 子要素を垂直方向に中央揃え
      marginRight: 10, // 右マージンを追加
    },
    colorCircle: {
      width: 15,
      height: 15,
      borderRadius: "100%",
    },
    colorText: {
      display: "flex", // 追加
      alignItems: "center", // 追加
      flex: 1.5,
      textAlign: "center",
      color: "gray",
    },
    sizeText: {
      flex: 1,
      color: "gray",
    },
    itemInfoText: {
      margin: "auto", // 追加
      fontSize: 14,
      textAlign: "center",
      justifyContent: "center",
    },
    info: {
      textAlign: "center",
      fontSize: 14,
      color: "gray",
    },
    grid: {
      padding: "0 30px",
      marginBottom: 10,
    },
    quantityArea: {
      flex: 1,
      textAlign: "center",
      cursor: "default",
    },
    arrow: {
      cursor: "pointer",
      color: "gray",
    },
    quantity: {
      color: "gray",
    },
    reserveEndArea: {
      cursor: "default",
      flex: 1,
      backgroundColor: "rgb(104, 104, 104)",
    },
    reserveEndText: {
      fontSize: 12,
      color: "white",
      lineHeight: 2,
    },
    remainingInfo: {
      color: "gray",
      fontSize: 12,
      margin: "5px",
    },
    remainingQuantity: {
      fontSize: 18,
      fontWeight: 100,
    },
  });

  const { sale, inProduction, endOfSale } = project.salesStatus;

  const limitCheck = (
    quantity: number,
    reserve: number,
    limit: number,
    selectedQuantity: number,
    count: number
  ) => {
    if (count === -1) {
      if (quantity === 0) return false;
      if (selectedQuantity === 0) return false;
      return true;
    }

    const reserveTotal =
      project.salesStatus.inProduction || project.salesStatus.sale
        ? selectedQuantity + reserve
        : quantity + reserve;
    if (reserveTotal === limit) return false;

    return true;
  };

  const productSelectedCount = (
    data: LatestColorSalesInfo[],
    colorIndex: number,
    detailIndex: number,
    count: number
  ) => {
    const newData = [...data];

    const nowQuantity = newData[colorIndex].nowQuantity;
    const reserve =
      project.salesStatus.inProduction || project.salesStatus.sale
        ? newData[colorIndex].detail[detailIndex].targetReserve
        : newData[colorIndex].reserve;
    const limit = newData[colorIndex].limit;
    const selectedQuantity =
      newData[colorIndex].detail[detailIndex].selectedQuantity;
    const check = limitCheck(
      nowQuantity,
      reserve,
      project.salesStatus.inProduction || project.salesStatus.sale
        ? newData[colorIndex].detail[detailIndex].reserveLimit
        : limit,
      selectedQuantity,
      count
    );
    if (!check) return;

    newData[colorIndex].nowQuantity += count;
    newData[colorIndex].detail[detailIndex].selectedQuantity += count;
    setSalesDetailData(newData);
  };

  const remainingCalc = (productData: LatestColorSalesInfo) => {
    const remainingQuantity = productData.limit - productData.reserve;
    return remainingQuantity;
  };

  const statusInProductionRemainingCalc = (detail: {
    size: string;
    stock: number;
    ref: string;
    selectedQuantity: number;
    reserveLimit: number;
    targetReserve: number;
  }) => {
    const remainingQuantity = detail.reserveLimit - detail.targetReserve;
    return remainingQuantity;
  };
  const classes = useStyles();

  return (
    <div className={classes.itemBox}>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={4}>
          <div className={classes.info}>カラー</div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.info}>サイズ</div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.info}>数量</div>
        </Grid>
      </Grid>
      {salesDetailData.map((pcsData, index) => (
        <div key={`${pcsData.color}${pcsData.value}`}>
          {!(inProduction || sale || endOfSale) &&
            remainingCalc(pcsData) < 11 && (
              <Typography align="right" className={classes.remainingInfo}>
                {`${pcsData.color} 予約可能枚数 残り `}
                <span className={classes.remainingQuantity}>
                  {` ${remainingCalc(pcsData)} `}
                </span>
                {`  着`}
              </Typography>
            )}
          {pcsData.detail.map((item, idx) => {
            return (
              <div key={`${pcsData.color}${pcsData.value}`}>
                {(project.salesStatus.inProduction ||
                  project.salesStatus.sale) &&
                  statusInProductionRemainingCalc(item) > 0 && (
                    <Typography align="right" className={classes.remainingInfo}>
                      {`${pcsData.color} 予約可能枚数 残り `}
                      <span className={classes.remainingQuantity}>
                        {` ${statusInProductionRemainingCalc(item)} `}
                      </span>
                      {`  着`}
                    </Typography>
                  )}
                <Grid
                  container
                  spacing={1}
                  className={classes.grid}
                  key={`${item.ref}`}
                >
                  <div className={classes.detailBox} key={`${item.ref}`}></div>
                  <Grid item xs={4}>
                    <div className={classes.colorCircleBox}>
                      <div
                        className={classes.colorCircle}
                        style={
                          pcsData.value === "#FFFFFF"
                            ? {
                                background: `${pcsData.value}`,
                                border: "1px solid black",
                              }
                            : { background: `${pcsData.value}` }
                        }
                      />
                      <div className={classes.colorText}>
                        <Typography className={classes.itemInfoText}>
                          {pcsData.color}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.sizeText}>
                      <Typography className={classes.itemInfoText}>
                        {item.size}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    {((inProduction || sale || endOfSale) &&
                      item.targetReserve === item.reserveLimit) ||
                    pcsData.reserve === pcsData.limit ? (
                      <div className={classes.reserveEndArea}>
                        <Typography
                          align="center"
                          className={classes.reserveEndText}
                        >
                          {endOfSale ? "SOLD OUT" : "予約受付終了"}
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.quantityArea}>
                        <span
                          className={classes.arrow}
                          onClick={() =>
                            productSelectedCount(
                              salesDetailData,
                              index,
                              idx,
                              -1
                            )
                          }
                        >
                          ◁　
                        </span>
                        <span className={classes.quantity}>
                          {item.selectedQuantity}
                        </span>
                        <span
                          className={classes.arrow}
                          onClick={() =>
                            productSelectedCount(salesDetailData, index, idx, 1)
                          }
                        >
                          　▷
                        </span>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
