import { WantUserInfo } from "../../components/Header/WantUserInfo";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { PostText } from "../Post/PostText";
import GridLayout from "react-grid-layout";
import { ProjectPostStatus } from "../Post/ProjectPost/ProjectPostStatus";
import { GrayBack } from "../../components/MyPage/GrayBack";
import { Text } from "../../components/atoms/Text/Text";
import { useEffect, useState } from "react";
import { fetchWantedProjects } from "../../features/projectPostOperation";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";

type WantedProject =
  | {
      creatorUid: string;
      icon: string;
      displayName: string;
      thumbnailImage: string | undefined;
      salesStatus:
        | {
            production: boolean;
            underReservation: boolean;
            sale: boolean;
            endOfSale: boolean;
          }
        | undefined;
      projectName: string | undefined;
      itemDescription: string | undefined;
      projectId: string;
    }
  | undefined;

export const WantProjects = () => {
  const { width } = useWindowSize();
  const useStyles = makeStyles((theme) => ({
    projectNameArea: {
      paddingLeft: 10,
      paddingTop: 15,
    },
    projectNameText: {
      fontWeight: "bold",
      fontSize: 12,
    },
    image: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
    imageArea: {
      margin: "0 auto 10px",
    },
    wantProjectArea: {
      marginTop: 0,
    },
    projectArea: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgb(240, 240, 240)",
      },
    },
    statusArea: {
      marginTop: 9,
      marginLeft: -9,
      position: "relative",
    },
  }));
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [wantedProjects, setWantedProjects] = useState<WantedProject[]>([]);

  const fetchData = async (uid: string) => {
    const res = await fetchWantedProjects(uid);
    setWantedProjects(res);
  };

  useEffect(() => {
    fetchData(user.uid);
  }, [user.uid]);

  return (
    <div className={classes.wantProjectArea}>
      {wantedProjects.map((project) => (
        <Link
          href={`/project-post/${project?.creatorUid}/${project?.projectId}`}
          to={`/project-post/${project?.creatorUid}/${project?.projectId}`}
          style={{
            textDecoration: "none",
            color: "black",
          }}
        >
          <div className={classes.projectArea}>
            <WantUserInfo
              uid={user.uid}
              creatorDisplayName={project?.displayName ?? ""}
              creatorPhotoUrl={project?.icon ?? ""}
              creatorUid={project?.creatorUid ?? ""}
            />
            <div className={classes.projectNameArea}>
              <Typography className={classes.projectNameText}>
                {`${project?.projectName}`}
              </Typography>
              <PostText label={project?.itemDescription ?? ""} />
            </div>
            <div className={classes.statusArea}>
              <ProjectPostStatus status={project?.salesStatus!} />
            </div>
            <div className={classes.imageArea}>
              <GridLayout
                className="layout"
                isDraggable={false}
                isResizable={false}
                rowHeight={width > 499 ? 100 : 75}
                width={width > 499 ? 500 : width}
                margin={[1, 1]}
              >
                <div
                  key={0}
                  data-grid={{
                    x: 0,
                    y: 0,
                    w: 20,
                    h: 6,
                    i: "0",
                  }}
                >
                  <img
                    src={project?.thumbnailImage}
                    className={classes.image}
                    alt="postImage"
                  />
                </div>
              </GridLayout>
            </div>
            <GrayBack height={1} isDark />
          </div>
        </Link>
      ))}
    </div>
  );
};
