import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";
import LikeIcon from "../../../assets/icon/likeYesPush.png";
import UnLikeIcon from "../../../assets/icon/likeNoPush.png";
import { switchLiked } from "../../../features/socialOperation";
import { Post } from "../../../Types/userType";
import { db } from "../../../firebase";
import * as Path from "../../../refPath";
import { selectUser } from "../../../features/userSlice";
import { LoginInfo } from "../../../components/feature/LoginInfo/LoginInfo";
import { logError } from "../../../lib/logError";
import { collection, getDocs } from "firebase/firestore";
import { getDocumentRef, likedUsersCollection } from "../../../firebasePaths";

type Props = {
  post: Post;
};

export const Like = (props: Props) => {
  const { post } = props;
  const useStyles = makeStyles({
    like: {
      flex: 1,
    },
    likeArea: {
      cursor: "pointer",
      margin: "0 auto",
      display: "flex",
    },
    likeIcon: {
      width: 20,
      height: 20,
      marginTop: 11,
    },
    likeTextAra: {
      marginLeft: 7,
    },
    likeText: {
      lineHeight: 3.5,
      fontSize: 12,
      marginLeft: 4,
    },
    likeLength: {
      marginLeft: 7,
    },
  });
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [likeCounter, setLikeCounter] = useState(post.likeCount);
  const [show, setShow] = useState(false);
  const inputLikeCounter = useCallback(
    (counter: number) => {
      setLikeCounter(counter);
    },
    [setLikeCounter]
  );

  const [likeState, setLikeState] = useState(false);

  const inputLikeState = useCallback(
    (liked: boolean) => {
      setLikeState(liked);
    },
    [setLikeState]
  );

  const fetchLike = async (postData: Post) => {
    const { uid } = user;
    if (postData.projectPostRef) {
      const likedUsersRef = collection(
        getDocumentRef(postData.projectPostRef),
        "likedUsers"
      );
      await getDocs(likedUsersRef).then((snapshot) => {
        snapshot.forEach((doc) => {
          inputLikeCounter(postData.likeCount);
          if (doc.data().id === uid) {
            inputLikeState(true);
          }
        });
      });
    } else if (!postData.projectPostRef && postData.postID) {
      const likedUsersRef = likedUsersCollection(postData.uid, postData.postID);
      await getDocs(likedUsersRef).then((snapshot) => {
        snapshot.forEach((doc) => {
          inputLikeCounter(postData.likeCount);
          if (doc.data().id === uid) {
            inputLikeState(true);
          }
        });
      });
    }
  };

  const like = () => {
    if (user.isSignedIn) {
      try {
        switchLiked(likeState, post, inputLikeCounter, inputLikeState);
      } catch (error) {
        logError(error);
      }
      return;
    }
    setShow(true);
  };

  useEffect(() => {
    fetchLike(post);
  }, [post]);

  return (
    <div className={classes.like}>
      <div className={classes.likeArea}>
        <img
          alt="like"
          src={likeState ? LikeIcon : UnLikeIcon}
          className={classes.likeIcon}
          onClick={() => like()}
        />
        <div className={classes.likeText}>
          <Typography className={classes.likeText}>いいね！</Typography>
        </div>
        {/* <div className={classes.likeLength}>
          <Typography className={classes.likeText}>{likeCounter}</Typography>
        </div> */}
      </div>
      <LoginInfo isShow={show} setState={setShow} />
    </div>
  );
};
