import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

export const ProductSkeleton = () => {
  const useStyles = makeStyles({
    productCard: {
      display: "flex",
      flexWrap: "wrap",
    },
    product: {
      paddingTop: 20,
      width: "50%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgb(230,230,230)",
        transition: "all 0.3s",
      },
    },
    img: {
      width: 230,
      height: 287.5,
      margin: "0 auto",
      display: "block",
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: 172.5,
        height: 215.625,
      },
    },
    colorArea: {
      width: 120,
      margin: "0 auto",
      marginTop: 20,
      marginBottom: 5,
      height: 30,
      display: "flex",
    },
    colorBox: {
      width: 20,
      height: 20,
      margin: "2px auto",
    },
    memerName: {
      height: 30,
    },
    productName: {
      height: 50,
    },
    infoText: {
      marginLeft: 10,
    },
    statusArea: {
      position: "relative",
    },
    skeletonPrice: {},
    skeletonBorder: {},
  });

  const skeletonArray = [1, 2, 3, 4];
  const classes = useStyles();
  return (
    <div className={classes.productCard}>
      {skeletonArray.map((sa: number) => (
        <div key={sa} className={classes.product}>
          <div className={classes.img}>
            <Skeleton
              animation="wave"
              variant="rect"
              width="100%"
              height="100%"
            />
          </div>
          <div className={classes.colorArea}>
            <div className={classes.colorBox}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={20}
                height={20}
              />
            </div>
          </div>
          <div className={classes.memerName}>
            <Skeleton animation="wave" variant="rect" width={70} height={20} />
          </div>
          <div className={classes.productName}>
            <Skeleton animation="wave" variant="rect" width={70} height={20} />
          </div>
          <div className={classes.skeletonPrice}>
            <Skeleton
              animation="wave"
              height={30}
              width={100}
              style={{ margin: "0 auto 10px" }}
            />
          </div>
          <div className={classes.skeletonBorder}>
            <Skeleton
              animation="wave"
              height={1}
              width={180}
              style={{ margin: "0 auto 30px" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
