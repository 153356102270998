import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, selectUser } from "./features/userSlice";
import { resetStripeCustomer } from "./features/purchaseSlice";
import { analytics, auth } from "./firebase";
import { RouteProps } from "react-router-dom";
import { creditIDSetStore } from "./features/productOperation";
import { onAuthStateChanged } from "firebase/auth";
import { userDocument } from "./firebasePaths";
import { getDoc } from "firebase/firestore";
import { setUserId } from "firebase/analytics";

export const Auth = ({ children }: RouteProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (authUser) => {
      // メール認証済チェック
      if (authUser) {
        const usersRef = userDocument(authUser.uid);
        setUserId(analytics, authUser.uid);
        getDoc(usersRef).then((snapshot: { data: () => any }) => {
          const data = snapshot.data();
          dispatch(
            // TODO: storeに格納するパラメーターを再定義する
            login({
              uid: authUser.uid,
              displayName: data.displayName ? data.displayName : data.username,
              username: data.username,
              role: data.role,
              isSignedIn: true,
              photoUrl: data.photoUrl,
              notice: data.notice,
              selfIntroduction: data.selfIntroduction,
              twitterAccount: data.twitterAccount,
              tiktokAccount: data.tiktokAccount,
              instagramAccount: data.instagramAccount,
              followCount: data.followCount,
              followerCount: data.followerCount,
              postCount: data.postCount,
              followInfo: data.followInfo,
              likeInfo: data.likeInfo,
              wantInfo: data.wantInfo,
              commentInfo: data.commentInfo,
              mentionInfo: data.mentionInfo,
            })
          );
          dispatch(creditIDSetStore());
        });
      } else {
        dispatch(logout());
        dispatch(resetStripeCustomer({ customerID: "" }));
      }
    });
    return () => {
      unSub();
    };
  }, []);

  return <>{children}</>;
};
