export const product = [
  {
    productRef: "",
    size: "",
    colorIndex: 0,
    reserve: 0,
    sizeIndex: 0,
    retailPrice: "",
    image: "",
    stock: NaN,
    color: "",
    request: 0,
    projectRef: "",
  },
];

export const reservation = {
  numberToShip: 0,
  reservations: 0,
};

export const projectData = {
  memer: { label: "", value: "" },
  category: { label: "", value: "" },
  colors: [{ label: "", value: "", limit: 0 }],
  detailSize: [""],
  gender: { label: "", value: "" },
  imageDescriptions: [""],
  images: [""],
  imageDescription: "",
  itemDescription: "",
  materialAttention: "",
  measuringImage: "",
  orderDeadline: "",
  productNumber: "",
  projectName: "",
  projectRef: "",
  reservationDate: "",
  retailPrice: 0,
  memerComment: "",
  scheduleDate: "",
  reservations: 0,
  numberToShip: 0,
  rows: [
    {
      id: 0,
      name: "",
      col_1: 0,
      col_2: 0,
      col_3: 0,
      col_4: 0,
      col_5: 0,
      col_6: 0,
    },
  ],
  size: [{ label: "", value: "" }],
  sizeDataTable: [
    {
      id: 0,
      name: "",
      col_1: 0,
      col_2: 0,
      col_3: 0,
      col_4: 0,
      col_5: 0,
      col_6: 0,
    },
  ],
  threeDimages: [
    {
      size: "",
      wearingData: [
        {
          imageUrl: "",
          height: 0,
          bust: 0,
          waist: 0,
          hip: 0,
        },
        {
          imageUrl: "",
          height: 0,
          bust: 0,
          waist: 0,
          hip: 0,
        },
        {
          imageUrl: "",
          height: 0,
          bust: 0,
          waist: 0,
          hip: 0,
        },
      ],
    },
  ],
  toBeShipDate: "",
  salesStatus: {
    production: false,
    underReservation: false,
    sale: false,
    endOfSale: false,
  },
  minimumProduction: 0,
  isAdded: false,
};

export const productsUtil = [
  {
    calculate: 0,
    color: "",
    colorIndex: 0,
    createdAt: { seconds: 0, nanoseconds: 0 },
    productRef: "",
    projectName: "",
    request: 0,
    reserve: 0,
    retailPrice: 0,
    sale: false,
    size: "",
    sizeIndex: 0,
    sold: 0,
    stock: 0,
    image: "",
  },
];

export const sizeRange = [
  { value: "XS" },
  { value: "S" },
  { value: "M" },
  { value: "L" },
  { value: "XL" },
  { value: "XXL" },
  { value: "F" },
  { value: "80-90" },
  { value: "100-110" },
  { value: "120-130" },
];
