import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";
import { selectSqueeze, squeezeChange } from "../features/userSlice";
import { Squeeze } from "../Types/userType";
import { SqueezeKeys } from "../Types/postTypes";

type Props = {
  projectClick: () => void;
  productClick: () => void;
  // allClick: () => void;
  // wantClick: () => void;
};

export const PostSqueeze = (props: Props) => {
  const { projectClick, productClick } = props;
  const useStyles = makeStyles({
    squeezeArea: {
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& p": {
        margin: 3,
        fontSize: 14,
        "@media screen and (max-width: 499px)": {
          fontSize: 12,
        },
      },
      "@media screen and (max-width: 499px)": {
        height: 40,
      },
    },
    squeezeBox: {
      flex: 1,
      textAlign: "center",
    },
    selectBorder: {
      borderBottom: "1px solid rgba(169, 169, 169, 0.9)",
      margin: "0 auto",
      marginBottom: 10,
      borderRadius: 2,
      width: "70%",
      transition: "all 1s",
      "@media screen and (max-width: 499px)": {
        width: "80%",
      },
    },
    blackText: {
      color: "rgba(0,0,0,0.7)",
      transition: "all 1s",
      cursor: "pointer",
    },
    grayText: {
      color: "rgb(169, 169, 169)",
      cursor: "pointer",
    },
    radioArea: {
      display: "none",
    },
    squeezeText: {
      height: 20,
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const squeeze = useSelector(selectSqueeze);
  const switchRadio = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const targetID = e.target.id as SqueezeKeys;
    const postSqueezeKey: SqueezeKeys[] = Object.keys(squeeze) as SqueezeKeys[];
    const matchIndex = postSqueezeKey.indexOf(targetID);

    const newObj: Squeeze = {
      Product: false,
      Project: false,
      All: false,
      Want: false,
    };
    postSqueezeKey.forEach((key, idx: number) => {
      newObj[key] = matchIndex === idx ? true : false;
    });
    dispatch(squeezeChange(newObj));
  }, []);

  return (
    <div className={classes.squeezeArea}>
      <div className={classes.radioArea}>
        <label htmlFor="topHeader">
          <input
            type="radio"
            name="topHeader"
            checked={squeeze.Product}
            id="Product"
            onChange={switchRadio}
          />
          <input
            type="radio"
            name="topHeader"
            checked={squeeze.Project}
            id="Project"
            onChange={switchRadio}
          />
          {/* <input
            type="radio"
            name="example"
            checked={squeeze.All}
            id="All"
            onChange={switchRadio}
          />
          <input
            type="radio"
            name="example"
            checked={squeeze.Want}
            id="Want"
            onChange={switchRadio}
          /> */}
        </label>
      </div>
      <div className={classes.squeezeBox}>
        <div className={classes.squeezeText}>
          <Typography
            className={classes.blackText}
            onClick={squeeze.Product ? undefined : productClick}
          >
            商品一覧
          </Typography>
          {squeeze.Product && <div className={classes.selectBorder} />}
        </div>
      </div>
      <div className={classes.squeezeBox}>
        <div className={classes.squeezeText}>
          <Typography
            className={classes.blackText}
            onClick={squeeze.Project ? undefined : projectClick}
          >
            クリエイター投稿
          </Typography>
          {squeeze.Project && <div className={classes.selectBorder} />}
        </div>
      </div>
      {/* <div className={classes.squeezeFollow}>
        <div className={classes.squeezeText}>
          <Typography
            className={classes.blackText}
            onClick={squeeze.All ? undefined : allClick}
          >
            ユーザー投稿
          </Typography>
          {squeeze.All && <div className={classes.selectBorder} />}
        </div>
      </div>
      <div className={classes.squeezeWant}>
        <div className={classes.squeezeText}>
          <Typography
            className={classes.blackText}
            onClick={squeeze.Want ? undefined : wantClick}
          >
            ☆欲しい
          </Typography>
          {squeeze.Want && <div className={classes.selectBorder} />}
        </div>
      </div> */}
    </div>
  );
};
