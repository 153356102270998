import { Header } from "../Header/Header";
import { Done } from "../../templates/Done/Done";

const DeliveryDone = () => {
  return (
    <div>
      <Header label="住所登録" transitionLocation="/?" />
      <Done info="登録完了しました" linkText="TOPページへ" />
    </div>
  );
};

export default DeliveryDone;
