import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import GridLayout from "react-grid-layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { selectUser } from "../../features/userSlice";
import { useHistory } from "react-router-dom";
import { SalesStatus } from "../../Types/productType";
import {
  isProjectLink,
  projectRouterChoice,
} from "../../features/helpers/routerHelper";

type Props = {
  images: {
    path: string;
    id: string;
  }[];
  projectRef: string;
  salesStatus?: SalesStatus;
  currentWidth: number;
};

export const PropsQuiltedFiveImages = React.memo((props: Props) => {
  const { images, projectRef, salesStatus, currentWidth } = props;

  const useStyles = makeStyles((theme: any) => ({
    root: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
    image: {
      objectFit: "cover",
      borderRadius: 0,
      width: "100%",
      height: "100%",
    },
    leftImage: {
      objectFit: "cover",
      borderRadius: 0,
      width: "100%",
      height: "100%",
    },
  }));

  const classes = useStyles();

  const setting = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { push } = useHistory();

  const user = useSelector(selectUser);
  const uid = user.uid;
  return (
    <div
      className={classes.root}
      onClick={() =>
        isProjectLink(salesStatus, projectRef) &&
        push(projectRouterChoice(salesStatus, projectRef), uid)
      }
    >
      <Slider {...setting}>
        <GridLayout
          className="layout"
          isDraggable={false}
          isResizable={false}
          cols={20}
          rowHeight={currentWidth > 499 ? 100 : 75}
          width={currentWidth > 499 ? 500 : currentWidth}
          style={{ transition: "none" }}
          margin={[1, 1]}
        >
          <div key={0} data-grid={{ x: 0, y: 0, w: 13, h: 3, i: "0" }}>
            <img
              src={images[0].path}
              className={classes.image}
              alt="postImage"
            />
          </div>
          <div key={1} data-grid={{ x: 0, y: 1, w: 13, h: 3, i: "1" }}>
            <img
              src={images[1].path}
              className={classes.image}
              alt="postImage"
            />
          </div>
          <div key={2} data-grid={{ x: 14, y: 0, w: 7, h: 2, i: "2" }}>
            <img
              src={images[2].path}
              className={classes.image}
              alt="postImage"
            />
          </div>
          <div key={3} data-grid={{ x: 14, y: 1, w: 7, h: 2, i: "3" }}>
            <img
              src={images[3].path}
              className={classes.image}
              alt="postImage"
            />
          </div>
          <div key={4} data-grid={{ x: 14, y: 2, w: 7, h: 2, i: "4" }}>
            <img
              src={images[4].path}
              className={classes.image}
              alt="postImage"
            />
          </div>
        </GridLayout>

        {images.map(
          (img) =>
            img.path !== "" && (
              <GridLayout
                className="layout"
                isDraggable={false}
                isResizable={false}
                cols={20}
                rowHeight={currentWidth > 499 ? 100 : 75}
                width={currentWidth > 499 ? 500 : currentWidth}
                margin={[1, 1]}
              >
                <div key={0} data-grid={{ x: 0, y: 0, w: 20, h: 6, i: "0" }}>
                  <img
                    src={img.path}
                    className={classes.image}
                    alt="postImage"
                  />
                </div>
              </GridLayout>
            )
        )}
      </Slider>
    </div>
  );
});
