// done
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { signIn, signInGoogle } from "../../features/userOperation";
import { makeStyles, TextField } from "@material-ui/core";
import GoogleIcon from "../../assets/icon/Googleicon.png";
import { SnsAuth } from "./SnsAuth";
import MeMETitle from "../../components/feature/LoginInfo/MeMETitle";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { Text } from "../../components/atoms/Text/Text";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { toast } from "react-toastify";

type Auth = {
  email: string;
  password: string;
};
const useStyles = makeStyles({
  submit: {
    height: 55,
    marginTop: 15,
    color: "white",
  },
  pwLost: {
    display: "block",
    marginTop: 40,
    marginBottom: 20,
    marginRight: "auto",
    marginLeft: "auto",
    textAlign: "center",
    color: "skyblue",
    cursor: "pointer",
    textDecoration: "underline",
  },
  snsMessage: {
    textAlign: "center",
    fontWeigth: "lite",
  },
  border: {
    width: 30,
    borderBottomWidth: 1,
    borderBottomColor: "black",
  },
  googleIcon: {
    width: 45,
    height: 45,
    marginRight: 50,
  },
  snsAuth: {
    display: "block",
  },
  loginBtn: {
    display: "inline-block",
    color: "rgb(151, 151, 151)",
    fontSize: 30,
    margin: " 0 auto ",
    fontWeight: "lighter",
    "@media screen and (max-width: 499px)": {
      fontSize: 20,
    },
  },
  newSubmit: {},
  loginContainer: {
    width: 500,
    margin: "0 auto",
    "@media screen and (max-width: 499px)": {
      width: "100vw",
    },
  },
  inputArea: {
    width: "80%",
    margin: "0 auto 10px",
    // display: "flex",
  },
  snsAuthImageArea: {
    flex: 1,
  },
  snsAuthTextArea: {
    flex: 5,
  },
  dummyArea: {
    height: 150,
    "@media screen and (max-width: 499px)": {
      height: 50,
    },
  },
  btn: {
    display: "none",
  },
  textField: {
    "& label.Mui-focused": {
      color: "rgb(130, 130, 130)", // フォーカス時のラベル色
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgb(130, 130, 130)", // フォーカス時のボーダー色
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "rgb(130, 130, 130)", // フォーカス時のボーダー色（アウトラインスタイル）
      },
    },
  },
});
export const SignIn = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectTo = params.get("from") || "/";
  const addressPattern =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({ defaultValues });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const google = () => {
    dispatch(signInGoogle(history, undefined, redirectTo));
  };
  const onSubmit = async (data: Auth) => {
    const res = await dispatch(signIn(data.email, data.password, redirectTo));
    if (typeof res === "string") toast.warning(res);
  };

  const btnClick = () => {
    const realBtn = document.getElementById("noneBtn");
    realBtn?.click();
  };

  return (
    <div className={classes.loginContainer}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MeMETitle />
          <div className={classes.inputArea}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "必須です",
                pattern: {
                  value: addressPattern,
                  message: "アドレス形式で入力してください",
                },
              }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  size="small"
                  label="メールアドレス"
                  fullWidth={true}
                  variant="standard"
                  {...field}
                />
              )}
            />
            {errors.email?.message && (
              <Text text={errors.email.message} color="rgb(215, 74, 32)" />
            )}
          </div>
          <div className={classes.inputArea}>
            <Controller
              name={"password"}
              control={control}
              rules={{
                required: "必須です",
              }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  size="small"
                  label="パスワード"
                  fullWidth={true}
                  variant="standard"
                  type="password"
                  {...field}
                />
              )}
            />
            {errors.password?.message && (
              <Text text={errors.password.message} color="rgb(215, 74, 32)" />
            )}
          </div>
          <div className={classes.inputArea}>
            <GrayButton
              className={{
                color: "white",
                marginTop: 10,
              }}
              onClick={() => btnClick()}
              width="100%"
            >
              ログイン
            </GrayButton>
          </div>
          <div className="module-spacer--small" />
          <Link to="/selfpasswordreset" className={classes.pwLost}>
            パスワードを忘れた場合
          </Link>
          <SpaceBox height={20} />
          <SnsAuth
            snsIcon={GoogleIcon}
            snsLabel="Googleでログイン"
            width={30}
            height={30}
            onClick={() => dispatch(google)}
          />
          <div className={classes.inputArea}>
            <GrayButton
              className={{
                color: "white",
                marginTop: 10,
              }}
              onClick={() => dispatch(push("/signup"))}
              width="100%"
            >
              新規アカウント作成
            </GrayButton>
          </div>
          <button id="noneBtn" className={classes.btn}>
            submit
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
