import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

type Props = {
  label: string;
  detail: string;
};

export const ReserveItemInfo = (props: Props) => {
  const { label, detail } = props;
  const useStyles = makeStyles({
    itemInfo: {
      height: 25,
      width: 300,
      margin: "0 auto 5px",
      display: "flex",
      "@media screen and (max-width: 499px)": {
        width: 225,
      },
    },
    itemInfoLeft: {
      flex: 1.5,
    },
    itemInfoRight: {
      flex: 1,
    },
    label: {
      paddingLeft: 10,
      fontSize: 14,
      lineHeight: 2.5,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    rightLabel: {
      fontSize: 14,
      lineHeight: 2.5,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.itemInfo}>
      <div className={classes.itemInfoRight}>
        <Typography align="center" className={classes.label}>
          {label}
        </Typography>
      </div>
      <div className={classes.itemInfoLeft}>
        <Typography align="center" className={classes.rightLabel}>
          {detail}
        </Typography>
      </div>
    </div>
  );
};
