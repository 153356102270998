import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles, Typography } from "@material-ui/core";
import GrayBorder from "../UIKit/GrayBorder";
import Status from "./Status";
import { ProductData, PurchaseHistoryType } from "../../Types/deliveryType";
import { TotalAmount } from "./TotalAmount";
import dayjs from "dayjs";
import { HistoryProduct, OrderHistory } from "../../Types/productType";
import { SpaceBox } from "../UIKit/SpaceBox";

type Props = {
  purchaseHistory: OrderHistory;
};

const DeliveryStatus = (props: Props) => {
  const { purchaseHistory } = props;
  const purchaseDate = dayjs
    .unix(purchaseHistory.createdAt.seconds)
    .format("YYYY年MM月DD日");
  const [calculationAmount, setCalculationAmount] = useState({
    price: 0,
    totalPrice: 0,
    postage: 0,
  });

  const totalAmountCalculation = () => {
    let total = 0;
    purchaseHistory.productData.map((pd: HistoryProduct) => {
      const priceMultipliedByQuantity = pd.price * pd.quantity;
      total += priceMultipliedByQuantity;
    });
    setCalculationAmount({
      price: total,
      totalPrice: total + purchaseHistory.postage,
      postage: purchaseHistory.postage,
    });
  };

  const useStyles = makeStyles({
    deliveryHistoryArea: {
      // paddingTop: 20,
    },
    deliveryHistory: {
      display: "flex",
      marginBottom: 20,
    },
    deliveryImg: {
      width: "100%",
      padding: "0 10px",
    },
    deliveryInfo: {
      width: "100%",
      marginRight: 10,
      "& p": {
        margin: "10px auto 0",
        textAlign: "center",
        fontSize: 15,
      },
    },
    placeAndLength: {
      display: "flex",
    },
    place: {
      flex: 7,
      "& p": {
        textAlign: "left",
      },
    },
    productLength: {
      flex: 3,
    },
    fontBold: {
      fontWeight: "bold",
    },
    icon: {
      margin: "0 auto",
      objectFit: "cover",
      width: 160,
      height: 200,
      display: "block",
    },
    dummyArea: {
      height: 20,
    },
    totalAmountArea: {},
    total: {
      display: "flex",
      borderBottom: "1px solid black",
      width: "50%",
      margin: "0 auto",
      marginBottom: 10,
    },
    totalText: {
      flex: 1,
    },
    postage: {},
    purchaseDateArea: {
      textAlign: "center",
      padding: 20,
    },
  });
  const classes = useStyles();

  const amount = (price: number, quantity: number) => {
    return price * quantity;
  };

  useEffect(() => {
    totalAmountCalculation();
  }, []);
  return (
    <div className={classes.deliveryHistoryArea}>
      <div className={classes.purchaseDateArea}>
        <Typography>{`購入日 ${purchaseDate}`}</Typography>
      </div>
      {purchaseHistory.productData.map((pd: ProductData) => (
        <div className={classes.deliveryHistory}>
          <div className={classes.deliveryImg}>
            <img src={pd.image} className={classes.icon} alt="productImage" />
          </div>
          <div className={classes.deliveryInfo}>
            <Typography>{pd.projectName}</Typography>
            <GrayBorder />
            <Typography>{pd.size}</Typography>
            <GrayBorder />
            <Typography>{pd.color}</Typography>
            <GrayBorder />
            <div className={classes.placeAndLength}>
              <div className={classes.place}>
                <Typography>{`¥${amount(
                  pd.price,
                  pd.quantity
                ).toLocaleString()}`}</Typography>
              </div>
              <div className={classes.productLength}>
                <Typography>{`${pd.quantity}点`}</Typography>
              </div>
            </div>
          </div>
        </div>
      ))}
      <SpaceBox height={10} />
      <TotalAmount
        kind="商品合計"
        price={calculationAmount.price.toLocaleString()}
      />
      <TotalAmount
        kind="送料"
        price={calculationAmount.postage.toLocaleString()}
      />
      <TotalAmount
        kind="合計金額"
        price={calculationAmount.totalPrice.toLocaleString()}
      />
      <Status
        sagawaStatus={purchaseHistory.sagawaStatus}
        deliveryMessage={purchaseHistory.deliveryMessage}
        deliveryScheduledDate={purchaseHistory.deliveryScheduledDate}
        url={purchaseHistory.changeDeliveryDateUrl}
        orderNumber={purchaseHistory.orderNumber}
      />
      <SpaceBox height={20} />
    </div>
  );
};

export default DeliveryStatus;
