import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useWindowSize } from "react-use";
import { calculateSize } from "../../features/helpers/imageHelper";

type Props = {
  color?: string;
  images: string[];
};

export const MeMerImage = React.memo((props: Props) => {
  const { color, images } = props;
  const { width } = useWindowSize();

  const imageSize = calculateSize(width);

  const useStyles = makeStyles({
    photoArea: {
      backgroundColor: color ?? "rgb(247, 229,229)",
    },
    title: {
      height: 80,
    },
    photoPr: {
      lineHeight: 5,
      fontSize: 18,
      fontWeight: "bold",
    },
    img: {
      width: "100%",
      objectFit: "cover",
      borderRadius: 10,
    },
    dummyArea: {
      height: 100,
    },
    inputTouchArea: {
      width: "100%",
      height: "100%",
      backgroundColor: "rgb(240,240,240)",
      position: "relative",
      cursor: "pointer",
    },
    image: {
      width: 166,
      height: 221,
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: imageSize.width,
        height: imageSize.height,
      },
    },
    imageInputArea: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    invisibleFlame: {
      width: 166,
      height: 221,
      marginBottom: 1,
      "@media screen and (max-width: 499px)": {
        width: imageSize.width,
        height: imageSize.height,
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.photoArea}>
      <div className={classes.title}>
        <Typography align="center" className={classes.photoPr}>
          PR PHOTO
        </Typography>
      </div>
      <div className={classes.imageInputArea}>
        {images.map((image, index) => {
          return image !== "" ? (
            <div className={classes.invisibleFlame} key={image}>
              <div className={classes.inputTouchArea}>
                <img alt="PrPhoto" src={image} className={classes.image} />
              </div>
            </div>
          ) : (
            <div className={classes.invisibleFlame} key={index} />
          );
        })}
      </div>
      <div className={classes.dummyArea} />
    </div>
  );
});
