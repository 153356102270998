import React from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import MeME from "../../../assets/icon/tomemeLogo.png";

const MeMETitle = () => {
  const { push } = useHistory();

  const useStyles = makeStyles((theme) => ({
    titleCenter: {
      "& h1": {
        textAlign: "center",
      },
    },
    MeMEIcon: {
      width: 200,
      margin: "100px 0",
      cursor: "pointer",
      opacity: 1,
      animation: "$fadeIn 1.5s ease-in-out, $slideIn 1.5s ease-in-out", // 複数のアニメーションを適用
    },
    iconArea: {
      width: 200,
      margin: "0 auto",
      overflow: "hidden", // アニメーション要素のオーバーフローを非表示に
    },
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
    "@keyframes slideIn": {
      "0%": {
        clipPath: "inset(0 100% 0 0)", // 左側から右側へと順に表示
      },
      "100%": {
        clipPath: "inset(0)", // 全体が見えるように
      },
    },
  }));

  const classes = useStyles();
  return (
    <div className={`${classes.titleCenter}`}>
      <div className={classes.iconArea} onClick={() => push("/")}>
        <img
          src={MeME}
          className={`${classes.MeMEIcon}`}
          alt="title"
          height={37}
          width={200}
        />
      </div>
    </div>
  );
};

export default React.memo(MeMETitle);
