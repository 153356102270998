import { createTheme } from "@material-ui/core/styles";

// Pick colors on https://material.io/resources/color/#!/

export const theme = createTheme({
  palette: {
    primary: {
      light: "#e5e5e5",
      main: "#b7b7b7",
      dark: "#424242",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
