import { Header } from "../../components/Header/Header";
import { Done } from "./Done";

const CartDone = () => {
  return (
    <div>
      <Header label="カートに入れました" transitionLocation={"/?"} />
      <Done info="カートに入れました。" linkText="TOPページへ" />
    </div>
  );
};

export default CartDone;
