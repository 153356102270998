import { Typography, makeStyles } from "@material-ui/core";
import { LatestColorSalesInfo } from "../../../Types/productType";
type Props = {
  minimumProduction: number;
  salesDetailData: LatestColorSalesInfo[];
};
export const ProductLimitCount = (props: Props) => {
  const { minimumProduction, salesDetailData } = props;
  const useStyles = makeStyles({
    countBox: {
      margin: "40px 15px 0",
      border: "solid 1px gray",
      padding: 15,
    },
    text: {
      color: "gray",
    },
    quantity: {
      color: "red",
      fontSize: 18,
    },
  });
  const classes = useStyles();

  const remainingCount = () => {
    const reserveCount = salesDetailData.reduce(
      (acc, cur) => acc + cur.reserve,
      0
    );

    return minimumProduction - reserveCount;
  };

  const count = remainingCount();
  return (
    <>
      {count <= 5 && count > 0 && (
        <div className={classes.countBox}>
          <Typography align="center" className={classes.text}>
            生産目標達成まであと
            <span className={classes.quantity}>{` ${count}`}</span> 着
          </Typography>
        </div>
      )}
    </>
  );
};
