import { getDoc } from "firebase/firestore";
import { addressDocument } from "../../firebasePaths";
import { countUpOrderNumber } from "../../function/cloudFunctions";

export const deliveryOrderNumber = async () => {
  const orderNumber = await countUpOrderNumber();
  return orderNumber.data;
};

export const fetchDeliveryLocationData = async (
  uid: string,
  aid: string
): Promise<{
  address: string;
  zipcode: string;
  phoneNumber: string;
  name: string;
}> => {
  const addressRes = await getDoc(addressDocument(uid, aid));

  const addressData = addressRes.data();
  const address = `${addressData?.prefecture}${addressData?.city}${addressData?.town}${addressData?.address} ${addressData?.buildingName}`;
  const zipcode = addressData?.zipcode ?? "";
  const phoneNumber = addressData?.phoneNumber ?? "";
  const name = `${addressData?.lastName ?? ""} ${addressData?.firstName ?? ""}`;

  return {
    address,
    zipcode,
    phoneNumber,
    name,
  };
};
