import { useState } from "react";
import { MentionPostTo } from "./MentionPostTo";
import { makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { addStoreMentionTo } from "../../../features/postOperation";
import { AdminMentionTo } from "../PostUIKit/AdminMentionTo";
import MentionToSkeleton from "../PostUIKit/MentionToSkeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  resetProjectState,
  selectProjectPost,
} from "../../../features/projectPostSlice";
import { addProjectPostStoreMentionTo } from "../../../features/projectPostOperation";
import { StoreResetHeader } from "../../../components/Header/StoreResetHeader";
import { transitionFunc } from "../../../function/common";
import { resetState } from "../../../features/postSlice";
import { User } from "../../../Types/userType";
import { fetchMentionUser } from "../../../features/socialOperation";
import { Text } from "../../../components/atoms/Text/Text";
import { SpaceBox } from "../../../components/UIKit/SpaceBox";
import { GrayButton } from "../../../components/atoms/GrayButton/GrayButton";

const useStyles = makeStyles({
  card: {
    height: 57,
    "& h2": {
      margin: "0 auto",
      width: "86%",
      padding: "23px",
    },
  },
  noSelect: {
    fontSize: "32px",
    marginLeft: "30px",
  },
  Icon: {
    width: 58,
    height: 58,
    objectFit: "cover",
  },
  container: {
    width: 500,
    margin: "0 auto",
    overflowX: "hidden",
    "@media screen and (max-width: 499px)": {
      width: "100vw",
    },
  },
  footerArea: {
    height: 50,
  },
  notSpecified: {
    marginLeft: 40,
    fontSize: 20,
    lineHeight: 3,
  },
  userInfoBar: {
    height: 57,
    display: "flex",
  },
  userName: {
    margin: "10px 0 10px 10px",
    flex: 1,
  },
  Name: {
    lineHeight: 3,
    fontSize: 12,
    color: "gray",
  },
  userIcon: {
    width: 60,
    margin: "10px 0 10px 10px",
  },
  iconBox: {
    width: 56,
    height: 56,
  },
  info: {
    padding: 15,
  },
  buttonArea: {
    marginTop: 10,
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
  },
});
export const MentionPostToList = (props: RouteComponentProps) => {
  const { history } = props;
  const projectPostSelector = useSelector(selectProjectPost);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [users, setUsers] = useState<User[]>([]);
  const [noMention, setNoMention] = useState(true);
  const [mentionTos, setMentionTos] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingOfLoading, setLoadingOfLoading] = useState(false);
  const skeletonLength: number[] = Array(9).fill(0);

  const toggleSelectedUser = (uid: string, selected: boolean) => {
    const selectedUser = users.filter((user) => {
      if (user.uid === uid) {
        user.selected = !selected;
      }
      return user.selected;
    });
    setNoMention(selectedUser.length === 0);
    setMentionTos(selectedUser);
  };

  //  * setMentionToを空にする
  const toggleNoMention = () => {
    setNoMention(true);

    users.forEach((user) => {
      user.selected = false;
    });
  };

  const adminMention = () => {
    const displayName = "tomeme運営";
    const photoUrl =
      "https://firebasestorage.googleapis.com/v0/b/meme-project-f3275.appspot.com/o/images%2Fgq8cVJ2nnCf9OEQ1?alt=media&token=f7021bf7-ef18-4914-89a7-445a84944a04";
    const selected = true;
    const isAdmin = true;

    let adminUser: {
      displayName: string;
      photoUrl: string;
      selected: boolean;
      uid: string;
      isAdmin: boolean;
    };
    if (process.env.NODE_ENV === "development") {
      adminUser = {
        displayName,
        photoUrl,
        selected,
        uid: "LpMogfNR0NNif47AoWZOcF2CHmD2",
        isAdmin,
      };
    } else {
      adminUser = {
        displayName,
        photoUrl,
        selected,
        uid: "oLrSc2iRT9bR9byBoqbG9zvZdRk1",
        isAdmin,
      };
    }
    return adminUser;
  };
  const storeResetArray = [resetState, resetProjectState];

  return (
    <div className={classes.container} id="Container">
      <SpaceBox height={45} />
      <StoreResetHeader
        label="投稿先"
        transitionFunc={transitionFunc}
        path={history.goBack}
        storeReset={storeResetArray}
      />
      <div className={classes.info}>
        <Text
          text="クリエイターになりたい人はtomeme運営宛にコーディネート写真などアップしてください。"
          color="black"
        />
      </div>
      <AdminMentionTo
        mentionTos={mentionTos}
        adminMention={adminMention}
        setMentionTos={setMentionTos}
      />
      <div className={classes.info}>
        <Text text="クリエイターに対して投稿する" color="black" />
      </div>
      <div className={classes.buttonArea}>
        <GrayButton
          width={280}
          onClick={() =>
            dispatch(
              fetchMentionUser(setUsers, setLoading, setLoadingOfLoading)
            )
          }
        >
          メンションするクリエイターを探す
        </GrayButton>
      </div>
      {!noMention && (
        <div className={classes.buttonArea}>
          <GrayButton width={280} onClick={() => toggleNoMention()}>
            全てのメンションを外す
          </GrayButton>
        </div>
      )}
      <SpaceBox height={20} />
      {loadingOfLoading &&
        skeletonLength.map(
          (_, index) =>
            loading && (
              <div key={index}>
                <MentionToSkeleton />
              </div>
            )
        )}
      {users.map(
        (user) =>
          user.photoUrl && (
            <label key={user.uid}>
              <SpaceBox height="3px" />
              <input
                className="u-display-none"
                type="checkbox"
                checked={user.selected}
                onChange={() => toggleSelectedUser(user.uid, user.selected)}
              />
              <MentionPostTo
                selected={user.selected}
                photoUrl={user.photoUrl}
                displayName={user.displayName}
              />
            </label>
          )
      )}
      <div className={classes.buttonArea}>
        <GrayButton
          width={280}
          onClick={() =>
            dispatch(
              projectPostSelector.projectID
                ? addProjectPostStoreMentionTo(mentionTos)
                : addStoreMentionTo(mentionTos)
            )
          }
        >
          次へ
        </GrayButton>
      </div>
      <div className={classes.footerArea} />
    </div>
  );
};
