import React from "react";
import masterCard from "../../assets/icon/Master.png";
import { makeStyles } from "@material-ui/core";

const MasterCard = () => {
  const useStyles = makeStyles({
    cardBlock: {
      marginTop: 11,
      width: 65.115,
      height: 44.379,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.cardBlock}>
      <img src={masterCard} alt="masterCard" />
    </div>
  );
};

export default MasterCard;
