import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ControllerRenderProps } from "react-hook-form";

type Props = {
  id?: string;
  infoLabel: string;
  labelRed?: string;
  place: string;
  field?: ControllerRenderProps<any, any>;
  error?: { message?: string };
};

export const DeliveryInput = React.memo((props: Props) => {
  const { id, infoLabel, labelRed, place, field, error } = props;
  const useStyles = makeStyles({
    inputContainer: {},
    textInfo: {
      height: 20,
      "& p": {
        fontSize: 13,
        margin: "0 0 0 5px",
      },
    },
    textInput: {
      height: 20,
    },
    textField: {
      margin: "",
      padding: "0 0 0 0 ",
    },
    labelBlack: {
      color: "block",
    },
    labelRed: {
      color: "red",
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.inputContainer}>
      <div className={classes.textInfo}>
        <Typography>
          {infoLabel}
          <span className={classes.labelRed}>{labelRed}</span>
          {error && <span className={classes.labelRed}>{error.message}</span>}
        </Typography>
      </div>
      <TextField
        id={id}
        placeholder={place}
        inputProps={{ "aria-label": "naked" }}
        fullWidth
        {...field}
      />
    </div>
  );
});
