import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";
import { Post } from "../../../Types/userType";
import want from "../../../assets/icon/Want.png";
import unWant from "../../../assets/icon/unWant.png";
import { switchWanted } from "../../../features/socialOperation";
import { selectUser } from "../../../features/userSlice";
import { LoginInfo } from "../../../components/feature/LoginInfo/LoginInfo";
import { logError } from "../../../lib/logError";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { getDocumentRef } from "../../../firebasePaths";

type Props = {
  post: Post;
};

export const Want = React.memo((props: Props) => {
  const { post } = props;
  const useStyles = makeStyles({
    want: {
      flex: 1,
    },
    wantArea: {
      cursor: "pointer",
      margin: "0 auto",
      display: "flex",
    },
    starIcon: {
      width: 20,
      height: 20,
      marginTop: 9,
    },
    wantTextArea: {
      marginLeft: 7,
    },
    wantText: {
      lineHeight: 3.5,
      fontSize: 12,
      marginLeft: 4,
    },
    wantLength: {
      marginLeft: 7,
    },
  });
  const classes = useStyles();
  const user = useSelector(selectUser);
  const { uid } = user;
  const { projectRef } = post;
  const [wantCounter, setWantCounter] = useState(0);
  const [wantState, setWantState] = useState(false);
  const [show, setShow] = useState(false);
  const inputWantCounter = useCallback(
    (counter: number) => {
      setWantCounter(counter);
    },
    [setWantCounter]
  );

  const inputWantState = useCallback(
    (wanted: boolean) => {
      setWantState(wanted);
    },
    [setWantState]
  );

  const fetchWant = async () => {
    const wantedUsersRef = collection(
      getDocumentRef(projectRef),
      "wantedUsers"
    );
    await getDocs(wantedUsersRef).then((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.data().wantedUserUid === uid) {
          inputWantState(true);
        }
      });
    });

    const wantCountRef = doc(
      collection(getDocumentRef(projectRef), "wantCount"),
      "wantCount"
    );
    await getDoc(wantCountRef).then((doc) => {
      const data = doc.data();
      if (data) {
        inputWantCounter(data.count);
      }
    });
  };

  const wantAction = () => {
    if (user.isSignedIn) {
      try {
        switchWanted(wantState, post, setWantCounter, setWantState);
        return;
      } catch (error) {
        logError(error);
      }
    }
    setShow(true);
  };

  useEffect(() => {
    fetchWant();
  }, [post]);

  return (
    <div className={classes.want}>
      <div className={classes.wantArea}>
        <img
          alt="want"
          src={wantState ? want : unWant}
          className={classes.starIcon}
          onClick={() => wantAction()}
        />
        <div className={classes.wantTextArea}>
          <Typography className={classes.wantText}>ほしい！</Typography>
        </div>
        {/* <div className={classes.wantLength}>
          <Typography className={classes.wantText}>{wantCounter}</Typography>
        </div> */}
      </div>
      <LoginInfo isShow={show} setState={setShow} />
    </div>
  );
});
