import {
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Center } from "../../../components/atoms/Layout/Center";

type Props = {
  image: string;
  sizeDataTable: {
    id: number;
    col_1: number;
    col_2: number;
    col_3: number;
    col_4: number;
    col_5: number;
    col_6: number;
    name: string;
  }[];
  detailSize: string[];
};

export const ProductSizeDetail = (props: Props) => {
  const { image, detailSize, sizeDataTable } = props;

  const newDetailSize = [...detailSize.slice(1)];
  const headerRow = ["サイズ", ...newDetailSize];
  const sizeDetail = ["", "", "", "", "", "", ""];
  const dataRows = sizeDataTable.map((size) => {
    const rowData = [
      size.name,
      size.col_1,
      size.col_2,
      size.col_3,
      size.col_4,
      size.col_5,
      size.col_6,
    ];
    return rowData.slice(0, sizeDetail.length + 1);
  });
  const sizeDetailData = [headerRow, ...dataRows];
  const useStyles = makeStyles({
    sizeDetailBox: {
      marginTop: 40,
      padding: "0 40px",
      "@media screen and (max-width: 499px)": {
        padding: "0 10px",
      },
    },
    titleText: {
      fontSize: 14,
      fontWeight: "bold",
    },
    imageBox: {
      marginTop: 40,
      width: 250,
      height: 250,
      margin: "0 auto", // このスタイルにより、imageBoxは既に中央に配置されています。
      display: "flex", // flexboxを使用して中央揃えを行います。
      justifyContent: "center", // 水平方向の中央揃え
      alignItems: "center", // 垂直方向の中央揃え
    },
    table: {
      border: "none",
      fontSize: 10,
      marginTop: 30,
    },
    sell: {
      fontSize: 12,
      width: "14.28%",
      padding: "5px 10px",
      textAlign: "center",
      "@media screen and (max-width: 499px)": {
        padding: 5,
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.sizeDetailBox}>
      <Typography align="center" className={classes.titleText}>
        サイズ詳細
      </Typography>
      <div className={classes.imageBox}>
        <img src={image} alt="sizeDetail" />
      </div>
      <Center>
        <Table className={classes.table}>
          <TableBody>
            {sizeDetailData.map((items, index) => {
              return (
                <TableRow key={String(`${items}${index}`)}>
                  {items.map((item, idx) => {
                    return (
                      <TableCell
                        className={classes.sell}
                        key={String(`${item}${index}${idx}`)}
                      >
                        {item === 0 ? "" : item}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Center>
    </div>
  );
};
