import { useHistory } from "react-router";
import { makeStyles, Typography } from "@material-ui/core";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { SpaceBox } from "../../components/UIKit/SpaceBox";

export const SignUpConfirm = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    bigText: {
      marginBottom: 30,
    },
    text: {
      padding: "0 10px",
    },
    buttonArea: {
      marginTop: 20,
    },
  });
  const { push } = useHistory();
  const classes = useStyles();
  return (
    <div>
      <BrowserBackHeader label="メール送信済み" browserBack={history} />
      <SpaceBox height={100} />
      <Typography align="center" variant="h5" className={classes.bigText}>
        登録ありがとうございます。
      </Typography>
      <Typography className={classes.text}>
        入力されたメールアドレスへ認証用リンクを送信しました。
      </Typography>
      <Typography className={classes.text}>
        メールの受信を確認いただき、メール文のリンクにアクセスすることで、登録完了となります。
      </Typography>
      <div className={classes.buttonArea}>
        <GrayButton onClick={() => push("/signin")} width="100%">
          ログイン画面へ戻る
        </GrayButton>
      </div>
    </div>
  );
};
