import { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { PrimaryButton } from "../../components/UIKit";
import { fetchCart } from "../../features/cartOperation";
import { useDispatch } from "react-redux";
import { CartProduct } from "../../Types/productType";
import { db } from "../../firebase";
import CartDetaile from "./CartDetaile";
import { fetchSaleProductList } from "../../features/productOperation";
import { ProductMap } from "../Product/ProductParts/ProductMap";
import { GrayBack } from "../../components/MyPage/GrayBack";
import { ProductSkeleton } from "../Skeleton/ProductSkeleton";
import { SpaceBox } from "../../components/UIKit/SpaceBox";

const Cart = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match, location } = props;
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [cartData, setCartData] = useState<CartProduct[]>([]);
  const [productList, setProductList] = useState([]);
  const [cartStock, setCartStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const uid = match.params.id;
  const deleteBoolLength = location.pathname.split("/").length;

  const cartStockCheck = () => {
    let boolBox: boolean[] = [];
    cartData.forEach((cd, index) => {
      cd.quantity <= cartStock[index]
        ? boolBox.push(true)
        : boolBox.push(false);
    });

    return boolBox.some((value) => value === false);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setCartStock([]);
      dispatch(fetchCart(setCartData, setCartStock));
      dispatch(fetchSaleProductList(setProductList, setLoading));
    }

    return () => {
      isMounted = false;
    };
  }, [db]);

  return (
    <div>
      <BrowserBackHeader label="カート内一覧" browserBack={history} />
      <SpaceBox height={50} />
      {cartData.length > 0 ? (
        <>
          <CartDetaile
            cartData={cartData}
            cartStock={cartStock}
            deleteBoolLength={deleteBoolLength}
            setCartStock={setCartStock}
          />
          <SpaceBox height={50} />
          {cartStockCheck() && (
            <Typography align="center" style={{ color: "red" }}>
              在庫不足の商品があります
            </Typography>
          )}
          <PrimaryButton
            label={"購入手続きへ"}
            onClick={() => push(`/${uid}/purchase/confirm`, cartData)}
            margin={"0 auto"}
            display={"block"}
            disabled={cartStockCheck()}
            width={"80%"}
          />
          <SpaceBox height={50} />
        </>
      ) : (
        <>
          <SpaceBox height={100} />
          <Typography align="center" variant="h6">
            現在カートは空です
          </Typography>
          <SpaceBox height={100} />
        </>
      )}
      <GrayBack height={50} label={"販売中の商品"} align={"center"} />
      {loading ? (
        <ProductSkeleton />
      ) : (
        <ProductMap productList={productList} />
      )}{" "}
    </div>
  );
};

export default Cart;
