import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { Project } from "../../Types/projectType";
import { ProjectCard } from "./ProjectCard";
import ProductInfo from "./Parts/ProductInfo";
import { SpaceBox } from "../UIKit/SpaceBox";
import { fetchSingleProjectData } from "../../features/userOperation";
import SalesDetailes from "./Parts/SalesDetailes";

const ProjectSales = (
  props: RouteComponentProps<{
    uid: string;
    pid: string;
  }>
) => {
  const { history } = props;
  const dispatch = useDispatch();
  const project = history.location.state as Project;
  const coloring = () => {
    if (project.salesStatus.sale) {
      return "#edffff";
    } else if (project.salesStatus.underReservation) {
      return "#edffce";
    } else if (project.salesStatus.production) {
      return "#edaace";
    }
  };
  const useStyles = makeStyles({
    card: {
      height: "140px",
      borderRadius: 8,
      // margin: 28,
      marginTop: 60,
      boxShadow: "0 0 8px rgba(0, 0, 0, .16)",
      display: "flex",
      color: "#212121",
      backgroundColor: coloring(),
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  // 発売日
  const releaseDate = new Date(project.toBeShipDate);
  const month = releaseDate.getMonth() + 1;
  const day = releaseDate.getDate();

  // 販売数
  const [numberOfSales, setNumberOfSales] = useState<number>(0);
  // 上代
  const price = project.productFee.toLocaleString();

  // 日毎の売り上げ
  const [dailySalesDetaile, setDailySalesDetaile] = useState([]);
  useEffect(() => {
    dispatch(
      fetchSingleProjectData(
        setNumberOfSales,
        project.projectRef,
        setDailySalesDetaile
      )
    );
  }, []);

  return (
    <div>
      <div className={classes.card}>
        <BrowserBackHeader label={"プロジェクト別売上"} browserBack={history} />
        <ProjectCard project={project} />
      </div>
      <SpaceBox height={50} />
      <ProductInfo leftKind={"発売日"} detaile={`${month}月${day}日`} />
      <ProductInfo leftKind={"販売数"} detaile={String(numberOfSales)} />
      <ProductInfo leftKind={"上代"} detaile={price} />
      <SpaceBox height={20} />
      <Typography align="center">売上</Typography>
      <SpaceBox height={20} />
      {dailySalesDetaile.map((dsd: { price: string; salesDate: string }) => (
        <SalesDetailes detaile={dsd} />
      ))}
    </div>
  );
};

export default ProjectSales;
