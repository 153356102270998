import React from "react";
import visa from "../../assets/icon/VISA.png";

import { makeStyles } from "@material-ui/core";
const Visa = () => {
  const useStyles = makeStyles({
    cardBlock: {
      marginTop: 11,
      width: 65.115,
      height: 44.379,
    },
  });

  // <div className={classes.cardBlock}>
  //     <img src={visa} alt="visaCard" className={classes.cardImage} />
  //   </div>
  const classes = useStyles();
  return (
    <div className={classes.cardBlock}>
      <img src={visa} alt="visaCard" />
    </div>
  );
};

export default Visa;
