import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";

const PrimaryButton = (props: any) => {
  const arg: string = props.onClickArg;
  const useStyles = makeStyles((theme: any | never) =>
    createStyles({
      button: {
        // propsにカラーを指定しなければthemeのメインカラー指定すればその色になる
        backgroundColor: props.bgColor
          ? props.bgColor
          : theme.palette.primary.main,
        color: "#FFFFFF",
        fontSize: 16,
        height: props.height ? props.height : 48,
        // marginBottom: 16,
        borderRadius: props.borderRadius,
        margin: props.margin,
        width: props.width,
        display: props.display,
        padding: props.padding,
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
        "@media screen and (max-width: 499px)": {
          // width: 250,
          fontSize: 14,
        },
      },
    })
  );
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      fullWidth={props.fullWidth}
      variant="contained"
      disabled={props.disabled}
      onClick={() => props.onClick(arg)}
    >
      {props.label}
    </Button>
  );
};

export default PrimaryButton;
