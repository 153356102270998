import {
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { SpaceBox } from "../../UIKit/SpaceBox";

interface PROPS {
  label: string;
  field: object;
}

const SNSAccountInput = (props: PROPS) => {
  const useStyles = makeStyles({
    snsInputArea: {
      padding: "0 10px 0 10px",
    },
    textInfo: {
      height: 20,
    },
    labelBlack: {
      color: "block",
      fontSize: 12,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.snsInputArea}>
      <div className={classes.textInfo}>
        <p>
          <span
            className={classes.labelBlack}
          >{`${props.label}アカウント`}</span>
        </p>
      </div>
      <SpaceBox height={10} />
      <TextField
        placeholder={"アカウントID"}
        fullWidth
        {...props.field}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography style={{ paddingLeft: 10 }}>@</Typography>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SNSAccountInput;
