import { makeStyles, Typography } from "@material-ui/core";

export const ReservePaymentHeader = () => {
  const useStyles = makeStyles({
    title: {
      // marginTop: 20,
      height: 60,
    },
    paymentTitle: {
      fontWeight: "bold",
      lineHeight: 5,
      color: "rgb(50, 50, 50)",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <Typography align="center" className={classes.paymentTitle}>
        送付先・お支払い情報
      </Typography>
    </div>
  );
};
