import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { Post } from "../../Types/userType";
import { ProjectTitle } from "./ProjectPost/ProjectTitle";
import { PostText } from "./PostText";
import { MentionPost } from "../../components/UIKit/MentionPost";
import { ProjectPostFooter } from "./ProjectPostFooter";
import { ProjectPostStatus } from "./ProjectPost/ProjectPostStatus";
import { PostTime } from "./PostTime";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { UserInfo } from "../../components/Header/UserInfo";
import { PropsSwitchQuiltedImages } from "../../components/PostImage/PropsSwitchQuiltedImages";
import { useState } from "react";
import { SalesStatus } from "../../Types/productType";
import { useHistory } from "react-router-dom";
import { projectRouterChoice } from "../../features/helpers/routerHelper";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";

type Props = {
  posts: Post[];
  fetchPost: (isDelete?: boolean) => void;
};

export const TopPosts = (props: Props) => {
  const { posts, fetchPost } = props;
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { push } = useHistory();
  const user = useSelector(selectUser);
  const uid = user.uid;
  const useStyles = makeStyles({
    separation: {
      height: 0.5,
      backgroundColor: "rgb(235, 232, 232)",
    },
    statusArea: {
      marginTop: 9,
      // marginBottom: 5,
      marginLeft: -9,
      position: "relative",
    },
    projectNameArea: {
      paddingLeft: 10,
      paddingTop: 15,
    },
    projectNameText: {
      fontWeight: "bold",
      fontSize: 12,
    },
    loadingArea: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  });
  const classes = useStyles();

  const isNormalPost = (projectRef?: string, status?: SalesStatus) => {
    if (projectRef) {
      if (status?.official) return false;
      return true;
    }

    return true;
  };
  return (
    <>
      {posts.length > 0 &&
        posts.map((post: Post) => (
          <div key={post.postID}>
            <UserInfo post={post} posts={posts} />
            <div className={classes.statusArea}>
              {post.salesStatus && (
                <ProjectPostStatus status={post.salesStatus} />
              )}
            </div>
            {post.projectRef ? (
              <PropsSwitchQuiltedImages
                images={post.images}
                projectRef={post.projectRef}
                salesStatus={post.salesStatus}
              />
            ) : (
              <PropsSwitchQuiltedImages images={post.images} />
            )}
            <SpaceBox height={20} />
            {post.title && <ProjectTitle title={post.title} />}
            {post.mentionTo.length > 0 && (
              <MentionPost users={post.mentionTo} />
            )}
            <PostText label={post.body ?? ""} />
            {isNormalPost(post.projectRef, post.salesStatus) && (
              <ProjectPostFooter post={post} />
            )}
            {post.projectName && !post.salesStatus.official && (
              <div
                className={classes.projectNameArea}
                onClick={() =>
                  post.projectRef &&
                  !post.salesStatus?.official &&
                  push(
                    projectRouterChoice(post.salesStatus, post.projectRef),
                    uid
                  )
                }
              >
                <Typography className={classes.projectNameText}>
                  プロジェクト名
                </Typography>
                <Typography className={classes.projectNameText}>
                  {`　${post.projectName}`}
                </Typography>
                <PostText label={post.projectDescription ?? ""} />
              </div>
            )}

            {deleteLoading && (
              <div className={classes.loadingArea}>
                <CircularProgress size={50} color="primary" thickness={2.5} />
              </div>
            )}
            <PostTime
              seconds={post.createdAt.seconds}
              post={post}
              fetchPost={fetchPost}
              setDeleteLoading={setDeleteLoading}
            />
            <div className={classes.separation} />
          </div>
        ))}
    </>
  );
};
