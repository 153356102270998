import React from "react";
import { makeStyles } from "@material-ui/core";

import discover from "../../assets/icon/discover.png";
const Discover = () => {
  const useStyles = makeStyles({
    cardBlock: {
      marginTop: 11,
      width: 65.115,
      height: 44.379,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.cardBlock}>
      <img src={discover} alt="discoverCard" />
    </div>
  );
};

export default Discover;
