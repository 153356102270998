import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import post from "../../../assets/icon/post.png";
import { selectUser } from "../../../features/userSlice";

const ProjectPostButton = () => {
  const { push } = useHistory();
  const uid = useSelector(selectUser).uid;

  const useStyles = makeStyles({
    projectPostButtonArea: {},
    button: {
      zIndex: 2,
      position: "fixed",
      bottom: 70,
      right: "calc(50% - 230px)",
      "@media screen and (max-width: 499px)": {
        right: "calc(50% - 180px)",
      },
      width: 40,
      height: 40,
      borderRadius: 50,
      backgroundColor: "rgb(255,185,185)",
      boxShadow: "0px 4px 4px gray",
      flex: 1,
      cursor: "pointer",
      display: "grid",
      placeItems: "center",
    },
    post: {
      width: 20,
      height: 20,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.projectPostButtonArea}>
      <div className={classes.button} onClick={() => push(`/projects/${uid}`)}>
        <img src={post} className={classes.post} alt="post" />
      </div>
    </div>
  );
};

export default ProjectPostButton;
