import { useEffect } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import GrayBorder from "../../components/UIKit/GrayBorder";
import { CartProduct } from "../../Types/productType";
import { cartStockChange, deleteCart } from "../../features/cartOperation";
import { useDispatch } from "react-redux";
import { SpaceBox } from "../../components/UIKit/SpaceBox";

interface PROPS {
  cartData: CartProduct;
  cartStock?: number[];
  deleteBoolLength: number;
  index?: number;
  setCartStock?: Function;
}

const CartItem = (props: PROPS) => {
  const useStyles = makeStyles({
    PurchaseHistoryArea: {
      paddingTop: 20,
      borderBottom: "1px solid rgb(234, 234, 234)",
    },
    PurchaseHistory: {
      display: "flex",
    },
    PurchaseImg: {
      flex: 4,
    },
    icon: {
      margin: "0 auto",
      objectFit: "cover",
      width: 160,
      height: 160,
      display: "block",
    },
    purchaseInfo: {
      flex: 5,
      "& p": {
        margin: "11px auto 0",
        textAlign: "center",
        fontSize: 14,
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    purchaseAndLength: {
      display: "flex",
    },
    place: {
      flex: 7,
      paddingLeft: 10,
      "& p": {
        textAlign: "left",
      },
    },
    purchaseLengthAndDeleteArea: {
      flex: 3,
    },
    subtotal: {
      marginTop: 10,
    },
    dummyArea: {
      height: 20,
    },
    priceArea: {},
    stockArea: {},
    redText: {
      color: "red",
    },
    stockUpDownArea: {
      display: "flex",
    },
    stockText: {
      cursor: "pointer",
      display: "block",
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const cartData = props.cartData;
  const price = cartData.retailPrice.toLocaleString();
  const totalPrice = cartData.retailPrice * cartData.quantity;
  const totalPriceString = totalPrice.toLocaleString();

  const stockDownCheck = () => {
    if (cartData.quantity === 1) {
      const dummyStockData = JSON.parse(JSON.stringify(props.cartStock));
      props.index &&
        props.setCartStock &&
        dispatch(
          deleteCart(
            cartData.cartRef,
            dummyStockData,
            props.index,
            props.setCartStock
          )
        );
    } else {
      dispatch(cartStockChange(cartData.cartRef, -1));
    }
  };

  const cartItemDelete = () => {
    const dummyStockData = JSON.parse(JSON.stringify(props.cartStock));
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.PurchaseHistoryArea}>
      <div className={classes.PurchaseHistory}>
        <div className={classes.PurchaseImg}>
          <img src={cartData.image} className={classes.icon} alt="cartImage" />
        </div>
        <div className={classes.purchaseInfo}>
          <p>{cartData.projectName}</p>
          <GrayBorder />
          <p>{cartData.size}</p>
          <GrayBorder />
          <p>{cartData.color}</p>
          <GrayBorder />
          <div className={classes.purchaseAndLength}>
            <div className={classes.place}>
              <div className={classes.priceArea}>
                <p>¥{price}</p>
              </div>
              <div className={classes.stockArea}>
                {props.cartStock &&
                  props.cartStock.map(
                    (cs: number, index: number) =>
                      props.index === index &&
                      (cartData.quantity <= cs ? (
                        <Typography key={index}>残り{cs}点</Typography>
                      ) : (
                        <Typography className={classes.redText} key={index}>
                          在庫不足残り{cs}点
                        </Typography>
                      ))
                  )}

                {/* {cartData.quantity <= stock ? (
                  <Typography>残り{stock}点</Typography>
                ) : (
                  <Typography className={classes.redText}>
                    在庫不足残り{stock}点
                  </Typography>
                )} */}
              </div>
              <p className={classes.subtotal}>小計　¥{totalPriceString}</p>
            </div>
            <div className={classes.purchaseLengthAndDeleteArea}>
              <div className={classes.stockUpDownArea}>
                <Typography
                  className={classes.stockText}
                  onClick={() => stockDownCheck()}
                >
                  ◀
                </Typography>
                <Typography className={classes.stockText}>
                  {cartData.quantity}点
                </Typography>
                <Typography
                  className={classes.stockText}
                  onClick={() => dispatch(cartStockChange(cartData.cartRef, 1))}
                >
                  ▶
                </Typography>
              </div>
              {props.deleteBoolLength === 3 && (
                <Button variant="outlined" onClick={() => cartItemDelete()}>
                  削除
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <SpaceBox height={15} />
    </div>
  );
};

export default CartItem;
