import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { SpaceBox } from "./SpaceBox";
import PrimaryButton from "./PrimaryButton";

type Props = {
  setCheckModal: React.Dispatch<React.SetStateAction<boolean>>;
  userInfo: any;
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
};

const Confirm = (props: Props) => {
  const { setCheckModal, userInfo, setImages } = props;
  const useStyles = makeStyles({
    modalArea: {
      border: "1px solid gray",
      width: 500,
      height: 200,
      backgroundColor: "white",
      borderRadisu: 10,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    btnArea: {
      width: 200,
      margin: "0 auto",
    },
  });
  const classes = useStyles();

  let imageBox: any = userInfo.images.map((img: any, i: number) => {
    const w = Math.ceil(Math.random() * 2);
    const y = Math.ceil(Math.random() * 2) + 1;
    return {
      image: img,
      x: (i * 2) % 3,
      y: Math.floor(i / 6) * y,
      w: w,
      h: y,
      i: i.toString(),
    };
  });

  const changeClick = () => {
    setImages(imageBox);
    setCheckModal(false);
  };
  return (
    <div className={classes.modalArea}>
      <SpaceBox height={30} />
      <Typography align="center">ランダムで配置が変わります。</Typography>
      <Typography align="center">元に戻せないのでご注意ください。</Typography>
      <div className={classes.btnArea}>
        <PrimaryButton label={"変更"} onClick={() => changeClick()} />
        <PrimaryButton
          label={"キャンセル"}
          margin={"0 0 0 20px"}
          onClick={() => setCheckModal(false)}
        />
      </div>
    </div>
  );
};

export default Confirm;
