import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { ProductSlider } from "./ProductParts/ProductSlider";
import { ProductIntroduction } from "./ProductParts/ProductIntroduction";
import { ProductReserve } from "./ProductParts/ProductReserve";
import Modal from "react-modal";
import { ThreeDAvatar } from "./ProductParts/ThreeDAvatar";
import { projectData, sizeRange } from "../../features/productUtil";
import { animateScroll as scroll } from "react-scroll";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import {
  SalesProject,
  Products,
  LatestColorSalesInfo,
} from "../../Types/productType";
import { RouteComponentProps } from "react-router-dom";
import { ProductPrice } from "./ProductParts/ProductPrice";
import { ProductThreeDImageAccess } from "./ProductParts/ProductThreeDImageAccess";
import { ProductColorSize } from "./ProductParts/ProductColorSize";
import { Colors, Label } from "../../Types/projectType";
import { ProductLimitCount } from "./ProductParts/ProductLimitCount";
import { ProductExplanation } from "./ProductParts/ProductExplanation";
import { ProductSizeDetail } from "./ProductParts/ProductSizeDetail";
import { ProductBuyButton } from "./ProductParts/ProductBuyButton";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { ProjectPost } from "../Post/ProjectPost";
import { getDocumentRef, projectDocument } from "../../firebasePaths";
import { collection, getDoc, getDocs } from "firebase/firestore";
import { Center } from "../../components/atoms/Layout/Center";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { Text } from "../../components/atoms/Text/Text";
import { toast } from "react-toastify";
import { AppThought } from "./AppThought";
import { logEvent } from "firebase/analytics";
import { analytics, analyticsCustomLogEvent } from "../../firebase";
import { ReviewList } from "../../components/review/ReviewList";
import {
  calculationPrice,
  isWithinPeriod,
} from "../../features/helpers/projectHelper";

type SizeColorUnion = {
  value: string;
  limit: number;
  label: string;
  size: string;
};

export const ProductSales = (
  props: RouteComponentProps<{ id: string; uid: string }>
) => {
  const { match, history } = props;
  const useStyles = makeStyles({
    overlay: {
      margin: "0 auto",
      width: 500,
      opacity: 1,
      height: "100%",
      transform: "translateX(0px)",
      transition: "all 300ms ease-in-out",
      zIndex: 3,
      backgroundColor: "white",
      outline: "none",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    noteArea: {
      padding: "0 20px",
    },
    productArea: {
      overflowX: "hidden",
    },
  });
  const classes = useStyles();

  const projectID = match.params.id;
  const creatorUid = match.params.uid;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [project, setProject] = useState<SalesProject>(projectData);
  const [salesDetailData, setSalesDetailData] = useState<
    LatestColorSalesInfo[]
  >([]);
  const projectRef = projectDocument(creatorUid, projectID);
  const productsRef = collection(getDocumentRef(projectRef.path), "products");

  const { underReservation, inProduction, cancel, endOfSale } =
    project.salesStatus;
  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 0 });
  };

  const mergeData = (
    colorsData: SizeColorUnion[],
    productsData: Products[]
  ): LatestColorSalesInfo[] => {
    const mergedData = colorsData.reduce((acc, { value, limit, label }) => {
      const existingData = acc.find((data) => data.color === label);

      if (existingData) {
        existingData.limit = limit;
        existingData.value = value;
      } else {
        acc.push({
          color: label,
          value,
          limit,
          reserve: 0,
          detail: [],
          nowQuantity: 0,
        });
      }

      return acc;
    }, [] as LatestColorSalesInfo[]);

    productsData.forEach(
      ({ color, size, reserve, stock, productRef, reserveLimit }) => {
        const existingData = mergedData.find((data) => data.color === color);

        if (existingData) {
          existingData.reserve += reserve;
          existingData.detail.push({
            size,
            stock,
            ref: existingData.detail.some((d) => d.size === size)
              ? ""
              : productRef,
            selectedQuantity: 0,
            reserveLimit: reserveLimit ?? 0,
            targetReserve: reserve,
          });
        }
      }
    );

    const createResultItem = (
      item: LatestColorSalesInfo
    ): LatestColorSalesInfo => {
      const detail = item.detail.sort((a, b) => {
        const indexA = sizeRange.findIndex((r) => r.value === a.size);
        const indexB = sizeRange.findIndex((r) => r.value === b.size);
        return indexA - indexB;
      });
      return {
        ...item,
        detail,
      };
    };

    const result = mergedData.map(createResultItem);
    return result;
  };

  const salesItemDataConvert = (
    colors: Colors[],
    sizes: Label[],
    products: Products[]
  ) => {
    const sizeColorUnion = colors
      ?.map((color) => {
        const sizeMix = sizes.map((size) => {
          return { ...color, size: size.label };
        });
        return sizeMix;
      })
      .flatMap((innerArray) => innerArray);
    const result = mergeData(sizeColorUnion, products);
    setSalesDetailData(result);
  };

  const fetchData = async () => {
    try {
      const projectDoc = await getDoc(projectRef);
      const projectData = projectDoc.data() as SalesProject;
      const snapshot = await getDocs(productsRef);
      const products = snapshot.docs.map((doc) => doc.data()) as Products[];
      setProject(projectData);
      salesItemDataConvert(projectData.colors, projectData.size, products);
    } catch (error) {
      toast.error("商品の情報取得に失敗しました。\nリロードしてください。");
      return;
    }
  };

  const {
    startDiscountPeriod,
    endDiscountPeriod,
    periodDiscountRate,
    retailPrice,
  } = project;

  const calcPrice = calculationPrice(
    String(retailPrice),
    startDiscountPeriod,
    endDiscountPeriod,
    periodDiscountRate
  );

  useEffect(() => {
    scrollToTop();
    fetchData();
    logEvent(analytics, "view_item");
  }, []);

  return (
    <div className={classes.productArea}>
      <BrowserBackHeader
        label={`${project?.memer.label} Project`}
        browserBack={history}
      />
      <SpaceBox height={20} />
      <ProductSlider images={project.images} />
      <Center
        className={{
          marginTop: 20,
        }}
      >
        <div className={classes.noteArea}>
          <Text
            color="black"
            text="※商品画像はサンプルのため、色味やサイズ、素材の混率等の仕様に変更がある場合がございますので、予めご了承ください。また、使用しているパソコンのモニターやスマートフォン等の設定により、現物と画像の色味が若干異なる場合もございます、予めご了承ください。"
          />
        </div>
      </Center>
      <ProductIntroduction
        memerComment={project.memerComment}
        projectName={project.projectName}
      />
      {/* TODO: 発送終わった時点など、タイミングによって表示を変える status.saleで消える予定*/}
      {(underReservation || inProduction) && (
        <ProductReserve toBeShipDate={project.toBeShipDate} />
      )}
      <ProductPrice
        retailPrice={String(retailPrice)}
        calcPrice={calcPrice}
        startDiscountPeriod={String(startDiscountPeriod)}
        endDiscountPeriod={String(endDiscountPeriod)}
        periodDiscountRate={periodDiscountRate}
        isWithinPeriod={isWithinPeriod(
          startDiscountPeriod,
          endDiscountPeriod,
          periodDiscountRate
        )}
      />
      <ProductColorSize
        salesDetailData={salesDetailData}
        setSalesDetailData={setSalesDetailData}
        project={project}
      />
      {!cancel && !endOfSale && (
        <ProductBuyButton
          project={project}
          salesDetailData={salesDetailData}
          calcPrice={calcPrice}
        />
      )}
      {/* {!project.salesStatus.sale && (
        <>
          <ProductSaleInfo
            isAdded={project.isAdded}
            salesDetailData={salesDetailData}
            minimumProductionQuantity={project.minimumProduction}
            salesStatus={project.salesStatus}
          />
        </>
      )} */}
      <ReviewList creatorUid={creatorUid} />
      <AppThought />
      {/* <ProductColorSize
        salesDetailData={salesDetailData}
        setSalesDetailData={setSalesDetailData}
      />
      {!project.salesStatus.cancel && !project.salesStatus.endOfSale && (
        <ProductBuyButton project={project} salesDetailData={salesDetailData} />
      )} */}
      <ProductLimitCount
        salesDetailData={salesDetailData}
        minimumProduction={project.minimumProduction}
      />
      <ProductExplanation text={project.itemDescription} title="商品説明" />
      <ProductSizeDetail
        image={project.measuringImage}
        detailSize={project.detailSize}
        sizeDataTable={project.sizeDataTable}
      />
      <ProductThreeDImageAccess setModalIsOpen={setModalIsOpen} />
      <Center
        className={{
          marginTop: 20,
        }}
      >
        <Text
          text="↓↓↓ 3Dで動かしてみたい方はこちら ↓↓↓"
          color="black"
          className={{
            letterSpacing: 1.5,
          }}
        />
      </Center>
      <Center
        className={{
          marginTop: 20,
        }}
      >
        <a href={project.closetUrl} target="_blank" rel="noopener noreferrer">
          <GrayButton
            width={340}
            backgroundColor="rgb(106,106,106)"
            onClick={() => analyticsCustomLogEvent("3d_avatar_clicked")}
          >
            3D Avatar
          </GrayButton>
        </a>
      </Center>
      <Center
        className={{
          marginTop: 20,
        }}
      >
        <div>
          <Text text="Avatar サイズ" fontSize={14} isDark />
          <Text text={`身長　　${project.closetHeight}cm`} isDark />
          <Text text={`バスト　${project.closetBust}cm`} isDark />
        </div>
      </Center>
      <Center
        className={{
          marginTop: 20,
        }}
      >
        <Center
          className={{
            width: 300,
          }}
        >
          <Text
            text="3D Avatarはデータ量が多いためWi-Fi環境での使用をお勧めします。"
            color="black"
            className={{
              fontWeight: "bold",
              letterSpacing: 1.5,
            }}
          />
        </Center>
      </Center>
      <ProductExplanation
        text={project.materialAttention}
        title="素材及びお取り扱い上の注意"
      />
      <SpaceBox height={40} />

      <ProjectPost projectRef={project.projectRef} />
      <SpaceBox height={40} />
      <Modal
        closeTimeoutMS={300}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className={classes.overlay}
        overlayClassName={{
          base: "overlayThreeD-base",
          afterOpen: "overlayThreeD-after",
          beforeClose: "overlayThreeD-before",
        }}
      >
        <ThreeDAvatar
          project={project}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
        />
      </Modal>
    </div>
  );
};
