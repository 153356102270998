import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

type Props = {
  toBeShipDate: string;
};

export const ProductReserve = (props: Props) => {
  const { toBeShipDate } = props;
  const useStyles = makeStyles({
    reverseArea: {
      marginTop: 20,
      color: "rgb(74,74,74)",
    },
    textTop: {
      paddingTop: 13,
      width: 275,
      marginLeft: 15,
    },
    textBottom: {
      paddingTop: 3,
      width: 275,
      marginLeft: 15,
    },
    reverseProduct: {
      fontSize: 14,
      fontWeight: "bold",
    },
    reverseShip: {
      fontSize: 14,
    },
  });
  const classes = useStyles();
  const shipMonth = new Date(toBeShipDate).getMonth();
  const shipDate = new Date(toBeShipDate).getDate();
  const dateReturn = (day: number) => {
    if (day <= 10) {
      return "上旬";
    } else if (day >= 10 && day <= 20) {
      return "中旬";
    } else if (day >= 20 && day <= 31) {
      return "下旬";
    }
    return;
  };

  const season = dateReturn(shipDate);

  return (
    <div className={classes.reverseArea}>
      <div className={classes.textTop}>
        <Typography className={classes.reverseProduct}>予約商品</Typography>
      </div>
      <div className={classes.textBottom}>
        <Typography className={classes.reverseShip}>{`発送予定:${
          shipMonth + 1
        }月${season}から順次発送`}</Typography>
      </div>
    </div>
  );
};
