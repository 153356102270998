import {
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  startAfter,
} from "firebase/firestore";
import {
  projectDocument,
  purchaseHistoryDocument,
  reviewDocument,
  reviewsCollection,
  userDocument,
} from "../firebasePaths";
import { Project } from "../Types/projectType";
import { db } from "../firebase";
import dayjs from "dayjs";
import { GetReview } from "../Types/reviewType";
import { User } from "../Types/userType";

export const fetchReviewProject = async (
  creatorId: string,
  projectId: string
) => {
  const projectRef = projectDocument(creatorId, projectId);
  const project = await getDoc(projectRef);
  return project.data() as Project;
};

export const postReview = async (
  creatorId: string,
  reviewComment: string | undefined,
  reviewScore: number,
  userName: string,
  uid: string,
  projectId: string,
  purchaseHistoryId: string,
  projectName: string,
  projectImage: string
) => {
  const transactionResult = await runTransaction(db, async (transaction) => {
    const purchaseHistoryRef = purchaseHistoryDocument(uid, purchaseHistoryId);
    const purchaseHistoryDoc = await transaction.get(purchaseHistoryRef);
    const targetPurchaseHistory = purchaseHistoryDoc.data();

    const targetProjectId = targetPurchaseHistory?.projectId;
    if (targetProjectId !== projectId)
      throw new Error("この商品のレビューはできません。");

    const reviewRef = reviewDocument(creatorId, purchaseHistoryId);
    const reviewDoc = await transaction.get(reviewRef);
    if (reviewDoc.exists())
      throw new Error("この商品のレビューは既に行われている可能性があります。");

    const reviewData = {
      reviewComment: reviewComment ?? "",
      reviewScore,
      userName,
      uid,
      projectName,
      image: projectImage,
      postedAt: serverTimestamp(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };
    transaction.set(reviewRef, reviewData);
    transaction.set(purchaseHistoryRef, { isReview: true }, { merge: true });
  });

  return transactionResult;
};

export const getReviews = async (
  creatorUid: string,
  lastDoc: DocumentSnapshot | null
) => {
  const reviewRef = reviewsCollection(creatorUid);
  let reviewsQuery = query(reviewRef, orderBy("reviewScore", "desc"), limit(3));
  if (lastDoc) {
    reviewsQuery = query(reviewsQuery, startAfter(lastDoc));
  }
  const querySnapshot = await getDocs(reviewsQuery);
  const data = querySnapshot.docs.map((doc) => {
    const data = doc.data() as GetReview;
    return {
      ...data,
      id: doc.id,
      postedAt: dayjs(data.postedAt.seconds * 1000).format("YYYY/MM/DD"),
    };
  });
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

  return { data, lastDoc: lastVisible };
};

export const fetchCreatorName = async (creatorUid: string) => {
  const creatorRef = userDocument(creatorUid);
  const creator = await getDoc(creatorRef);
  const data = creator.data() as User;
  return data?.displayName;
};
