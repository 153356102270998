import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import StarRateIcon from "@material-ui/icons/StarRate";
import { Review as ReviewType } from "../../Types/reviewType";
import { Text } from "../atoms/Text/Text";

type Props = {
  review: ReviewType & { id: string };
};

const useStyles = makeStyles({
  root: {
    maxWidth: 480,
    padding: 10,
    backgroundColor: "rgb(245, 245, 245)",
    "@media screen and (max-width: 499px)": {
      maxWidth: 350,
    },
    margin: "0 auto 10px",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  starIcon: {
    color: "#FFD700",
    verticalAlign: "bottom",
    fontSize: 20,
  },
  greyStarIcon: {
    color: "#ccc",
    verticalAlign: "bottom",
    fontSize: 20,
  },
  reviewContent: {
    display: "flex",
    gap: 20,
  },
  reviewImageContent: {
    width: 100,
    height: 100,
    "@media screen and (max-width: 499px)": {
      width: 80,
      height: 80,
    },
  },
  image: {
    width: 100,
    height: 100,
    objectFit: "cover",
    "@media screen and (max-width: 499px)": {
      width: 80,
      height: 80,
    },
  },
  reviewCommentContent: {
    width: "100%",
    minWidth: 200,
    padding: "0 20px",
  },
  commentContent: {
    width: "100%",
    minWidth: 200,
    padding: 10,
    backgroundColor: "white",
  },
  reviewHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  reviewScoreContent: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const Review = (props: Props) => {
  const { review } = props;
  const classes = useStyles();
  const fullStars = Array(review.reviewScore).fill(
    <StarRateIcon className={classes.starIcon} />
  );
  const emptyStars = Array(5 - review.reviewScore).fill(
    <StarRateIcon className={classes.greyStarIcon} />
  );
  return (
    <div className={classes.root} key={review.id}>
      <div className={classes.reviewContent}>
        <div className={classes.reviewImageContent}>
          <img
            src={review.image}
            alt="projectImage"
            className={classes.image}
          />
        </div>
        <div className={classes.reviewCommentContent}>
          <div className={classes.reviewHeader}>
            <Text isDark text={review.projectName} />
            <Text isDark text={review.postedAt} />
          </div>
          <div className={classes.reviewScoreContent}>
            <Typography>
              {fullStars}
              {emptyStars}
            </Typography>
            <Text
              isDark
              text={review.userName}
              className={{ verticalAlign: "bottom" }}
            />
          </div>
          <div className={classes.commentContent}>
            <Text
              text={review.reviewComment}
              isDark
              className={{ overflowWrap: "break-word" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
