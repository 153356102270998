import { auth } from "../firebase/index";
import { push } from "connected-react-router";
import { AppThunk } from "../app/store";
import { CartProduct } from "../Types/productType";
import {
  cartsCollection,
  cartsDocument,
  getDocumentRef,
} from "../firebasePaths";
import {
  deleteDoc,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { logError } from "../lib/logError";

const timestamp = serverTimestamp();

export const cartIn = (setCartData: Omit<CartProduct, "cartRef">): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;

    const cartRef = cartsDocument(uid);
    const cartsRef = cartsCollection(uid);
    let refBox: string[] = [];
    let cartDataBox: CartProduct[] = [];

    getDocs(cartsRef).then((snapshot: any) => {
      if (snapshot.docs.length > 0) {
        snapshot.forEach((cart: any) => {
          const cartData = cart.data();
          refBox.push(cartData.productRef);
          cartDataBox.push(cartData);
        });

        if (refBox.some((value) => value === setCartData.productRef)) {
          dispatch(cartUpdate(setCartData, cartDataBox));
        } else {
          setDoc(cartRef, {
            ...setCartData,
            cartRef: cartRef.id,
            createdAt: timestamp,
          })
            .then(() => {
              dispatch(push(`/cart/${uid}`));
            })
            .catch(() => {
              alert("失敗しました");
            });
        }
      } else {
        setDoc(cartRef, {
          ...setCartData,
          cartRef: cartRef.id,
          createdAt: timestamp,
        })
          .then(() => {
            dispatch(push(`/cart/${uid}`));
          })
          .catch(() => {
            alert("失敗しました");
          });
      }
    });
  };
};

const cartUpdate = (
  setCartData: Omit<CartProduct, "cartRef">,
  cartData: CartProduct[]
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;

    cartData.map((cd: CartProduct, index: number) => {
      if (cd.productRef === setCartData.productRef) {
        const newQuantity = cd.quantity + setCartData.quantity;
        const updateCartRef = cartsDocument(uid, cd.cartRef);

        setDoc(
          updateCartRef,
          { quantity: newQuantity, updateAt: timestamp },
          { merge: true }
        )
          .then(() => {
            dispatch(push(`/cart/${uid}`));
          })
          .catch((err: Error) => {
            logError(err);
          });
      }
    });
  };
};

export const fetchCart = (
  setCartData: Function,
  setCartStock: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    let unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const cartRef = cartsCollection(user.uid);

        onSnapshot(cartRef, (snapshot) => {
          setCartData(
            snapshot.docs.map((doc) => ({
              ...doc.data(),
            }))
          );
        });

        getDocs(cartRef).then((snapshot) => {
          snapshot.forEach((doc) => {
            const cartData = doc.data();
            const productRef = getDocumentRef(cartData.productRef);
            getDoc(productRef).then((product) => {
              const productData = product.data();
              setCartStock((prev: number[]) => [...prev, productData?.stock]);
            });
          });
        });
      }
      unsubscribe();
    });
  };
};

export const stockCheck = (
  setStock: Function,
  productRef: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const productStockRef = getDocumentRef(productRef);

    getDoc(productStockRef).then((product) => {
      const productData = product.data();
      setStock(productData?.stock);
    });
  };
};

export const deleteCart = (
  cartRef: string,
  stockData?: number[],
  index?: number,
  setCartStock?: Function | undefined
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const cartsRef = cartsDocument(uid, cartRef);
    if (window.confirm("アイテムを削除します")) {
      deleteDoc(cartsRef)
        .then((res) => {
          index && stockData?.splice(index, 1);
          setCartStock && setCartStock(stockData);

          if (index === 0) {
            window.location.reload();
          }
        })
        .catch((err) => {
          logError(err);
        });
    }
  };
};

export const cartDataLength = (setCartData: Function): AppThunk => {
  return async (dispatch): Promise<void> => {
    let unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const cartRef = cartsCollection(user.uid);
        onSnapshot(cartRef, (snapshot) => {
          snapshot.docs.map((doc) => ({
            ...doc.data(),
          }));
        });
      }
      unsubscribe();
    });
  };
};

export const cartAllDelete = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    let unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const cartsRef = cartsCollection(user.uid);
        getDocs(cartsRef).then((snapshot) => {
          snapshot.forEach((doc) => {
            deleteDoc(doc.ref);
          });
        });
      }
      unsubscribe();
    });
  };
};

export const cartStockChange = (
  cartRef: string,
  changeNumber: number
): AppThunk => {
  return async (dispatch): Promise<void> => {
    let unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const cartsRef = cartsDocument(user.uid, cartRef);

        setDoc(
          cartsRef,
          { quantity: increment(changeNumber) },
          { merge: true }
        );
      }
      unsubscribe();
    });
  };
};
