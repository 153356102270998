import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

type Props = {
  photoUrl: string;
  displayName: string;
  selected: boolean;
};

export const MentionPostTo = React.memo((props: Props) => {
  const { displayName, photoUrl, selected } = props;
  const useStyles = makeStyles({
    card: {
      height: 70,
      cursor: "pointer",
    },
    userInfoBar: {
      height: 70,
      display: "flex",
    },
    Name: {
      lineHeight: 6,
      fontSize: 12,
    },
    userIcon: {
      width: 120,
      height: 60,
      marginTop: 6,
    },
    iconBox: {
      display: "grid",
      width: 58,
      height: 58,
      marginLeft: 40,
      textAlign: "center",
      justifyContent: "center",
      alignContent: "center",
    },
    Icon: {
      borderRadius: 10,
      width: 54,
      height: 54,
      objectFit: "cover",
    },
    checked: {
      backgroundColor: "rgb(210, 210, 210)",
      height: "100%",
    },
    unChecked: {
      height: "100%",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={selected ? classes.checked : classes.unChecked}>
        <div className={classes.userInfoBar}>
          <div className={classes.userIcon}>
            <div className={classes.iconBox}>
              <img src={photoUrl} className={classes.Icon} alt="icon" />
            </div>
          </div>
          <Typography className={classes.Name}>{displayName}</Typography>
        </div>
      </div>
    </div>
  );
});
