import { useCallback, useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import threeLine from "../../assets/icon/threeLine.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import tomemeLogo from "../../assets/icon/tomemeLogo.png";
import "../../vertical.css";
import cart from "../../assets/icon/cart.png";
import notice from "../../assets/icon/notice.png";
import want from "../../assets/icon/header-want.png";
import { Info } from "../../Types/userType";
import { fetchInfoUnRead } from "../../features/postOperation";
import { selectUser } from "../../features/userSlice";
import { openModal } from "../../features/controlBoolSlice";
import { animateScroll as scroll } from "react-scroll";
import { CartProduct } from "../../Types/productType";
import { cartDataLength } from "../../features/cartOperation";
import Headroom from "react-headroom";

const useStyles = makeStyles((theme) => ({
  titleCenter: {
    textAlign: "center",
    fontWeight: "lighter",
    marginTop: 20,
    marginBottom: 0,
    cursor: "pointer",
  },
  headerBorder: {
    margin: "0 auto",
    borderBottom: "0.5px solid rgba(0,0,0, 0.2)",
    backgroundColor: "white",
    width: 500,
    "@media screen and (max-width: 499px)": {
      width: "100vw",
      margin: "0",
    },
  },
  hamburger: {
    width: 23,
    height: 23,
    margin: "18px 0 0 20px",
    float: "left",
    "@media screen and (max-width: 499px)": {
      width: 15,
      height: 15,
      marginTop: 14,
    },
  },
  hamburgerMenu: {
    flex: 1,
    cursor: "pointer",
  },
  mainHeader: {
    width: 500,
    height: 54,
    display: "flex",
    "@media screen and (max-width: 499px)": {
      width: "100vw",
      height: 38,
      margin: "0",
    },
  },
  mainTitle: {
    flex: 5,
  },
  MeMEicon: {
    width: 100,
    margin: "22px auto 0",
    display: "block",
    objectFit: "cover",
    cursor: "pointer",
    "@media screen and (max-width: 499px)": {
      width: 80,
      marginTop: 15,
    },
  },
  wantArea: {
    flex: 1,
    position: "relative",
  },
  cartIconArea: {
    flex: 1,
    position: "relative",
  },
  wantIcon: {
    width: 35,
    height: 35,
    display: "block",
    margin: "15px auto 0",
    cursor: "pointer",
    "@media screen and (max-width: 499px)": {
      width: 17,
      height: 17,
      marginTop: 15,
    },
  },
  cartIcon: {
    width: 25,
    height: 25,
    display: "block",
    margin: "18px auto 0",
    cursor: "pointer",
  },
  countIcon: {
    position: "absolute",
    top: 8,
    left: "50%",
    width: 22,
    height: 20,
    paddingTop: 2,
    backgroundColor: "rgb(255,185,185)",
    borderRadius: 15,
    cursor: "pointer",
    "@media screen and (max-width: 499px)": {
      width: 17,
      height: 15,
      paddingTop: 1,
    },
  },
  lengthText: {
    fontSize: 12,
    color: "white",
    "@media screen and (max-width: 499px)": {
      fontSize: 10,
    },
  },
}));

export const MainHeader = (): JSX.Element => {
  const { push } = useHistory();
  const classes = useStyles();

  const { isSignedIn, uid } = useSelector(selectUser);
  const [cartData, setCartData] = useState<CartProduct[]>([]);

  const cartLengthCheck = cartData.length;
  const dispatch = useDispatch();

  const scrollTopMove = () => {
    scroll.scrollToTop({ duration: 0 });
    push("/menu");
  };

  const inputCart = useCallback((cart) => {
    setCartData(cart);
  }, []);

  const [newInfo, setNewInfo] = useState<Info[]>([]);

  const infoLengthCheck = newInfo.length;
  const infoLength = infoLengthCheck.toString();

  const inputNewInfo = useCallback((newInfo) => {
    setNewInfo(newInfo);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(fetchInfoUnRead(inputNewInfo));
      dispatch(cartDataLength(inputCart));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Headroom style={{ zIndex: 100 }}>
      <div className={classes.headerBorder} id="border">
        <div className={classes.mainHeader}>
          <div className={classes.hamburgerMenu}>
            <img
              src={threeLine}
              className={classes.hamburger}
              onClick={() => scrollTopMove()}
              alt="threeLine"
            />
          </div>
          <div className={classes.mainTitle}>
            <img
              src={tomemeLogo}
              className={classes.MeMEicon}
              onClick={() => push("/")}
              alt="memeIcon"
            />
          </div>
          {/* <div
            className={classes.bellIconArea}
            onClick={() => (isSignedIn ? push("/info") : dispatch(openModal()))}
          >
            {infoLengthCheck > 0 && (
              <div className={classes.countIcon}>
                <Typography align="center" className={classes.lengthText}>
                  {infoLength}
                </Typography>
              </div>
            )}
            <img src={notice} className={classes.bellIcon} alt="bell" />
          </div> */}
          <div
            className={classes.wantArea}
            onClick={() =>
              isSignedIn
                ? push(`/wanted-project/${uid}`)
                : dispatch(openModal())
            }
          >
            {infoLengthCheck > 0 && (
              <div className={classes.countIcon}>
                <Typography align="center" className={classes.lengthText}>
                  {infoLength}
                </Typography>
              </div>
            )}
            <img src={want} className={classes.wantIcon} alt="bell" />
          </div>
          {/* <div
            className={classes.cartIconArea}
            onClick={() =>
              isSignedIn ? push(`/cart/${uid}`) : dispatch(openModal())
            }
          >
            {cartLengthCheck > 0 && (
              <div className={classes.countIcon}>
                <Typography align="center" className={classes.lengthText}>
                  {cartLength}
                </Typography>
              </div>
            )}
            <img src={cart} className={classes.cartIcon} alt="cart" />
          </div> */}
        </div>
      </div>
    </Headroom>
  );
};
