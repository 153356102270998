export const creatorInfo = {
  uid: "",
  displayName: "",
  photoUrl: "",
  selfIntroduction: "",
  followCount: 0,
  followerCount: 0,
  postCount: 0,
  role: "",
  images: [],
  topProfile: "",
  bottomProfile: "",
  layouts: [],
  profileColor: undefined,
  // layouts: [{ w: 0, h: 0, x: 0, y: 0, i: '' }],
};

export const infoUtil = {
  likeInfo: false,
  followInfo: false,
  commentInfo: false,
  wantInfo: false,
  mentionInfo: false,
};

export const profileEditUserInfo = {
  displayName: "",
  notice: false,
  twitterAccount: "",
  tiktokAccount: "",
  instagramAccount: "",
  selfIntroduction: "",
};
