import { makeStyles } from "@material-ui/core";
import { QuiltedFiveImages } from "./QuiltedFiveImages";
import { QuiltedFourImages } from "./QuiltedFourImages";
import { QuiltedOneImages } from "./QuiltedOneImages";
import { QuiltedThreeImages } from "./QuiltedThreeImages";
import { QuiltedTwoImages } from "./QuiltedTwoImages";
import { Images } from "../../Types/imageType";
import { useWindowSize } from "react-use";

type Props = {
  images: Images[];
  setImages: React.Dispatch<React.SetStateAction<Images[]>>;
};

export const SwitchQuiltedImages = (props: Props): JSX.Element => {
  const { images, setImages } = props;
  const { width } = useWindowSize();
  const useStyles = makeStyles({
    info: {
      width: 500,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
      "& p": {
        textAlign: "center",
      },
    },
    imageArea: {
      width: 198,
      height: 248,
      border: "1px solid rgb(239,239,239)",
      margin: "30px auto 0",
      "& p": {
        textAlign: "center",
        lineHeight: 13,
      },
    },
    cameraImg: {
      width: 150,
      display: "block",
      margin: "40px auto 0",
    },
  });
  const classes = useStyles();
  switch (images.length) {
    case 5:
      return (
        <QuiltedFiveImages
          images={images}
          setImages={setImages}
          currentWidth={width}
        />
      );
    case 4:
      return (
        <QuiltedFourImages
          images={images}
          setImages={setImages}
          currentWidth={width}
        />
      );
    case 3:
      return (
        <QuiltedThreeImages
          images={images}
          setImages={setImages}
          currentWidth={width}
        />
      );
    case 2:
      return (
        <QuiltedTwoImages
          images={images}
          setImages={setImages}
          currentWidth={width}
        />
      );
    case 1:
      return (
        <QuiltedOneImages
          images={images}
          setImages={setImages}
          currentWidth={width}
        />
      );
    default:
      return (
        <>
          <div className={classes.imageArea}>
            <p>写真が選択されていません</p>
          </div>
        </>
      );
  }
};
