import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const CommentSkeleton = () => {
  const useStyles = makeStyles({
    addCommentArea: {
      display: "flex",
    },
    addIcon: {
      flex: 1,
    },
    addName: {
      flex: 1.2,
    },
    dummyArea: {
      flex: 0.5,
    },
    addComment: {
      flex: 5,
      "& p": {
        fontSize: 12,
        marginTop: 28,
        marginBottom: 0,
      },
    },
    loadingIcon: {
      borderRadius: 10,
      margin: "10px 0 10px 10px",
    },
    returnArea: {
      flex: 1.2,
      "& p": {
        fontSize: 10,
        color: "rgb(112,112,112)",
        borderBottom: "1px solid rgb(112,112,112)",
        textAlign: "center",
        display: "block",
        margin: "30px 9px 0",
        "@media screen and (max-width: 499px)": {
          margin: "30px 2px 0 ",
        },
      },
    },
    returnText: {
      cursor: "pointer",
    },
    border: {
      width: "95%",
      borderBottom: "1px solid rgb(151,151,151)",
      margin: "0 auto 0",
    },
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.addCommentArea}>
        <div className={classes.addIcon}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.loadingIcon}
            width={50}
            height={50}
          />
        </div>
        <div className={classes.addName}>
          <Skeleton
            animation="wave"
            height={10}
            width="90%"
            style={{ marginTop: 29, marginLeft: 5 }}
          />
        </div>
        <div className={classes.dummyArea}></div>
        <div className={classes.addComment}>
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginTop: 29, marginLeft: 7 }}
          />
        </div>
        <div className={classes.returnArea}>
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginTop: 29, marginLeft: 14 }}
          />
        </div>
        <div>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.loadingIcon}
            width={18}
            height={24}
            style={{ marginTop: 23, marginRight: 4 }}
          />
        </div>
      </div>
      <div className={classes.border} />
    </div>
  );
};

export default CommentSkeleton;
