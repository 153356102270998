import { CircularProgress, Typography, makeStyles } from "@material-ui/core";

type Props = {
  className?: object;
  text?: string;
};
export const Loading = (props: Props) => {
  const { className, text } = props;
  const useStyles = makeStyles({
    loadingArea: {
      display: "flex", // 追加: flexboxを使用
      flexDirection: "column", // 追加: 子要素を縦に並べる
      alignItems: "center", // 追加: 中央揃え
      justifyContent: "center", // 追加: 中央揃え
      position: "fixed", // または 'absolute'
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      ...className,
      borderRadius: 10,
      backgroundColor: "rgba(230, 230, 230, 0.9)",
      width: 170,
      height: 170,
      zIndex: 1000,
    },
    textArea: {
      position: "fixed", // または 'absolute'
      top: "80%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    text: {
      marginTop: 10,
    },
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.loadingArea}>
        <CircularProgress
          size={40}
          color="primary"
          thickness={2.5}
          style={{ margin: "0 auto" }}
        />
        {text && (
          <div className={classes.textArea}>
            <Typography align="center" color="primary" className={classes.text}>
              {text}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
