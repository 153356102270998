import { useHistory } from "react-router";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { passwordReset } from "../../function/cloudFunctions";
import { RouteComponentProps } from "react-router-dom";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { logError } from "../../lib/logError";

export const PasswordReset = (props: RouteComponentProps) => {
  const { history } = props;
  const { push } = useHistory();

  const pwReset = () => {
    passwordReset()
      .then((res) => {
        if (
          window.confirm(
            "登録しているメールアドレス宛にパスワードリセットメールを送信しました。"
          )
        ) {
          push("/");
        }
      })
      .catch((err) => {
        logError(err);
      });
  };

  return (
    <div>
      <BrowserBackHeader label={"パスワード再設定"} browserBack={history} />
      <SpaceBox height={50} />
      <GrayButton onClick={() => pwReset()} width="100%">
        パスワード再設定メールを送る
      </GrayButton>
    </div>
  );
};
