import { makeStyles } from "@material-ui/core";
import { stripeDeletePaymentMethod } from "../../function/cloudFunctions";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Center } from "../atoms/Layout/Center";
import { Credit } from "../../Types/paymentType";
import { Text } from "../atoms/Text/Text";

type Props = {
  setDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  credit?: Credit;
  setUpdateActive: React.Dispatch<
    React.SetStateAction<{
      active: boolean;
      text: string;
    }>
  >;
};

export const CreditDeleteSelect = (props: Props) => {
  const { setDeleteOpen, credit, setUpdateActive } = props;

  const useStyles = makeStyles({
    modalArea: {
      padding: 20,
      backgroundColor: "white",
      zIndex: 100,
    },
    modalInfo: {
      marginBottom: 20,
    },
    modalBtnArea: {
      alignItems: "center",
      display: "flex",
    },
  });

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const yesHandleDeleteClose = () => {
    setDeleteOpen(false);
    setUpdateActive({ active: true, text: "削除中" });
    stripeDeletePaymentMethod({ prevPaymentMethodID: credit?.pmID })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {});
  };

  const classes = useStyles();
  return (
    <div className={classes.modalArea}>
      <div className={classes.modalInfo}>
        <Text
          fontSize={14}
          align="center"
          text="クレジットカードを削除しますか？"
        />
      </div>
      <div className={classes.modalBtnArea}>
        <Center
          className={{
            flex: 1,
          }}
        >
          <GrayButton width={80} onClick={() => yesHandleDeleteClose()}>
            はい
          </GrayButton>
        </Center>
        <Center
          className={{
            flex: 1,
          }}
        >
          <GrayButton width={100} onClick={() => handleDeleteClose()}>
            いいえ
          </GrayButton>
        </Center>
      </div>
    </div>
  );
};
