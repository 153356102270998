import { GrayButton } from "../../../components/atoms/GrayButton/GrayButton";
import { Center } from "../../../components/atoms/Layout/Center";
import { analyticsCustomLogEvent } from "../../../firebase";

export const ProductThreeDImageAccess = ({ setModalIsOpen }: any) => {
  const isOpen = () => {
    analyticsCustomLogEvent("size_based_3d_avatar_clicked");
    setModalIsOpen(true);
  };
  return (
    <Center
      className={{
        marginTop: 20,
      }}
    >
      <GrayButton
        className={{
          display: "block",
          borderRadius: 1,
          backgroundColor: "rgb(106,106,106)",
          color: "white",
        }}
        onClick={isOpen}
        width={340}
      >
        サイズ、身長別のイメージを見る
      </GrayButton>
    </Center>
  );
};
