import React, { useEffect, useState, createRef, useCallback } from "react";
import {
  Button,
  CircularProgress,
  Fade,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { Layout } from "react-grid-layout";
import { creatorInfo } from "../../features/userUtil";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import Backdrop from "@material-ui/core/Backdrop";
import Confirm from "../UIKit/Confirm";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { uploadMemerLayoutImage } from "../../features/imageOperation";
import { SketchPicker } from "react-color";
import type { ColorResult, RGBColor } from "react-color";
import { colorConvert } from "../../features/helpers/profileHelper";
import { SpaceBox } from "../UIKit/SpaceBox";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { toast } from "react-toastify";
import deleteMark from "../../assets/icon/close.png";
import { logError } from "../../lib/logError";
import { userDocument } from "../../firebasePaths";
import { getDoc, setDoc } from "firebase/firestore";
import { useWindowSize } from "react-use";
import { calculateSize } from "../../features/helpers/imageHelper";

type User = {
  uid: string;
  displayName: string;
  photoUrl: string;
  selfIntroduction: string;
  followCount: number;
  followerCount: number;
  role: string;
  images: string[];
  topProfile: string;
  bottomProfile: string;
  layouts: Layout[];
  profileColor?: RGBColor;
};

export const MeMerImageLayout = (
  props: RouteComponentProps<{ uid: string }>
) => {
  const { match, history } = props;
  const [userInfo, setUserInfo] = useState<User>(creatorInfo);
  const [color, setColor] = useState<RGBColor>({
    r: 255,
    g: 255,
    b: 255,
    a: 100,
  });

  const { width } = useWindowSize();

  const imageSize = calculateSize(width);
  const useStyles = makeStyles({
    photoArea: {
      backgroundColor: colorConvert(color),
      width: 500,
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    title: {
      height: 80,
    },
    photoPr: {
      lineHeight: 4,
    },
    img: {
      width: "100%",
      objectFit: "cover",
      borderRadius: 10,
    },
    box: {},
    btnArea: {},
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalArea: {},
    addPhotoBlock: {},
    addPhoto: {
      width: 28,
      margin: "10px auto 0",
      cursor: "pointer",
    },
    count: {
      width: 28,
      margin: "0 auto",
      paddingLeft: 9,
    },
    attachmentInput: {
      display: "none",
    },
    test: {
      position: "relative",
    },
    loading: {
      position: "fixed",
      top: "50%",
      left: "44%",
      zIndex: 10,
    },
    colorBtn: {
      marginTop: 15,
      backgroundColor: "white",
    },
    imageInputArea: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    noneInput: {
      display: "none",
    },
    invisibleFlame: {
      width: 166,
      height: 221,
      marginBottom: 1,
      "@media screen and (max-width: 499px)": {
        width: imageSize.width,
        height: imageSize.height,
      },
    },
    inputTouchArea: {
      width: "100%",
      height: "100%",
      backgroundColor: "rgb(240,240,240)",
      position: "relative",
      cursor: "pointer",
    },
    close: {
      position: "absolute",
      top: 5,
      right: 5,
      width: 20,
      cursor: "pointer",
      zIndex: 100000,
      color: "white",
      filter: "invert(100%)",
    },
    photoIcon: {
      width: 40,
      height: 40,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      cursor: "pointer",
    },
    image: {
      width: 166,
      height: 221,
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: imageSize.width,
        height: imageSize.height,
      },
    },
    buttonLayout: {
      display: "inline-block",
      margin: "0 0 5px 5px",
    },
  });
  const classes = useStyles();
  const uid = match.params.uid;
  const userRef = userDocument(uid);

  const ref = createRef<HTMLInputElement>();

  // 画像と座標を格納したstate
  const [images, setImages] = useState<string[]>(Array(18).fill(""));
  // Layoutを変更できる状態にするためのステート
  const [layoutChange, setLayoutChange] = useState<boolean>(false);
  // ランダム配置の確認モーダルを出すようのステート
  const [checkModal, setCheckModal] = useState<boolean>(false);
  const [colorModal, setColorModal] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const changeBtn = () => {
    setLayoutChange(!layoutChange);
  };

  const handleClose = () => {
    setCheckModal(false);
  };

  const colorModalOpen = () => {
    setLayoutChange(!layoutChange);
    setColorModal(true);
  };

  const handleColorClose = () => {
    setColorModal(false);
  };

  const fetchImage = useCallback(async () => {
    try {
      await getDoc(userRef).then(async (doc) => {
        if (doc.exists()) {
          const data = doc.data()!;
          const userData = {
            uid: data.uid,
            displayName: data.displayName,
            photoUrl: data.photoUrl,
            selfIntroduction: data.selfIntroduction,
            followCount: data.followCount,
            followerCount: data.followerCount,
            role: data.role,
            images: data.images ? data.images : [""],
            topProfile: data.topProfile ? data.topProfile : "",
            bottomProfile: data.bottomProfile ? data.bottomProfile : "",
            layouts: data.layouts ? data.layouts : [""],
            profileColor: data.profileColor ?? {
              r: 247,
              g: 229,
              b: 229,
              a: 1,
            },
          };
          setUserInfo(userData);
          setImages(userData.images);
          setColor(userData.profileColor);
        }
      });
    } catch (error) {
      logError(error);
      toast.error("ユーザー情報の取得に失敗しました。");
    }
  }, []);

  const inputImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const reader = new FileReader();
    reader.onload = async () => {
      await imageUpload(e, index);
    };
    if (e.target && e.target.files) {
      const files = e.target.files;
      reader.readAsDataURL(files[0]);
    }
  };

  const imageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setLoadingState(true);
    await uploadMemerLayoutImage(
      e,
      userRef,
      index,
      setLoadingState,
      images,
      setImages
    );
  };

  const handleChange = useCallback(async (color: ColorResult) => {
    try {
      await setDoc(userRef, { profileColor: color.rgb }, { merge: true });
      setColor(color.rgb);
    } catch (error) {
      logError(error);
      toast.error("背景色の変更に失敗しました。");
    }
  }, []);

  const deleteImage = async (index: number) => {
    const deletedImages = images.map((image, idx) => {
      if (index === idx) return "";
      return image;
    });

    try {
      const userRef = userDocument(userInfo.uid);
      await setDoc(userRef, { images: deletedImages }, { merge: true }).then(
        () => {
          setImages(deletedImages);
        }
      );
    } catch (error) {
      logError(error);
      toast.error("削除に失敗しました。");
    }
    handleClose();
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <div className={classes.photoArea}>
      <BrowserBackHeader label="PR PHOTO 編集ページ" browserBack={history} />
      <SpaceBox height={15} />
      <div className={classes.title}>
        <Typography align="center" variant="h6" className={classes.photoPr}>
          PR PHOTO
        </Typography>
      </div>
      {loadingState && (
        <CircularProgress
          className={classes.loading}
          size={50}
          color="inherit"
          thickness={2.5}
        />
      )}
      <div className={classes.btnArea}>
        <div className={classes.buttonLayout}>
          <GrayButton onClick={() => changeBtn()} width={150}>
            {layoutChange ? "編集をやめる" : "PHOTO編集"}
          </GrayButton>
        </div>
        {layoutChange && (
          <div className={classes.buttonLayout}>
            <GrayButton onClick={() => colorModalOpen()} width={220}>
              PHOTOフレームカラーを選択
            </GrayButton>
          </div>
        )}
      </div>
      <div className={classes.imageInputArea}>
        {images.map((image, index: number) => {
          return image !== "" ? (
            <div className={classes.invisibleFlame}>
              <div
                className={classes.inputTouchArea}
                onClick={() => ref.current!.click}
              >
                <img alt="PrPhoto" src={image} className={classes.image} />
                {layoutChange && (
                  <img
                    src={deleteMark}
                    className={classes.close}
                    alt="deleteImage"
                    onClick={() => deleteImage(index)}
                  />
                )}
              </div>
            </div>
          ) : (
            <label>
              <input
                type="file"
                className={classes.noneInput}
                ref={ref}
                onChange={(e) => inputImage(e, index)}
              />
              <div className={classes.invisibleFlame}>
                {layoutChange && (
                  <div
                    className={classes.inputTouchArea}
                    onClick={() => ref.current!.click}
                    // style={{
                    //   margin: index % 3 === 1 ? "0 1px 1px 0" : "0 0 1px 0",
                    // }}
                  >
                    <AddAPhotoIcon className={classes.photoIcon} />
                  </div>
                )}
              </div>
            </label>
          );
        })}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={checkModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={checkModal}>
          <Confirm
            setCheckModal={setCheckModal}
            userInfo={userInfo}
            setImages={setImages}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={colorModal}
        onClose={handleColorClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={colorModal}>
          <div>
            <SketchPicker onChange={handleChange} color={color} />
            <Button
              variant="contained"
              fullWidth={true}
              className={classes.colorBtn}
              onClick={() => handleColorClose()}
            >
              保存
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
