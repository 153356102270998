import { useDispatch } from "react-redux";
import { signInGoogle, signUp } from "../../features/userOperation";
import GoogleIcon from "../../assets/icon/Googleicon.png";
import { makeStyles, MenuItem, TextField, Typography } from "@material-ui/core";
import MeMETitle from "../../components/feature/Signup/MeMETitle";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { SnsAuth } from "./SnsAuth";
import { SignUpFormValue } from "../../Types/userType";
import {
  generateDayOptions,
  generateMonthOptions,
  generateYearOptions,
} from "../../lib/userHelper";

export const SignUp = (props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const addressPattern =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;

  const useStyles = makeStyles({
    container: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    inputArea: {
      width: "80%",
      margin: "0 auto 15px",
    },
    buttonArea: {
      width: "80%",
      margin: "50px auto",
    },
    textArea: {
      width: "80%",
      margin: "0 auto",
    },
    signInArea: {
      margin: "0 auto",
    },
    signInLink: {
      cursor: "pointer",
      margin: "30px auto",
      textAlign: "center",
      textDecoration: "underline",
      color: "skyblue",
      display: "block",
      whiteSpace: "nowrap",
    },
    btn: {
      display: "none",
    },
    errorText: {
      color: "red",
      fontSize: 10,
    },
    requiredText: {
      color: "rgb(149, 148, 148)",
      fontSize: 10,
    },
    textField: {
      "& label.Mui-focused": {
        color: "rgb(130, 130, 130)", // フォーカス時のラベル色
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "rgb(130, 130, 130)", // フォーカス時のボーダー色
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "rgb(130, 130, 130)", // フォーカス時のボーダー色（アウトラインスタイル）
        },
      },
    },
    dateInputArea: {
      display: "flex",
      justifyContent: "space-between",
      width: "80%",
      margin: "0 auto 15px",
    },
    yearSelect: {
      width: "50%",
    },
    monthDaySelect: {
      width: "25%",
    },
  });
  const classes = useStyles();

  const defaultValues = {
    displayName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "empty",
    birthYear: '',
    birthMonth: '',
    birthDay: '',
  };
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = methods;

  const routerHistory = useHistory();
  const selectedMonth = watch("birthMonth");
  const onSubmit = async (data: SignUpFormValue) => {
    try {
      dispatch(signUp(data));
    } catch (error) {
      toast.error("アカウント登録に失敗しました。\n再度やり直してください。");
    }
  };
  const google = () => {
    dispatch(signInGoogle(routerHistory, history.location.state as string));
  };
  const btnClick = () => {
    const realBtn = document.getElementById("noneBtn");
    realBtn?.click();
  };

  return (
    <div className={classes.container}>
      <FormProvider {...methods}>
        <MeMETitle />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.textArea}>
            <Typography className={classes.requiredText}>※必須項目</Typography>
          </div>
          {errors.displayName && (
            <div className={classes.textArea}>
              <Typography className={classes.errorText}>
                {errors.displayName.message}
              </Typography>
            </div>
          )}
          <div className={classes.inputArea}>
            <Controller
              name="displayName"
              control={control}
              rules={{
                required: "※※必須です",
                minLength: {
                  value: 2,
                  message: "2文字以上で入力してください",
                },
                maxLength: {
                  value: 15,
                  message: "15文字以下で入力してください",
                },
              }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="ユーザー名"
                  fullWidth={true}
                  variant="standard"
                  {...field}
                />
              )}
            />
          </div>
          {errors.username && (
            <div className={classes.textArea}>
              <Typography className={classes.errorText}>
                {errors.username.message}
              </Typography>
            </div>
          )}
          {/* <div className={classes.inputArea}>
            <Controller
              name="username"
              control={control}
              rules={{
                required: "※※必須です",
                minLength: {
                  value: 4,
                  message: "4文字以上で入力してください",
                },
                maxLength: {
                  value: 15,
                  message: "15文字以下で入力してください",
                },
              }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="ユーザー名"
                  fullWidth={true}
                  variant="standard"
                  {...field}
                />
              )}
            />
          </div>
          {errors.email && (
            <div className={classes.textArea}>
              <Typography className={classes.errorText}>
                {errors.email.message}
              </Typography>
            </div>
          )} */}
          <div className={classes.inputArea}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "※必須です",
                pattern: {
                  value: addressPattern,
                  message: "アドレス形式で入力してください",
                },
              }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="メールアドレス"
                  fullWidth={true}
                  variant="standard"
                  type="email"
                  {...field}
                />
              )}
            />
          </div>
          {errors.password && (
            <div className={classes.textArea}>
              <Typography className={classes.errorText}>
                {errors.password.message}
              </Typography>
            </div>
          )}
          <div className={classes.inputArea}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "※必須です",
                minLength: {
                  value: 6,
                  message: "6文字以上で入力してください",
                },
              }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="パスワード(半角英数字で6文字以上)"
                  fullWidth={true}
                  variant="standard"
                  type="password"
                  {...field}
                />
              )}
            />
          </div>
          {errors.confirmPassword && (
            <div className={classes.textArea}>
              <Typography className={classes.errorText}>
                {errors.confirmPassword.message}
              </Typography>
            </div>
          )}
          <div className={classes.inputArea}>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: "※必須です",
                minLength: {
                  value: 6,
                  message: "6文字以上で入力してください",
                },
              }}
              render={({ field }) => (
                <TextField
                  className={classes.textField}
                  label="パスワードの再確認"
                  fullWidth={true}
                  variant="standard"
                  type="password"
                  {...field}
                />
              )}
            />
          </div>
          <div className={classes.textArea}>
            <Typography className={classes.requiredText}>※任意項目</Typography>
          </div>
          <div className={classes.dateInputArea}>
            <Controller
              name="birthYear"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  className={`${classes.textField} ${classes.yearSelect}`}
                  label="生年"
                  variant="standard"
                  {...field}
                >
                  {generateYearOptions()}
                </TextField>
              )}
            />
            <Controller
              name="birthMonth"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  className={`${classes.textField} ${classes.monthDaySelect}`}
                  label="月"
                  variant="standard"
                  {...field}
                >
                  {generateMonthOptions()}
                </TextField>
              )}
            />
            <Controller
              name="birthDay"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  className={`${classes.textField} ${classes.monthDaySelect}`}
                  label="日"
                  variant="standard"
                  {...field}
                >
                  {generateDayOptions(selectedMonth)}
                </TextField>
              )}
            />
          </div>
          <div className={classes.inputArea}>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  className={classes.textField}
                  label="性別"
                  fullWidth={true}
                  variant="standard"
                  {...field}
                >
                  <MenuItem value="empty">選択しない</MenuItem>
                  <MenuItem value="man">男性</MenuItem>
                  <MenuItem value="woman">女性</MenuItem>
                  <MenuItem value="other">その他</MenuItem>
                </TextField>
              )}
            />
          </div>
          <div className={classes.buttonArea}>
            <GrayButton onClick={() => btnClick()} width="100%">
              次へ
            </GrayButton>
          </div>
          <SnsAuth
            snsIcon={GoogleIcon}
            snsLabel="Googleでログイン"
            width={30}
            height={30}
            onClick={() => dispatch(google)}
          />
          <div className={classes.signInArea}>
            <Link className={classes.signInLink} href="/signin" to="/signin">
              アカウントをお持ちの方はこちら
            </Link>
          </div>
          <button id="noneBtn" className={classes.btn}>
            submit
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
