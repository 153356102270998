import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectPost } from "../../features/postSlice";
import { selectProjectPost } from "../../features/projectPostSlice";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { MultipleImageArea } from "../../components/UIKit/MultipleImageArea";
import { Images } from "../../Types/imageType";
import { SpaceBox } from "../../components/UIKit/SpaceBox";

export const SelectImages = (props: RouteComponentProps) => {
  const { history } = props;
  const postState = useSelector(selectPost);
  const projectPostState = useSelector(selectProjectPost);
  const projectID = projectPostState.projectID;

  const useStyles = makeStyles((theme: any) => ({
    root: {
      width: 500,
      height: 625,
      backgroundColor: theme.palette.background.paper,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    container: {
      width: 500,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
  }));
  const classes = useStyles();
  const [images, setImages] = useState<Images[]>(
    projectID !== "" ? projectPostState.images : postState.images
  );
  return (
    <div className={classes.container} id="Container">
      <BrowserBackHeader label="写真選択" browserBack={history} />
      <MultipleImageArea images={images} setImages={setImages} />
      <SpaceBox height={30} />
    </div>
  );
};
