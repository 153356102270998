import {
  DocumentData,
  DocumentReference,
  QueryConstraint,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
} from "firebase/firestore";
import { db } from "./firebase";
import { logError } from "./lib/logError";

export const usersCollection = () => collection(db, "users");
export const projectsDisplayCollection = () =>
  collection(db, "projectsDisplay");

export const projectsDisplayCollectionGroup = () =>
  collectionGroup(db, "projectsDisplay");
export const creatorHopeCollection = () => collection(db, "creatorHope");

export const userDocument = (uid?: string) =>
  uid ? doc(usersCollection(), uid) : doc(usersCollection());

export const userPrivate = (uid: string) =>
  doc(collection(userDocument(uid), "userPrivate"), uid);

export const postsCollection = (uid: string) =>
  collection(userDocument(uid), "posts");

export const postsCollectionGroup = () => collectionGroup(db, "posts");

export const postDocument = (uid: string, postID?: string) =>
  postID ? doc(postsCollection(uid), postID) : doc(postsCollection(uid));

export const projectsCollection = (uid: string) =>
  collection(userDocument(uid), "projects");

export const projectsCollectionGroup = () => collectionGroup(db, "projects");

export const projectDocument = (uid: string, projectID?: string) =>
  projectID
    ? doc(projectsCollection(uid), projectID)
    : doc(projectsCollection(uid));

export const productsCollection = (uid: string) =>
  collection(userDocument(uid), "projects");

export const projectPostsCollection = (uid: string, projectID: string) =>
  collection(projectDocument(uid, projectID), "posts");

export const projectPostDocument = (
  uid: string,
  projectID: string,
  projectPostID?: string
) =>
  projectPostID
    ? doc(projectPostsCollection(uid, projectID), projectPostID)
    : doc(projectPostsCollection(uid, projectID));

export const likedPostsCollection = (uid: string) =>
  collection(userDocument(uid), "likedPosts");

export const likedPostDocument = (uid: string, postID?: string) =>
  postID
    ? doc(likedPostsCollection(uid), postID)
    : doc(likedPostsCollection(uid));

export const projectLikedPostsCollection = (uid: string) =>
  collection(userDocument(uid), "projectLikedPosts");

export const projectLikedPostsDocument = (uid: string, pjID?: string) =>
  pjID
    ? doc(projectLikedPostsCollection(uid), pjID)
    : doc(projectLikedPostsCollection(uid));
// collection(userDocument(uid), "projectLikedPosts");

export const likedUsersCollection = (uid: string, postID: string) =>
  collection(postDocument(uid, postID), "likedUsers");

export const likedUserDocument = (
  uid: string,
  postID: string,
  likedUserID?: string
) =>
  likedUserID
    ? doc(likedUsersCollection(uid, postID), likedUserID)
    : doc(likedUsersCollection(uid, postID));

export const wantedProjectsCollection = (uid: string) =>
  collection(userDocument(uid), "wantedProjects");

export const wantedProjectDocument = (uid: string, projectId?: string) =>
  projectId
    ? doc(wantedProjectsCollection(uid), projectId)
    : doc(wantedProjectsCollection(uid));

export const wantedUsersCollection = (uid: string, postID: string) =>
  collection(postDocument(uid, postID), "wantedUsers");

export const wantedUserDocument = (
  uid: string,
  postID: string,
  wantedUserID?: string
) =>
  wantedUserID
    ? doc(wantedUsersCollection(uid, postID), wantedUserID)
    : doc(wantedUsersCollection(uid, postID));

export const followsCollection = (uid: string) =>
  collection(userDocument(uid), "follows");

export const followersCollection = (uid: string) =>
  collection(userDocument(uid), "followers");

export const commentsCollection = (uid: string, postID: string) =>
  collection(postDocument(uid, postID), "comments");

export const commentsDocument = (
  uid: string,
  postID: string,
  commentID?: string
) =>
  commentID
    ? doc(commentsCollection(uid, postID), commentID)
    : doc(commentsCollection(uid, postID));

export const commentedPostsCollection = (uid: string) =>
  collection(userDocument(uid), "commentedPosts");

export const commentedPostDocument = (uid: string, commentID?: string) =>
  commentID
    ? doc(commentedPostsCollection(uid), commentID)
    : doc(commentedPostsCollection(uid));

export const commentedUsersCollection = (uid: string, postID: string) =>
  collection(postDocument(uid, postID), "commentedUsers");

export const commentedUserDocument = (
  uid: string,
  postID: string,
  commentUser?: string
) =>
  commentUser
    ? doc(commentedUsersCollection(uid, postID), commentUser)
    : doc(commentedUsersCollection(uid, postID));

///users/6fSP4Kf95MZn8C7Nb2BuHz7PZMc2/projects/6q0D0o3GtIOU1xOuuwO5/posts/CcyCJRBIpy4f1ICNFR64/comments/s99zNlBCjaRrDyxeJyif
export const replyCommentsCollection = (
  uid: string,
  postID: string,
  commentID: string
) => collection(commentsDocument(uid, postID, commentID), "replyComments");

export const socialInfosCollection = (uid: string) =>
  collection(userDocument(uid), "socialInfos");

export const purchaseInfosCollection = (userID: string) =>
  collection(userDocument(userID), "purchaseInfos");

export const purchaseInfosDocument = (userID: string) =>
  doc(purchaseInfosCollection(userID), userID);

export const purchaseHistoryCollection = (uid: string) =>
  collection(userDocument(uid), "purchaseHistory");

export const purchaseHistoryDocument = (uid: string, historyID?: string) =>
  historyID
    ? doc(purchaseHistoryCollection(uid), historyID)
    : doc(purchaseHistoryCollection(uid));

export const reviewsCollection = (uid: string) =>
  collection(userDocument(uid), "reviews");

export const reviewDocument = (uid: string, historyID?: string) =>
  historyID
    ? doc(reviewsCollection(uid), historyID)
    : doc(reviewsCollection(uid));

export const addressesCollection = (userID: string) =>
  collection(purchaseInfosDocument(userID), "addresses");

export const addressDocument = (userID: string, addressID?: string) =>
  addressID
    ? doc(addressesCollection(userID), addressID)
    : doc(addressesCollection(userID));

export const creditsCollection = (userID: string) =>
  collection(purchaseInfosDocument(userID), "credits");

export const creditsDocument = (userID: string, creditsID?: string) =>
  creditsID
    ? doc(creditsCollection(userID), creditsID)
    : doc(creditsCollection(userID));

export const unpaidReservesCollection = (userID: string) =>
  collection(userDocument(userID), "unpaidReserves");
//   [user(userID), "unpaidReserves"].join("/");

export const unpaidReservesDocument = (
  userID: string,
  unpaidReserveID?: string
) => {
  return unpaidReserveID
    ? doc(unpaidReservesCollection(userID), unpaidReserveID)
    : doc(unpaidReservesCollection(userID));
};
//   [unpaidReserves(userID), unpaidReserveID].join("/");

export const paymentAuthorizationsCollection = (
  uid: string,
  projectID: string
) => collection(projectDocument(uid, projectID), "paymentAuthorizations");

export const fetchDocumentRef = (ref: string) => doc(db, ref);

export const paymentIntentsCollection = (
  docRef: DocumentReference<DocumentData, DocumentData>
) => collection(docRef, "paymentIntents");

export const cartsCollection = (uid: string) =>
  collection(userDocument(uid), "carts");

export const cartsDocument = (uid: string, cartID?: string) =>
  cartID ? doc(cartsCollection(uid), cartID) : doc(cartsCollection(uid));

export const getDocumentRef = (path: string): DocumentReference => {
  return doc(db, path);
};
