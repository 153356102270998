import { makeStyles } from "@material-ui/core";
import left from "../../assets/icon/left.png";
import right from "../../assets/icon/right.png";
import { fetchSortCreatorList } from "../../features/creatorOperation";
import { toast } from "react-toastify";

type Props = {
  setUsersList: Function;
  setArrowCount: Function;
  arrowCount: number;
  lastId: string;
  firstId: string;
  lastReadCreator: Date;
  setLastReadCreator: Function;
  firstReadCreator: Date;
  setFirstReadCreator: Function;
  setLoading: Function;
  readLastId: string;
  setReadLastId: Function;
  readFirstId: string;
  setReadFirstId: Function;
};

export const CreatorPagination = (props: Props) => {
  const {
    setLastReadCreator,
    setArrowCount,
    setFirstReadCreator,
    setLoading,
    setReadFirstId,
    setReadLastId,
    setUsersList,
    readFirstId,
    readLastId,
    lastId,
    lastReadCreator,
    arrowCount,
    firstId,
    firstReadCreator,
  } = props;
  const arrowPush = async (action: "next" | "prev", targetDate: Date) => {
    setUsersList([]);
    setLoading(true);
    if (action === "next") arrowCount === 1 && setArrowCount(arrowCount + 1);
    try {
      const response = await fetchSortCreatorList(targetDate, action);
      setLastReadCreator(response.lastLeadCreator);
      setFirstReadCreator(response.firstRadCreator);
      setReadLastId(response.lastId);
      setReadFirstId(response.firstId);
      setUsersList(response.creatorList);
    } catch (error) {
      toast.error("データの取得に失敗しました。");
    }
    setLoading(false);
  };

  const useStyles = makeStyles({
    paginationBox: {
      display: "flex",
    },
    prev: {
      flex: 1,
    },
    next: {
      flex: 1,
    },
    prevBtn: {},
    nextBtn: {},
    arrow: {
      width: 20,
      height: 30,
      display: "block",
      margin: "0 auto",
      cursor: "pointer",
    },
    center: {
      flex: 1,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.paginationBox}>
      <div className={classes.prev}>
        <div className={classes.prevBtn}>
          {firstId !== readFirstId && (
            <img
              src={left}
              className={classes.arrow}
              onClick={() => arrowPush("prev", firstReadCreator)}
              alt="arrow"
            />
          )}
        </div>
      </div>
      <div className={classes.center}></div>
      <div className={classes.next}>
        <div className={classes.nextBtn}>
          {lastId !== readLastId && (
            <img
              src={right}
              className={classes.arrow}
              onClick={() => arrowPush("next", lastReadCreator)}
              alt="arrow"
            />
          )}
        </div>
      </div>
    </div>
  );
};
