import { Typography, makeStyles } from "@material-ui/core";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { useEffect } from "react";

type Props = {
  id?: string;
  label?: string;
  infoLabel: string;
  labelBlack?: string;
  labelRed: string;
  place: string;
  field: any;
  error?: any;
  setValue: any;
};

export const Zipcode = (props: Props) => {
  const { id, infoLabel, labelRed, labelBlack, place, field, error, setValue } =
    props;
  const useStyles = makeStyles({
    zipcodeContainer: {
      display: "flex",
    },
    inputContainerLeft: {
      flex: 6,
    },
    textInfo: {
      height: 20,
      "& p": {
        fontSize: 13,
        margin: "0 0 0 5px",
      },
    },
    labelBlack: {
      color: "block",
    },
    labelRed: {
      color: "red",
    },
  });
  const classes = useStyles();
  const ZIPCODE_API_ENDPOINT = "https://api.zipaddress.net/?zipcode=";
  const zipcode = field.value;
  // TODO: あとでuseCallbackで実装して再レンダリング減るか確認
  const fetchZipcode = (zipcode: number) => {
    axios.get(`${ZIPCODE_API_ENDPOINT}${zipcode}`).then((res) => {
      if (res.data.code === 404 || res.data.code === 400) {
        toast.error("郵便番号が間違っています。");
      } else if (res.data.data.pref) {
        setValue("pref", res.data.data.pref);
        setValue("city", res.data.data.city);
        setValue("town", res.data.data.town);
      } else if (res.data.code === 500) {
        toast.error("サーバーから応答がありません。");
      }
    });
  };
  useEffect(() => {
    if (String(zipcode).length === 7) {
      fetchZipcode(zipcode);
    }
  }, [zipcode]);

  return (
    <div className={classes.zipcodeContainer}>
      <div className={classes.inputContainerLeft}>
        <div className={classes.textInfo}>
          <Typography>
            {infoLabel}
            <span className={classes.labelRed}>{labelRed}</span>
            <span className={classes.labelBlack}>{labelBlack}</span>
            {error && <span className={classes.labelRed}>{error.message}</span>}
          </Typography>
        </div>
        <TextField
          id={id}
          placeholder={place}
          inputProps={{ "aria-label": "naked" }}
          fullWidth
          {...field}
        />
      </div>
    </div>
  );
};
