import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

interface Modal {
  isOpen: boolean
}

interface Loding {
  isLoading: boolean
}
interface MeMerLoding {
  isMeMerLoading: boolean
}

export const controlBoolSlice = createSlice({
  name: 'controlBool',
  initialState: {
    notLoginModal: {
      isOpen: false,
    },
    loading: {
      isLoading: true,
    },
    memerLoading: {
      isMeMerLoading: true,
    },
    postLoading: {
      loading: true,
    },
  },
  reducers: {
    openModal: (state) => {
      state.notLoginModal.isOpen = true
    },
    closeModal: (state) => {
      state.notLoginModal.isOpen = false
    },
    openLoading: (state) => {
      state.loading.isLoading = true
    },
    closeLoading: (state) => {
      state.loading.isLoading = false
    },
    openMeMerLoading: (state) => {
      state.memerLoading.isMeMerLoading = true
    },
    closeMeMerLoading: (state) => {
      state.memerLoading.isMeMerLoading = false
    },
    closePostChangeLoading: (state) => {
      state.postLoading.loading = false
    },
    openPostChangeLoading: (state) => {
      state.postLoading.loading = true
    },
  },
})
export const {
  openModal,
  closeModal,
  openLoading,
  closeLoading,
  openMeMerLoading,
  closeMeMerLoading,
  closePostChangeLoading,
  openPostChangeLoading,
} = controlBoolSlice.actions
export const selectModal = (state: RootState) => state.controlBool.notLoginModal
export const selectLoading = (state: RootState) => state.controlBool.loading
// export const selectFetchPost = (state: RootState) => state.post.fetchPost
export const memerLoading = (state: RootState) => state.controlBool.memerLoading
export const postLoading = (state: RootState) => state.controlBool.postLoading
export default controlBoolSlice.reducer
