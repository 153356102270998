import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const TopSkeletonText = () => {
  const useStyles = makeStyles({
    skeletonSocial: {
      display: "flex",
    },
    skeletonSocialBtn: {
      flex: 1,
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Skeleton
        animation="wave"
        height={10}
        width="100%"
        style={{ marginTop: 15 }}
      />
      <div className={classes.skeletonSocial}>
        <div className={classes.skeletonSocialBtn}>
          <Skeleton
            animation="wave"
            height={15}
            width="70%"
            style={{ marginTop: 20 }}
          />
        </div>
        <div className={classes.skeletonSocialBtn}>
          <Skeleton
            animation="wave"
            height={15}
            width="70%"
            style={{ marginTop: 20 }}
          />
        </div>
        <div className={classes.skeletonSocialBtn}>
          <Skeleton
            animation="wave"
            height={15}
            width="70%"
            style={{ marginTop: 20 }}
          />
        </div>
      </div>
      <Skeleton
        animation="wave"
        height={10}
        width="20%"
        style={{ marginTop: 15 }}
      />
    </div>
  );
};

export default TopSkeletonText;
