import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const TopSkeletonIcon = () => {
  const useStyles = makeStyles({
    userInfoBar: {
      height: 80,
      display: "flex",
    },
    userName: {
      margin: "10px 0 10px 10px",
      flex: 5,
    },
    Name: {
      lineHeight: 3,
      fontSize: 12,
      color: "gray",
    },
    userFollow: {
      margin: "10px 0 10px 10px",
      flex: 2,
    },
    followBtn: {
      margin: "0 auto",
      marginTop: 15,
      backgroundColor: "rgb(44, 145,245)",
      color: "white",
      width: 90,
      fontSize: 13,
      textAlign: "center",
      paddingTop: 5,
      paddingBottom: 5,
      "@media screen and (max-width: 499px)": {
        width: 75,
        marginRight: 10,
      },
    },
    "MuiGridListTile-root": {
      overflow: "hidden",
    },
    loadingIcon: {
      borderRadius: 10,
      margin: "10px 0 10px 10px",
    },
    skeletonFollow: {
      margin: "0 auto",
    },
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.userInfoBar}>
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.loadingIcon}
          width={58}
          height={58}
        />
        <div className={classes.userName}>
          <Skeleton
            animation="wave"
            height={10}
            width="20%"
            style={{ marginTop: 23 }}
          />
        </div>
        <div className={classes.userFollow}>
          <Skeleton
            animation="wave"
            variant="rect"
            width={130}
            height={30}
            style={{ marginTop: 15, marginRight: 5, borderRadius: 5 }}
            className={classes.skeletonFollow}
          />
        </div>
      </div>
    </div>
  );
};

export default TopSkeletonIcon;
