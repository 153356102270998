import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { TopFooter } from "../Footer/TopFooter";
import { UserProfileType } from "../../Types/userType";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { selectUser } from "../../features/userSlice";
import { SelfIntroduction } from "../MyPage/SelfIntroduction";
import { UserFollowInfo } from "../MyPage/UserFollowInfo";
import { UserIcon } from "../UIKit/UserIcon";
import { SortPost } from "../../templates/Post/SortPost";
import { MyPageFollowBtn } from "../UIKit/MyPageFollowBtn";
import { Name } from "../UIKit/Name";
import { useHistory } from "react-router-dom";

type Props = {
  userInfo: UserProfileType | undefined;
};

export const MyUserProfile = (props: Props) => {
  const { userInfo } = props;
  const history = useHistory();
  const myProfileUid = useSelector(selectUser).uid;

  const useStyles = makeStyles({
    container: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    userProfile: {
      marginTop: 30,
      display: "flex",
    },
    userProfileLeft: {
      flex: 1,
    },
    userProfileRight: {
      flex: 2,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {userInfo && (
        <div>
          <BrowserBackHeader
            label={userInfo.displayName}
            browserBack={history}
          />
          <TopFooter effect="mypage" />
          <div className={classes.userProfile}>
            <div className={classes.userProfileLeft}>
              <UserIcon
                photoUrl={userInfo.photoUrl}
                width="98px"
                height="98px"
                margin="0 0 0 20px"
              />
            </div>
            <div className={classes.userProfileRight}>
              <Name displayName={userInfo.displayName} margin="10px 0 10px 0" />
              <UserFollowInfo
                follow={userInfo.followCount}
                follower={userInfo.followerCount}
                post={userInfo.postCount}
                uid={myProfileUid}
              />
            </div>
          </div>
          <SelfIntroduction bottomProfile={userInfo.selfIntroduction} />
          {myProfileUid !== userInfo.uid && (
            <MyPageFollowBtn anotherUser={userInfo.uid} />
          )}
          <SortPost uid={userInfo.uid} />
        </div>
      )}
    </div>
  );
};
