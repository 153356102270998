import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface Purchase {
  customerID: string;
}

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState: {
    customerID: "",
  },
  reducers: {
    updateStripeCustomer: (state, action: PayloadAction<Purchase>) => {
      state.customerID = action.payload.customerID;
    },
    resetStripeCustomer: (state, action: PayloadAction<Purchase>) => {
      state.customerID = action.payload.customerID;
    },
  },
});

export const { updateStripeCustomer, resetStripeCustomer } =
  purchaseSlice.actions;

export const selectPurchase = (state: RootState) => state.purchase.customerID;
export default purchaseSlice.reducer;
export const getPurchaseSelector = createSelector(selectPurchase, (pur) => {
  return pur;
});
