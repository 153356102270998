import { makeStyles } from "@material-ui/core";

type Props = {
  label: string;
};
const DeliveryUserInfo = (props: Props) => {
  const { label } = props;
  const useStyles = makeStyles({
    deliveryInfo: {
      marginLeft: 20,
      "& p": {
        margin: "2px 0 2px",
        fontSize: 12,
      },
    },
    deliveryUserInfo: {},
  });
  const classes = useStyles();
  return (
    <div className={classes.deliveryInfo}>
      <div className={classes.deliveryUserInfo}>
        <p>{label}</p>
      </div>
    </div>
  );
};

export default DeliveryUserInfo;
