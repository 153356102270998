import { makeStyles } from "@material-ui/core";
import { Comment } from "./PostUIKit/Comment";
import { Want } from "./PostUIKit/Want";
import { Like } from "./PostUIKit/Like";
import { Post } from "../../Types/userType";

type Props = {
  post: Post;
};

export const ProjectPostFooter = (props: Props) => {
  const { post } = props;
  const useStyles = makeStyles({
    projectPostFooter: {
      display: "flex",
      margin: "0 auto",
      width: "96%",
    },
    createdDate: {
      margin: "0 auto",
      width: "96%",
      "& p": {
        fontSize: 12,
        color: "gray",
      },
    },
    garbageBoxIcon: {
      float: "right",
      cursor: "pointer",
      width: 24,
      filter: "opacity(55%)",
    },
    timeText: {
      fontWeight: 100,
      fontSize: 12,
      color: "gray",
    },

    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const classes = useStyles();

  return (
    <div>
      <div className={classes.projectPostFooter}>
        <Comment post={post} />
        {post.title && <Want post={post} />}
        <Like post={post} />
      </div>
    </div>
  );
};
