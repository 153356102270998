import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const TopSkeletonImg = () => {
  const useStyles = makeStyles({
    skeletonIcon: {
      width: 500,
      height: 625,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
        height: 506.25,
      },
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.skeletonIcon}
      />
    </div>
  );
};

export default TopSkeletonImg;
