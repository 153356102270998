import React from "react";
import { makeStyles } from "@material-ui/core";

type Props = {
  height: string | number;
  backgroundColor?: string;
};

export const SpaceBox = React.memo((props: Props) => {
  const { height, backgroundColor } = props;
  const useStyles = makeStyles({
    spaceBox: {
      height,
      backgroundColor: backgroundColor ?? "",
    },
  });
  const classes = useStyles();
  return <div className={classes.spaceBox} />;
});
