import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { selectUser } from "../../../features/userSlice";
import { UserIcon } from "../../UIKit/UserIcon";
import { Name } from "../../UIKit/Name";
import { MyFollowInfo } from "../../MyPage/MyFollowInfo";

export const UserMyPage = (): JSX.Element => {
  const useStyles = makeStyles({
    userMyPage: {
      display: "flex",
      marginTop: 5,
    },
    userMyPageLeft: {
      marginLeft: 20,
      flex: 1,
    },
    userMyPageRight: {
      flex: 2,
    },
    dummyArea: {
      height: 20,
    },
    dummyArea2: {
      height: 30,
    },
  });
  const classes = useStyles();
  const selector = useSelector(selectUser);
  return (
    <div className={classes.userMyPage}>
      <div className={classes.userMyPageLeft}>
        <UserIcon
          photoUrl={selector.photoUrl}
          width="98px"
          height="98px"
          margin="10px 0 10px 10px"
        />
      </div>
      <div className={classes.userMyPageRight}>
        <div className={classes.dummyArea} />
        <Name
          displayName={selector.displayName}
          margin="0 0 10px 0"
          fontSize="14px"
        />
        <MyFollowInfo uid={selector.uid} />
        <div className={classes.dummyArea} />
      </div>
    </div>
  );
};
