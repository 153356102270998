import axios, { AxiosRequestConfig } from "axios";
import React, { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppThunk } from "../app/store";
import PrimaryButton from "../components/UIKit/PrimaryButton";
import { TextInput } from "../components/UIKit/TextInput";

export const fetchDeliveryStatus = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    const headers = new Headers();
    headers.set("Content-type", "application/json");
    const okurijo = ["980000000836", "980000000825", "980000000884"];
    const sagawaReqBody = {
      okurijoList: [
        {
          okurijoNo: okurijo[0],
        },
      ],
      henkDataSyube: "1",
    };

    // const URL =
    //   process.env.NODE_ENV === "development"
    //     ? "https://asia-northeast1-meme-project-f3275.cloudfunctions.net/fetchDeliveryStatus"
    //     : "https://asia-northeast1-meme-project-f3275.cloudfunctions.net/fetchProdDeliveryStatus";

    const URL =
      "https://asia-northeast1-meme-project-f3275.cloudfunctions.net/fetchProdDeliveryStatus";
    const config: AxiosRequestConfig = {
      url: URL,
      headers: headers,
      method: "POST",
      data: { postData: sagawaReqBody },
    };
    const response = await axios(config);
  };
};

const MethodTest = () => {
  const dispatch = useDispatch();
  const deliveryStatus = useCallback(() => {
    dispatch(fetchDeliveryStatus());
  }, []);

  const [email, setEmail] = useState("");
  return (
    <div>
      <TextInput
        variant="outlined"
        fullWidth={true}
        label={"文字をいれる"}
        multiline={false}
        required={true}
        rows={1}
        value={email}
        type={"email"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(e.target.value);
        }}
      />
      <PrimaryButton label={"sendEmailtest"} onClick={() => {}} />
      <PrimaryButton label={"SAGAWAAAA"} onClick={deliveryStatus} />
    </div>
  );
};

export default MethodTest;
