import { useHistory } from "react-router";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { unLoginPasswordReset } from "../../function/cloudFunctions";
import { RouteComponentProps } from "react-router-dom";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { logError } from "../../lib/logError";

type Props = {
  email: string;
};

export const SelfPasswordReset = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    btnArea: {
      marginTop: 30,
    },
    text: {
      marginTop: 30,
      marginBottom: 30,
    },
    btn: {
      display: "none",
    },
    errorText: {
      marginBottom: 10,
      color: "red",
    },
    textField: {
      "& label.Mui-focused": {
        color: "rgb(130, 130, 130)",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "rgb(130, 130, 130)",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "rgb(130, 130, 130)",
        },
      },
    },
  });
  const classes = useStyles();
  const { push } = useHistory();

  const addressPattern =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;

  const defaultValues = {
    email: "",
  };

  const methods = useForm({ defaultValues });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = (data: Props): void => {
    unLoginPasswordReset(data)
      .then((res) => {
        if (
          window.confirm("入力したアドレスにリセットメールを送信しました。")
        ) {
          push("/signin");
        }
      })
      .catch((err) => {
        logError(err);
      });
  };

  const btnClick = () => {
    const realBtn = document.getElementById("noneBtn");
    realBtn?.click();
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BrowserBackHeader label="パスワードリセット" browserBack={history} />
          <Typography className={classes.text}>
            登録したメールアドレスを入力してください
          </Typography>
          {errors.email && (
            <Typography className={classes.errorText}>
              {errors.email.message}
            </Typography>
          )}
          <Controller
            name="email"
            control={control}
            rules={{
              required: "必須です",
              pattern: {
                value: addressPattern,
                message: "アドレス形式で入力してください",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="メールアドレス"
                fullWidth={true}
                variant="standard"
                className={classes.textField}
              />
            )}
          />
          <div className={classes.btnArea}>
            <GrayButton onClick={() => btnClick()} width="100%">
              メールを送信
            </GrayButton>
          </div>
          <button id="noneBtn" className={classes.btn}>
            submit
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
