import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { UserProfile } from "./UserProfile";
import { UserProfileType } from "../../Types/userType";
import { RouteComponentProps } from "react-router-dom";
import { userDocument } from "../../firebasePaths";
import { getDoc } from "firebase/firestore";
import { CreatorProfile } from "../Creator/CreatorProfile";

export const SwitchProfile = (
  props: RouteComponentProps<{ id: string; uid?: string }>
) => {
  const { history, match } = props;
  const displayUserUid = match.params.id;
  const uid = match.params.uid ?? "";

  const userRef = userDocument(displayUserUid);
  const [userInfo, setUserInfo] = useState<UserProfileType>();
  const urlSplit = history.location.pathname.split("/")[2];

  const fetchUserData = () => {
    getDoc(userRef).then((doc) => {
      if (doc.exists()) {
        const data = doc.data()!;
        const userData = {
          uid: data.uid,
          displayName: data.displayName,
          photoUrl: data.photoUrl,
          selfIntroduction: data.selfIntroduction,
          followCount: data.followCount,
          followerCount: data.followerCount,
          postCount: data.postCount,
          role: data.role,
          images: data.images ? data.images : [""],
          topProfile: data.topProfile ? data.topProfile : "",
          bottomProfile: data.bottomProfile ? data.bottomProfile : "",
          profileColor: data.profileColor ?? {
            r: 247,
            g: 229,
            b: 229,
            a: 1,
          },
        };
        setUserInfo(userData);
      }
    });
  };
  useEffect(() => {
    fetchUserData();
  }, [urlSplit]);

  return (
    <>
      {userInfo?.role === "memer" ? (
        <CreatorProfile userInfo={userInfo} uid={uid} />
      ) : (
        <UserProfile userInfo={userInfo} uid={uid} />
      )}
    </>
  );
};
