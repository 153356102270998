export const stripeError = {
  payment: {
    authorization: "Stripe Payment Authorization Error",
  },
};

export const fireStoreBatchError = {
  notArray: "Batch Error",
};
export const postDeleteError = {
  notArray: "Batch Error",
};
