import React from "react";
import { makeStyles } from "@material-ui/core";

type Props = {
  imageIcon: string;
  width: number;
  height: number;
  sWidth: number;
  sHeight: number;
};
export const MenuFooter = (props: Props) => {
  const { imageIcon, width, height, sWidth, sHeight } = props;
  const useStyles = makeStyles({
    menuFooter: {
      flex: 1,
    },
    Icon: {
      width: width,
      height: height,
      margin: "1px auto 0",
      display: "block",
      "@media screen and (max-width: 499px)": {
        width: sWidth,
        height: sHeight,
      },
    },
    IconArea: {
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.menuFooter}>
      <div className={classes.IconArea}>
        <img src={imageIcon} className={classes.Icon} alt="imageIcon" />
      </div>
    </div>
  );
};
