import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { UserIcon } from "../../../components/UIKit/UserIcon";
import { Name } from "../../../components/UIKit/Name";
import { relativeDate } from "../../../function/common";
import { ReplyComment } from "../../../Types/userType";

type Props = {
  reply: ReplyComment;
};
export const ReturnComment = (props: Props) => {
  const { reply } = props;
  const relativeDateText = relativeDate(reply.createdAt.seconds);
  const useStyles = makeStyles({
    returnCommentArea: {
      display: "flex",
    },
    dummyArea: {
      flex: 1.5,
    },
    returnIcon: {
      flex: 1,
    },
    returnName: {
      flex: 8,
    },
    returnComment: {
      "& p": {
        fontSize: 12,
        marginTop: 20,
        marginBottom: 0,
      },
      width: 237,
      "@media screen and (max-width: 499px)": {
        width: 150,
      },
    },
    postCommentText: {
      fontSize: 8,
      color: "gray",
      marginTop: 5,
    },
    dateText: {
      lineHeight: 3,
      fontSize: 8,
      color: "gray",
      marginTop: 15,
      marginBottom: 0,
    },
    dummyArea2: {
      marginRight: 20,
    },
  });
  const classes = useStyles();

  const replyComment = reply.reply
    .split(/(\n)/)
    .map((item: string, index: number) => {
      return (
        <React.Fragment key={index}>
          {item.match(/\n/) ? <br /> : item}
        </React.Fragment>
      );
    });

  return (
    <div>
      <div className={classes.returnCommentArea}>
        <div className={classes.dummyArea} />
        <div className={classes.returnIcon}>
          <UserIcon photoUrl={reply.userInfo.photoUrl} width={50} height={50} />
        </div>
        <div className={classes.returnName}>
          <Name
            displayName={reply.userInfo.displayName}
            fontSize={8}
            fontWeight="bold"
            margin="2px 0 0 0"
            textAlign={"left"}
            color="rgb(100, 100, 100)"
          />
          <Typography className={classes.postCommentText}>
            {replyComment}
          </Typography>
          <Typography className={classes.postCommentText}>
            {relativeDateText}
          </Typography>
        </div>
        <div className={classes.dummyArea2} />
      </div>
    </div>
  );
};
