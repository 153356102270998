import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import clsx from "clsx";
import { StepIconProps } from "@material-ui/core/StepIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(0),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    deliveryMessage: {
      fontSize: 12,
      // fontWeight: "fontWeightLight",
    },
  })
);

// const useColorlibStepIconStyles = makeStyles({
//   root: {
//     backgroundColor: '#ccc',
//     zIndex: 1,
//     color: '#fff',
//     width: 50,
//     height: 50,
//     display: 'flex',
//     borderRadius: '50%',
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   active: {
//     backgroundImage:
//       'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
//     boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
//   },
//   completed: {
//     backgroundImage:
//       'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
//   }
// })

// function ColorlibStepIcon(props: StepIconProps) {
//   const classes = useColorlibStepIconStyles()
//   const { active, completed } = props

//   const icons: { [index: string]: React.ReactElement } = {
//     1: <FiberManualRecordIcon />,
//     2: <FiberManualRecordIcon />,
//     3: <FiberManualRecordIcon />,
//     4: <FiberManualRecordIcon />,
//     5: <FiberManualRecordIcon />,
//     6: <CheckCircleIcon />
//   }

const useQontoStepIconStyles = makeStyles({
  root: {
    width: "100%",
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#00e5ff",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#00e5ff",
    zIndex: 1,
    fontSize: 18,
  },
  stepCircle: {
    width: 25,
    height: 25,
  },
  checkCircle: {
    width: 22,
    height: 22,
    marginLeft: 1,
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <CheckCircleIcon className={classes.checkCircle} />
      ) : (
        <FiberManualRecordIcon className={classes.stepCircle} />
      )}
      {/* {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )} */}
    </div>
  );
}

type Props = {
  activeStep: number;
  deliveryStatusArray: string[];
  deliveryMessage: string;
};

export const DeliveryStepper = (props: Props) => {
  const { activeStep, deliveryStatusArray, deliveryMessage } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {deliveryStatusArray.map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            <StepContent>
              <Typography className={classes.deliveryMessage}>
                {deliveryMessage}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
