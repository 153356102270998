import { makeStyles } from "@material-ui/core";

type Props = {
  images: string[];
};

export const ProductThreeDImages = (props: Props) => {
  const { images } = props;
  const useStyles = makeStyles({
    imageArea: {
      display: "flex",
      flexWrap: "wrap",
    },
    threeDimages: {
      width: 155,
      height: 233,
      border: "1px solid black",
      margin: "10px 2px",
      "@media screen and (max-width: 499px)": {
        marginTop: 10,
        width: 115,
        height: 143,
        margin: 1,
      },
    },
    img: {
      width: 155,
      height: 233,
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: 115,
        height: 143,
      },
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.imageArea}>
      {images.map(
        (image) =>
          image !== "" && (
            <div className={classes.threeDimages}>
              <img src={image} className={classes.img} alt="idImage" />
            </div>
          )
      )}
    </div>
  );
};
