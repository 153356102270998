import React from "react";
import Router from "./Router";
import { makeStyles } from "@material-ui/core";
import { Auth } from "./Auth";
const App: React.FC = () => {
  const useStyles = makeStyles({
    container: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Router />
    </div>
  );
};

export default App;
