export const address = {
  aid: "",
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
  zipcode: "0000000",
  prefecture: "",
  municipality: "",
  address: "",
  buildingName: "",
  phoneNumber: "",
  uid: "",
  mainAddress: false,
  city: "",
  town: "",
};

export const creditList = [
  {
    last4: "",
    brand: "",
    expMonth: 0,
    expYear: 0,
    primaryCard: false,
    pmID: "",
  },
];

export const useCreditUtil = {
  last4: "",
  brand: "",
  expMonth: 0,
  expYear: 0,
  primaryCard: false,
  pmID: "",
};
