/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from "@material-ui/core";
import { Text } from "../../components/atoms/Text/Text";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { SpanMixText } from "../../components/atoms/Text/SpanMixText";

export const AppThought = () => {
  const useStyles = makeStyles({
    thoughtArea: {
      margin: "5px 15px",
      border: "solid 1px black",
      padding: 15,
      marginTop: 40,
    },
    hpLink: {
      margin: "20px 0",
    },
    link: {
      textDecoration: "none",
    },
    boldText: {
      fontWeight: "bold",
      color: "#100000",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.thoughtArea}>
      <Text color="#707070" text="tomemeとは" />
      <div className={classes.hpLink}>
        <a href="https://tomeme.co.jp" className={classes.link} target="_blank">
          <Text color="#34A9DD" text="tomemeの使い方" />
        </a>
      </div>
      <SpanMixText color="#707070">
        tomemeは、
        <span className={classes.boldText}>
          『作りたい人の思いをつなげ、必要なだけ作り、必要な人に届ける』
        </span>
        をコンセプトにアパレル廃棄ゼロを目標にしたサスティナブルな取り組みをしています。
      </SpanMixText>
      <div className={classes.hpLink}>
        <a
          href="https://tomeme.co.jp/fashionissue/"
          className={classes.link}
          target="_blank"
        >
          <Text color="#34A9DD" text="ファッションの環境問題" />
        </a>
      </div>
      <Text
        text="ユーザーの皆様の予約、ほしいのアクションで必要な生産数を決めていきます。
        予約数及び、欲しいの数量が生産可能な数量に達した場合のみ生産確定となります。"
        color="#707070"
      />

      <SpanMixText color="#707070">
        生産可能な数量に達しない場合は自動的に予約キャンセルとなりますので予めご了承ください。
        クレジットカードの決済は生産進行確定後、
        <span className={classes.boldText}>商品の発送時に決済となります。</span>
        予約段階では決済されません。
      </SpanMixText>
      <SpaceBox height={20} />
      <Text
        text="tomemeでは商品を大量に生産、在庫することがありません。
        確実にご購入されたい方は、予約受付中にご予約をお勧めします。"
        color="#707070"
      />
    </div>
  );
};
