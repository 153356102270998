import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";

type Props = {
  className?: object;
  width: number;
  height: number;
};

export const Skelton = (props: Props) => {
  const { className, width, height } = props;
  const useStyles = makeStyles({
    loadingIcon: {
      ...className,
    },
  });
  const classes = useStyles();
  return (
    <Skeleton
      animation="wave"
      variant="rect"
      className={classes.loadingIcon}
      width={width}
      height={height}
    />
  );
};
