import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
type Props = {
  rightText: string;
  leftText: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  description?: string;
};

const Selection = (props: Props) => {
  const { rightText, leftText, onClick, onCancel, description } = props;
  const useStyles = makeStyles({
    reserveBox: {
      width: 468,
      paddingTop: 50,
      border: "1px solid gray",
      backgroundColor: "white",
      "@media screen and (max-width: 499px)": {
        width: 373,
      },
      textAlign: "center",
      borderRadius: 10,
    },
    buttonArea: {
      marginTop: 50,
      height: 50,
      display: "flex",
    },
    leftBtn: {
      width: "50%",
      margin: "0 5px 0 10px",
    },
    rightBtn: {
      width: "50%",
      margin: "0 10px 0 5px",
    },
    descriptionText: {
      color: "rgba(0, 0, 0, 0.87)",
    },
  });
  const classes = useStyles();

  const textConvert = description
    ? description
        .split(/(\n)/)
        .map((item) => (
          <React.Fragment key={item}>
            {item.match(/\n/) ? (
              <br />
            ) : (
              <Typography className={classes.descriptionText}>
                {item}
              </Typography>
            )}
          </React.Fragment>
        ))
    : "";

  return (
    <div className={classes.reserveBox}>
      {textConvert}
      <div className={classes.buttonArea}>
        <div className={classes.leftBtn}>
          <Button variant="outlined" fullWidth={true} onClick={onClick}>
            {leftText}
          </Button>
        </div>
        <div className={classes.rightBtn}>
          <Button variant="outlined" fullWidth={true} onClick={onCancel}>
            {rightText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Selection;
