import React, { useState } from "react";
import { makeStyles, Radio, Typography } from "@material-ui/core";
import DeliveryUserInfo from "./DeliveryUserInfo";
import { SpaceBox } from "../UIKit/SpaceBox";

const DeliveryAddressChange = (props: any) => {
  const { changeAddress, setAddress, setOpen } = props;
  const useStyles = makeStyles({
    addressInfoArea: {
      paddingBottom: 10,
      borderBottom: "1px solid gray",
      "&:hover": {
        backgroundColor: "rgb(201,201,201)",
        transition: "all 0.3s",
      },
    },
    addressTitle: {
      height: 20,
      marginBottom: 5,
      "& p": {
        margin: "5px 0 0 5px",
        fontSize: 14,
      },
    },
    addressArea: {
      display: "flex",
    },
    addressCheckArea: {
      flex: 1,
    },
    addressInfo: {
      flex: 7,
      cursor: "pointer",
    },
    deliveryInfo: {
      marginLeft: 20,
    },
    deliveryUserInfo: {},
    postCode: {},
    radio: {
      margin: "25px auto 0",
      display: "block",
    },
    modalArea: {
      width: 500,
      height: 250,
      position: "fixed",
      top: 100,
      border: "1px solid black",
      backgroundColor: "white",
      zIndex: 100,
      boxShadow: "5px 5px 5px black",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    modalInfo: {
      height: 100,
      "& p": {
        textAlign: "center",
        fontSize: 20,
        marginTop: 60,
      },
    },
    modalInfoEdit: {
      height: 50,
      "& p": {
        textAlign: "center",
        fontSize: 15,
        marginTop: 20,
      },
    },
    modalAddress: {
      height: 100,
      marginLeft: 150,
      "@media screen and (max-width: 499px)": {
        marginLeft: 100,
      },
      "& p": {
        fontSize: 12,
        margin: "0 0",
      },
    },
    modalBtnArea: {
      height: 80,
      alignItems: "center",
      display: "flex",
    },
    btnLeft: {
      flex: 1,
    },
    btnRight: {
      flex: 1,
    },
    btnCenter: {
      flex: 1,
    },
    selectBtn: {
      margin: "0 auto",
      display: "block",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    link: {
      textDecoration: "none",
    },
    infoText: {
      fontSize: 14,
    },
    changeBtn: {
      border: "1px solid gray",
      width: 75,
      margin: "15px auto 0",
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        backgroundColor: "rgb(234,234,234)",
      },
    },
    background: {
      backgroundColor: "white",
      width: 350,
      padding: 20,
    },
    changeBtnDisable: {
      border: "1px solid gray",
      width: 150,
      margin: "15px auto 0",
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        backgroundColor: "rgb(234,234,234)",
      },
    },
  });
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("0");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const changeDeliveryAddress = () => {
    setAddress(changeAddress[Number(selectedValue) - 1]);
    setOpen(false);
  };

  return (
    <div className={classes.background}>
      {changeAddress.map((addr: any, index: number) => (
        <div key={index}>
          <div className={classes.addressInfoArea}>
            {/* 住所1とか2って書いてある一段目 */}
            <div className={classes.addressTitle}>
              {/* 下のpは後でforとかで回した時に数字はlengthで表示 */}
              <p>住所{index + 1}</p>
            </div>
            <div className={classes.addressArea}>
              <div className={classes.addressCheckArea}>
                <Radio
                  checked={selectedValue === String(index + 1)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                  // TODO: あとで型つける
                  // onClick={(e: any) => handleOpen(e.target.value)}
                  // onClick={e => handleOpen(e)}
                  value={String(index + 1)}
                  color="default"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "1" }}
                  className={classes.radio}
                />
              </div>
              <div className={classes.addressInfo}>
                {/* <div className={classes.addressInfo} onClick={() => handleOpenEdit(index)}> */}
                <DeliveryUserInfo
                  label={addr.lastName + "  " + addr.firstName}
                />
                {/* 郵便番号 */}
                <DeliveryUserInfo label={"〒" + addr.zipcode} />
                {/* 住所 */}
                <DeliveryUserInfo
                  label={addr.prefecture + addr.city + addr.town + addr.address}
                />
                {/* 物件名 */}
                <DeliveryUserInfo label={addr.buildingName} />
                {/* 電話番号 */}
                <DeliveryUserInfo label={"TEL " + addr.phoneNumber} />
              </div>
            </div>
          </div>
          <SpaceBox height={5} />
          {/* <GrayBack height={5} /> */}
        </div>
      ))}
      {selectedValue !== "0" ? (
        <div className={classes.changeBtn}>
          <Typography
            className={classes.infoText}
            align="center"
            onClick={() => changeDeliveryAddress()}
          >
            決定する
          </Typography>
        </div>
      ) : (
        <div className={classes.changeBtnDisable}>
          <Typography
            className={classes.infoText}
            align="center"
            onClick={() => setOpen(false)}
          >
            変更しない
          </Typography>
        </div>
      )}
    </div>
  );
};

export default DeliveryAddressChange;
