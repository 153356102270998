import { AppThunk } from "../app/store";
import { db, auth } from "../firebase/index";
import { setProductsStore, setProductStore } from "../features/productSlice";
import { push } from "connected-react-router";
import {
  Reserve,
  ReserveJoinData,
  ReserveProduct,
  SalesProject,
} from "../Types/productType";
import { Purchase, updateStripeCustomer } from "./purchaseSlice";
import { Project } from "../Types/projectType";
import { requestProject } from "../function/cloudFunctions";
import {
  creditsCollection,
  getDocumentRef,
  projectsCollectionGroup,
  projectsDisplayCollection,
  projectsDisplayCollectionGroup,
  unpaidReservesCollection,
  unpaidReservesDocument,
} from "../firebasePaths";
import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  writeBatch,
} from "firebase/firestore";
import { timeout } from "./helpers/timeHelper";

export const fetchProduct = (projectRef: string): AppThunk => {
  return async (dispatch): Promise<void> => {
    const projectRefArray = projectRef!.split("/");
    const memerUid = projectRefArray[1];

    const projectDocumentRef = getDocumentRef(projectRef);
    getDoc(projectDocumentRef).then((doc) => {
      const data = doc.data();
      dispatch(setProductStore(data));
    });

    const productsRef = collection(projectDocumentRef, "products");
    const productsBox: any[] = [];
    getDocs(productsRef)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          productsBox.push(data);
        });
      })
      .then(() => {
        dispatch(setProductsStore(productsBox));
        dispatch(push(`/product/${memerUid}/${projectDocumentRef.id}`));
      });
  };
};

export const fetchChoiceProductStock = (
  setProductStock: Function,
  productRef: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const numberArray: number[] = [];
    const productStockRef = getDocumentRef(productRef);
    getDoc(productStockRef).then((product) => {
      const productData = product.data();
      [...Array(productData?.stock)].map((_, i) => numberArray.push(i + 1));
      setProductStock(numberArray);
    });
  };
};

export const sendReserveProduct = (
  reserve: Omit<Reserve, "rpid">
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const batch = writeBatch(db);
    const unpaidReservesRef = unpaidReservesDocument(uid);
    const { productRef, quantity, projectRef } = reserve;
    const choiceProductRef = getDocumentRef(productRef);
    const projectReservationsRef = getDocumentRef(projectRef);
    batch.set(unpaidReservesRef, { ...reserve, rpid: unpaidReservesRef.id });
    batch.set(
      choiceProductRef,
      { reserve: increment(quantity) },
      { merge: true }
    );
    batch.set(
      projectReservationsRef,
      { reservations: increment(quantity) },
      { merge: true }
    );
    batch.commit();

    dispatch(push("/reservedone"));
  };
};

export const deleteReserveProduct = (
  reserveRef: string,
  rpid: string,
  quantity: number
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const uid = auth.currentUser!.uid;
    const productRef = getDocumentRef(reserveRef);
    const unpaidReserveRef = unpaidReservesDocument(uid, rpid);
    const batch = writeBatch(db);
    batch.delete(unpaidReserveRef);
    batch.set(productRef, { reserve: increment(-quantity) }, { merge: true });
    batch.commit();
  };
};

type Detail = {
  selectedQuantity: number;
  size: string;
  ref: string;
};

type ReservedProduct = {
  detail: Detail[];
  color: string;
  value: string;
};

type CreatedAt = {
  seconds: number;
  nanoseconds: number;
};

type Data = {
  projectRef: string;
  aid: string;
  paymentMethodID: string;
  uid: string;
  customerID: string;
  address: string;
  zipcode: string;
  createdAt: CreatedAt;
  pid: string;
  rpid: string;
  postage: number;
  reservedProducts: ReservedProduct[];
  orderNumber: string;
  quantity: number;
  phoneNumber: string;
  price: number;
  name: string;
  isPaid: boolean;
  id: string;
};

export const fetchReserveProduct = async (
  setReserve: React.Dispatch<React.SetStateAction<ReserveJoinData[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let unsubscribe = auth.onAuthStateChanged(async (user) => {
    if (user) {
      const uid = user.uid;
      const unpaidReservesRef = unpaidReservesCollection(uid);
      const q = query(unpaidReservesRef, orderBy("createdAt", "desc"));
      const unpaidReserveSnapshot = await getDocs(q);
      const reserveDataArray: Data[] = [];

      unpaidReserveSnapshot.forEach((reserveDoc) => {
        const reserveData = reserveDoc.data() as Data;
        reserveDataArray.push({ ...reserveData, id: reserveDoc.id });
      });

      const unpaidReserveData = reserveDataArray.map(async (reserve) => {
        const projectRef = getDocumentRef(reserve.projectRef);
        const project = (await getDoc(projectRef)).data() as SalesProject;
        const image = project.images[0];
        const { projectName, salesStatus, reservations, minimumProduction } =
          project;
        const reserveProducts: ReserveProduct[] = [];
        reserve.reservedProducts.map((product) => {
          const detailRes = product.detail.forEach((detail) => {
            reserveProducts.push({
              itemCategory: project.category.label,
              image,
              projectName,
              color: product.color,
              size: detail.size,
              price: reserve.price,
              quantity: detail.selectedQuantity,
            });
          });
          return detailRes;
        });
        const reserveDataResponse = {
          salesStatus,
          reservations,
          minimumProduction,
          reserveProducts,
          isPaid: reserve.isPaid,
          createdAt: reserve.createdAt,
          id: reserve.id,
        };
        return reserveDataResponse;
      });

      const reserveData = await Promise.all(unpaidReserveData);
      setReserve(reserveData);
      setLoading(false);
    }
    unsubscribe();
  });
};

export const fetchProject = (
  projectRef: string,
  setProject: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const ref = getDocumentRef(projectRef);
    getDoc(ref).then((doc) => {
      const data = doc.data();
      if (data) {
        const { numberToShip, reservations } = data;
        const newData = { numberToShip, reservations };
        setProject(newData);
      }
    });
  };
};

// export const fetchProductList = async (
//   setProductList: React.Dispatch<React.SetStateAction<Project[]>>,
//   setLoading: React.Dispatch<React.SetStateAction<boolean>>
// ) => {
//   setLoading(true);

//   try {
//     const projectsDisplayRef = query(
//       projectsDisplayCollection(),
//       orderBy("createdAt", "desc")
//     );
//     const snapshot = await getDocs(projectsDisplayRef);
//     snapshot.forEach((doc: any) => {
//       const data = doc.data() as Project;
//       if (
//         data.salesStatus.underReservation ||
//         data.salesStatus.sale ||
//         data.salesStatus.inProduction
//         // data.salesStatus.production
//       ) {
//         setProductList((prev) => [...prev, data]);
//       }
//     });

//     setLoading(false);
//   } catch (error) {
//     throw error;
//   }
// };

export const fetchProductList = async (
  setProductList: React.Dispatch<React.SetStateAction<Project[]>>,
  pageSize: number,
  lastDoc?: QueryDocumentSnapshot<DocumentData, DocumentData> | null,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (setLoading) setLoading(true);
  // 重複対策
  try {
    let projectsDisplayRef = query(
      projectsDisplayCollection(),
      orderBy("createdUnixTime", "desc"),
      where("isDisplay", "==", true),
      limit(pageSize)
    );

    if (lastDoc) {
      projectsDisplayRef = query(
        projectsDisplayCollection(),
        orderBy("createdUnixTime", "desc"),
        where("isDisplay", "==", true),
        startAfter(lastDoc),
        limit(pageSize)
      );
    }

    const snapshot = await getDocs(projectsDisplayRef);
    const lastVisible = snapshot.docs[snapshot.docs.length - 1];

    const newProducts = snapshot.docs.map((doc) => {
      const data = doc.data() as Project;
      return { ...data, id: doc.id };
    });
    setProductList((prev) => {
      const uniqueProjects = Array.from(
        new Map(
          [...prev, ...newProducts].map((item) => [item.id, item])
        ).values()
      );
      return uniqueProjects;
    });

    if (setLoading) setLoading(false);

    return lastVisible;
  } catch (error) {
    if (setLoading) setLoading(false);
    throw error;
  }
};

export const fetchProjectDisplayLastId = async (
  setLastId: React.Dispatch<React.SetStateAction<string>>
) => {
  const projectsDisplayRef = query(
    projectsDisplayCollection(),
    orderBy("createdUnixTime", "asc"),
    where("isDisplay", "==", true),
    limit(1)
  );
  const snapshot = await getDocs(projectsDisplayRef);
  if (snapshot.docs.length > 0) setLastId(snapshot.docs[0].id);
};

export const fetchReserveProductList = (
  setProductList: Function,
  setLoading: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const projectsRefQuery = query(
      projectsCollectionGroup(),
      where("salesStatus.underReservation", "==", true),
      orderBy("createdAt", "desc")
    );

    await getDocs(projectsRefQuery).then(async (snapshot) => {
      snapshot.forEach((doc) => {
        const data = doc.data();
        setProductList((prev: any) => [...prev, data]);
      });
      setLoading(false);
    });
  };
};

export const fetchSaleProductList = (
  setProductList: Function,
  setLoading: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    const projectsRefQuery = query(
      projectsCollectionGroup(),
      where("salesStatus.sale", "==", true),
      orderBy("createdAt", "desc")
    );
    await getDocs(projectsRefQuery).then(async (snapshot) => {
      snapshot.forEach((doc) => {
        const data = doc.data();
        setProductList((prev: any) => [...prev, data]);
      });
      setLoading(false);
    });
  };
};

export const creditIDSetStore = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    let unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const creditsRef = creditsCollection(user.uid);

        getDocs(creditsRef).then((snapshot) => {
          snapshot.forEach((doc) => {
            const data = doc.data();
            const p: Purchase = { customerID: data.stripeCustomerID };
            dispatch(updateStripeCustomer(p));
          });
        });
      }
      unsubscribe();
    });
  };
};

export const projectRequest = (project: Project): AppThunk => {
  return async (dispatch): Promise<void> => {
    const requestArray =
      project.requestArray !== undefined ? project.requestArray : [];
    const boolBox: boolean[] = [];
    let unsubscribe = auth.onAuthStateChanged((user) => {
      const uid = user?.uid;
      if (user) {
        requestArray.forEach((ra: string) => {
          ra === uid ? boolBox.push(true) : boolBox.push(false);
        });

        if (!boolBox.includes(true)) {
          requestArray.push(uid ?? "");
          const data = {
            ...project,
            requestArray: requestArray,
          };
          requestProject(data).then(() => {
            dispatch(push("/requestdone"));
          });
        } else {
          alert("すでにリクエスト済みです。");
        }
      }
      unsubscribe();
    });
  };
};
