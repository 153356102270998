import { Typography, makeStyles } from "@material-ui/core";

type Props = {
  snsIcon: string;
  snsLabel: string;
  width: number;
  height: number;
  onClick: () => () => void;
};

export const SnsAuth = (props: Props) => {
  const { snsIcon, snsLabel, width, height, onClick } = props;
  const useStyles = makeStyles({
    container: {
      // width: 500,
      margin: "0 auto",
      marginBottom: 20,
    },
    inputArea: {
      border: "1px solid rgb(151,151,151)",
      width: "calc(80% - 2px)",
      margin: "0 auto",
      cursor: "pointer",
      display: "flex",
    },
    snsAuthImageArea: {
      marginLeft: 10,
    },
    snsAuthTextArea: {
      width: "100%",
      "& p": {
        fontSize: 16,
        textAlign: "center",
        color: "rgb(151,151,151)",
        lineHeight: 2,
      },
      "@media screen and (max-width: 499px)": {
        flex: 4,
      },
    },
    Icon: {
      width: width,
      height: height,
      display: "block",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.inputArea} onClick={() => onClick()}>
        <div className={classes.snsAuthImageArea}>
          <img src={snsIcon} className={classes.Icon} alt="icon" />
        </div>
        <div className={classes.snsAuthTextArea}>
          <Typography>{snsLabel}</Typography>
        </div>
      </div>
    </div>
  );
};
