import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

interface Location {
  location: string
}

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    location: {
      location: 'home',
    },
  },
  reducers: {
    changeLocation: (state, action: PayloadAction<Location>) => {
      state.location = action.payload
    },
  },
})

export const { changeLocation } = locationSlice.actions
export const selectLocation = (state: RootState) => state.location.location

export default locationSlice.reducer
