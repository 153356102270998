import React from "react";
import diner from "../../assets/icon/Diner.png";

import { makeStyles } from "@material-ui/core";

const DinersClub = () => {
  const useStyles = makeStyles({
    cardBlock: {
      marginTop: 11,
      width: 65.115,
      height: 44.379,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.cardBlock}>
      <img src={diner} alt="DinersCard" />
    </div>
  );
};

export default DinersClub;
