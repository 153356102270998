import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import SaleBox from "./SaleParts/SaleBox";
import RewardHistory from "./SaleParts/RewardHistory";
import { ProjectMap } from "../../templates/Post/ProjectPost/ProjectMap";
import { RouteComponentProps } from "react-router-dom";
import { memerReward } from "../../features/userOperation";
import { SpaceBox } from "../UIKit/SpaceBox";

export const MeMerSalesConfirmation = (
  props: RouteComponentProps<{ uid: string }>
) => {
  const { history, match } = props;

  const [totalSales, setTotalSales] = useState<number>(0);
  const [reward, setReward] = useState<number>(0);
  const dispatch = useDispatch();
  const uid = match.params.uid;
  const status = "salesCheck";

  // 当月
  const dt = new Date(); // 「今日」のインスタンスを取得
  // 月末にしている
  const endMonth = new Date(dt.getFullYear(), dt.getMonth() + 1, 0, 23, 59, 59);
  // 日付を１日にしている
  const setDate = new Date(dt.setDate(1));
  // 時間を0秒にしている
  const startMonth = new Date(setDate.setHours(0, 0, 0));

  const [beginningMonth, setBeginningMonth] = useState(startMonth);
  const [endOfMonth, setEndOfMonth] = useState(endMonth);

  const startDate = () => {
    const fullYear = beginningMonth.getFullYear();
    const year = String(fullYear).slice(2, 4);
    let data =
      year +
      "/" +
      (beginningMonth.getMonth() + 1) +
      "/" +
      beginningMonth.getDate();
    return data;
  };

  const endDate = () => {
    const fullYear = endOfMonth.getFullYear();
    const year = String(fullYear).slice(2, 4);
    let data =
      year + "/" + (endOfMonth.getMonth() + 1) + "/" + endOfMonth.getDate();
    return data;
  };

  useEffect(() => {
    setTotalSales(0);
    setReward(0);
    dispatch(
      memerReward(beginningMonth, endOfMonth, setTotalSales, setReward, uid)
    );
  }, [beginningMonth, endOfMonth]);

  return (
    <div>
      <BrowserBackHeader label="Creator売上" browserBack={history} />
      <SpaceBox height={30} />
      <Typography align="center" variant="h6">
        {startDate()}~{endDate()}の売上
      </Typography>
      <SpaceBox height={10} />
      <SaleBox saleText="売上" rewardAmount={totalSales.toLocaleString()} />
      <SaleBox saleText="報酬額" rewardAmount={reward.toLocaleString()} />
      <RewardHistory
        setBeginningMonth={setBeginningMonth}
        setEndOfMonth={setEndOfMonth}
      />
      <SpaceBox height={40} />
      <Typography align="center" variant="h6">
        プロジェクト別売上
      </Typography>
      <ProjectMap uid={uid} status={status} />
    </div>
  );
};
