import { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { TopFooter } from "../components/Footer/TopFooter";
import { selectSqueeze, selectUser } from "../features/userSlice";
import {
  selectModal,
  closeModal,
  postLoading,
} from "../features/controlBoolSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchFirstProjectsPosts,
  fetchPosts,
  fetchProjectsPosts,
  projectsLastIdGet,
} from "../features/postOperation";
import TopSkeleton from "./TopSkeleton";
import { PostSqueeze } from "./PostSqueeze";
import { Post } from "../Types/userType";
import ProjectPostButton from "./Post/ProjectPost/ProjectPostButton";
import InfiniteScroll from "react-infinite-scroller";
import {
  resetState,
  selectFetchCheckPostId,
  selectFetchCheckProjectPostId,
  selectFetchLastPost,
  selectFetchOldestId,
  selectFetchOldestProjectId,
  selectFetchPost,
  selectFetchProjectLastPost,
  selectFetchProjectPost,
} from "../features/postSlice";
import { TopPosts } from "./Post/TopPosts";
import "../Views/App.css";
import { LoginInfo } from "../components/feature/LoginInfo/LoginInfo";
import { logError } from "../lib/logError";
import WantInfo from "../components/feature/WantInfo/WantInfo";
import { SpaceBox } from "../components/UIKit/SpaceBox";
import { MainHeader } from "../components/Header/MainHeader";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { GrayBack } from "../components/MyPage/GrayBack";
import { toast } from "react-toastify";
import { resetProjectState } from "../features/projectPostSlice";
import { Project } from "../Types/projectType";
import { ProductSkeleton } from "./Skeleton/ProductSkeleton";
import { ProductMap } from "./Product/ProductParts/ProductMap";
import {
  fetchProductList,
  fetchProjectDisplayLastId,
} from "../features/productOperation";
import { analyticsCustomLogEvent } from "../firebase";

export const Top = () => {
  const useStyles = makeStyles({
    container: {
      width: 500,
      margin: "0 auto",
      top: 0,
      overflow: "hidden",
      outline: "none",
      "@media screen and (max-width: 499px)": {
        width: "100%",
        margin: "0",
      },
    },
  });

  const classes = useStyles();
  const user = useSelector(selectUser);
  // store系
  const squeeze = useSelector(selectSqueeze);
  const [wantInfoShow, setWantInfoShow] = useState(false);
  const [productList, setProductList] = useState<Project[]>([]);
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<
    DocumentData,
    DocumentData
  > | null>(null);
  const [productHasMore, setProductHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [lastId, setLastId] = useState("");
  // ProjectPost
  // 読み込んでるPOST
  const storeProjectPost = useSelector(
    selectFetchProjectPost
  ) as unknown as Post[];
  // 今読み込んでるPOSTの最後のデータ
  const storeProjectLastPost = useSelector(selectFetchProjectLastPost);
  // 対象の全投稿の最後のpostID↓のCheckPostIDと比較をして制御する
  const storeOldestProjectId = useSelector(selectFetchOldestProjectId);
  // PostIDを格納している基本的にlength-1で最後のIDを使う
  const storeCheckProjectPostId = useSelector(selectFetchCheckProjectPostId);

  // AllPost
  // 読み込んでるPOST
  const storePost = useSelector(selectFetchPost) as unknown as Post[];
  // 今読み込んでるPOSTの最後のデータ
  const storeLastPost = useSelector(selectFetchLastPost);
  // 対象の全投稿の最後のpostID↓のCheckPostIDと比較をして制御する
  const storeOldestId = useSelector(selectFetchOldestId);
  // PostIDを格納している基本的にlength-1で最後のIDを使う
  const storeCheckPostId = useSelector(selectFetchCheckPostId);

  // loading
  const storeLoading = useSelector(postLoading);
  const modal = useSelector(selectModal).isOpen;
  const dispatch = useDispatch();

  const productClick = () => {
    const product = document.getElementById("Product");
    product?.click();
  };

  const projectClick = () => {
    const project = document.getElementById("Project");
    project?.click();
    if (
      storeOldestProjectId !==
      storeCheckProjectPostId[storeCheckProjectPostId.length - 1]
    ) {
      dispatch(fetchFirstProjectsPosts());
    }
    storeOldestProjectId === "" && dispatch(projectsLastIdGet());
    analyticsCustomLogEvent("creator_posts_clicked");
  };

  const fetchMoreProducts = async () => {
    const lastVisible = await fetchProductList(setProductList, 4, lastDoc);
    setIsFetching(true);
    if (lastVisible.id === lastId) {
      setProductHasMore(false);
      return;
    }
    setLastDoc(lastVisible);
  };

  const fetchMoreData = async () => {
    if (isFetching) return; // Prevent multiple fetches
    setIsFetching(true);
    if (
      squeeze.Project &&
      storeOldestProjectId !==
        storeCheckProjectPostId[storeCheckProjectPostId.length - 1]
    ) {
      try {
        dispatch(
          fetchProjectsPosts(
            storeProjectLastPost,
            storeProjectPost,
            storeOldestProjectId
          )
        );
      } catch (error) {
        toast.error("投稿の取得に失敗しました。\nリロードしてください");
      } finally {
        setIsFetching(false);
      }
    }
    if (
      squeeze.All &&
      storeOldestId !== storeCheckPostId[storeCheckPostId.length - 1]
    ) {
      try {
        dispatch(fetchPosts(storeLastPost, storePost, storeOldestId));
      } catch (error) {
        toast.error("投稿の取得に失敗しました。\nリロードしてください");
      } finally {
        setIsFetching(false);
      }
    }

    if (squeeze.Product) {
      try {
        await fetchMoreProducts();
      } catch (error) {
        logError(error);
        toast.error("商品の取得に失敗しました。\nリロードしてください");
      } finally {
        setIsFetching(false);
      }
    }
  };

  const hasMoreReturn = () => {
    if (squeeze.Project) {
      return (
        storeOldestProjectId !==
        storeCheckProjectPostId[storeCheckProjectPostId.length - 1]
      );
    }
    if (squeeze.All) {
      return storeOldestId !== storeCheckPostId[storeCheckPostId.length - 1];
    }
    if (squeeze.Product) {
      return productHasMore;
    }
  };

  const fetchPost = async (isDelete?: boolean) => {
    if (squeeze.Project) {
      if (
        storeProjectLastPost.postID !== storeOldestProjectId ||
        storeProjectLastPost.postID === ""
      ) {
        dispatch(fetchFirstProjectsPosts());
        dispatch(projectsLastIdGet());
      }
      return;
    }
    if (isDelete) {
      const project = document.getElementById("Project");
      project?.click();
      dispatch(fetchFirstProjectsPosts());
      dispatch(projectsLastIdGet());
    }
  };

  const targetLoader = () => {
    if (squeeze.Project) return <TopSkeleton />;
    if (squeeze.Product) return <ProductSkeleton />;
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchPost();
      } catch (error) {
        analyticsCustomLogEvent("top_page_db_fetch_error");
        toast.error("投稿の取得に失敗しました。\nリロードしてください");
      }
    })();
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchProducts = async () => {
      try {
        if (isMounted) {
          const lastVisible = await fetchProductList(
            setProductList,
            4,
            lastDoc
          );
          setLastDoc(lastVisible);
          await fetchProjectDisplayLastId(setLastId);
        }
      } catch (error) {
        if (isMounted) {
          logError(error);
          analyticsCustomLogEvent("top_page_db_fetch_error");
          toast.error("商品の取得に失敗しました。\nリロードしてください");
        }
      }
    };
    fetchProducts();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    // 初期化系
    dispatch(resetProjectState());
    dispatch(resetState());
  }, []);

  return (
    <div className={classes.container} id="Container">
      <LoginInfo isShow={modal} handleClose={() => dispatch(closeModal())} />
      <WantInfo isShow={wantInfoShow} setState={setWantInfoShow} />
      <MainHeader />
      <PostSqueeze projectClick={projectClick} productClick={productClick} />
      {(user.role === "memer" || user.role === "admin") && (
        <ProjectPostButton />
      )}
      <GrayBack height={1} isDark />
      {storeLoading.loading && squeeze.Project && <TopSkeleton />}
      <InfiniteScroll
        loadMore={fetchMoreData}
        hasMore={hasMoreReturn()}
        initialLoad={false}
        loader={targetLoader()}
      >
        {squeeze.Product && <ProductMap productList={productList} />}
        {squeeze.Project && (
          <TopPosts posts={storeProjectPost} fetchPost={fetchPost} />
        )}
      </InfiniteScroll>

      <SpaceBox height={45} />
      <TopFooter uid={user.uid} effect="home" />
    </div>
  );
};
