import TextField from "@material-ui/core/TextField";

export const TextInput = (props: any): JSX.Element => {
  /*
  wullWidth
  */
  return (
    <TextField
      style={props.style}
      fullWidth={props.fullWidth}
      label={props.label}
      margin={props.margin}
      variant={props.variant ? props.variant : "outlined"}
      multiline={props.multiline}
      required={props.required}
      value={props.value}
      type={props.type}
      onChange={props.onChange}
      autoFocus={props.autoFocus}
      placeholder={props.placeholder}
      inputRef={props.reference}
      id={props.id}
      {...props.field}
    />
  );
};
