import { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import UseCreditChange from "../../../components/Payment/UseCreditChange";
import { Credit } from "../../../Types/paymentType";
import CreditCardViesList from "../../../components/Payment/CreditCardViesList";
import { SpaceBox } from "../../../components/UIKit/SpaceBox";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";

type Props = {
  useCreditList: Credit[];
  useCredit: Credit;
  setUseCredit: React.Dispatch<React.SetStateAction<Credit>>;
  timeOver?: boolean;
  salesProject?: unknown;
  fetchCredit?: (uid: string, customerId?: string) => Promise<void>;
};

export const ReservePayment = (props: Props) => {
  const {
    useCreditList,
    useCredit,
    setUseCredit,
    timeOver,
    salesProject,
    fetchCredit,
  } = props;

  const user = useSelector(selectUser);
  const useStyles = makeStyles({
    whiteArea: {
      width: 490,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: 355,
      },
      paddingTop: 15,
      paddingLeft: 20,
    },
    infoText: {
      fontSize: 14,
    },
    changeBtn: {
      border: "1px solid gray",
      width: 75,
      margin: "5px auto 0",
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        backgroundColor: "rgb(234,234,234)",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cardArea: {
      display: "flex",
    },
    cardAreaLeft: {
      flex: 0.8,
      "@media screen and (max-width: 499px)": {
        flex: 1.2,
      },
    },
    cardAreaRight: {
      flex: 5,
    },
    cardImage: {
      width: 52.092,
      height: 35.5,
    },
    registeredInfo: {
      width: 200,
      margin: "0 auto",
      display: "flex",
      justifyContent: "between",
    },
  });
  const classes = useStyles();
  const { push } = useHistory();
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const creditChange = () => {
    setOpenCreditModal(true);
  };

  const handleCreditModalClose = () => {
    setOpenCreditModal(false);
  };

  const domReturn = (isButton: boolean) => {
    if (isButton) {
      if (!timeOver && (useCreditList.length === 0 || useCredit.last4 === ""))
        return (
          <div className={classes.registeredInfo}>
            <div
              className={classes.changeBtn}
              onClick={() =>
                push(`/${user.uid}/payment/register`, salesProject)
              }
            >
              <Typography className={classes.infoText} align="center">
                登録する
              </Typography>
            </div>
            <div
              className={classes.changeBtn}
              onClick={() => {
                if (fetchCredit) fetchCredit(user.uid);
              }}
            >
              <Typography className={classes.infoText} align="center">
                再取得
              </Typography>
            </div>
          </div>
        );
      return (
        <div className={classes.changeBtn} onClick={() => creditChange()}>
          <Typography className={classes.infoText} align="center">
            変更する
          </Typography>
        </div>
      );
    }
    if (!timeOver && (useCreditList.length === 0 || useCredit.last4 === ""))
      return (
        <>
          <Typography className={classes.infoText}>未登録</Typography>
        </>
      );
    return (
      <>
        <Typography className={classes.infoText}>
          支払い情報が確認できませんでした
        </Typography>
        <Typography className={classes.infoText}>
          戻ってやり直していただくか、支払情報を登録してください。
        </Typography>
      </>
    );
  };

  useEffect(() => {
    useCreditList.forEach((uc: Credit) => {
      uc.primaryCard && setUseCredit(uc);
    });
  }, [useCreditList]);

  return (
    <>
      <div className={classes.whiteArea}>
        {useCredit.last4 !== "" ? (
          <div className={classes.cardArea}>
            <div className={classes.cardAreaLeft}>
              <CreditCardViesList creditBrand={useCredit.brand} />
            </div>
            <div className={classes.cardAreaRight}>
              <Typography className={classes.infoText}>
                クレジットカード
              </Typography>
              <Typography
                className={classes.infoText}
              >{`****-****-****-${useCredit.last4}`}</Typography>
            </div>
          </div>
        ) : (
          <>{domReturn(false)}</>
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openCreditModal}
          onClose={handleCreditModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCreditModal}>
            <UseCreditChange
              useCreditList={useCreditList}
              setUseCredit={setUseCredit}
              setOpenCreditModal={setOpenCreditModal}
            />
          </Fade>
        </Modal>
      </div>
      {domReturn(true)}
      <SpaceBox height={10} />
    </>
  );
};
