import React from "react";
import { UserIcon } from "../UIKit/UserIcon";
import { Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Post } from "../../Types/userType";
import { useSelector, useDispatch } from "react-redux";
import { createStyles } from "@material-ui/core";
import { refreshLoading } from "../../features/loadingOperation";
import { selectUser } from "../../features/userSlice";
import { FollowBtn } from "../UIKit/FollowBtn";

type Props = {
  post: Post;
  posts: Post[];
};

export const UserInfo = React.memo((props: Props) => {
  const { post, posts } = props;
  const dispatch = useDispatch();
  const uid = post.uid;
  const user = useSelector(selectUser);

  const useStyles = makeStyles((Theme: any) =>
    createStyles({
      userInfoBar: {
        height: 70,
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 499px)": {
          height: 50,
        },
      },
      userName: {
        display: "flex",
        alignItems: "center",
        marginTop: 10,
        marginLeft: 10,
      },
      Name: {
        lineHeight: 3,
        fontSize: 12,
      },
      "MuiGridListTile-root": {
        overflow: "hidden",
      },
      nameLink: {
        textDecoration: "none",
        color: "black",
        flex: 5,
      },
    })
  );
  const classes = useStyles();

  // const isFollow = (id?: string, myId?: string) => {
  //   if (!id || !myId) return false;
  //   if (id !== myId) return true;
  //   return false;
  // };

  return (
    <div className={classes.userInfoBar}>
      <Link
        to={`/profile/${uid}/${user.uid}`}
        onClick={() => dispatch(refreshLoading(`/profile/${uid}/${user.uid}`))}
      >
        <UserIcon
          photoUrl={post.userInfo.photoUrl}
          width={58}
          height={58}
          sWidth={48}
          sHeight={48}
          margin="10px 0 0 10px"
        />
      </Link>
      <Link
        to={`/profile/${uid}/${user.uid}`}
        onClick={() => dispatch(refreshLoading(`/profile/${uid}/${user.uid}`))}
        className={classes.nameLink}
      >
        <div className={classes.userName}>
          <Typography className={classes.Name}>
            {post.userInfo.displayName}
          </Typography>
        </div>
      </Link>
      {/* {isFollow(uid, user.uid) && <FollowBtn post={post} posts={posts} />} */}
    </div>
  );
});
