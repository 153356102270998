import { Area } from "react-easy-crop/types";

export const urlToBlob = async (url: string) => {
  const response = await fetch(url);
  return response.blob();
};

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area
): Promise<string> => {
  const image = new Image();
  image.src = imageSrc;
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Canvas context is not available");
  }

  return new Promise((resolve, reject) => {
    image.onload = () => {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      // As a blob
      canvas.toBlob((file) => {
        if (file) {
          resolve(URL.createObjectURL(file));
        } else {
          reject(new Error("Canvas toBlob failed"));
        }
      }, "image/jpeg");
    };
    image.onerror = () => {
      reject(new Error("Image loading error"));
    };
  });
};

const getScrollbarWidth = () => {
  // Create a temporary div container and append it into the body
  const container = document.createElement("div");
  // Append the container in the body
  document.body.appendChild(container);
  // Force scrollbar on the container
  container.style.overflow = "scroll";

  // Add inner div without width and measure the container's width
  const inner = document.createElement("div");
  container.appendChild(inner);

  // Calculate the width based on the container width minus its child width
  const scrollbarWidth = container.offsetWidth - inner.offsetWidth;
  // Remove the container from the body
  document.body.removeChild(container);

  return scrollbarWidth;
};

const isScrollbarVisible = () => {
  return window.innerWidth > document.documentElement.clientWidth;
};

export const calculateSize = (width: number) => {
  const scrollbarWidth = getScrollbarWidth();
  const isShow = isScrollbarVisible();
  if (width <= 499) {
    // 画像が3枚並ぶように幅を計算
    const imageWidth = isShow
      ? (width - scrollbarWidth) / 3 - 1
      : width / 3 - 1; // 1pxは隙間のために引く
    // 375pxの時のアスペクト比を維持するための高さを計算
    const aspectRatio = 167.777778 / 124.3333333;
    const imageHeight = imageWidth * aspectRatio;
    return { width: imageWidth, height: imageHeight };
  }
  // 500px以上の場合は固定サイズ
  return { width: 166, height: 221 };
};
