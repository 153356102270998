import { makeStyles } from "@material-ui/core";
import { Project } from "../../../Types/projectType";
import { useWindowSize } from "react-use";
import { Product } from "./Product";

type Props = {
  productList: Project[];
};

export const ProductMap = (props: Props) => {
  const { productList } = props;
  const { width } = useWindowSize();

  const useStyles = makeStyles({
    productCard: {
      display: "flex",
      flexWrap: "wrap",
    },
    product: {
      paddingTop: 20,
      width: "50%",
      cursor: "pointer",
      paddingBottom: 10,
      "&:hover": {
        backgroundColor: "rgb(230,230,230)",
        transition: "all 0.3s",
      },
    },
    img: {
      width: 230,
      height: 287.5,
      margin: "0 auto",
      display: "block",
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: width * (172.5 / 375),
        height: width * (215.625 / 375),
      },
    },
    colorArea: {
      marginLeft: 10,
      display: "flex",
      justifyContent: "center",
    },
    colorBox: {
      width: 15,
      height: 15,
      border: "1px solid rgba(210, 210, 210, 0.9)",
      margin: "0 5px",
    },
    statusArea: {
      position: "relative",
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.productCard}>
      {productList.map((pl) => pl.image && <Product product={pl} />)}
    </div>
  );
};
