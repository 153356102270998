import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { newLineConvert } from "../../../features/helpers/textHelper";

interface PROPS {
  memerComment: string;
  projectName: string;
}

export const ProductIntroduction = (props: PROPS) => {
  const { memerComment, projectName } = props;
  const useStyles = makeStyles({
    introductionArea: {
      paddingTop: 40,
      color: "rgb(74,74,74)",
    },
    title: {
      marginLeft: 8,
      fontSize: 14,
      fontWeight: "bold",
    },
    introduction: {
      width: 470,
      padding: "0 15px",
      margin: "20px auto 0",
      "@media screen and (max-width: 499px)": {
        width: 352.5,
      },
    },
    comment: {
      fontSize: 14,
      fontWeight: "lighter",
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.introductionArea}>
      <div className={classes.title}>
        <Typography className={classes.title}>
          {projectName === "" ? "Project" : projectName}
        </Typography>
      </div>
      {/* <div className={classes.introduction}>
        <Typography className={classes.comment}>
          {newLineConvert(memerComment)}
        </Typography>
      </div> */}
    </div>
  );
};
