import { Typography, makeStyles } from "@material-ui/core";
import { ReserveItemInfo } from "./ReserveItemInfo";
import { ReservePrice } from "./ReservePrice";

type Props = {
  productData: {
    color: string;
    value: string;
  };
  productDetail: {
    ref: string;
    size: string;
    selectedQuantity: number;
  };
  image: string;
  projectName: string;
  retailPrice: number;
  itemName: string;
};

export const ReserveProduct = (props: Props) => {
  const {
    productDetail,
    productData,
    image,
    projectName,
    retailPrice,
    itemName,
  } = props;
  const useStyles = makeStyles({
    container: {
      marginTop: 40,
    },
    dummyArea: {
      height: 20,
    },
    productDetail: {
      display: "flex",
      height: 170,
      margin: "0 15px",
    },
    left: {
      width: 180,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 135,
      },
    },
    right: {
      width: 320,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 240,
      },
    },
    imageArea: {
      margin: "0 auto",
      width: 120,
    },
    image: {
      width: 120,
      height: 150,
      objectFit: "cover",
    },
    shipText: {
      marginBottom: 10,
    },
    projectNameSignboard: {
      fontWeight: "bold",
    },
    projectName: {
      marginBottom: 10,
    },
    totalPriceArea: {
      marginLeft: 30,
    },
    headerTextArea: {
      paddingLeft: 15,
      margin: "20px 0",
      "@media screen and (max-width: 499px)": {
        paddingLeft: 20,
      },
      "& p": {
        fontSize: 14,
        "@media screen and (max-width: 499px)": {
          fontSize: 12,
        },
      },
    },
    border: {
      margin: "0 auto",
      width: "90%",
      borderBottom: "1px solid gray",
    },
  });
  const classes = useStyles();
  const total = Number(retailPrice) * productDetail.selectedQuantity;
  const price = Number(total).toLocaleString();
  return (
    <div className={classes.container}>
      <div className={classes.headerTextArea}>
        <Typography className={classes.projectNameSignboard}>
          プロジェクト名
        </Typography>
        <Typography className={classes.projectName}>{projectName}</Typography>
      </div>
      <div className={classes.productDetail}>
        <div className={classes.left}>
          <div className={classes.imageArea}>
            <img src={image} className={classes.image} alt="productImage" />
          </div>
        </div>
        <div className={classes.right}>
          <ReserveItemInfo label="アイテム名" detail={itemName} />
          <ReserveItemInfo label="サイズ" detail={productDetail.size} />
          <ReserveItemInfo label="カラー" detail={productData.color} />
          <ReservePrice
            price={`¥${price}`}
            quantity={productDetail.selectedQuantity}
          />
        </div>
      </div>
      <div className={classes.border} />
    </div>
  );
};
