import { Header } from "../Header/Header";
import { Done } from "../../templates/Done/Done";
const CreditDone = () => {
  return (
    <div>
      <Header label="クレジットカード登録" transitionLocation={"/?"} />
      <Done info="登録完了しました" linkText="TOPページへ" />
    </div>
  );
};

export default CreditDone;
