import { createRef } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { uploadInquiryImage } from "../../../features/imageOperation";
import { SpaceBox } from "../../UIKit/SpaceBox";
import { imageTypeCheck } from "../../../lib/imageTypeCheck";

type Props = {
  label: string;
  setImage: Function;
  setAttachment: Function;
};

export const InquiryAttach = (props: Props) => {
  const { label, setImage, setAttachment } = props;
  const useStyles = makeStyles({
    inputArea: {
      margin: "0 20px",
    },
    input: {
      width: "100%",
      marginTop: 10,
      borderRadius: 20,
    },
    title: {
      marginLeft: 5,
      display: "block",
      fontSize: 15,
    },
    icon: {
      marginTop: 10,
      cursor: "pointer",
      width: 50,
      height: 50,
    },
    attachmentInput: {
      display: "none",
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = createRef<HTMLInputElement>();

  const inputImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      const file = e.target.files[0];
      try {
        imageTypeCheck(file);
      } catch (error) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setAttachment(reader.result);
        dispatch(uploadInquiryImage(e, setImage));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={classes.inputArea}>
      <Typography className={classes.title}>{label}</Typography>
      <AttachFileIcon
        className={classes.icon}
        onClick={() => ref.current!.click()}
      />
      <input
        type="file"
        className={classes.attachmentInput}
        ref={ref}
        onChange={inputImage}
      />
      <SpaceBox height={30} />
    </div>
  );
};

