import { makeStyles, Typography } from "@material-ui/core";

type Props = {
  kind: string;
  price: string | number;
  isBorder?: boolean;
};
export const TotalAmount = (props: Props) => {
  const { kind, price, isBorder } = props;
  const useStyles = makeStyles({
    total: {
      display: "flex",
      borderBottom: isBorder ? "1px solid black" : "none",
      width: "50%",
      marginBottom: 10,
    },
    totalText: {
      flex: 1,
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.total}>
        <Typography className={classes.totalText} align="center">
          {kind}
        </Typography>
        <Typography
          className={classes.totalText}
          align="center"
        >{`¥${price}`}</Typography>
      </div>
    </div>
  );
};
