import React, { ReactNode } from "react";
import { Button, makeStyles } from "@material-ui/core";

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained";
  children?: ReactNode;
  width?: number | string;
  color?: string;
  backgroundColor?: string;
  borderRadius?: number;
  className?: object;
  disabledColor?: string;
  type?: "button" | "submit" | "reset";
};

export const GrayButton = (props: Props) => {
  const {
    onClick,
    disabled,
    variant,
    children,
    width,
    color,
    backgroundColor,
    borderRadius,
    className,
    disabledColor,
    type = "button",
  } = props;
  const useStyles = makeStyles((theme) => ({
    button: {
      width: width ?? 150,
      color: color ?? "white",
      backgroundColor: disabled
        ? disabledColor ?? "rgb(200, 200, 200)"
        : backgroundColor ?? "rgb(175, 175, 175)", // disabled時の色を条件に追加
      borderRadius: borderRadius ?? 0,
      textTransform: "none",
      "&:hover": {
        backgroundColor: disabled ? undefined : "rgb(210, 210, 210)", // disabled時はhover効果を無効に
      },
      ...className,
    },
  }));
  const classes = useStyles();
  return (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      className={classes.button}
      type={type}
    >
      {children}
    </Button>
  );
};
