import React from "react";
import Linkify from "react-linkify";

export const newLineConvert = (text: string) => {
  return text.split(/(\n)/).map((item: string, index: number) => {
    return (
      <React.Fragment key={index}>
        {item.match(/\n/) ? <br /> : item}
      </React.Fragment>
    );
  });
};

export const newLineConvertWithUrl = (text: string) => {
  return text.split(/(\n)/).map((item: string, index: number) => {
    return (
      <React.Fragment key={index}>
        {item.match(/\n/) ? (
          <br />
        ) : (
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                target="_blank"
                rel="noreferrer"
                href={decoratedHref}
                key={key}
              >
                {decoratedText}
              </a>
            )}
          >
            {item}
          </Linkify>
        )}
      </React.Fragment>
    );
  });
};
