import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Switch, TextField, makeStyles } from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { registerCard } from "../../features/PaymentOperation";
import visa from "../../assets/icon/VISA.png";
import master from "../../assets/icon/Master.png";
import jcb from "../../assets/icon/JCB.png";
import discover from "../../assets/icon/discover.png";
import diners from "../../assets/icon/Diner.png";
import american from "../../assets/icon/American.png";
import CreditNumberTest from "./CreditNumberTest";
import { History as BrowserHistory } from "history";
import { SpaceBox } from "../UIKit/SpaceBox";
import { creditsCollection } from "../../firebasePaths";
import { getDocs } from "firebase/firestore";
import { Text } from "../atoms/Text/Text";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Loading } from "../atoms/Loading/Loading";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { selectUser } from "../../features/userSlice";

type Props = {
  history: BrowserHistory;
  uid: string;
  salesProject?: unknown;
};

type FormValue = {
  cardHolderName: string;
};

export const StripeCreditCreate = (props: Props) => {
  const { history, uid, salesProject } = props;
  const originalWidth = 65.115;
  const originalHeight = 44.379;

  const newWidth = (originalWidth * 3) / 4;
  const newHeight = (originalHeight * 3) / 4;
  const useStyles = makeStyles({
    cardText: {
      margin: "20px 0",
    },
    cardKindText: {},
    inputArea: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    checked: {
      border: "1px solid gray",
      borderRadius: 20,
      width: "100%",
      height: 50,
      backgroundColor: "lightcyan",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    unChecked: {
      border: "1px solid gray",
      borderRadius: 20,
      width: "100%",
      height: 50,
      backgroundColor: "white",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    cardBlockFlex: {
      display: "flex",
    },
    cardBlock: {
      flex: 1,
    },
    cardImage: {
      width: originalWidth,
      height: originalHeight,
      "@media screen and (max-width: 499px)": {
        width: newWidth,
        height: newHeight,
      },
    },
  });
  const classes = useStyles();
  const stripe = useStripe();
  const element = useElements();
  const { username } = useSelector(selectUser);
  const { push } = useHistory();
  const [customerID, setCustomerID] = useState("");
  const [isMain, setIsMain] = useState(false);
  const customerIDRef = creditsCollection(uid);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const getCustomerId = async () => {
      await getDocs(customerIDRef).then((snapshot) => {
        snapshot.forEach((cusData) => {
          const data = cusData.data();
          if (isMounted) {
            setCustomerID(data.stripeCustomerID);
          }
        });
      });
    };

    getCustomerId();
    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = async (data: FormValue) => {
    const { cardHolderName } = data;
    setActive(true);
    const { status, customerId } = await registerCard(
      stripe,
      element,
      customerID,
      isMain,
      cardHolderName,
      uid,
      username
    );
    if (status === "failed") return;
    setCustomerID(customerId);
    const cardElement = element?.getElement(CardElement);
    if (cardElement) {
      cardElement.clear();
    }
    toast.success("クレジットカードの登録が完了しました。");
    setActive(false);
  };

  const switchCheck = () => {
    setIsMain(!isMain);
  };

  const switchText = () => {
    if (isMain) return "ON";
    return "OFF";
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValue>();

  const validateName = (value: string) => {
    const nameRegex = /^[A-Za-z\s\-.']+$/;
    if (!nameRegex.test(value)) {
      return "名前には英字、スペース、ハイフン、ピリオド、アポストロフィのみ使用できます。";
    }
    return true;
  };
  return (
    <div>
      <BrowserBackHeader label="クレジット登録" browserBack={history} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {isActive && <Loading text="登録中" />}
        <Text
          text="クレジットカード所有者の名前"
          className={{
            margin: "20px 0",
          }}
        />
        <Controller
          name="cardHolderName"
          control={control}
          rules={{ required: "必須です", validate: validateName }}
          render={({ field }) => (
            <TextField
              placeholder="TARO TOMEME"
              {...field}
              style={{
                width: "100%",
                color: "rgb(80, 80, 80)",
              }}
              error={!!errors.cardHolderName}
              helperText={
                errors.cardHolderName && errors?.cardHolderName?.message
              }
            />
          )}
        />
        <Text
          text="クレジットカードの番号を入力してください"
          className={{
            margin: "20px 0",
          }}
        />
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: "16px",
                color: "rgb(80, 80, 80)",
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        <SpaceBox height={20} />
        <div className={classes.inputArea}>
          <Text
            text={`メインカードに設定する(${switchText()})`}
            className={{
              TextAlign: "center",
            }}
          />
          <Switch
            onChange={() => {
              switchCheck();
            }}
            color="primary"
            value={isMain}
          />
        </div>
        <Text
          text="登録可能カード"
          className={{
            margin: "20px 0 5px 0",
          }}
        />
        <div className={classes.cardBlockFlex}>
          <div className={classes.cardBlock}>
            <img src={visa} className={classes.cardImage} alt="visaCard" />
          </div>
          <div className={classes.cardBlock}>
            <img src={master} className={classes.cardImage} alt="masterCard" />
          </div>
          <div className={classes.cardBlock}>
            <img src={jcb} className={classes.cardImage} alt="jcbCard" />
          </div>
          <div className={classes.cardBlock}>
            <img
              src={discover}
              className={classes.cardImage}
              alt="discoverCard"
            />
          </div>
          <div className={classes.cardBlock}>
            <img src={diners} className={classes.cardImage} alt="dinersCard" />
          </div>
          <div className={classes.cardBlock}>
            <img
              src={american}
              className={classes.cardImage}
              alt="americanCard"
            />
          </div>
        </div>
        <SpaceBox height={30} />
        <Center>
          <GrayButton type="submit" width="100%">
            カード情報を登録する
          </GrayButton>
        </Center>
        {salesProject && (
          <Center
            className={{
              marginTop: 20,
            }}
          >
            <GrayButton
              onClick={() => push("/product/reserve", salesProject)}
              width="100%"
            >
              購入画面に戻る
            </GrayButton>
          </Center>
        )}
      </form>
      <SpaceBox height={50} />
      {process.env.REACT_APP_ENV === "development" && <CreditNumberTest />}
    </div>
  );
};
