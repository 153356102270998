import React, { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { sendPost } from "../../features/postOperation";
import { UserIcon } from "../../components/UIKit/UserIcon";
import { selectPost } from "../../features/postSlice";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { selectProjectPost } from "../../features/projectPostSlice";
import TextField from "@material-ui/core/TextField";
import { sendProjectPost } from "../../features/projectPostOperation";
import { PostImages } from "../../Types/projectType";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { QuiltedTwoImages } from "../../components/PostImage/QuiltedTwoImages";
import { QuiltedFiveImages } from "../../components/PostImage/QuiltedFiveImages";
import { QuiltedFourImages } from "../../components/PostImage/QuiltedFourImages";
import { QuiltedOneImages } from "../../components/PostImage/QuiltedOneImages";
import { QuiltedThreeImages } from "../../components/PostImage/QuiltedThreeImages";
import { useWindowSize } from "react-use";

export const PostInput = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    container: {
      width: 500,
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    snsNameBlock: {
      padding: "10px",
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },

    toggleBox: {
      marginLeft: "auto",
    },
    textInput: {
      width: 480,
      resize: "none",
      padding: 10,
      marginBottom: 30,
      borderTop: "1px solid rgb(169,169,169)",
      borderBottom: "1px solid rgb(169,169,169)",
      margin: "0 auto",
      display: "block",
      fontSize: 16,
      "@media screen and (max-width: 499px)": {
        width: "90%",
      },
    },
    "::placeholder": {
      color: "rgb(239,239,239)",
    },
    titleInput: {
      width: 480,
      padding: 1,
      margin: "0 auto",
      display: "block",
      fontSize: 16,
      "@media screen and (max-width: 499px)": {
        width: "96%",
      },
    },
    postTitle: {
      lineHeight: 4,
    },
    userName: {
      lineHeight: 4,
    },
    box: {
      borderTop: "1px solid  rgb(169,169,169)",
      borderBottom: "1px solid  rgb(169,169,169)",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    text: {
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    btn: {
      display: "none",
    },
    btnArea: {
      display: "grid",
      justifyContent: "center",
      alignContent: "center",
    },
    errorMessage: {
      color: "red",
      fontSize: 12,
    },
    noImage: {
      marginTop: 10,
      textAlign: "center",
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const postSelector = useSelector(selectPost);
  const projectPost = useSelector(selectProjectPost);
  const projectPostSelector = useSelector(selectProjectPost);
  const [images, setImages] = useState(postSelector.images);
  const { projectID } = projectPostSelector;
  const { width } = useWindowSize();
  const NoImage = () => {
    return (
      <div className={classes.noImage}>
        <Typography>画像がありません</Typography>
        <Typography>投稿を最初からやり直してください</Typography>
      </div>
    );
  };

  const SwitchQuiltedImages: React.FC<PostImages> = ({
    images,
    setImages,
    currentWidth,
  }) => {
    if (projectID !== "") {
      switch (projectPostSelector.images?.length) {
        case 5:
          return (
            <QuiltedFiveImages
              projectImages={projectPostSelector.images}
              currentWidth={currentWidth}
            />
          );
        case 4:
          return (
            <QuiltedFourImages
              projectImages={projectPostSelector.images}
              currentWidth={currentWidth}
            />
          );
        case 3:
          return (
            <QuiltedThreeImages
              projectImages={projectPostSelector.images}
              currentWidth={currentWidth}
            />
          );
        case 2:
          return (
            <QuiltedTwoImages
              projectImages={projectPostSelector.images}
              currentWidth={currentWidth}
            />
          );
        case 1:
          return (
            <QuiltedOneImages
              projectImages={projectPostSelector.images}
              currentWidth={currentWidth}
            />
          );
        default:
          return <NoImage />;
      }
    } else {
      switch (images?.length) {
        case 5:
          return (
            <QuiltedFiveImages
              images={images}
              setImages={setImages}
              currentWidth={currentWidth}
            />
          );
        case 4:
          return (
            <QuiltedFourImages
              images={images}
              setImages={setImages}
              currentWidth={currentWidth}
            />
          );
        case 3:
          return (
            <QuiltedThreeImages
              images={images}
              setImages={setImages}
              currentWidth={currentWidth}
            />
          );
        case 2:
          return (
            <QuiltedTwoImages
              images={images}
              setImages={setImages}
              currentWidth={currentWidth}
            />
          );
        case 1:
          return (
            <QuiltedOneImages
              images={images}
              setImages={setImages}
              currentWidth={currentWidth}
            />
          );
        default:
          return <NoImage />;
      }
    }
  };

  const methods = useForm();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const submitClick = () => {
    const btn = document.getElementById("none");
    btn?.click();
  };

  const onSubmit = (data: any) => {
    if (images.length >= 1 || projectPostSelector.images.length >= 1) {
      dispatch(
        projectPost.projectID
          ? sendProjectPost(data.title, data.body)
          : sendPost(data.body, images, postSelector.mentionTos)
      );
    } else {
      toast.error("投稿に失敗しました。");
    }
  };

  return (
    <FormProvider {...methods}>
      <div className={classes.container} id="Container">
        <BrowserBackHeader label="新規投稿" browserBack={history} />
        <SpaceBox height={5} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {postSelector.mentionTos.length === 0 ? (
            <></>
          ) : (
            <Box
              display="flex"
              justifyContent="flex-start"
              p={1}
              m={1}
              bgcolor="background.paper"
              className={classes.box}
            >
              <Box
                p={1}
                justifyContent="flex-end"
                className={classes.postTitle}
              >
                投稿先：
              </Box>
              {postSelector.mentionTos.map(
                (
                  sendTo: { displayName: string; photoUrl: string },
                  index: number
                ) => (
                  <Box display="flex" key={index}>
                    <UserIcon
                      photoUrl={sendTo.photoUrl}
                      width="58px"
                      height="58px"
                      margin="10px 0 10px 10px"
                    />
                  </Box>
                )
              )}
            </Box>
          )}
          {projectPostSelector.mentionTos.length !== 0 && (
            <Box
              display="flex"
              justifyContent="flex-start"
              bgcolor="background.paper"
              className={classes.box}
            >
              <Box
                p={1}
                justifyContent="flex-end"
                className={classes.postTitle}
              >
                投稿先：
              </Box>
              {projectPostSelector.mentionTos.map(
                (
                  sendTo: { displayName: string; photoUrl: string },
                  index: number
                ) => (
                  <Box display="flex" key={index}>
                    <UserIcon
                      photoUrl={sendTo.photoUrl}
                      width="58px"
                      height="58px"
                      margin="10px 0 10px 10px"
                    />
                  </Box>
                )
              )}
            </Box>
          )}
          <SpaceBox height={4} />
          <SwitchQuiltedImages
            images={images}
            setImages={setImages}
            currentWidth={width}
          />

          <>
            <SpaceBox height={20} />
            {projectPost.projectID && (
              <>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="タイトル"
                      fullWidth={true}
                      className={classes.titleInput}
                      {...field}
                    />
                  )}
                />
                <SpaceBox height={20} />
              </>
            )}
            {errors.body && (
              <Typography className={classes.errorMessage}>
                {errors.body.message}
              </Typography>
            )}
            <Controller
              name="body"
              control={control}
              rules={{ required: "投稿する場合は文章を入力してください" }}
              render={({ field }) => (
                <TextareaAutosize
                  rows={6}
                  rowsMax={30}
                  placeholder="投稿文"
                  className={classes.textInput}
                  aria-label="maximum height"
                  {...field}
                />
              )}
            />
            <div className={classes.btnArea}>
              <GrayButton onClick={() => submitClick()}>投稿する</GrayButton>
            </div>
            <input type="submit" id="none" className={classes.btn} />
          </>
        </form>
        <SpaceBox height={40} />
      </div>
    </FormProvider>
  );
};
