import { getFunctions, httpsCallable } from "firebase/functions";
// import { functions } from "../firebase";
import Stripe from "stripe";
import { PaymentIntentMetaDataType } from "../Types/stripeType";

const functions = getFunctions();
functions.region = "asia-northeast1";
/* Slack */
export const slackNotice = httpsCallable(functions, "slackNotice");
/* Project */
export const requestProject = httpsCallable(functions, "requestProject");

/* Product */
export const sendReserveProduct = httpsCallable<unknown, string[]>(
  functions,
  "sendReserveProduct"
);
export const sendBuyProduct = httpsCallable(functions, "sendBuyProduct");

/* ProjectPost */
export const projectPostWanted = httpsCallable(functions, "projectPostWanted");
export const projectPostUnWanted = httpsCallable(
  functions,
  "projectPostUnWanted"
);

/* Social */
export const followerAddApi = httpsCallable(functions, "followerAddApi");
export const initEmailVerified = httpsCallable(functions, "initEmailVerified");
export const creatorHopeEmail = httpsCallable(functions, "creatorHopeEmail");
export const inquiryAdminNotice = httpsCallable(
  functions,
  "inquiryAdminNotice"
);
export const passwordReset = httpsCallable(functions, "passwordReset");
export const unLoginPasswordReset = httpsCallable(
  functions,
  "unLoginPasswordReset"
);
export const sendComment = httpsCallable(functions, "sendComment");
export const deletePost = httpsCallable<unknown, string[]>(
  functions,
  "deletePost"
);
export const deleteComment = httpsCallable(functions, "deleteComment");
export const sendReplayComment = httpsCallable(functions, "sendReplayComment");

/* Stripe */
export const stripeRetrievePaymentIntent = httpsCallable<
  unknown,
  PaymentIntentMetaDataType
>(functions, "stripeRetrievePaymentIntent");
export const stripeIntentPaymentMethod = httpsCallable<
  unknown,
  Stripe.Response<Stripe.PaymentIntent> & { statusCode?: number }
>(functions, "stripeIntentPaymentMethod");

type StripeCreateCustomerCardInput = {
  email: string;
  paymentMethodID: string;
  uid: string;
  username: string;
};
type StripeCreateCustomerCardOutput = {
  statusCode?: number;
  id?: string;
};

/**
 * Stripeのアカウントとカードの登録をする
 * @param email: string
 * @param paymentMethodID: string
 * @param uid: string
 * @param username: string
 *
 * args { email, paymentMethodID, uid, username }
 *
 *  await stripe.customers.create({
        description: data.username,
        email: context.auth.token.email,
        metadata: { user_id: data.uid },
        payment_method: data.paymentMethodID,
      });
 */
export const stripeCreateCustomerCard = httpsCallable<
  StripeCreateCustomerCardInput,
  StripeCreateCustomerCardOutput
>(functions, "stripeCreateCustomerCard");

type StripeRetrievePaymentMethodInput = {
  customerID: string;
};
type StripeRetrievePaymentMethodOutput = {
  statusCode?: number;
  data: {
    id: string;
    customer: string;
    metadata: {
      primary_card: "true" | "false";
    };
    card: {
      brand: string;
      country: string;
      display_brand: string;
      exp_month: number;
      exp_year: number;
      fingerprint: string;
      funding: string;
      last4: string;
    };
  }[];
};

export const stripeRetrievePaymentMethod = httpsCallable<
  StripeRetrievePaymentMethodInput,
  StripeRetrievePaymentMethodOutput
>(functions, "stripeRetrievePaymentMethod");

/**
 * 現在のメインカードをサブカードにアップデートする関数
 * @param paymentMethodID: string
 *
 * args { paymentMethodID }
 *
 * return stripe.paymentMethods.update(paymentMethodID, { metadata: { primary_card: 'false' } })
 */
export const stripeMainCardUpdateMethod = httpsCallable(
  functions,
  "stripeMainCardUpdateMethod"
);

type AttachPaymentMethodInput = {
  paymentMethodID: string;
  customerID: string;
};

export type AttachPaymentMethodOutput = {
  statusCode?: number;
  message?: string;
  paymentMethod?: Stripe.PaymentMethod;
};

/**
 * Stripeにカードを登録する
 * @param paymentMethodID: string
 * @param customerID: string
 *
 * args { paymentMethodID, customerID }
 *
 * return stripe.paymentMethods.attach(paymentMethodID, { customer: customerID })
 */
export const stripeAttachPaymentMethod = httpsCallable<
  AttachPaymentMethodInput,
  AttachPaymentMethodOutput
>(functions, "stripeAttachPaymentMethod");

/**
 * メインカードにアップデートするする関数
 * @param paymentMethodID: string
 *
 * args { paymentMethodID }
 *
 * return stripe.paymentMethods.update(paymentMethodID, { metadata: { primary_card: 'true' } })
 */

export const stripeMainCardMainUpdateMethod = httpsCallable(
  functions,
  "stripeMainCardMainUpdateMethod"
);
export const stripeDeletePaymentMethod = httpsCallable(
  functions,
  "stripeDeletePaymentMethod"
);

/* Sagawa */
export const countUpOrderNumber = httpsCallable<unknown, string>(
  functions,
  "countUpOrderNumber"
);

/* Admin */
export const adminTrue = httpsCallable(functions, "adminTrue");
