import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { PrimaryButton } from "../../UIKit";
import { BrowserBackHeader } from "../../Header/BrowserBackHeader";
import Input from "./Input";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { selectUser } from "../../../features/userSlice";
import TextArea from "./TextArea";
import MidInfo from "./MidInfo";
import SNSAccountInput from "./SNSAccountInput";
import { animateScroll as scroll } from "react-scroll";
import { RouteComponentProps } from "react-router-dom";
import { SpaceBox } from "../../UIKit/SpaceBox";
import { sendCreatorHope } from "../../../features/creatorOperation";

const CreatorHopeReg = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    none: {
      display: "none",
    },
  });
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const defaultValues = {
    name: "",
    phoneNumber: null,
    email: "",
    userName: user.displayName,
    creatorConcept: "",
    selfInfo: "",
    appealPoint: "",
    twitter: "",
    tiktok: "",
    instagram: "",
  };

  const methods = useForm({ defaultValues });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data: any) => {
    setValue("userName", user.displayName);
    dispatch(sendCreatorHope(data));
  };

  const submitClick = () => {
    const submit = document.getElementById("none");
    submit?.click();
  };

  const numberRegExp = /^[0-9]+$/;
  const emailReg =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;

  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 0 });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <BrowserBackHeader
            label="tomemeクリエイター希望"
            browserBack={history}
          />
          <SpaceBox height={55} />
          <Typography align="center" style={{ fontSize: 13, color: "gray" }}>
            tomemeとのやり取りの際に使用する連絡先です。
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{ required: "　入力必須です" }}
            render={({ field }) => (
              <Input
                notice="名前(本名で登録お願いします)　"
                place="　例) 渋谷 渋子"
                error={errors.name && errors.name.message}
                field={field}
                id="nameInput"
                label="名前"
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: "　入力必須です",
              pattern: {
                value: numberRegExp,
                message: "　数字で入力してください",
              },
              maxLength: {
                value: 11,
                message: "　桁が多いです",
              },
              minLength: {
                value: 10,
                message: "　桁が足りません",
              },
            }}
            render={({ field }) => (
              <Input
                notice="電話番号(ハイフン無し)　"
                place="　例) 09012345678"
                error={errors.phoneNumber && errors.phoneNumber.message}
                field={field}
                id="phoneNumberInput"
                label="電話番号"
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: "　入力必須です",
              pattern: {
                value: emailReg,
                message: "　アドレスの形式で入力してください",
              },
            }}
            render={({ field }) => (
              <Input
                notice="メールアドレス　"
                place="　例) abc@tomeme.com"
                error={errors.email && errors.email.message}
                field={field}
                id="emailInput"
                label="メールアドレス"
              />
            )}
          />
          <Controller
            name="userName"
            control={control}
            render={({ field }) => (
              <Input
                notice="ユーザーネーム　"
                displayName={user.displayName}
                error={errors.userName && errors.userName.message}
                field={field}
                id="userNameInput"
                label="ユーザーネーム"
              />
            )}
          />
          <SpaceBox height={80} />
          <MidInfo
            label={
              "どんな思い出、どんな洋服を作りたいかクリエイターコンセプトにご記入ください"
            }
          />
          <Controller
            name="creatorConcept"
            control={control}
            rules={{ required: "　入力必須です" }}
            render={({ field }) => (
              <TextArea
                label="クリエイターコンセプト　"
                redLabel="※必須"
                place="作成コンセプト"
                error={errors.creatorConcept && errors.creatorConcept.message}
                field={field}
              />
            )}
          />
          <MidInfo label="クリエイターページで記載する自己紹介の記入をお願いします。" />
          <Controller
            name="selfInfo"
            control={control}
            rules={{ required: "　入力必須です" }}
            render={({ field }) => (
              <TextArea
                label="自己紹介　"
                redLabel="※必須"
                place="自己紹介"
                error={errors.selfInfo && errors.selfInfo.message}
                field={field}
              />
            )}
          />

          <MidInfo
            label={
              "自身の経歴などアピールできるポイントがあれば記入お願いします。"
            }
          />
          <Controller
            name="appealPoint"
            control={control}
            render={({ field }) => (
              <TextArea
                label="アピールポイント　"
                place="アピールポイント"
                field={field}
              />
            )}
          />
          <Typography
            style={{ fontSize: 14, color: "gray", padding: "0 10px 0 10px" }}
          >
            各SNSのアカウトを記入してください
          </Typography>
          <Typography
            style={{ fontSize: 14, color: "gray", padding: "0 10px 0 10px" }}
          >
            ※アクティブに使用しているアカウントのみで大丈夫です。
          </Typography>
          <SpaceBox height={30} />
          <Controller
            name="twitter"
            control={control}
            render={({ field }) => (
              <SNSAccountInput label="Twitter" field={field} />
            )}
          />
          <Controller
            name="tiktok"
            control={control}
            render={({ field }) => (
              <SNSAccountInput label="TikTok" field={field} />
            )}
          />
          <Controller
            name="instagram"
            control={control}
            render={({ field }) => (
              <SNSAccountInput label="Instagram" field={field} />
            )}
          />
        </div>
        <input type="submit" id="none" className={classes.none} />
        <SpaceBox height={50} />
        <PrimaryButton
          label="送信する"
          margin="0 auto"
          display="block"
          width="60%"
          onClick={() => submitClick()}
        />
        <SpaceBox height={150} />
      </form>
    </FormProvider>
  );
};

export default CreatorHopeReg;
