export const enterSplitTextContent = (txt: string) => {
  const splitText = txt.split(/(\n)/g);
  if (splitText.length === 1) {
    // length 1 は 改行がない場合
    return <>{splitText[0]}</>;
  } else {
    return splitText.map((st) => <div>{st}</div>);
  }
};

export const termsOfServicetextContents = () => [
  {
    caption: "1. (本サービスの内容)",
    contents:
      "　本サービスとは、「tomeme」と称するウェブサイト（以下「本サイト」といいます。）上で、当社が販売する洋服（以下「本商品」といいます。）の購入ができるサービスをいいます。",
  },
  {
    caption: "2. (利用規約の適用)",
    contents: `1. 本規約は、本サイト上で、本サービスを利用する際の一切の行為に適用されます。
2. ユーザーは、自らの意思と責任のもと、本サービスを利用するものとし、利用に関わるすべての責任はユーザーが負うものとします。
3. 当社が本サイト上で掲載する本サービスの利用に関する事項は、本規約の一部を構成するものとします。`,
  },
  {
    caption: "3. (ユーザー登録申込み)",
    contents: `
    1. 本サービスの利用を希望する者（以下「利用希望者」といいます。）は、当社に対して、本規約を遵守することに同意し、かつ、当社指定のユーザー登録申込みフォームから必要事項を届け出ることにより、ユーザー登録申込みを行うことができます。ただし、利用希望者本人がユーザー登録申込みの手続を行うものとし、第三者に行わせることはできません。
    2. 利用希望者は、ユーザー登録申込み時その他当社からの依頼により申告する際、いかなる虚偽の申告も行わないものとします。
    3. ユーザー登録手続は、ユーザー登録申込みに対する当社の承諾をもって完了するものとし、その時点でユーザーとなるものとします。
    4. ユーザーは、ユーザー登録後、直ちに本サービスを利用することができます。`,
  },
  {
    caption: "4. (利用資格)",
    contents: `　当社は、利用希望者が以下に定める事由のいずれかに該当することが判明した場合、前条のユーザー登録申込みを承諾しないことがあります。また、当該ユーザー登録申込みを承諾した後でも、ユーザーが、以下に定める事由のいずれかに該当することが判明した場合、承諾を取り消し、ユーザー資格を停止することがあります。
    1. 日本国外に在住し、又は日本国外の住所、配送先を登録した場合
    2. 過去に本規約違反等によりユーザー資格を除名された場合
    3. 申込みの際に当社に届け出た事項に虚偽、誤記又は記入もれがあった場合
    4. 当社に対する債務の履行を怠ったことがある場合
    5. 当人名義以外のクレジットカード情報を登録した場合
    6. 反社会的勢力に属している場合、又はそのおそれがあると当社が判断した場合
    7. その他、当社がユーザー登録希望者による本サービスの利用が不適当であると判断した場合
    `,
  },
  {
    caption: "5. (未成年者のユーザー登録申込み)",

    contents: `1. 利用希望者が１８歳未満の未成年である場合、ユーザー登録申込みをする際に、親権者等の法定代理人の同意を得なければなりません。
    2. 未成年である利用希望者がユーザー登録申込みをした場合、当該未成年である利用希望者は、法定代理人の同意を得て、ユーザー登録申込みを行ったものとみなします。
    3. 未成年である利用希望者が、法定代理人の同意がないにもかかわらず同意があると偽り又は年齢について成年と偽った場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスの利用に関する一切の法律行為を取り消すことはできません。
    4. 本規約の同意時に未成年であったユーザーが成年に達した後に本サービスを利用した場合、当該ユーザーは本サービスに関する一切の法律行為を追認したものとみなされます。`,
  },
  {
    caption: "6. (ユーザーID及びパスワードの管理)",
    contents: `
    1. ユーザーは、１つのユーザーID及びパスワード（以下「ユーザーID等」といいます。）のみを保有できるものとし、複数のユーザーID等を保有することはできないものとします。
    2. ユーザーは、ユーザー登録時に定めるユーザーID等の管理責任を負うものとします。
    3. ユーザーは、ユーザーID等を第三者に利用させたり、貸与、譲渡、売買、質入等をしたりすることはできないものとします。
    4. ユーザーID等の管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、当社は一切の責任を負わないものとします。ユーザーID等が不正に利用されたことにより当社に損害が生じた場合、ユーザーは、当社に対し、その損害を賠償するものとします。
    5. ユーザーが保有するユーザーIDによりなされた本サービスの利用は、すべて当該ユーザーの行為とみなすものとし、当該ユーザーは、当該利用により生じた債務を負うものとします。ただし、当社の責めに帰すべき事由により、ユーザーIDが第三者に不正利用された場合は、この限りではありません。
    6. ユーザーは、ユーザーID等を第三者に知られた場合、あるいはユーザーID等が第三者に使用されている疑いのある場合には、直ちに当社にその旨連絡するとともに、当社の指示がある場合にはこれに従うものとします。
    7. ユーザーは、定期的にパスワードを変更する義務があるものとし、その義務を怠ったことにより損害が生じた場合、当社は一切の責任を負わないものとします。当社が特に認めた場合を除き、ユーザーID等の再発行は行わないものとします。
`,
  },
  {
    caption: "7. (登録費用等)",
    contents: `1. 本サービスのユーザー登録は、無料とします。
    2. 本サービスの利用に伴う通信料は、ユーザーの負担とします。
    3. 本商品の代金については、第９条に従い、本商品の売買ごとにお支払いただきます。
    `,
  },
  {
    caption: "8. (本商品の購入申請)",
    contents: `
1. 本商品の購入を希望するユーザーは、当社所定の方法で本商品の購入申請を行うものとします。ただし、当社は、ユーザーに対して、予め、本商品のうち特定の商品につき、購入申請可能な個数の上限を設定することがあります。
2. ユーザーと当社との間の売買契約は、前項の購入申請に対し、当社から承諾の通知をした時点で成立するものとします。ただし、当社が前項の購入申請を受けてから（本条第４項に基づき購入予約を行った場合は、当該予約に係る本商品の生産開始日から）１０営業日以内にユーザーに対して何らの通知もしない場合は、当該期間が経過した時をもって、売買契約が成立しなかったものとみなします。
3. 購入申請とは、本商品購入の意思表示であり、一度購入申請を行った場合、ユーザーの側から一方的に変更又は取消しを行うことはできないものとします。ユーザーは本商品の内容や取引条件をよく確認の上、購入申請を行うものとします。
4. 本商品の購入を希望するユーザーは、当社所定の方法で本商品の購入予約を行うことができ、次の各号のいずれかの事由に該当しない限り、本商品の生産開始日に第１項の購入申請を行ったものとみなします。
　1. 当該予約に係る本商品の生産開始日までに、ユーザーが当該予約を取り消した場合
　2. 当該予約に係る本商品の生産開始日までに、当社が、ユーザーに対し、第１９条第２項又は第３項に基づき本サービスを廃止する旨の通知を行った場合
5. 当社は、本サービスにおいて売買される本商品につき、本商品の表示記載の内容以外の点については、その品質、性能、他の商品との適合性その他のいかなる保証も行いません。ただし、当社の故意又は重過失による場合は除きます。
`,
  },
  {
    caption: "9. (代金の支払及び本商品の配送等)",
    contents: `1. 当社と、ユーザーとの間で本商品の売買契約が成立した場合、当該ユーザーは当社の定める方法に従い、当該代金全額を支払うものとします。なお、支払方法によっては別途手数料をご負担いただく場合があります。
    2. 当社は、当社が別途定める発送時期に、本商品の発送を行うものとします。
    3. 本商品の所有権は、ユーザーに本商品の受渡しが現実になされた時点でユーザーに移転するものとします。
    4. 当社は、当社の定める方法により、ユーザーが当社に届け出た住所へ本商品を配送します。
    5. ユーザーは、当社所定の方式により届け出ない限り、本商品の配送先を変更することはできないものとします。
    6. 当社からの本商品の引渡義務の履行は、購入時に指定した住所への本商品の出荷をもって、完了したものとします。当社は、配送先不明等によるトラブルに関しては、ユーザーが登録している連絡先へ連絡すること、及び本商品購入の際に指定した送付先に商品を出荷することにより、当社に故意又は重過失がある場合を除き免責されるものとします。
    7. 本商品の引渡し期日は、当社が別途定める日時とし、やむを得ない事由等により当初の引渡し期日を変更する場合があります。なお、ユーザーが購入申請時に入力したお届け日の指定にかかる日付及びお届け時間は、あくまで引渡日の目安を定めるものに過ぎず、実際の引渡日がお届け日の指定にかかる日付及びお届け時間に遅れた場合でも、当社は当該遅滞による責任を一切負いません。
    8. 本商品の配送に関わる送料は、ユーザーが負担するものとします。
    `,
  },
  {
    caption: "10. (本商品の返品・交換)",
    contents: `1. 当社は、ユーザー都合による本商品の返品及び返金並びに交換を一切受け付けません。
    2. 前項の定めにかかわらず、ユーザーが購入した本商品についてサイズ変更を希望する場合は、当該本商品についてユーザーが希望するサイズの在庫が存在する場合に限り、当社は当該本商品のサイズ変更に応ずるものとします。ただし、次の各号に定める事由のいずれかに該当する場合（当社又は当該本商品の製造若しくは当該ユーザーに対する販売若しくは配送に不可欠の役割を担った当社の役員、従業員、委託先、その他関係者が故意に当該事由を発生させた場合を除きます。）は、この限りではありません。
    　1. ユーザーが当該本商品を受領してから７日以上が経過している場合（ユーザーが第三者に当該本商品を受領させた場合も含みます。）
    　2. 当該本商品についてユーザー又は第三者のために裾上げや寸法直し等を行った場合
    　3. ユーザー又は第三者が当該本商品を使用した場合
    　4. ユーザー又は第三者が当該本商品を洗濯した場合
    　5. ユーザー又は第三者が当該本商品からタグやラベル、しつけ等の付属物を取り外し、又は、当該本商品の包装やパッケージ等を処分した場合
    　6. ユーザー又は第三者の責めに帰すべき事由により、当該本商品にキズ、破損又は汚損が生じた場合
    　7. その他、当社が返品又は交換に応じることが適当でないと合理的に判断する場合
    `,
  },
  {
    caption: "11. (契約不適合責任)",
    contents: `1. ユーザーは、本商品受領後７日以内にこれを検査し、本商品の種類、品質又は数量に関して、契約の内容に適合しない（以下「不適合」といいます。）ときは、具体的な理由を付して、直ちに当社に申し出るものとします。
2. ユーザーは、当社に対して、本商品受領日から７日以内に、本サイトの問い合わせフォームから、件名に「返品・交換希望」と明記した上、具体的な不適合の内容を示して通知し、かつ、前条第２項第２号ないし第７号に定める事由のいずれにも該当しない場合に限り、当社は、代金の返金、本商品の修補、又は本商品の代替品若しくは不足分の引渡しを行うものとします。本商品の不適合に関する当社の責任は、本条に定めるものに限られ、ユーザーは、本商品の不適合を理由として、代金の減額、損害賠償の請求、契約解除その他いかなる請求も行うことはできません。ただし、当社に故意又は重過失がある場合は、この限りではありません。
3. 前項の定めに基づき、ユーザーが、当社に対して、本商品の代替品の引渡しを求めた場合であっても、本商品の在庫が存在しない場合は、当社は、代金の返金をもって本商品の代替品の引渡しに代えることができるものとします。
`,
  },
  {
    caption: "12. (量産前情報)",
    contents: `1. 本商品の最終のサンプルが完成し、当社が本商品の量産を決定するまでの間に、本サイトで公開される当該本商品に関する一切の情報（以下「量産前情報」といいます。）は、本商品の完成形を保証するものではありません。
2. 当社は、量産前情報に基づき本商品を購入したことによってユーザーに生じた不利益及び損害について責任を負いません。
`,
  },
  {
    caption: "13. (売買契約の解除)",
    contents: `1. 当社は、ユーザーが当社の定める時期までに本商品の代金を支払わなかった場合、催告なくユーザーと当社間の売買契約を解除することができるものとします。
2. 当社は、以下に定める事由のいずれかに該当する場合は、催告なくユーザーと当社間の売買契約を解除することができるものとします。

　1. ユーザーが日本国外に在住し、又は日本国外の住所、配送先を登録した場合
　2. ユーザーが本規約及び法令に違反した場合
　3. ユーザーが購入申請等の際に当社へ届け出た事項に虚偽、誤記又は記入もれがあった場合
　4. ユーザーが当社に対する債務の支払を怠ったことがある場合
　5. ユーザーが当社及び業務提携企業の運営・サービス提供又は他のユーザーによる本サイト利用を妨害し、若しくはそれらに支障をきたす行為を行った場合
　6. ユーザーが合理的な理由なく返品又は受取拒否をしたことがある場合
　7. 当社が本サービスにおいて提示した本商品の代金、その他の販売条件に、明らかな誤記があったことが判明した場合
　8. その他当社が不適当と判断した場合
    `,
  },
  {
    caption: "14. (損害賠償)",
    contents: `
1. 当社は、ユーザーが本規約に違反することにより、当社が何らかの損害を被った場合、当該ユーザーに対して当該損害の賠償を請求できるものとします。
2. 当社は、当社が当事者となってユーザーに販売した本商品に関して、当社の責めに帰すべき事由に基づきユーザーに損害が生じた場合、本規約の定める範囲内で、直接かつ現実の通常損害に限り、当該本商品の販売代金を上限として損害を賠償します。ただし、当社に故意又は重過失がある場合を除きます。
3. 当社は、アクセス過多、その他予期せぬ要因に基づく本サイトの表示速度の低下や障害等によって生じたいかなる損害についても、一切責任を負いません。
4. 当社は、ユーザーが本サービスを利用する際に発生する通信費用や設備投資について、一切負担しないものとします。
5. 当社は、ユーザーが本サイトを利用する際に、コンピュータウイルス等、有害なプログラム等による損害を受けないことを保証しないものとし、ユーザーに損害が生じたとしても一切責任を負いません。
6. 当社は、ユーザーが本サイトを利用する際に使用するいかなる機器、ソフトウェアについても、その動作保証を一切しないものとし、ユーザーに損害が生じたとしても一切責任を負いません。
7. 当社が相当の安全策を講じたにもかかわらず、以下の事由により損害が生じた場合、当社はいかなる責任も負わないものとします。

　1. 通信回線やコンピュータ等に障害が生じたことにより、本サイトのシステムの中断・遅滞・中止等によって発生した損害
　2. 本サイトが改竄されたことにより生じた損害
　3. 本サイトに関するデータへの不正アクセスにより生じた損害

8. 当社は、ユーザーが本サイトを利用できなかったことにより発生した一切の損害について、いかなる責任も負わないものとします。
9. 上記各項記載事項の他、本サービスのご利用にあたりユーザーに発生した一切の損害について、当社は原則として責任を負いません。
`,
  },
  {
    caption: "15. (ユーザー登録の取消し)",
    contents: `1. ユーザーは、当社所定の手続により本サービスへのユーザー登録を取り消すことができます。なお、ユーザー登録を取り消した場合でも、ユーザーは、当社との間で既に締結した売買契約の効力を否定することはできません。
2. ユーザーが死亡した場合、当社は、当該ユーザーがその時点でユーザー登録を取り消したものとみなします。
`,
  },
  {
    caption: "(ユーザー資格の停止・除名)",
    contents: `
1. 当社は、以下の事由がある場合、ユーザーに何ら事前の通知又は催告をすることなく、ユーザー資格を一時停止し、又は除名することができるものとします。

　1. ユーザーID、パスワード及び本サイトを不正に使用・利用し、又は使用・利用させた場合
　2. ユーザーが本商品の購入代金を定められた時期までに支払わなかった場合
　3. ユーザーに対し、差押え、仮差押え、仮処分、強制執行、破産、民事再生等の申立てがなされた場合、又は、ユーザーが自ら破産、民事再生等の申立てをした場合
　4. ユーザーが申込みの際に当社に届け出た事項に虚偽の内容若しくは不足があったとき又はそのおそれがあるとき
　5. ユーザーが当社に届け出ているメールアドレスに電子メールが不通のとき、又はユーザーが当社からの照会、資料提出等の要請に対して速やかに対処しないとき
　6. ユーザーが申込みの際に当社に届け出たクレジットカードの与信確認に問題が生じた場合
　7. 反社会的勢力若しくは反社会的活動を行う団体に所属し、又はこれらと密接な関係を有する場合
　8. 一定回数以上のパスワードの入力ミスがあるなどユーザーのセキュリティを確保するために必要な場合
　9. ユーザーが一定期間にわたって本サービスを利用していない場合
　10. ユーザーが本サイトの運営又は他のユーザーの本サイト利用を妨害し支障を及ぼし又は及ぼすおそれがある行為をした場合
　11. ユーザーが、ユーザー対象者ではないことが判明し、又はユーザー対象者の地位を失った場合
　12. その他、ユーザーが本規約のいずれかの条項又は当社が本サイト上で定めた事項に違反した場合
　13. その他、ユーザーとして不適格と当社が判断した場合

2. 当社は、ユーザーが前項の各号の事由がある場合、当該ユーザーに対して第１３条各項で定める売買契約の解除等を講じることができるものとします。
3. ユーザー資格の停止又は除名の措置を受けたユーザーは、当該措置の理由の開示を求めることができないものし、当該措置について争わないものとします。
  `,
  },
  {
    caption: "17. (本サービスの中断、停止)",
    contents: `
1. 当社は、以下の何らかの事由に該当する場合、ユーザーに事前に通知することなく本サービスの一部若しくは全部を一時中断、又は停止することがあります。

　1. 本サービスの提供のための装置、システムの保守点検、更新を行う場合
　2. 火災、停電、天災、システム障害等により、本サービスの提供が困難な場合
　3. 戦争、内乱、暴動、騒擾、労働争議等により、本サービスの運営が不能となった場合
　4. 本サービス提供のためのシステムの不良及び第三者からの不正アクセス、コンピュータウイルスの感染等により本サービスを提供できない場合
　5. 必要な電気通信事業者の役務が提供されない場合
　6. 法令等に基づく措置により本サービスを運営できない場合
　7. 本サイトの運営が技術上又は経済上著しく困難な場合
　8. その他本サイトの運営に関して当社の業務の遂行上支障がある場合、又はそのおそれがあると当社が判断した場合
　9. その他、当社が本サービスの一時中断若しくは停止が必要であると判断した場合

2. 当社は、当社に故意又は重過失がある場合を除いて、本サービスの提供の一時中断、停止等の発生により、ユーザーが被ったいかなる損害についても一切の責任を負わないものとします。
`,
  },
  {
    caption: "18. (本サービスの変更)",
    contents: `
　当社は、いつでも本サービスの内容を事前の告知なく、変更することができるものとします。当該変更に伴い、ユーザーに不利益や損害が発生した場合でも、当社はその責任を負わないものとします。
`,
  },
  {
    caption: "19. (本サービスの廃止)",
    contents: `
  1. 当社は、本サービスの全部又は一部をいつでも廃止することができる権利を有します。
  2. 本サービスの全部又は一部を廃止する場合、当社は廃止する１ヶ月以上前にユーザーに対して通知を行います。
  3. 当社が予期し得ない事由又は法令・規則の制定・改廃、天災等のやむを得ない事由で、本サービスを廃止する場合において、１ヶ月以上前の通知が不能な場合であっても、当社は可能な限り速やかにユーザーに対して通知を行います。
  4. 本条に定める手続に従って通知がなされた場合は、当社は本サービスの廃止の結果について何ら責任を負いません。
  `,
  },
  {
    caption: "20. (届出事項の変更)",
    contents: `
　ユーザーは、ユーザー登録申込みの際に当社に届け出た事項に変更のあった場合は、当社に遅滞なく所定の方法により届け出るものとします。この届出を怠ったことによりユーザーが不利益を被った場合、当社は、一切責任を負わないものとします。
 `,
  },
  {
    caption: "21. (連絡・通知の方法)",
    contents: `
1. 当社は、本サービスの利用に関して、書面の送付、電子メールの送信、本サイトにおける告知その他当社が適当と認める方法によりユーザーに通知を行うことができるものとし、ユーザーはこれに同意するものとします。
2. 当社は、前項の通知を行うときは、ユーザーがユーザー登録申込み時（届出事項の変更を行った場合は、当該変更時とします。）に届け出た連絡先又はユーザーが購入申請時に入力した連絡先に対して通知を行えば足りるものとします。
3. ユーザーがこれらの変更・修正等の届出を怠ったことにより通知が不到達となった場合でも、当社は、ユーザー又は第三者に生じる結果及び損害等について一切責任を負わないものとします。
  `,
  },
  {
    caption: "22. (禁止行為)",
    contents: `1. ユーザーは、本サービスの利用にあたって、以下に該当する行為又はそのおそれがある行為を行ってはならないものとします。
　1. 本サービスを不正の目的をもって利用する行為
　2. 当社、業務提携企業その他の第三者の名誉若しくは信用を毀損し、又はプライバシーを侵害する行為
　3. 本商品のクリエイターや他のユーザーを誹謗中傷する行為
　4. 詐欺等の犯罪に結びつく行為
　5. 第三者になりすまして本サービスを利用する行為
　6. コンピュータウイルスなど有害なプログラム等を送信若しくは提供する行為、又は推奨する行為
　7. 当社、業務提携企業その他の第三者の情報を改ざん、消去する行為
　8. 当社、業務提携企業その他の第三者の設備を不正に利用し、又はその運営に支障を与える行為
　9. 法令、本規約若しくは公序良俗に違反する行為
　10. 本サービスの運営を妨害する行為
　11. 過度な返品及びキャンセル行為等本サービスの提供に支障をきたすおそれのある行為
　12. クレジットカード等を不正使用して本サービスを利用する行為
　13. 本サイト上で入力する事項その他当社からの依頼により申告する事項につき、虚偽の内容を申告する行為
　14. その他当社が不適当と判断する行為
  `,
  },
  {
    caption: "23. (投稿したコメントの削除)",
    contents: `　ユーザーが本サイト上で行ったコメント又は投稿（以下「コメント等」といいます。）について、ユーザーが本規約に違反し又は本規約の精神に照らして不適切な行為を行ったと当社が判断する場合には、当社は、当該コメント等を、事前の通知なく、当社の独自の判断で削除できるものとします。ただし、本項の定めは、当社がコメント等を削除する義務を負うことを意味するものではありません。`,
  },
  {
    caption: "24. (個人情報の取扱)",
    contents: `　当社は、個人情報を当社が別途規定する「プライバシーポリシー」に基づき、適切に取り扱うものとします。
    `,
  },
  {
    caption: "25. (知的財産権)",
    contents: `1. 本サイト上で公開される洋服のデザインに関する著作権は、当該洋服をデザインするデザイナー又は当社に属するものです。ユーザーは、方法又は形態の如何を問わず、デザインを無断で、複製、上映、公衆送信、頒布、譲渡、貸与、翻訳、翻案その他ユーザー個人の私的使用の範囲を超えて利用又は使用してはならないものとします。
2. 本サイトを含む当社ウェブサイトを構成する文章、画像、プログラムその他のデータ等についての一切の権利（所有権、著作権その他の知的財産権、肖像権、パブリシティー権等）は、当社又は当該権利を有する第三者に帰属します。ユーザーは、方法又は形態の如何を問わず、これらを当社に無断で複製、上映、公衆送信、頒布、譲渡、貸与、翻訳、翻案その他ユーザー個人の私的使用の範囲を超えて利用又は使用してはならないものとします。
`,
  },
  {
    caption: "26. (契約上の地位の譲渡等)",
    contents: `1. ユーザーは、当社の書面による事前の承諾なく、ユーザーと当社との一切の契約上の地位又は同契約に基づく権利義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
2. 当社は本サービスにかかる事業を第三者に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーが届け出た情報その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
`,
  },
  {
    caption: "27. (本規約の変更)",
    contents: `1. 当社は、ユーザーの事前の承諾を得ることなく、本規約を随時改定することができるものとします。
    2. 当社は、前項による本規約の変更をするときは、その効力発生日を定め、かつ、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生日を、ユーザーに対し、本規約変更の効力発生日前に、通知するものとします。ただし、文言の修正等、ユーザーに不利益を与えるものではない軽微な変更の場合には、当該通知を省略することができます。
    3. 本規約変更の効力発生日後に本サービスの利用を行った場合、ユーザーは本規約の変更に同意したものとみなします。
    `,
  },
  {
    caption: "28. (分離可能性)",
    contents: `　本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
    `,
  },
  {
    caption: "29. (準拠法及び合意管轄)",
    contents: `本規約に関する準拠法は日本法とし、本サービスに関して当社とユーザーとの間で生じる一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。
2022年7月16日　制定`,
  },
];

export const privacyPolicy = () => [
  {
    caption: "1.(個人情報等の利用目的)",
    contents: `
  当社は、取得した個人情報等を以下の目的のために利用します。
  　(1) 当社の商品・サービスの提供、改良、新規開発のため
  　(2) 料金等の請求及び決済のため
  　(3) 本人確認、認証サービスのため
  　(4) アンケート実施のため
  　(5) 懸賞、キャンペーンの実施のため
  　(6) マーケティング調査、統計、分析のため
  　(7) 当社のシステムメンテナンス、不具合対応のため
  　(8) ご連絡ならびに当社の規約、条件及びポリシーの変更などの通知をするため
  　(9) 広告の配信及びその成果確認のため
  　(10) サービスに関するご案内、ユーザーからの問い合わせ対応のため
  　(11) ユーザーからのユーザー登録申込みに対する審査のため
  　(12) ユーザーからの購入申請に対する承諾の通知をユーザーに行うため
  　(13) 当社からの商品の発送、関連するアフターサービスの提供ため
  　(14) 当社のウェブサイト、サービス、コンテンツ及び広告の開発、提供、メンテンナンス及び向上に役立てるため
  　(15) ターゲットを絞った当社又は第三者の商品又はサービスの広告の開発、提供のため
  　(16) 不正行為もしくは違法となる可能性のある行為を防止し、利用規約を執行するため
  　(17) その他当社の各サービスにおいて定める目的のため
  `,
  },
  {
    caption: "2.(利用目的の変更)",
    contents: `
  1. 当社は、個人情報等の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には、当社の定める方法により、ユーザーに通知又は公表します。
  2. 当社は、個人情報等の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。
  `,
  },
  {
    caption: "(個人情報等の適正な取得)",
    contents: `当社は、適法かつ適正な手段により個人情報等を取得し、偽りその他不正の手段により取得しません。`,
  },
  {
    caption: "(個人情報等の適正管理)",
    contents: `当社は、取得したユーザーの個人情報等を、以下の方法で適正に管理します。
　(1)データ内容の正確性の確保
　　当社は、取得したユーザーの個人情報等につき、利用目的の達成に必要な範囲内において、正確かつ最新の内容に保つとともに、利用する必要がなくなったときは、当該個人情報等を消去するよう努めます。
　(2)安全管理措置
　　当社は、ユーザーの個人情報等の漏洩、滅失又は毀損の防止その他の安全管理のために必要かつ適切な措置を講じます。
　(3)従業者の監督
　　当社は、ユーザーの個人情報等の適正管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。
　(4)委託先の監督
当社は、ユーザーの個人情報等の取扱いの全部又は一部を委託する場合は、委託先において個人情報等の適正管理が図られるよう、必要かつ適切な監督を行います。
    `,
  },
  {
    caption: "5. (個人情報等の委託)",
    contents: `当社は、利用目的の達成に必要な範囲内において、ユーザーの個人情報等の取扱いを協力会社に委託する場合があります。ただし、委託する個人情報等は、委託する業務を遂行するのに必要最小限の情報に限定します。
    `,
  },
  {
    caption: "6. (第三者提供)",
    contents: `当社は、ユーザーの個人情報等について、個人情報保護法その他の法令に反して、あらかじめ当該ユーザーの同意を得ないで、第三者（個人情報保護法第２７条５項各号に定める者は除きます。）に提供しません。
  `,
  },
  {
    caption: "7.（個人情報等の開示）",
    contents:
      "当社は、ユーザーから、個人情報保護法の定めに基づき個人情報等の開示を求められたときは、ご本人からの請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報等が存在しないときにはその旨を通知いたします。）。ただし、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、当該開示につきましては、手数料（１件あたり１，０００円）を頂戴しておりますので、あらかじめご了承ください。",
  },
  {
    caption: "8.(個人情報等の訂正等）",
    contents:
      "当社は、ユーザーから、個人情報等が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、ユーザー本人からの請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報等の内容の訂正等を行い、その旨をユーザーに通知します（訂正等を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。）。ただし、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。",
  },
  {
    caption: "9.(個人情報等の利用停止等）",
    contents:
      "当社は、ユーザーから、ユーザーの個人情報等が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合において、その請求に理由があることが判明した場合には、ユーザー本人からの請求であることを確認の上で、遅滞なく個人情報等の利用停止等を行い、その旨をユーザーに通知します。ただし、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、この限りではありません。",
  },
  {
    caption: "10.（Cookie（クッキー）その他の技術の利用)",
    contents: `当社のサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による当社のサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。ただし、Cookieを無効化すると、当社のサービスの一部の機能をご利用いただけなくなる場合があります。`,
  },

  {
    caption: "11.Googleアナリティクスの使用",
    contents: `当社は、本アプリの使用状況を理解し、サービスの改善に役立てるためにGoogleアナリティクスを使用しています。Googleアナリティクスは、お客様が本アプリをどのように使用しているかに関する情報を収集するためにCookieを利用します。この情報には、お客様のデバイスのIPアドレス、ブラウザの種類、アクセスしたページ、訪問の時間と日付などが含まれますが、これらは匿名で処理され、個人を特定するものではありません。`,
  },
  {
    caption: "12.データの収集と処理",
    contents: `Googleアナリティクスによって収集されるデータは、お客様の本アプリ利用状況の分析、レポートの作成、およびその他のサービスの提供のためにGoogleによって処理されます。このプロセスにおいて、Googleはデータを匿名化し、個人を特定する情報を収集しないようにします。お客様はGoogleアナリティクスのCookieの使用をオプトアウトすることが可能です。詳細については、Googleアナリティクスのオプトアウトページをご覧ください。`,
  },
  {
    caption: "13. (お問い合わせ)",
    contents:
      "開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報等の取扱いに関するお問い合わせは、下記の窓口までお願い致します。",
  },
  {
    caption: "",
    contents: `
    株式会社bodaijyu
代表取締役　石原雄三

`,
  },
  {
    caption: "",
    contents: `
〒150-0002　東京都渋谷区渋谷1-23-20　松本ビル6階
【tomeme-inquiry@tomeme.jp / https://tomeme.jp/inquiry`,
  },
  {
    caption: "",
    contents: `

制定：2022年7月16日`,
  },
];
