import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { GrayButton } from "../../atoms/GrayButton/GrayButton";
import { useHistory } from "react-router-dom";

type Props = {
  isShow: boolean;
  setState?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => {
    payload: undefined;
    type: string;
  };
  targetUrl?: string;
};

export const LoginInfo = React.memo((props: Props) => {
  const { isShow, setState, handleClose, targetUrl } = props;
  const { push } = useHistory();
  const useStyles = makeStyles(() => ({
    root: {
      position: "fixed",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      top: 0,
      left: 0,
      backgroundColor: "#00000040",
    },
    box: {
      width: 380,
      height: 220,
      border: "1px solid #00000040",
      backgroundColor: "white",
      padding: "20px",
    },
    text: {
      marginTop: 40,
      fontSize: 18,
      whiteSpace: "pre-line",
      color: "rgb(100, 100, 100)",
    },
    buttonBox: {
      flexGrow: 1,
      marginTop: 40,
      display: "flex",
      justifyContent: "center",
    },
    centerContent: {
      display: "flex",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  if (isShow) {
    return (
      <div
        className={classes.root}
        onClick={() => {
          if (handleClose) {
            handleClose();
            return;
          }
          if (setState) setState(false);
        }}
      >
        <div className={classes.box}>
          <Typography align="center" className={classes.text} noWrap={false}>
            {`ログインが必要です。\nログインもしくは新規登録をお願いします。`}
          </Typography>
          <div className={classes.buttonBox}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} className={classes.centerContent}>
                    <GrayButton
                      variant="contained"
                      onClick={() => {
                        push("/signin", targetUrl);
                      }}
                    >
                      ログイン
                    </GrayButton>
                  </Grid>
                  <Grid item xs={6} className={classes.centerContent}>
                    <GrayButton
                      variant="contained"
                      onClick={() => {
                        push("/signup", targetUrl);
                      }}
                    >
                      新規登録はこちら
                    </GrayButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
});
