import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CameraIcon from "../../assets/icon/camera.png";
import { makeStyles } from "@material-ui/core";
import { selectUser } from "../../features/userSlice";
import { uploadProfileImage } from "../../features/imageOperation";
import { Skelton } from "../atoms/Skelton/Skelton";

type Props = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const userStyles = makeStyles({
  iconSelectArea: {
    display: "flex",
    height: 76,
  },
  iconSelect: {
    flex: 1,
  },
  cameraFrame: {
    width: 55,
    height: 55,
    marginTop: 10,
    borderRadius: 10,
    border: "0.5px solid black",
    cursor: "pointer",
  },
  profileIiconInput: {
    display: "none",
  },
  camera: {
    margin: "2.5px 0 0 2.5px",
    width: 50,
    height: 50,
  },
  iconInfo: {
    paddingLeft: 20,
    flex: 7.5,
    "& p": {
      margin: 0,
      lineHeight: 6.5,
      fontSize: 12,
      color: "gray",
    },
  },
  iconSelectBtn: {
    flex: 1.5,
    marginRight: 10,
  },
  selectBtnBorder: {
    float: "right",
    fontSize: 10,
    width: 40,
    borderBottom: "0.5px solid gray",
    margin: "31px auto 0 auto",
    cursor: "pointer",
  },
  cameraArea: {
    width: 55,
    height: 55,
    border: "1px solid gray",
    borderRadius: 10,
    cursor: "pointer",
    margin: 9,
  },
  cameraInput: {
    display: "none",
  },
  cameraImage: {
    width: 55,
    height: 55,
    // margin: "2.5px 0 0 2.5px",
    objectFit: "cover",
    borderRadius: 9,
  },
});

export const ProfileIconSelect = (props: Props) => {
  const { setLoading } = props;
  const classes = userStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const ref = createRef<HTMLInputElement>();
  const nullImage =
    "https://firebasestorage.googleapis.com/v0/b/meme-project-f3275.appspot.com/o/null_images%2Fei-person.png?alt=media&token=53a26ce0-1552-4766-823f-dcd26959b558";

  const imageSet = () => {
    const imageInput = document.getElementById("imageSet");
    imageInput?.click();
  };

  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = user.photoUrl;
    img.onload = () => setImageLoading(false); // 画像が読み込まれたらスケルトンを非表示に
  }, [user.photoUrl]);
  return (
    <div className={classes.iconSelectArea}>
      <div className={classes.iconSelect}>
        <div
          className={classes.cameraArea}
          onClick={() => ref.current!.click()}
        >
          {imageLoading && <Skelton width={55} height={55} />}
          {!imageLoading && (
            <>
              {nullImage !== user.photoUrl ? (
                <img
                  src={user.photoUrl}
                  className={classes.cameraImage}
                  alt="icon"
                />
              ) : (
                <img
                  src={CameraIcon}
                  className={classes.cameraImage}
                  alt="camera"
                />
              )}
            </>
          )}
          <input
            type="file"
            ref={ref}
            className={classes.cameraInput}
            id="imageSet"
            onChange={(e) =>
              dispatch(uploadProfileImage(e, user.uid, setLoading))
            }
          />
        </div>
      </div>
      <div className={classes.iconInfo}>
        <p> プロフィール写真　※任意</p>
      </div>
      <div className={classes.iconSelectBtn}>
        <p className={classes.selectBtnBorder} onClick={() => imageSet()}>
          選択する
        </p>
      </div>
    </div>
  );
};
