import { Header } from "../Header/Header";
import { Done } from "../../templates/Done/Done";
const CreditDeleteDone = () => {
  return (
    <div>
      <Header label="クレジットカード登録" transitionLocation="/?" />
      <Done
        info="クレジットカードの削除が完了しました"
        linkText="TOPページへ"
      />
    </div>
  );
};

export default CreditDeleteDone;
