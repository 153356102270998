import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

interface PROPS {
  bgc?: string;
  border?: string;
}

const ReserveInfo = (props: PROPS) => {
  const useStyles = makeStyles({
    cancel: {
      backgroundColor: props.bgc ? props.bgc : "rgb(234,234,234)",
    },
    textBox: {
      border: props.border ? props.border : "1px solid black",
      margin: "0 auto",
      borderRadius: 10,
      width: 460,
      padding: 10,
      "@media screen and (max-width: 499px)": {
        width: 345,
      },
    },
    text: {
      fontSize: 14,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.cancel}>
      <div className={classes.textBox}>
        <Typography className={classes.text}>【キャンセルポリシー】</Typography>
        <Typography className={classes.text}>
          購入または予約完了後はキャンセルできません。
        </Typography>
        <Typography className={classes.text}>
          カラー、サイズ、個数に間違いがないかご注意ください。
        </Typography>
      </div>
    </div>
  );
};

export default ReserveInfo;
