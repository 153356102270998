import React from "react";
import { PropsQuiltedFiveImages } from "./PropsQuiltedFiveImages";
import { PropsQuiltedFourImages } from "./PropsQuiltedFourImages";
import { PropsQuiltedOneImages } from "./PropsQuiltedOneImages";
import { PropsQuiltedThreeImages } from "./PropsQuiltedThreeImages";
import { PropsQuiltedTwoImages } from "./PropsQuiltedTwoImages";
import { SalesStatus } from "../../Types/productType";
import { useWindowSize } from "react-use";

type Props = {
  images: { path: string; id: string }[];
  projectRef?: string;
  salesStatus?: SalesStatus;
};

export const PropsSwitchQuiltedImages = React.memo((props: Props) => {
  const { images, projectRef, salesStatus } = props;
  const { width } = useWindowSize();
  switch (images.length) {
    case 5:
      return (
        <PropsQuiltedFiveImages
          images={images}
          projectRef={projectRef ?? ""}
          salesStatus={salesStatus}
          currentWidth={width}
        />
      );
    case 4:
      return (
        <PropsQuiltedFourImages
          images={images}
          projectRef={projectRef ?? ""}
          salesStatus={salesStatus}
          currentWidth={width}
        />
      );
    case 3:
      return (
        <PropsQuiltedThreeImages
          images={images}
          projectRef={projectRef ?? ""}
          salesStatus={salesStatus}
          currentWidth={width}
        />
      );
    case 2:
      return (
        <PropsQuiltedTwoImages
          images={images}
          projectRef={projectRef ?? ""}
          salesStatus={salesStatus}
          currentWidth={width}
        />
      );
    case 1:
      return (
        <PropsQuiltedOneImages
          images={images}
          projectRef={projectRef ?? ""}
          salesStatus={salesStatus}
          currentWidth={width}
        />
      );
    default:
      return <div></div>;
  }
});
