import { useState, createRef, useEffect, useCallback } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { multipleUploadImage } from "../../features/imageOperation";
import Camera from "../../assets/icon/camera.png";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { addImagesActions } from "../../features/postOperation";
import Cropper, { Area, Point } from "react-easy-crop";
import Modal from "react-modal";
import "cropperjs/dist/cropper.css";
import { selectProjectPost } from "../../features/projectPostSlice";
import { addProjectImagesActions } from "../../features/projectPostOperation";
import { Images } from "../../Types/imageType";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { SwitchQuiltedImages } from "../PostImage/SwitchQuiltedImages";
import { SpaceBox } from "./SpaceBox";

type Props = {
  images: Images[];
  setImages: React.Dispatch<React.SetStateAction<Images[]>>;
};

export const MultipleImageArea = (props: Props) => {
  const { images, setImages } = props;
  const postProject = useSelector(selectProjectPost);
  const useStyles = makeStyles({
    icon: {
      height: 100,
      width: 100,
      display: "block",
      margin: "0 auto",
    },
    DisplayNone: {
      display: "none",
    },
    image: {
      objectFit: "cover",
    },
    cropperArea: {
      margin: "0 auto",
      zIndex: 3,
      objectFit: "cover",
      width: 450,
      height: 562.5,
      "@media screen and (max-width: 499px)": {
        width: 342,
        height: 427.5,
      },
    },
    updateProfileBtn: {
      margin: "0 auto",
      marginTop: 10,
    },
    textInfo: {
      " & p": {
        textAlign: "center",
        margin: "0 0 0 0",
        paddingTop: 10,
      },
    },
    overlayImageCropper: {
      height: "100%",
      width: 500,
      margin: "0 auto",
      borderRadius: 15,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
        margin: "0",
      },
    },
    cameraIcon: {
      width: 40,
      height: 40,
    },
    imageArea: {
      width: 198,
      height: 248,
      border: "1px solid black",
      margin: "10px auto 0",
      flex: 1,
      "& img": {
        width: 150,
        marginTop: 40,
      },
    },
    buttonArea: {
      display: "grid",
      justifyContent: "center",
      alignContent: "center",
    },

    modalArea: {
      marginTop: 54,
      "@media screen and (max-width: 499px)": {
        marginTop: 38,
      },
      margin: "0 auto",
    },
    bottomArea: {
      position: "fixed",
      bottom: 15,
      width: "500px",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    buttonBox: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
    },
    rightButton: {
      float: "right",
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = createRef<HTMLInputElement>();

  useEffect(() => {
    if (postProject.projectID !== "") {
      dispatch(addProjectImagesActions(images));
    } else {
      dispatch(addImagesActions(images));
    }
  }, [images]);

  const [image, setImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 10 });
  const [zoom, setZoom] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const onCropComplete = useCallback(
    (croppedArea: Point, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const inputImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImage(URL.createObjectURL(file));
      setModalIsOpen(true);
    }
  };

  const handleClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <SwitchQuiltedImages images={images} setImages={setImages} />
      <div className="u-text-right">
        <IconButton className={classes.icon}>
          <label>
            <img
              src={Camera}
              className={classes.cameraIcon}
              onClick={() => ref.current!.click}
              alt="camera"
            />

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
              className={classes.overlayImageCropper}
              closeTimeoutMS={300}
            >
              <div className={classes.modalArea}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  minZoom={0.1}
                  aspect={3 / 4}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="vertical-cover"
                />
              </div>
              <div className={classes.bottomArea}>
                <div className={classes.buttonBox}>
                  <div>
                    <GrayButton
                      onClick={() =>
                        multipleUploadImage(
                          image,
                          croppedAreaPixels,
                          setImages,
                          setModalIsOpen
                        )
                      }
                    >
                      選択範囲で反映
                    </GrayButton>
                  </div>
                  <div className={classes.rightButton}>
                    <GrayButton onClick={handleClose}>キャンセル</GrayButton>
                  </div>
                </div>
              </div>
            </Modal>
            <input
              className="u-display-none"
              ref={ref}
              type="file"
              id="image"
              accept="image/*"
              onChange={(e) => inputImage(e)}
            />
          </label>
        </IconButton>
      </div>
      <div className={classes.buttonArea}>
        <GrayButton
          disabled={!images.length}
          onClick={() => dispatch(push("/post/input"))}
        >
          次へ
        </GrayButton>
      </div>
    </>
  );
};
