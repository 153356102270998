import { useEffect } from "react";
import { useHistory } from "react-router";
import { makeStyles, Typography } from "@material-ui/core";
import { PrimaryButton } from "../../UIKit";
import { animateScroll as scroll } from "react-scroll";
import { SpaceBox } from "../../UIKit/SpaceBox";
import { Header } from "../../Header/Header";

const CreatorHopeDone = () => {
  const useStyles = makeStyles({
    topText: {
      padding: "40px 0 60px",
    },
    text: {
      fontSize: 24,
      fontWeight: "bold",
      paddingTop: 10,
    },
    midText: {
      paddingBottom: 50,
    },
    bottomText: {
      paddingBottom: 50,
    },
    grayText: {
      fontSize: 24,
      fontWeight: "bold",
      color: "gray",
      paddingBottom: 10,
    },
  });
  const classes = useStyles();
  const { push } = useHistory();

  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 0 });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <Header label="登録完了" transitionLocation={"/"} />
      <SpaceBox height={45} />
      <div className={classes.topText}>
        <Typography align="center" variant="h1" className={classes.text}>
          クリエイター希望登録
        </Typography>
        <Typography align="center" variant="h1" className={classes.text}>
          ありがとうございました
        </Typography>
      </div>

      <div className={classes.midText}>
        <Typography align="left" variant="h1" className={classes.grayText}>
          審査の結果、クリエイターとして活動していただく際には、tomemeからご連絡させていただきます。
        </Typography>
        <Typography align="left" variant="h1" className={classes.grayText}>
          審査結果は個別に対応することができかねるので予めご了承お願いします。
        </Typography>
      </div>
      <div className={classes.bottomText}>
        <Typography align="left" variant="h1" className={classes.grayText}>
          tomemeでも思いの持ったクリエイターを増やしていけるよう体制を整えておりますが、クリエイターごとに実務が必要なため、なかなか急拡大ができないところもございます。
        </Typography>
        <Typography align="left" variant="h1" className={classes.grayText}>
          クリエイターになった時のために、日々の投稿を続けていただき、いいね！やフォロワー数を増やしていきましょう。
        </Typography>
      </div>
      <PrimaryButton
        label="TOPページへ"
        onClick={() => push("/")}
        margin="0 auto"
        display="block"
      />
      <SpaceBox height={45} />
    </div>
  );
};

export default CreatorHopeDone;
