import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { Squeeze } from "../Types/userType";

interface USER {
  displayName: string;
  notice: boolean;
  selfIntroduction: string;
}
interface ProfilePhoto {
  photoUrl: string;
}

type toggleState = { notice: boolean };
type displayName = { displayName: string };

interface EditADDRESS {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  prefecture: string;
  city: string;
  town: string;
  address: string;
  buildingName: string;
  phoneNumber: string;
  mainAddress: boolean;
  zipcode: string;
  aid: string;
}

interface InfoSet {
  likeInfo: boolean;
  wantInfo: boolean;
  followInfo: boolean;
  commentInfo: boolean;
  mentionInfo: boolean;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      uid: "",
      username: "",
      photoUrl: "",
      displayName: "",
      isSignedIn: false,
      role: "",
      notice: false,
      selfIntroduction: "",
      followCount: 0,
      followerCount: 0,
      postCount: 0,
    },
    address: {
      lastName: "",
      firstName: "",
      lastNameKana: "",
      firstNameKana: "",
      prefecture: "",
      city: "",
      town: "",
      address: "",
      buildingName: "",
      phoneNumber: "",
      mainAddress: false,
      zipcode: "",
      aid: "",
    },
    squeeze: {
      Project: false,
      Product: true,
      All: false,
      Want: false,
      // Reservation: false,
      // Sale: false,
    },
  },

  reducers: {
    // action に firebaseから受け取ったuserのログイン情報をpayloadに受け取れるようにしておく
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = {
        uid: "",
        username: "",
        photoUrl: "",
        displayName: "",
        isSignedIn: false,
        role: "",
        notice: false,
        selfIntroduction: "",
        followCount: 0,
        followerCount: 0,
        postCount: 0,
      };
    },
    // 仕組みをちゃんと理解する
    // * TypeScriptのinterface　reduxのaction payload
    updateUserProfile: (state, action: PayloadAction<USER>) => {
      // state.user.displayName = action.payload.displayName;
      // state.user.photoUrl = action.payload.photoUrl;
      return {
        ...state,
        ...action.payload,
      };
    },
    // setPhotoUrl: (state, action: PayloadAction<ProfilePhoto>) => {
    //   state.user.photoUrl = action.payload.photoUrl
    // },
    previewImage: (state, action: PayloadAction<ProfilePhoto>) => {
      state.user.photoUrl = action.payload.photoUrl;
    },
    switchNotice: (state, action: PayloadAction<toggleState>) => {
      state.user.notice = action.payload.notice;
    },
    updateDisplayName: (state, action: PayloadAction<displayName>) => {
      state.user.displayName = action.payload.displayName;
    },

    editAddress: (state, action: PayloadAction<EditADDRESS>) => {
      state.address.lastName = action.payload.lastName;
      state.address.firstName = action.payload.firstName;
      state.address.lastNameKana = action.payload.lastNameKana;
      state.address.firstNameKana = action.payload.firstNameKana;
      state.address.prefecture = action.payload.prefecture;
      state.address.city = action.payload.city;
      state.address.town = action.payload.town;
      state.address.address = action.payload.address;
      state.address.buildingName = action.payload.buildingName;
      state.address.phoneNumber = action.payload.phoneNumber;
      state.address.mainAddress = action.payload.mainAddress!;
      state.address.zipcode = action.payload.zipcode;
      state.address.aid = action.payload.aid;
    },

    squeezeChange: (state, action: PayloadAction<Squeeze>) => {
      state.squeeze = action.payload;
    },

    editInfo: (state, action: PayloadAction<InfoSet>) => {
      // state.user.followInfo = action.payload.followInfo;
      // state.user.likeInfo = action.payload.likeInfo;
      // state.user.wantInfo = action.payload.wantInfo;
      // state.user.commentInfo = action.payload.commentInfo;
      // state.user.mentionInfo = action.payload.mentionInfo;
    },
  },
});

// upDateUserProfileをエクスポートした
export const {
  login,
  logout,
  updateUserProfile,
  previewImage,
  switchNotice,
  updateDisplayName,
  editAddress,
  squeezeChange,
  editInfo,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectAddress = (state: RootState) => state.user.address;
export const selectSqueeze = (state: RootState) => state.user.squeeze;
export default userSlice.reducer;
export const getUserSelector = createSelector(selectUser, (user) => {
  return user;
});
