import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import GrayBorder from "../UIKit/GrayBorder";
import { ProductData } from "../../Types/deliveryType";
import { TotalAmount } from "./TotalAmount";
import { OrderHistory } from "../../Types/productType";
import { SpaceBox } from "../UIKit/SpaceBox";

type Props = {
  purchaseHistory: OrderHistory;
};

const PurchaseHistory = (props: Props) => {
  const { purchaseHistory } = props;

  const [calculationAmount, setCalculationAmount] = useState({
    price: 0,
    totalPrice: 0,
    postage: 0,
  });

  const totalAmountCalculation = () => {
    let total = 0;
    purchaseHistory.productData.map((pd: ProductData) => {
      const priceMultipliedByQuantity = pd.price * pd.quantity;
      total += priceMultipliedByQuantity;
    });
    setCalculationAmount({
      price: total,
      totalPrice: total + purchaseHistory.postage,
      postage: purchaseHistory.postage,
    });
  };

  const useStyles = makeStyles({
    PurchaseHistoryArea: {
      paddingTop: 20,
    },
    PurchaseHistory: {
      display: "flex",
      marginBottom: 20,
    },
    PurchaseImg: {
      flex: 4,
    },
    icon: {
      margin: "0 auto",
      objectFit: "cover",
      width: 160,
      height: 200,
      display: "block",
    },
    purchaseInfo: {
      flex: 5,
      "& p": {
        margin: "11px auto 0",
        textAlign: "center",
        fontSize: 14,
      },
    },
    fontBold: {
      fontWeight: "bold",
    },
    purchaseAndLength: {
      display: "flex",
    },
    place: {
      flex: 7,
      "& p": {
        textAlign: "left",
      },
    },
    purchaseLength: {
      flex: 3,
    },
    subtotal: {
      marginTop: 10,
    },
    dummyArea: {
      height: 20,
    },
  });

  const amount = (price: number, quantity: number) => {
    return price * quantity;
  };

  const classes = useStyles();

  useEffect(() => {
    totalAmountCalculation();
  }, []);

  return (
    <div className={classes.PurchaseHistoryArea}>
      {purchaseHistory.productData.map((pd: ProductData) => (
        <div className={classes.PurchaseHistory}>
          <div className={classes.PurchaseImg}>
            {/* <img src={nishimura} className={classes.icon} /> */}
          </div>
          <div className={classes.purchaseInfo}>
            <p className={classes.fontBold}>お客様にお届け済み</p>
            <p>{pd.projectName}</p>
            <GrayBorder />
            <p>{pd.size}</p>
            <GrayBorder />
            <p>{pd.color}</p>
            <GrayBorder />
            <div className={classes.purchaseAndLength}>
              <div className={classes.place}>
                <p>{`¥${amount(pd.price, pd.quantity).toLocaleString()}`}</p>
              </div>
              <div className={classes.purchaseLength}>
                <p>{`${pd.quantity}点`}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <SpaceBox height={10} />
      <TotalAmount
        kind={"商品合計"}
        price={calculationAmount.price.toLocaleString()}
      />
      <TotalAmount
        kind={"送料"}
        price={calculationAmount.postage.toLocaleString()}
      />
      <TotalAmount
        kind={"合計金額"}
        price={calculationAmount.totalPrice.toLocaleString()}
      />
      <SpaceBox height={20} />
    </div>
  );
};

export default PurchaseHistory;
