import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../../components/Header/Header";
import { Done } from "../../Done/Done";
import { Typography } from "@material-ui/core";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { fetchXAccount } from "../../../features/userOperation";
import { logError } from "../../../lib/logError";
import { toast } from "react-toastify";
import { analyticsCustomLogEvent } from "../../../firebase";
import { Text } from "../../../components/atoms/Text/Text";

// makeStylesを使用してスタイルを定義
const useStyles = makeStyles((theme) => ({
  shareArea: {
    width: "100%",
    marginTop: 20,
  },
  shareButtonArea: {
    marginTop: 20,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnSns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "3rem",
    height: "3rem",
    boxSizing: "border-box",
    borderRadius: "50%",
    color: "white",
    fontSize: "1.5rem",
    textAlign: "center",
    textDecoration: "none",
    transition: "0.3s",
    backgroundColor: "#111319",
    cursor: "pointer",
    "& svg": {
      fill: "#fff",
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
  shareText: {
    fontWeight: "bold",
    letterSpacing: "0.15em",
  },
  imageArea: {
    width: 300,
    margin: "0 auto 20px",
    "@media screen and (max-width: 499px)": {
      width: 240,
    },
  },
  image: {
    width: "100%",
    height: "auto",
  },
  link: {
    textDecoration: "none",
  },
  linkText: {
    color: "rgb(63,101,227)",
    cursor: "pointer",
    fontSize: 16,
    textAlign: "center",
    marginTop: 0,
    textDecoration: "none",
  },
}));

export const ReserveDone = (props: RouteComponentProps) => {
  const { history } = props;
  const classes = useStyles(); // スタイルフックを呼び出し
  const { prevLocation, creatorUid, image } = history.location.state as {
    prevLocation: string;
    creatorUid: string;
    image: string;
  };

  const [xAccountName, setXAccountName] = useState("");
  const shareURL = `https://tomeme.jp${prevLocation}`;
  const tweetText = encodeURIComponent("tomemeで予約しました！");
  const url = encodeURIComponent(shareURL);
  const creatorAccount = encodeURIComponent(`@${xAccountName}`);
  const hashtags = encodeURIComponent(`tomeme,ファッション,サスティナブル,3D`);
  const twitterUrl = `https://twitter.com/intent/tweet?text=${creatorAccount}さんの製作した洋服を%0A${tweetText}%0A&url=${url}%0A&hashtags=${hashtags}`;

  const handleTweet = () => {
    window.open(twitterUrl, "_blank");
    analyticsCustomLogEvent("x_share_clicked");
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchXAccount(creatorUid, setXAccountName);
      } catch (error) {
        logError(error);
        toast.error("シェアに必要な情報の取得に失敗しました。");
      }
    })();
  }, []);
  return (
    <div>
      <Header label="商品予約" transitionLocation="/?" />
      <Text
        text="予約が完了しました"
        isDark
        fontSize="14"
        align="center"
        className={{ margin: "20px 0 10px", fontWeight: "bold" }}
      />
      <Text
        text={`ありがとうございます！\n商品到着まで、いましばらくお待ちください。`}
        isDark
        align="center"
        className={{ margin: "0 0 20px" }}
      />
      <div className={classes.imageArea}>
        <img src={image} alt="product" className={classes.image} />
      </div>
      <Link to="/?" className={classes.link}>
        <Typography className={classes.linkText}>TOPページへ</Typography>
      </Link>
      <div className={classes.shareArea}>
        <Text
          text="予約したことをクリエイターにシェアしよう！"
          isDark
          align="center"
          fontSize={16}
        />
        <Typography align="center" className={classes.shareText}></Typography>
        <div className={classes.shareButtonArea} onClick={() => handleTweet()}>
          <div className={classes.btnSns}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
