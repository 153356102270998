import { makeStyles } from "@material-ui/core";
import { mainCardUpdate } from "../../features/PaymentOperation";
import { Text } from "../atoms/Text/Text";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Center } from "../atoms/Layout/Center";
import { toast } from "react-toastify";

type Props = {
  customerID: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  indexNumber?: number;
  setUpdateActive: React.Dispatch<
    React.SetStateAction<{
      active: boolean;
      text: string;
    }>
  >;
};

export const CreditMainChange = (props: Props) => {
  const { customerID, setOpen, indexNumber, setUpdateActive } = props;
  const useStyles = makeStyles({
    modalArea: {
      padding: 20,
      backgroundColor: "white",
      zIndex: 100,
    },
    modalInfo: {
      marginBottom: 20,
    },
    modalBtnArea: {
      alignItems: "center",
      display: "flex",
    },
  });
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const yesHandleClose = async () => {
    setOpen(false);
    setUpdateActive({ active: true, text: "更新中" });
    try {
      await mainCardUpdate(customerID, indexNumber!);
    } catch (error) {
      toast.error("更新に失敗しました");
    }
  };

  return (
    <div className={classes.modalArea}>
      <div className={classes.modalInfo}>
        <Text
          text="メインのクレジットカードへ変更しますか？"
          fontSize={14}
          align="center"
        />
      </div>
      <div className={classes.modalBtnArea}>
        <Center
          className={{
            flex: 1,
          }}
        >
          <GrayButton width={80} onClick={() => yesHandleClose()}>
            はい
          </GrayButton>
        </Center>
        <Center
          className={{
            flex: 1,
          }}
        >
          <GrayButton width={80} onClick={() => handleClose()}>
            いいえ
          </GrayButton>
        </Center>
      </div>
    </div>
  );
};
