import { Header } from "../../components/Header/Header";
import { Done } from "./Done";

const BuyDone = () => {
  return (
    <div>
      <Header label="購入完了" transitionLocation={"/?"} />
      <Done
        info={`購入ありがとうございます！\n商品到着までお待ちください。`}
        linkText="TOPページへ"
      />
    </div>
  );
};

export default BuyDone;
