import { useState, useEffect } from "react";
import { MyUserProfile } from "./MyUserProfile";
import { MyCreatorProfile } from "./MyCreatorProfile";
import { UserProfileType } from "../../Types/userType";
import { RouteComponentProps } from "react-router-dom";
import { userDocument } from "../../firebasePaths";
import { getDoc } from "firebase/firestore";

export const SwitchMyProfile = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match } = props;
  const uid = match.params.id;
  const userRef = userDocument(uid);
  const [userInfo, setUserInfo] = useState<UserProfileType>();

  const urlSplit = history.location.pathname.split("/")[2];

  useEffect(() => {
    getDoc(userRef).then((doc) => {
      if (doc.exists()) {
        const data = doc.data()!;
        const userData = {
          uid: data.uid,
          displayName: data.displayName,
          photoUrl: data.photoUrl,
          selfIntroduction: data.selfIntroduction,
          followCount: data.followCount,
          followerCount: data.followerCount,
          postCount: data.postCount,
          role: data.role,
          images: data.images ? data.images : [""],
          topProfile: data.topProfile ? data.topProfile : "",
          bottomProfile: data.bottomProfile ? data.bottomProfile : "",
          layouts: data.layouts ? data.layouts : [""],
          profileColor: data.profileColor ?? {
            r: 247,
            g: 229,
            b: 229,
            a: 1,
          },
        };
        setUserInfo(userData);
      }
    });
  }, [urlSplit]);

  return (
    <>
      {userInfo?.role === "memer" ? (
        <MyCreatorProfile userInfo={userInfo} />
      ) : (
        <MyUserProfile userInfo={userInfo} />
      )}
    </>
  );
};
