import { useState, useEffect } from "react";
import { Project } from "../../../Types/projectType";
import { ProjectCardList } from "./ProjectCardList";
import { fetchProjects } from "../../../features/projectPostOperation";

type Props = {
  uid: string;
  status: string;
};

export const ProjectMap = (props: Props) => {
  const { uid, status } = props;
  const [project, setProject] = useState<Project[]>([]);

  useEffect(() => {
    if (status === "newPost") {
      fetchProjects(uid, setProject);
    }
  }, []);

  return (
    <div>
      {project.length >= 1 &&
        project.map((p) => (
          <div key={p.productNumber}>
            <ProjectCardList project={p} status={status} />
          </div>
        ))}
    </div>
  );
};
