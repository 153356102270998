import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";

import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import GridLayout from "react-grid-layout";
import { selectUser } from "../../features/userSlice";
import React from "react";
import { SalesStatus } from "../../Types/productType";
import {
  isProjectLink,
  projectRouterChoice,
} from "../../features/helpers/routerHelper";

type Props = {
  images: {
    id: string;
    path: string;
  }[];
  projectRef: string;
  salesStatus?: SalesStatus;
  currentWidth: number;
};

export const PropsQuiltedOneImages = React.memo((props: Props) => {
  const { images, projectRef, salesStatus, currentWidth } = props;
  const useStyles = makeStyles({
    root: {
      width: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    image: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  const { push } = useHistory();
  const user = useSelector(selectUser);
  const uid = user.uid;
  return (
    <div
      onClick={() =>
        isProjectLink(salesStatus, projectRef) &&
        push(projectRouterChoice(salesStatus, projectRef), uid)
      }
      className={classes.root}
    >
      <GridLayout
        className="layout"
        isDraggable={false}
        isResizable={false}
        rowHeight={currentWidth > 499 ? 100 : currentWidth / 6}
        width={currentWidth > 499 ? 500 : currentWidth}
        margin={[1, 1]}
      >
        <div
          key={0}
          data-grid={{
            x: 0,
            y: 0,
            w: 20,
            h: 6,
            i: "0",
          }}
        >
          <img src={images[0].path} className={classes.image} alt="postImage" />
        </div>
      </GridLayout>
    </div>
  );
});
