import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { SpaceBox } from "../../UIKit/SpaceBox";

export const InquiryTextArea = (props: any) => {
  const useStyles = makeStyles({
    inputArea: {
      margin: "0 20px",
    },
    title: {
      marginLeft: 5,
      marginBottom: 10,
      display: "block",
      fontSize: 12,
    },
    textArea: {
      width: 425,
      padding: 10,
      resize: "none",
      borderRadius: 5,
      borderColor: "rgb(196,196,196)",
      "@media screen and (max-width: 499px)": {
        width: 300,
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.inputArea}>
      <Typography className={classes.title}>{props.label}</Typography>
      <TextareaAutosize
        className={classes.textArea}
        minRows={8}
        name={props.name}
        value={props.value}
        {...props.field}
      />

      <SpaceBox height={5} />
    </div>
  );
};
