import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, TextareaAutosize, Typography } from "@material-ui/core";
import { creatorInfo } from "../../features/userUtil";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { SpaceBox } from "../UIKit/SpaceBox";
import { userDocument } from "../../firebasePaths";
import { getDoc } from "firebase/firestore";
import { RGBColor } from "react-color";
import { colorConvert } from "../../features/helpers/profileHelper";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Center } from "../atoms/Layout/Center";
import { toast } from "react-toastify";
import { creatorProfileEdit } from "../../features/userOperation";

type User = {
  uid: string;
  displayName: string;
  photoUrl: string;
  selfIntroduction: string;
  role: string;
  images: string[];
  topProfile: string;
  bottomProfile: string;
};

type Props = {
  marginTop?: string;
} & RouteComponentProps<{ uid: string }>;

export const CreatorProfileEdit = (props: Props) => {
  const { marginTop, history, match } = props;
  const [color, setColor] = useState<RGBColor>({
    r: 255,
    g: 255,
    b: 255,
    a: 100,
  });
  const useStyles = makeStyles({
    creatorTopIntroduction: {
      margin: "0 auto",
      backgroundColor: colorConvert(color),
      height: 320,
      width: 500,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    creatorName: {
      fontSize: 20,
      fontWeight: "bold",
      width: "85%",
      margin: "0 auto 0",
      "& p": {
        margin: "0 0",
      },
    },
    creatorIntroduction: {
      fontSize: 13,
      width: "90%",
      margin: "0 auto",
    },
    dummyArea: {
      height: 60,
    },
    creatorBottomIntroduction: {
      height: 100,
    },
    creatorIconArea: {
      width: 230,
      height: 230,
      margin: "0 auto",
      borderRadius: 15,
      backgroundColor: "white",
      marginTop: 35,
      marginBottom: 10,
      "@media screen and (max-width: 499px)": {
        width: 200,
        height: 200,
      },
    },
    creatorIcon: {
      width: 180,
      height: 180,
      margin: "25px 0 0 25px",
      borderRadius: 15,
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: 150,
        height: 150,
      },
    },
    followArea: {
      margin: "0 auto",
      width: "60%",
    },
    profileTextArea: {
      width: 400,
      resize: "none",
      display: "block",
      borderRadius: 10,
      margin: "0 auto",
      padding: 10,
      fontSize: 16,
      "@media screen and (max-width: 499px)": {
        width: 300,
      },
    },
    selfIntroduction: {
      width: 500,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    introductionArea: {
      margin: "0 auto",
      width: "70%",
      "& p": {
        fontSize: 13,
      },
    },
    profileText: {
      marginTop: marginTop ? marginTop : 50,
      "& p": {
        fontWeight: "bold",
        margin: "0 0 0 0",
        textAlign: "center",
      },
    },
    name: {
      fontWeight: "bold",
      margin: "0 0 0 0",
      textAlign: "center",
    },
    btn: {
      display: "none",
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const uid = match.params.uid;
  const urlSplit = history.location.pathname.split("/")[2];
  const userRef = userDocument(uid);
  const [userinfo, setUserinfo] = useState<User>(creatorInfo);

  const defaultValues = {
    topProfile: userinfo.topProfile,
    bottomProfile: userinfo.bottomProfile,
  };

  const methods = useForm({ defaultValues });
  const { control, setValue, handleSubmit } = methods;

  const onSubmit = (data: any) => {
    dispatch(creatorProfileEdit(uid, data));
  };
  const submitClick = () => {
    const btn = document.getElementById("none");
    btn?.click();
  };

  const fetchUser = async () => {
    try {
      await getDoc(userRef).then((doc) => {
        if (doc.exists()) {
          const data = doc.data()!;
          const userData = {
            uid: data.uid,
            displayName: data.displayName,
            photoUrl: data.photoUrl,
            selfIntroduction: data.selfIntroduction,
            role: data.role,
            images: data.images ? data.images : [""],
            topProfile: data.topProfile ? data.topProfile : "",
            bottomProfile: data.bottomProfile ? data.bottomProfile : "",
            profileColor: data.profileColor ?? {
              r: 247,
              g: 229,
              b: 229,
              a: 1,
            },
          };
          setUserinfo(userData);
          setValue("topProfile", data.topProfile);
          setValue("bottomProfile", data.bottomProfile);
          setColor(userData.profileColor);
        }
      });
    } catch (error) {
      toast.error("データの取得に失敗しました。");
    }
  };

  useEffect(() => {
    fetchUser();
  }, [urlSplit]);

  return (
    <div>
      <FormProvider {...methods}>
        <BrowserBackHeader
          label="Creatorプロフィール編集"
          browserBack={history}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.creatorTopIntroduction}>
            <div className={classes.dummyArea}></div>
            <div className={classes.creatorName}>
              <Typography variant="h6" className={classes.name}>
                {userinfo.displayName}
              </Typography>
            </div>
            <div className={classes.creatorIntroduction}>
              <Typography>上段プロフィール</Typography>
              <Controller
                name="topProfile"
                control={control}
                rules={{ required: "入力してください" }}
                render={({ field }) => (
                  <TextareaAutosize
                    rows={7}
                    rowsMax={7}
                    placeholder="Creatorページ上段のプロフィール"
                    className={classes.profileTextArea}
                    defaultValue={userinfo.topProfile}
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          <div className={classes.selfIntroduction}>
            <div className={classes.profileText}>
              <p>PROFILE</p>
            </div>
            <div className={classes.creatorIntroduction}>
              <Typography>下段プロフィール</Typography>
              <Controller
                name="bottomProfile"
                control={control}
                rules={{ required: "入力してください" }}
                render={({ field }) => (
                  <TextareaAutosize
                    rows={7}
                    rowsMax={7}
                    defaultValue={defaultValues.bottomProfile}
                    placeholder="Creatorページ下段のプロフィール"
                    className={classes.profileTextArea}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <input type="submit" id="none" className={classes.btn} />
        </form>
        <SpaceBox height={30} />
        <Center>
          <GrayButton onClick={() => submitClick()}>
            プロフィール保存
          </GrayButton>
        </Center>
      </FormProvider>
    </div>
  );
};
