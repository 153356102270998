import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { fetchProductList } from "../../features/productOperation";
import { TopFooter } from "../../components/Footer/TopFooter";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { ProductMap } from "./ProductParts/ProductMap";
import { ProductSkeleton } from "../Skeleton/ProductSkeleton";
import { Project } from "../../Types/projectType";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { toast } from "react-toastify";
import { logError } from "../../lib/logError";

export const ProductList = (props: RouteComponentProps) => {
  const { history } = props;
  const [productList, setProductList] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    const fetchProducts = async () => {
      try {
        if (isMounted) {
          await fetchProductList(setProductList, 4, undefined, setLoading);
        }
      } catch (error) {
        if (isMounted) {
          logError(error);
          toast.error("商品の取得に失敗しました。\nリロードしてください");
        }
      }
    };
    fetchProducts();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <BrowserBackHeader label="商品一覧" browserBack={history} />
      <TopFooter effect="product" />
      {loading ? <ProductSkeleton /> : <ProductMap productList={productList} />}
      <SpaceBox height={50} />
    </div>
  );
};
