import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { makeStyles } from "@material-ui/core";
import { PostFooter } from "./PostFooter";
import { MenuFooter } from "./MenuFooter";
import Person from "../../assets/icon/person.png";
import Personpush from "../../assets/icon/personpush.png";
import T from "../../assets/icon/T.png";
import Tpush from "../../assets/icon/productpush.png";
import homepush from "../../assets/icon/homepush.png";
import home from "../../assets/icon/home.png";
import MeMerIcon from "../../assets/icon/MeMer.png";
import MeMerIconpush from "../../assets/icon/MeMerpush.png";
import { Link } from "react-router-dom";
import { changeLocation, selectLocation } from "../../features/locationSlice";
import { LoginInfo } from "../feature/LoginInfo/LoginInfo";

type Props = {
  effect?: "home" | "mypage" | "creatorList" | "product";
  uid?: string;
};
export const TopFooter = (props: Props): JSX.Element => {
  const { effect, uid } = props;
  const isSignedIn = useSelector(selectUser).isSignedIn;
  const userData = useSelector(selectUser);
  const location = useSelector(selectLocation);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const useStyles = makeStyles({
    footerArea: {
      width: 500,
      display: "flex",
      margin: "0 auto",
      borderTop: "1px solid rgb(183,183,183)",
      borderBottom: "1px solid rgb(183,183,183)",
      position: "fixed",
      bottom: 0,
      zIndex: 100,
      backgroundColor: "white",
      height: 40,
      paddingTop: 5,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
        height: 30,
      },
    },
    link: {
      textDecoration: "none",
      flex: 1,
    },
    test: {},
  });
  const classes = useStyles();
  useEffect(() => {
    if (effect) {
      dispatch(changeLocation({ location: effect }));
    }
  }, []);

  return (
    <div className={classes.footerArea} id="footerScroll">
      {location.location === "home" ? (
        <MenuFooter
          imageIcon={homepush}
          width={30}
          height={30}
          sWidth={23}
          sHeight={23}
        />
      ) : (
        <Link to="/" className={classes.link}>
          <MenuFooter
            imageIcon={home}
            width={30}
            height={30}
            sWidth={23}
            sHeight={23}
          />
        </Link>
      )}
      {/* {location.location === "product" ? (
        <MenuFooter
          imageIcon={Tpush}
          width={30}
          height={33}
          sWidth={23}
          sHeight={25}
        />
      ) : (
        <Link to="/productlist" className={classes.link}>
          <MenuFooter
            imageIcon={T}
            width={30}
            height={33}
            sWidth={23}
            sHeight={25}
          />
        </Link>
      )} */}
      {/* <PostFooter /> */}
      {location.location === "creatorList" ? (
        <MenuFooter
          imageIcon={MeMerIconpush}
          width={35}
          height={32}
          sWidth={25}
          sHeight={23}
        />
      ) : (
        <Link to="/creator-list" className={classes.link}>
          <MenuFooter
            imageIcon={MeMerIcon}
            width={35}
            height={32}
            sWidth={25}
            sHeight={23}
          />
        </Link>
      )}
      {/* Trueの場合はLink Falseの場合はModal */}
      {isSignedIn ? (
        location.location === "mypage" && uid === userData.uid ? (
          <MenuFooter
            imageIcon={Personpush}
            width={23}
            height={33}
            sWidth={17}
            sHeight={24}
          />
        ) : (
          <Link to={"/my-profile/" + userData.uid} className={classes.link}>
            <MenuFooter
              imageIcon={Person}
              width={23}
              height={33}
              sWidth={17}
              sHeight={24}
            />
          </Link>
        )
      ) : (
        <div
          className={classes.link}
          onClick={() => {
            setShow(true);
          }}
        >
          <MenuFooter
            imageIcon={Person}
            width={23}
            height={33}
            sWidth={17}
            sHeight={24}
          />
        </div>
      )}
      <LoginInfo isShow={show} setState={setShow} />
    </div>
  );
};
