import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import { addProjectIDActions } from "../../../features/projectPostOperation";
import { Project } from "../../../Types/projectType";
import { ProjectCard } from "../../../components/Creator/ProjectCard";

type Props = {
  project: Project;
  status: string;
};

export const ProjectCardList = (props: Props) => {
  const { project, status } = props;
  const dispatch = useDispatch();
  const { push } = useHistory();

  const coloring = () => {
    if (project.salesStatus.sale) {
      return "#edffff";
    } else if (project.salesStatus.underReservation) {
      return "#edffce";
    } else if (project.salesStatus.production) {
      return "#edaace";
    } else if (project.salesStatus.official) {
      return "#d74a1f";
    }
  };

  const useStyles = makeStyles({
    card: {
      height: "100%",
      borderRadius: 8,
      margin: 28,
      boxShadow: "0 0 8px rgba(0, 0, 0, .16)",
      display: "flex",
      color: "#212121",
      backgroundColor: coloring(),
      cursor: "pointer",
    },
  });
  const classes = useStyles();

  const postAction = () => {
    !project.salesStatus.endOfSale
      ? dispatch(
          addProjectIDActions(
            project.projectRef,
            project.numberToShip,
            project.reservations,
            project.salesStatus,
            project.projectName,
            status
          )
        )
      : alert("終了したProductionです");
  };

  const salesAction = () => {
    const uid = project.projectRef.split("/")[1];
    const pid = project.projectRef.split("/")[3];
    push(`/mypage/creator/${uid}/saleconfimation/${pid}`, project);
  };

  const statusCheck = (status: string) => {
    if (status === "newPost") {
      postAction();
    } else if (status === "salesCheck") {
      salesAction();
    }
  };

  return (
    <div className={classes.card} onClick={() => statusCheck(status)}>
      <ProjectCard project={project} />
    </div>
  );
};
