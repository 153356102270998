import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles, Typography } from "@material-ui/core";
import { Info } from "../../Types/userType";
import { infoAlreadyRead } from "../../features/socialOperation";
import { selectUser } from "../../features/userSlice";
import { SpaceBox } from "../UIKit/SpaceBox";
import { fetchInfo } from "../../features/postOperation";
import { RouteComponentProps } from "react-router-dom";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { toast } from "react-toastify";

export const SocialInfo = (props: RouteComponentProps) => {
  const { history } = props;
  const [info, setInfo] = useState<Info[]>([]);

  const inputInfo = useCallback((info) => {
    setInfo(info);
  }, []);

  useEffect(() => {
    const fetchInitInfo = async () => {
      try {
        await dispatch(fetchInfo(inputInfo));
      } catch (error) {
        toast.error("通知の取得に失敗しました。");
      }
    };
    let isMounted = true;
    if (isMounted) {
      fetchInitInfo();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const useStyles = makeStyles({
    scroll: {},
    infoArea: {
      borderBottom: "1px solid rgb(239, 243, 244)",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgb(235, 232, 232)",
      },
    },
    topArea: {
      height: 60,
    },
    bottomArea: {
      // height: 60,
    },
    imageArea: {
      width: 50,
      height: 50,
      border: "1px solid gray",
      borderRadius: 10,
      marginTop: 10,
      marginLeft: 10,
    },
    img: {
      objectFit: "cover",
      width: "100%",
      borderRadius: 10,
      cursor: "pointer",
    },
    text: {
      fontSize: "15px",
      margin: "5px 0 10px 20px",
    },
    textMiniSize: {
      margin: "5px 0 10px 20px",
      fontSize: "14px",
      color: "#333",
    },
    textTitle: {
      fontSize: "15px",
      marginLeft: 20,
    },
    boldText: {
      fontWeight: "bold",
    },
    info: {
      zIndex: 10,
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const user = useSelector(selectUser);
  useEffect(() => {
    dispatch(infoAlreadyRead(info));
  }, []);

  return (
    <div className={classes.info}>
      <BrowserBackHeader label="通知" browserBack={history} />
      {info.map((info: any, index: number) => (
        <div key={index} className={classes.infoArea}>
          <div className={classes.topArea}>
            <div className={classes.imageArea}>
              <img
                src={info.actionUser.image}
                className={classes.img}
                onClick={() =>
                  push(`/profile/${info.actionUser.uid}/${user.uid}`)
                }
                alt="userIcon"
              />
            </div>
          </div>
          <div className={classes.bottomArea}>
            {info.kinds === "follow" && (
              <Typography className={classes.text}>
                <span className={classes.boldText}>
                  {info.actionUser.displayName}
                </span>
                さんにフォローされました
              </Typography>
            )}
            {info.kinds === "comment" && (
              <>
                <Typography className={classes.text}>
                  <span className={classes.boldText}>
                    {info.actionUser.displayName}
                  </span>
                  さんがあなたの投稿にコメントしました
                </Typography>
              </>
            )}
            {info.kinds === "like" && (
              <>
                <Typography className={classes.text}>
                  <span className={classes.boldText}>
                    {info.actionUser.displayName}
                  </span>
                  さんがあなたの投稿にいいねをしました
                </Typography>

                <Typography className={classes.textTitle}>投稿内容</Typography>

                <Typography className={classes.textMiniSize}>
                  {info.postData.body}
                </Typography>
              </>
            )}
            {info.kinds === "want" && (
              <>
                <Typography className={classes.text}>
                  <span className={classes.boldText}>
                    {info.actionUser.displayName}
                  </span>
                  さんがあなたの投稿にほしいを押しました
                </Typography>

                <Typography className={classes.textTitle}>投稿内容</Typography>

                <Typography className={classes.textMiniSize}>
                  {info.postData.body}
                </Typography>
              </>
            )}
            {info.kinds === "mention" && (
              <Typography className={classes.text}>
                <span className={classes.boldText}>
                  {info.actionUser.displayName}
                </span>
                さんがあなたをメンションをして投稿しました
              </Typography>
            )}
          </div>
        </div>
      ))}
      <SpaceBox height={41} />
    </div>
  );
};
