import React, { useState } from "react";
import { makeStyles, Radio, Typography } from "@material-ui/core";
import { GrayBack } from "../MyPage/GrayBack";
import { Credit } from "../../Types/paymentType";
import CreditCardViesList from "./CreditCardViesList";

const UseCreditChange = (props: any) => {
  const { useCreditList, setUseCredit, setOpenCreditModal } = props;
  const useStyles = makeStyles({
    creditInfoArea: {
      display: "flex",
      height: 66,
      backgroundColor: "white",
    },
    left: {
      flex: 1,
    },
    center: {
      flex: 4.5,
    },
    centerTop: {
      height: 33,
    },
    centerBottom: {
      height: 33,
    },
    radio: {
      margin: "13px auto 0",
      display: "block",
    },
    btn: {
      margin: "15px auto 0",
      display: "block",
    },
    textTop: {
      lineHeight: 4,
      fontSize: 12,
    },
    textBottom: {
      // lineHeight: 3,
      fontSize: 12,
    },
    leftCardArea: {
      flex: 2,
    },
    icon: {
      width: 100,
      height: 100,
      objectFit: "cover",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    infoText: {
      fontSize: 14,
    },
    changeBtn: {
      border: "1px solid gray",
      width: 75,
      margin: "15px auto 0",
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        backgroundColor: "rgb(234,234,234)",
      },
    },
    creditChangeBackground: {
      backgroundColor: "white",
      width: 350,
      padding: 20,
    },
    changeBtnDisable: {
      border: "1px solid gray",
      width: 150,
      margin: "15px auto 0",
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        backgroundColor: "rgb(234,234,234)",
      },
    },
  });
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("0");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const changeUseCredit = () => {
    setUseCredit(useCreditList[Number(selectedValue) - 1]);
    setOpenCreditModal((prev: boolean) => !prev);
  };

  // let hideCredit: Credit[] = [];
  // const hideCreditNumber = changeCredit.map((c: Credit, idx: number) => {
  //   let { creditNumber, ...omitData } = c;
  //   const hideNumber = creditNumber?.slice(-4);
  //   const newBody = { ...omitData, hideNumber };
  //   hideCredit.push(newBody);
  // });

  return (
    <div className={classes.creditChangeBackground}>
      {useCreditList.map((c: Credit, index: number) => (
        <div>
          <div className={classes.creditInfoArea}>
            <div className={classes.left}>
              <Radio
                checked={selectedValue === String(index + 1)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                value={String(index + 1)}
                color="default"
                name="radio-button-demo"
                inputProps={{ "aria-label": "1" }}
                className={classes.radio}
              />
            </div>
            <div className={classes.leftCardArea}>
              <CreditCardViesList creditBrand={c.brand} margin={"15px 0 0"} />
            </div>
            <div className={classes.center}>
              <div className={classes.centerTop}>
                <Typography
                  className={classes.textTop}
                >{`****-****-****-${c.last4}`}</Typography>
              </div>
              <div className={classes.centerBottom}>
                <Typography
                  className={classes.textBottom}
                >{`有効期限 ${c.expYear}/${c.expMonth}`}</Typography>
              </div>
            </div>
            {/* <div className={classes.right}>
              <Button variant='contained' className={classes.btn} onClick={() => handleDeleteOpen(index)}>
                削除
              </Button>
            </div> */}
          </div>
          <GrayBack height={5} />
        </div>
      ))}
      {selectedValue !== "0" ? (
        <div className={classes.changeBtn}>
          <Typography
            className={classes.infoText}
            align="center"
            onClick={() => changeUseCredit()}
          >
            決定する
          </Typography>
        </div>
      ) : (
        <div className={classes.changeBtnDisable}>
          <Typography
            className={classes.infoText}
            align="center"
            onClick={() => setOpenCreditModal(false)}
          >
            変更しない
          </Typography>
        </div>
      )}
    </div>
  );
};

export default UseCreditChange;
