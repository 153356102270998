import { RouteComponentProps } from "react-router";
import { ReserveProducts } from "./ReserveParts/ReserveProducts";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";

export const ReserveList = (props: RouteComponentProps) => {
  const { history } = props;

  return (
    <div >
      <BrowserBackHeader label="商品予約履歴一覧" browserBack={history} />
      <ReserveProducts />
    </div>
  );
};
