import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import { StripeCreditCreate } from "../components/Payment/StripeCreditCreate";
import { RouteComponentProps } from "react-router-dom";
import { getStripePublicKey } from "../function/common";

const stripePromise = loadStripe(getStripePublicKey());

export const PaymentCheckoutWrapper = (
  props: RouteComponentProps<{ uid: string }>
) => {
  const { history, match } = props;
  const uid = match.params.uid;
  const salesProject = history.location.state;
  return (
    <Elements stripe={stripePromise}>
      <StripeCreditCreate
        uid={uid}
        history={history}
        salesProject={salesProject}
      />
    </Elements>
  );
};
