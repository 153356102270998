import { CartProduct } from "../../Types/productType";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import CartItemMulti from "./CartItemMulti";
import TotalInCart from "./TotalInCart";

interface PROPS {
  cartData: CartProduct[];
  cartStock?: number[];
  deleteBoolLength: number;
  setCartStock?: Function;
}
const CartDetaile = (props: PROPS) => {
  const cartData = props.cartData;
  const cartStock = props.cartStock;
  return (
    <div>
      {cartData.map((cd: CartProduct, index: number) => (
        <div key={index}>
          <CartItemMulti
            cartData={cd}
            deleteBoolLength={props.deleteBoolLength}
            cartStock={cartStock}
            index={index}
            setCartStock={props.setCartStock}
          />
        </div>
      ))}
      <SpaceBox height={50} />
      {cartData.length > 0 && <TotalInCart cartData={cartData} />}
    </div>
  );
};

export default CartDetaile;
