import { makeStyles } from "@material-ui/core";

type Props = {
  displayName: string;
  margin?: string;
  marginTop?: number | string;
  textAlign?: string;
  fontSize?: string | number;
  color?: string;
  fontWeight?: string;
};

export const Name = (props: Props): JSX.Element => {
  const {
    displayName,
    margin,
    marginTop,
    textAlign,
    fontSize,
    color,
    fontWeight,
  } = props;
  const useStyles = makeStyles({
    nameArea: {
      cursor: "none",
      "& p": {
        margin: margin,
        marginTop: marginTop,
        textAlign: textAlign ? textAlign : "center",
        fontSize: fontSize,
        // color: color ? color : "rgb(122,122,122)",
        fontWeight: fontWeight,
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.nameArea}>
      <p>{displayName}</p>
    </div>
  );
};
