import { Typography, makeStyles } from "@material-ui/core";
import { LatestColorSalesInfo, SalesStatus } from "../../../Types/productType";

type Props = {
  isAdded?: boolean;
  salesDetailData?: LatestColorSalesInfo[];
  minimumProductionQuantity?: number;
  salesStatus?: SalesStatus;
};

export const ProductSaleInfo = (props: Props) => {
  const { isAdded, salesDetailData, minimumProductionQuantity, salesStatus } =
    props;
  const useStyles = makeStyles({
    frame: {
      margin: "5px 15px",
      border: "solid 1px red",
      padding: 15,
      marginTop: 40,
    },
    info: {
      color: "red",
      fontSize: 14,
    },
    addedInfo: {
      color: "red",
      fontSize: 18,
      fontWeight: "bold",
    },
    cancelInfo: {
      color: "red",
    },
  });
  const classes = useStyles();

  const addedDom = () => {
    return (
      <Typography className={classes.addedInfo} align="center">
        好評につき予約可能枚数を増やしました。
      </Typography>
    );
  };

  const defaultDom = () => {
    return (
      <Typography className={classes.info}>
        この商品は生産確定前の商品になります。
      </Typography>
    );
  };

  const goalDom = () => {
    return (
      <Typography className={classes.addedInfo} align="center">
        生産目標達成！！！
      </Typography>
    );
  };

  const productionDom = () => {
    return (
      <Typography className={classes.addedInfo} align="center">
        生産進行中
      </Typography>
    );
  };
  const cancelDom = () => {
    return (
      <>
        <Typography className={classes.cancelInfo} align="center">
          残念ながらこのプロジェクトは、
        </Typography>
        <Typography className={classes.cancelInfo} align="center">
          生産目標未達成のため中止になりました。
        </Typography>
      </>
    );
  };

  const selectedDom = () => {
    if (salesStatus?.cancel) return cancelDom();
    const reserveQuantity = salesDetailData?.reduce((acc, curr) => {
      return acc + curr.reserve;
    }, 0);

    if (minimumProductionQuantity && reserveQuantity) {
      if (
        salesStatus?.inProduction &&
        minimumProductionQuantity <= reserveQuantity
      )
        return productionDom();
      if (isAdded && minimumProductionQuantity <= reserveQuantity)
        return addedDom();

      if (
        !salesStatus?.inProduction &&
        minimumProductionQuantity <= reserveQuantity
      )
        return goalDom();
    }

    return defaultDom();
  };

  return <div className={classes.frame}>{selectedDom()}</div>;
};
