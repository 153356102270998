import { useState } from "react";
import { useSelector } from "react-redux";
import { Switch, Typography, makeStyles } from "@material-ui/core";
import { GrayBack } from "../MyPage/GrayBack";
import { DeliveryInput } from "./DeliveryInput";
import { CityInput } from "./CityInput";
import { Zipcode } from "./Zipcode";
import { selectAddress } from "../../features/userSlice";
import { isMainAddressCheck } from "../../features/addressOperation";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { PrefectureArray } from "../../features/prefectures";
import { Prefecture } from "../../Types/deliveryType";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Center } from "../atoms/Layout/Center";
import { Text } from "../atoms/Text/Text";

export const EditAddress = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    checkInput: {
      display: "none",
    },
    checked: {
      width: "100%",
      height: 50,
      backgroundColor: "lightcyan",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    unChecked: {
      width: "100%",
      height: 50,
      backgroundColor: "white",
      "& p": {
        fontSize: 14,
        margin: "0 0 0 0",
        marginLeft: 30,
        lineHeight: 3.5,
      },
    },
    buttonArea: {
      backgroundColor: "rgb(245,245,245)",
    },
    btn: {
      display: "none",
    },
    prefecturesContainer: {
      display: "flex",
    },
    prefecturesLeft: {
      flex: 6,
      "& p": {
        fontSize: 13,
        margin: "13px 0 8px 5px",
      },
    },
    prefecturesRight: {
      flex: 4,
    },
    labelRed: {
      color: "red",
    },
    prefecturesSelect: {
      width: "100%",
      marginTop: 7,
    },
    selectBox: {
      width: 200,
      height: 45,
      fontSize: 16,
      border: "none",
      borderBottom: "1px solid rgb(151,151,151)",
    },
    inputArea: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });
  const classes = useStyles();
  const editAddress = useSelector(selectAddress);
  const { push } = useHistory();

  const [mainAddress, setMainAddress] = useState(editAddress.mainAddress);

  const defaultValues = {
    lastName: editAddress.lastName,
    firstName: editAddress.firstName,
    lastNameKana: editAddress.lastNameKana,
    firstNameKana: editAddress.firstNameKana,
    zipcode: editAddress.zipcode,
    pref: editAddress.prefecture,
    city: editAddress.city,
    town: editAddress.town,
    address: editAddress.address,
    buildingName: editAddress.buildingName,
    phoneNumber: editAddress.phoneNumber,
    mainAddress: editAddress.mainAddress,
  };

  const methods = useForm({ defaultValues });
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  // 数字のみの正規表現変数
  const numberRegExp = /^[0-9]+$/;

  const onSubmit = async (data: any) => {
    await isMainAddressCheck({
      lastName: data.lastName,
      firstName: data.firstName,
      lastNameKana: data.lastNameKana,
      firstNameKana: data.firstNameKana,
      zipcode: data.zipcode,
      prefecture: data.pref,
      city: data.city,
      town: data.town,
      address: data.address,
      buildingName: data.buildingName,
      phoneNumber: data.phoneNumber,
      mainAddress: mainAddress,
      aid: editAddress.aid,
    });

    push("/mypage/delivery-list");
  };
  const submitClick = () => {
    const btn = document.getElementById("none");
    btn?.click();
  };
  const switchCheck = () => {
    setMainAddress(!mainAddress);
  };

  const switchText = () => {
    if (mainAddress) return "ON";
    return "OFF";
  };

  return (
    <FormProvider {...methods}>
      <div>
        <BrowserBackHeader label="住所編集" browserBack={history} />
        {editAddress.address ? (
          <>
            <GrayBack height={40} label="氏名入力" fontSize="12px" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                rules={{
                  required: "　入力必須です",
                }}
                render={({ field }) => (
                  <DeliveryInput
                    infoLabel=" 姓"
                    labelRed="※必須"
                    place=" 例) 渋谷"
                    field={field}
                    error={errors.lastName}
                  />
                )}
                control={control}
                name="lastName"
              />
              <Controller
                rules={{
                  required: "　入力必須です",
                }}
                render={({ field }) => (
                  <DeliveryInput
                    infoLabel=" 名"
                    labelRed="※必須"
                    place=" 例) 渋子"
                    field={field}
                    error={errors.firstName}
                  />
                )}
                control={control}
                name="firstName"
              />
              <Controller
                rules={{
                  required: "　入力必須です",
                }}
                render={({ field }) => (
                  <DeliveryInput
                    infoLabel=" セイ"
                    labelRed="※必須"
                    place=" 例) シブヤ"
                    field={field}
                    error={errors.lastNameKana}
                  />
                )}
                control={control}
                name="lastNameKana"
              />
              <Controller
                rules={{
                  required: "　入力必須です",
                }}
                render={({ field }) => (
                  <DeliveryInput
                    infoLabel=" メイ"
                    labelRed="※必須"
                    place=" 例) シブコ"
                    field={field}
                    error={errors.firstNameKana}
                  />
                )}
                control={control}
                name="firstNameKana"
              />
              <GrayBack height={35} label="住所入力" fontSize="12px" />
              <Controller
                rules={{
                  required: "　入力必須です",
                  pattern: {
                    value: numberRegExp,
                    message: "　数字で入力してください",
                  },
                  minLength: {
                    value: 7,
                    message: "　7文字で入力してください",
                  },
                  maxLength: {
                    value: 7,
                    message: "　7文字で入力してください",
                  },
                }}
                render={({ field }) => (
                  <Zipcode
                    infoLabel="郵便番号（ハイフンなし）"
                    labelRed="※必須"
                    place=" 例) 1500001"
                    field={field}
                    setValue={setValue}
                    error={errors.zipcode}
                  />
                )}
                control={control}
                name="zipcode"
              />
              <div className={classes.prefecturesContainer}>
                <div className={classes.prefecturesLeft}>
                  <p>
                    都道府県
                    <span className={classes.labelRed}>　※必須</span>
                    {errors.pref && (
                      <span className={classes.labelRed}>
                        {errors.pref.message}
                      </span>
                    )}
                  </p>
                </div>
                <div className={classes.prefecturesRight}>
                  <select
                    className={classes.selectBox}
                    disabled
                    {...register("pref", { required: "　必須です。" })}
                  >
                    {PrefectureArray.map((pref: Prefecture) => (
                      <option value={pref.value}>{pref.value}</option>
                    ))}
                  </select>
                </div>
              </div>
              <Controller
                rules={{
                  required: "　入力必須です",
                }}
                render={({ field }) => (
                  <CityInput
                    infoLabel=" 市区町村"
                    labelRed="※必須"
                    place=" 例 渋谷区渋谷"
                    field={field}
                    error={errors.city}
                    disabled
                  />
                )}
                control={control}
                name="city"
              />
              <Controller
                rules={{
                  required: "　入力必須です",
                }}
                render={({ field }) => (
                  <CityInput
                    infoLabel=" 町名"
                    labelRed="※必須"
                    place=" 例 渋谷"
                    field={field}
                    error={errors.town}
                    disabled
                  />
                )}
                control={control}
                name="town"
              />
              <Controller
                rules={{
                  required: "　入力必須です",
                  validate: (value) => {
                    return (
                      !/\s/.test(value) || " スペースを含めることはできません"
                    );
                  },
                }}
                render={({ field }) => (
                  <DeliveryInput
                    infoLabel=" 番地"
                    labelRed="※必須"
                    place=" 例) 1-23-20"
                    field={field}
                    error={errors.address}
                  />
                )}
                control={control}
                name="address"
              />
              <Controller
                render={({ field }) => (
                  <DeliveryInput
                    infoLabel=" 建物名・部屋番号"
                    place=" 例) 松本ビル601"
                    field={field}
                  />
                )}
                control={control}
                name="buildingName"
              />
              <Controller
                rules={{
                  required: "　入力必須です",
                  pattern: {
                    value: numberRegExp,
                    message: "　数字で入力してください",
                  },
                  maxLength: {
                    value: 11,
                    message: "　桁が多いです",
                  },
                  minLength: {
                    value: 10,
                    message: "　桁が足りません",
                  },
                }}
                render={({ field }) => (
                  <DeliveryInput
                    infoLabel=" 電話番号（ハイフンなし）"
                    labelRed="※必須"
                    place=" 例) 09011112222"
                    field={field}
                    error={errors.phoneNumber}
                  />
                )}
                control={control}
                name="phoneNumber"
              />
              <GrayBack height={60} />
              <div className={classes.inputArea}>
                <Text
                  text={`メインの住所に設定する(${switchText()})`}
                  className={{
                    TextAlign: "center",
                  }}
                />
                <Switch
                  onChange={() => {
                    switchCheck();
                  }}
                  color="primary"
                  value={mainAddress}
                />
              </div>
              <GrayBack height={60} />
              <div className={classes.buttonArea}>
                <Center>
                  <GrayButton width={250} onClick={() => submitClick()}>
                    更新する
                  </GrayButton>
                </Center>
                <input type="submit" id="none" className={classes.btn} />
              </div>
            </form>
          </>
        ) : (
          <Typography
            align="center"
            style={{
              margin: "30px 0",
            }}
          >
            前のページに戻ってやり直してください。
          </Typography>
        )}
      </div>
      <GrayBack height={150} />
    </FormProvider>
  );
};
