import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const MentionToSkeleton = () => {
  const useStyles = makeStyles({
    card: {
      height: 70,
      boxShadow: "0 8px 40px -12px rgba(0, 0, 0, 0.3)",
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
      },
    },
    userInfoBar: {
      height: 70,
      display: "flex",
    },
    userName: {
      margin: "6px 0 10px 10px",
      flex: 1,
    },
    Name: {
      lineHeight: 3,
      fontSize: 12,
      color: "gray",
    },

    userIcon: {
      width: 60,
      margin: "6px 0 10px 40px",
    },

    iconSkeleton: {
      width: 58,
      height: 58,
      borderRadius: 10,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.userInfoBar}>
        <div className={classes.userIcon}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.iconSkeleton}
          />
        </div>
        <div className={classes.userName}>
          <Skeleton
            animation="wave"
            height={10}
            width={60}
            style={{ marginTop: 24 }}
          />
        </div>
      </div>
    </div>
  );
};

export default MentionToSkeleton;
