import { makeStyles, Typography } from "@material-ui/core";
import { SpaceBox } from "../UIKit/SpaceBox";

type Props = {
  bottomProfile: JSX.Element[] | string;
};

export const SelfIntroduction = (props: Props) => {
  const { bottomProfile } = props;
  const useStyles = makeStyles({
    selfIntroduction: {
      backgroundColor: "white",
      width: 500,
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
      marginBottom: 20,
    },
    introductionArea: {
      margin: "0 auto",
      width: "70%",
      "& p": {
        fontSize: 13,
      },
    },
    profileText: {
      "& p": {
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    text: {
      lineHeight: 1.8,
    },
  });
  const classes = useStyles();
  return (
    <>
      <SpaceBox height={30} backgroundColor={"white"} />
      <div className={classes.selfIntroduction}>
        <div className={classes.profileText}>
          <Typography>PROFILE</Typography>
        </div>
        <SpaceBox height={10} />
        <div className={classes.introductionArea}>
          {/* ここに自己紹介書く */}
          <Typography className={classes.text}>{bottomProfile}</Typography>
        </div>
      </div>
    </>
  );
};
