import { Typography, makeStyles } from "@material-ui/core";
import { newLineConvertWithUrl } from "../../../features/helpers/textHelper";

type Props = {
  title: string;
  text: string;
};

export const ProductExplanation = (props: Props) => {
  const { title, text } = props;
  const useStyles = makeStyles({
    explanationBox: {
      marginTop: 40,
      padding: "0 40px",
      "@media screen and (max-width: 499px)": {
        padding: "0 20px",
      },
    },
    titleText: {
      fontSize: 14,
      fontWeight: "bold",
    },
    explanation: {
      marginTop: 20,
      fontSize: 14,
      wordBreak: "break-all",
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.explanationBox}>
      <Typography align="center" className={classes.titleText}>
        {title}
      </Typography>
      <Typography className={classes.explanation}>
        {newLineConvertWithUrl(text)}
      </Typography>
    </div>
  );
};
