import { makeStyles, Typography } from "@material-ui/core";
import {
  calculationPrice,
  isWithinPeriod,
} from "../../../features/helpers/projectHelper";

type Props = {
  price: number;
  startDiscountPeriod?: string;
  endDiscountPeriod?: string;
  periodDiscountRate?: number;
};
export const ProductListPrice = (props: Props) => {
  const { price, startDiscountPeriod, endDiscountPeriod, periodDiscountRate } =
    props;
  const useStyles = makeStyles({
    priceArea: {
      display: "flex",
      margin: "5px 10px 10px 10px",
      gap: 4,
    },
    price: {
      color: "rgb(80, 80, 80)",
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    beforeDiscountPrice: {
      color: "rgb(80, 80, 80)",
      fontSize: 14,
      textDecoration: "line-through",
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    discountRate: {
      backgroundColor: "#ED1A3D",
      padding: "0 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      opacity: 0.8,
    },
    arrow: {
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
  });
  const classes = useStyles();

  const isPeriod = isWithinPeriod(
    startDiscountPeriod,
    endDiscountPeriod,
    periodDiscountRate
  );

  const calcPrice = calculationPrice(
    String(price),
    startDiscountPeriod,
    endDiscountPeriod,
    periodDiscountRate
  );

  return (
    <div className={classes.priceArea}>
      {isPeriod && (
        <>
          <Typography
            className={classes.beforeDiscountPrice}
          >{`¥ ${price.toLocaleString()}`}</Typography>
          <span className={classes.arrow}>→</span>
        </>
      )}
      <Typography
        className={classes.price}
      >{`¥ ${calcPrice.toLocaleString()}(税込)`}</Typography>
    </div>
  );
};
