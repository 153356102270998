import { makeStyles, Typography } from "@material-ui/core";
import { SpaceBox } from "../../UIKit/SpaceBox";

interface PROPS {
  label: string;
}
const MidInfo = (props: PROPS) => {
  const useStyles = makeStyles({
    infoArea: {
      padding: "0 15px 0 15px",
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.infoArea}>
      <Typography style={{ fontSize: 13, color: "gray" }}>
        {props.label}
      </Typography>
      <SpaceBox height={10} />
    </div>
  );
};

export default MidInfo;
