import { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SpaceBox } from "../../UIKit/SpaceBox";

interface PROPS {
  setBeginningMonth: Function;
  setEndOfMonth: Function;
}
const RewardHistory = (props: PROPS) => {
  const { setBeginningMonth, setEndOfMonth } = props;
  const useStyles = makeStyles({
    gray: {
      paddingTop: 20,
      paddingBottom: 20,
      backgroundColor: "rgb(235,232,232)",
    },
    text: {},
    blueText: {
      color: "rgb(71,107,227)",
      cursor: "pointer",
    },
    dateArea: {
      display: "flex",
    },
    left: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
    dateSpace: {
      margin: "0 auto",
      display: "grid",
      textAlign: "center",
      justifyItems: "center",
      alignItems: "center",
    },
    right: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
    center: {},
  });
  const classes = useStyles();
  const Today = new Date();
  const [startDate, setStartDate] = useState(Today);
  const [endDate, setEndDate] = useState(Today);

  const historyRewardSearch = () => {
    const startDateResetTime = new Date(startDate.setHours(0, 0, 0));
    const endDateResetTime = new Date(endDate.setHours(0, 0, 0));

    setBeginningMonth(startDateResetTime);
    setEndOfMonth(endDateResetTime);
  };
  return (
    <div className={classes.gray}>
      <Typography align="center" className={classes.text}>
        過去の報酬履歴
      </Typography>
      <SpaceBox height={15} />
      <div className={classes.dateArea}>
        <div className={classes.left}>
          <ReactDatePicker
            className={classes.dateSpace}
            dateFormat="yyyy/MM/dd"
            selected={startDate}
            onChange={(selectedDate) => {
              setStartDate(selectedDate || Today);
            }}
          />
        </div>
        <div className={classes.center}>〜</div>
        <div className={classes.right}>
          <ReactDatePicker
            className={classes.dateSpace}
            dateFormat="yyyy/MM/dd"
            selected={endDate}
            onChange={(selectedDate) => {
              setEndDate(selectedDate || Today);
            }}
          />
        </div>
      </div>
      <SpaceBox height={15} />
      <Typography
        align={"center"}
        className={classes.blueText}
        onClick={() => historyRewardSearch()}
      >
        表示する
      </Typography>
    </div>
  );
};

export default RewardHistory;
