import { makeStyles, Typography } from "@material-ui/core";

type Props = {
  label: string;
};

export const ReserveGrayBack = (props: Props) => {
  const { label } = props;
  const useStyles = makeStyles({
    grayTitle: {
      height: 25,
      paddingTop: 20,
    },
    text: {
      marginLeft: 10,
      fontSize: 15,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.grayTitle}>
      <Typography className={classes.text}>{label}</Typography>
    </div>
  );
};
