import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

const initialPost = {
  title: "",
  body: "",
  images: [],
  path: "",
  id: "",
  mentionTos: [],
  twitterShare: "",
  facebookShare: "",
  instagramShare: "",
  tiktokShare: "",
};

// Postを格納するやつ
const fetchPostType = [
  {
    author: "",
    body: "",
    createdAt: { seconds: 0, nanoseconds: 0 },
    images: [],
    likeCount: 0,
    mentionTo: [],
    numberToShip: 0,
    postID: "",
    projectPostRef: "",
    projectRef: "",
    reservations: 0,
    search: true,

    title: "",
    uid: "",
    userFollowed: false,
    userInfo: {
      displayName: "",
      photoUrl: "",
      projectRef: "",
      uid: "",
    },
    salesStatus: {},
    userLiked: false,
    userWanted: false,
    wantCount: 0,
  },
];
// 取得している最後のPost
const lastDatePostType = {
  author: "",
  body: "",
  createdAt: { seconds: 0, nanoseconds: 0 },
  images: [],
  likeCount: 0,
  mentionTo: [],
  numberToShip: 0,
  postID: "",
  projectPostRef: "",
  projectRef: "",
  reservations: 0,
  search: true,

  title: "",
  uid: "",
  userFollowed: false,
  userInfo: {
    displayName: "",
    photoUrl: "",
    projectRef: "",
    uid: "",
  },
  userLiked: false,
  userWanted: false,
  wantCount: 0,
};

// 取得している最後のWant情報
const lastWantDatePostType = {
  createdAt: { seconds: 0, nanoseconds: 0 },
  id: "",
  postRef: "",
};

export const postSlice = createSlice({
  name: "post",
  initialState: {
    post: initialPost,
    // ProjectPost
    fetchProjectPost: fetchPostType,
    lastDateProjectPost: lastDatePostType,
    oldestProjectId: "",
    checkProjectPostId: [],
    // AllPost
    fetchPost: fetchPostType,
    lastDatePost: lastDatePostType,
    oldestId: "",
    checkPostId: [],
    // WantPost
    fetchWantPost: fetchPostType,
    lastWantDatePost: lastWantDatePostType,
    oldestWantId: "",
    checkWantPostId: [],
    // SortPost
    fetchSortPost: fetchPostType,
    lastDateSortPost: lastDatePostType,
    oldestSortId: "",
    checkSortPostId: [],
  },
  reducers: {
    // ProjectPostReducer
    fetchProjectPost: (state, action) => {
      state.fetchProjectPost = action.payload;
    },
    fetchProjectLastPost: (state, action) => {
      state.lastDateProjectPost = action.payload;
    },
    fetchOldestProjectId: (state, action) => {
      state.oldestProjectId = action.payload;
    },
    fetchCheckProjectPostId: (state, action) => {
      state.checkProjectPostId = action.payload;
    },
    // AllPostReducer
    fetchPost: (state, action) => {
      state.fetchPost = action.payload;
    },
    fetchLastPost: (state, action) => {
      state.lastDatePost = action.payload;
    },
    fetchOldestId: (state, action) => {
      state.oldestId = action.payload;
    },
    fetchCheckPostId: (state, action) => {
      state.checkPostId = action.payload;
    },
    // WantPostReducer
    fetchWantPost: (state, action) => {
      state.fetchWantPost = action.payload;
    },
    fetchWantLastPost: (state, action) => {
      state.lastWantDatePost = action.payload;
    },
    fetchOldestWantId: (state, action) => {
      state.oldestWantId = action.payload;
    },
    fetchCheckWantPostId: (state, action) => {
      state.checkWantPostId = action.payload;
    },
    // SortPostReducer
    fetchSortPost: (state, action) => {
      state.fetchSortPost = action.payload;
    },
    fetchSortLastPost: (state, action) => {
      state.lastDateSortPost = action.payload;
    },
    fetchOldestSortId: (state, action) => {
      state.oldestSortId = action.payload;
    },
    fetchCheckSortPostId: (state, action) => {
      state.checkSortPostId = action.payload;
    },
    addMentionTo: (state, action) => {
      state.post.mentionTos = action.payload.mentionTos;
      return state;
    },
    addImages: (state, action) => {
      state.post.images = action.payload.images;
    },
    resetState: (state) => {
      state.post = {
        title: "",
        body: "",
        images: [],
        path: "",
        id: "",
        mentionTos: [],
        twitterShare: "",
        facebookShare: "",
        instagramShare: "",
        tiktokShare: "",
      };
    },
  },
});
export const {
  addMentionTo,
  addImages,
  resetState,
  fetchProjectPost,
  fetchProjectLastPost,
  fetchOldestProjectId,
  fetchCheckProjectPostId,
  fetchPost,
  fetchLastPost,
  fetchOldestId,
  fetchCheckPostId,
  fetchWantPost,
  fetchWantLastPost,
  fetchOldestWantId,
  fetchCheckWantPostId,
  fetchSortPost,
  fetchSortLastPost,
  fetchOldestSortId,
  fetchCheckSortPostId,
} = postSlice.actions;
export const selectPost = (state: RootState) => state.post.post;
// ProjectPostSelector
export const selectFetchProjectPost = (state: RootState) =>
  state.post.fetchProjectPost;
export const selectFetchProjectLastPost = (state: RootState) =>
  state.post.lastDateProjectPost;
export const selectFetchOldestProjectId = (state: RootState) =>
  state.post.oldestProjectId;
export const selectFetchCheckProjectPostId = (state: RootState) =>
  state.post.checkProjectPostId;
// AllPostSelector
export const selectFetchPost = (state: RootState) => state.post.fetchPost;
export const selectFetchLastPost = (state: RootState) =>
  state.post.lastDatePost;
export const selectFetchOldestId = (state: RootState) => state.post.oldestId;
export const selectFetchCheckPostId = (state: RootState) =>
  state.post.checkPostId;
// WantPostSelector
export const selectFetchWantPost = (state: RootState) =>
  state.post.fetchWantPost;
export const selectFetchWantLastPost = (state: RootState) =>
  state.post.lastWantDatePost;
export const selectFetchOldestWantId = (state: RootState) =>
  state.post.oldestWantId;
export const selectFetchCheckWantPostId = (state: RootState) =>
  state.post.checkWantPostId;
// SortPostSelector
export const selectFetchSortPost = (state: RootState) =>
  state.post.fetchSortPost;
export const selectFetchSortLastPost = (state: RootState) =>
  state.post.lastDateSortPost;
export const selectFetchOldestSortId = (state: RootState) =>
  state.post.oldestSortId;
export const selectFetchCheckSortPostId = (state: RootState) =>
  state.post.checkSortPostId;
export default postSlice.reducer;
