import React from "react";
import { UserIcon } from "../UIKit/UserIcon";
import { Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createStyles } from "@material-ui/core";
import { refreshLoading } from "../../features/loadingOperation";

type Props = {
  uid: string;
  creatorUid: string;
  creatorPhotoUrl: string;
  creatorDisplayName: string;
};

export const WantUserInfo = React.memo((props: Props) => {
  const { uid, creatorUid, creatorPhotoUrl, creatorDisplayName } = props;
  const dispatch = useDispatch();

  const useStyles = makeStyles((Theme: any) =>
    createStyles({
      userInfoBar: {
        height: 70,
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 499px)": {
          height: 50,
        },
      },
      userName: {
        flex: 5,
        display: "flex",
        alignItems: "center",
        marginTop: 10,
        marginLeft: 10,
      },
      Name: {
        lineHeight: 3,
        fontSize: 12,
        color: "gray",
      },
      "MuiGridListTile-root": {
        overflow: "hidden",
      },
    })
  );
  const classes = useStyles();

  return (
    <div className={classes.userInfoBar}>
      <Link
        to={`/profile/${creatorUid}/${uid}`}
        onClick={() =>
          dispatch(refreshLoading(`/profile/${creatorUid}/${uid}`))
        }
      >
        <UserIcon
          photoUrl={creatorPhotoUrl}
          width={58}
          height={58}
          sWidth={48}
          sHeight={48}
          margin="10px 0 0 10px"
        />
      </Link>
      <div className={classes.userName}>
        <Typography className={classes.Name}>{creatorDisplayName}</Typography>
      </div>
    </div>
  );
});
