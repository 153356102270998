import * as Path from "../refPath";
import { db, auth, FirebaseTimestampType } from "../firebase/index";
import { AppThunk } from "../app/store";
import { Post, MentionUserData, User } from "../Types/userType";
import {
  addProjectID,
  addProjectMentionTo,
  addProjectImages,
  resetProjectState,
} from "./projectPostSlice";
import { push } from "connected-react-router";
import { fetchFirstProjectsPosts } from "./postOperation";
import { squeezeChange } from "./userSlice";
import { Project } from "../Types/projectType";
import { Images } from "../Types/imageType";
import {
  getDocumentRef,
  projectsCollection,
  userDocument,
  usersCollection,
  wantedProjectDocument,
  wantedProjectsCollection,
} from "../firebasePaths";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from "firebase/firestore";

const userRef = usersCollection();

export const fetchProjects = (memerID: string, setProject: Function) => {
  const projectRef = projectsCollection(memerID);
  const q = query(projectRef, orderBy("createdAt", "desc"));
  getDocs(q).then((snapshot) => {
    const projectResults: any[] = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      projectResults.push(data);
    });
    setProject(projectResults);
  });
};

// export const sellingProjects = async (
//   memerID: string,
//   setProject: React.Dispatch<React.SetStateAction<Project[]>>
// ) => {
//   // const projectPath = Path.projects(memerID);
//   // const projectRef = db.collection(projectPath);
//   const projectRef = projectsCollection(memerID); //Path.projects(memerID);
//   const projectResults: Project[] = [];
//   // const q = query(
//   //   projectRef,
//   //   orderBy("createdAt", "desc"),
//   //   where("salesStatus.endOfSale", "==", true)
//   // );
//   // await getDocs(q)
//     await projectRef
//       .orderBy("createdAt", "desc")
//       .where("salesStatus.endOfSale", "==", true)
//       .get()
//     .then((snapshot) => {
//       snapshot.forEach((doc) => {
//         const data = doc.data() as Project;
//         projectResults.push(data);
//       });
//     });
//   await projectRef
//     .orderBy("createdAt", "desc")
//     .where("salesStatus.sale", "==", true)
//     .get()
//     .then((snapshot) => {
//       snapshot.forEach((doc) => {
//         const data = doc.data() as Project;
//         projectResults.push(data);
//       });
//     });

//   projectResults.sort((a, b) => {
//     const dateA = new Date(
//       a.createdAt.seconds * 1000 + Math.floor(a.createdAt.nanoseconds / 1000000)
//     );
//     const dateB = new Date(
//       b.createdAt.seconds * 1000 + Math.floor(b.createdAt.nanoseconds / 1000000)
//     );
//     return dateB.getTime() - dateA.getTime();
//   });
//   setProject(projectResults);
// };

export const addProjectIDActions = (
  projectRef: string,
  numberToShip: number,
  reservations: number,
  salesStatus: {
    production: boolean;
    underReservation: boolean;
    sale: boolean;
    endOfSale: boolean;
  },
  projectName: string,
  status: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    if (status === "newPost") {
      dispatch(
        addProjectID({
          projectID: projectRef,
          numberToShip: numberToShip,
          reservations: reservations,
          salesStatus,
          projectName: projectName,
        })
      );
      dispatch(push("/post/mention-to"));
    }
    if (status === "salesCheck") {
    }
  };
};

export const addProjectPostStoreMentionTo = (
  mentionTos: MentionUserData[]
): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(addProjectMentionTo({ mentionTos }));
    dispatch(push("/post/select-image"));
  };
};

export const addProjectImagesActions = (images: Images[]): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(addProjectImages({ images }));
  };
};

export const sendProjectPost = (title: string, body: string): AppThunk => {
  return async (dispatch, getState): Promise<void> => {
    const batch = writeBatch(db);
    const getStateProjectPost = getState().projectPost.projectPost;
    const userState = getState().user.user;
    const timestamp = serverTimestamp();
    const uid = auth.currentUser!.uid;
    const projectPost = getStateProjectPost.projectID;
    const projectPostRef = doc(
      collection(getDocumentRef(projectPost), "posts")
    ); // db.doc(projectPost).collection("posts").doc();

    const salesStatus = getStateProjectPost.salesStatus;
    const userInfo = {
      displayName: userState.displayName,
      photoUrl: userState.photoUrl,
      uid: userState.uid,
    };
    const projectPostNewData = {
      author: userDocument(userState.uid).path, // userRef.doc(userState.uid).path,
      uid: userState.uid,
      title: title,
      body: body,
      images: getStateProjectPost.images,
      mentionTo: getStateProjectPost.mentionTos,
      createdAt: timestamp,
      likeCount: 0,
      wantCount: 0,
      projectPostRef: projectPostRef.path,
      projectRef: projectPost,
      numberToShip: getStateProjectPost.numberToShip,
      reservations: getStateProjectPost.reservations,
      userInfo: userInfo,
      search: true,
      salesStatus,
      projectName: getStateProjectPost.projectName,
      isProject: true,
    };

    batch.set(projectPostRef, projectPostNewData, { merge: true });
    const userPostCountRef = userDocument(uid); // userRef.doc(uid);
    batch.set(userPostCountRef, { postCount: increment(1) }, { merge: true });
    // // これはcloud functionsで書く // ??????????????
    // dispatch(userInfoMention(projectPostNewData, uid));
    batch.commit().then(() => {
      dispatch(resetProjectState());
    });

    //TODO: postのストアを空にする ???????????
    const squeezeObj = {
      Product: false,
      All: false,
      Project: true,
      // Reservation: false,
      // Sale: false,
      Want: false,
    };

    dispatch(squeezeChange(squeezeObj));
    dispatch(fetchFirstProjectsPosts());
    dispatch(push("/"));
  };
};

type WantedProject = {
  author: string;
  pid: string;
  projectRef: string;
  createdAt: FirebaseTimestampType;
};

export const fetchWantedProjects = async (uid: string) => {
  const wantedProjects: WantedProject[] = [];
  const wantedProjectsRef = wantedProjectsCollection(uid);

  await getDocs(wantedProjectsRef).then((snapshot) => {
    snapshot.forEach(async (wantedProjectDoc) => {
      if (wantedProjectDoc.exists()) {
        const wantedProjectData = wantedProjectDoc.data() as WantedProject;
        wantedProjects.push(wantedProjectData);
      }
    });
  });

  const projects = await Promise.all(
    wantedProjects.map(async (project) => {
      const projectPath = project.projectRef;
      const projectRef = getDocumentRef(projectPath);
      const projectResponse = await getDoc(projectRef);
      if (projectResponse.exists()) {
        return projectResponse.data() as Project;
      }
      return undefined;
    })
  );
  const filetedProjects = projects.filter((project) => project !== undefined);
  const userInfoMergeProjects = await Promise.all(
    filetedProjects.map(async (project) => {
      const creatorUid = project?.memer.value;
      const creatorRef = userDocument(creatorUid);
      const responseCreatorData = await getDoc(creatorRef);
      if (responseCreatorData.exists()) {
        const userData = responseCreatorData.data() as User;
        const projectRefSplit = project?.projectRef.split("/");
        const projectId = projectRefSplit![3] ?? "";
        const mergeData = {
          creatorUid: userData.uid,
          icon: userData.photoUrl,
          displayName: userData.displayName,
          thumbnailImage: project?.thumbnailImage,
          salesStatus: project?.salesStatus,
          projectName: project?.projectName,
          itemDescription: project?.itemDescription,
          projectId,
        };
        return mergeData;
      }
    })
  );
  return userInfoMergeProjects;
};

export const fetchProject = async (ref: string) => {
  const projectRef = getDocumentRef(ref);
  const projectResponse = await getDoc(projectRef);

  if (projectResponse.exists()) {
    return projectResponse.data() as Project;
  }
};
