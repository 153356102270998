import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

type Props = {
  title: string;
};

export const ProjectTitle = (props: Props) => {
  const { title } = props;
  const useStyles = makeStyles({
    title: {
      padding: "10px 5px 0 10px",
      fontWeight: "bold",
      fontSize: 14,
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title}>{title}</Typography>
    </div>
  );
};
