import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import { Header } from "../Header/Header";

const DeliveryDeleteDone = () => {
  const { push } = useHistory();
  const useStyles = makeStyles({
    info: {
      height: 200,
      "& p": {
        display: "block",
        fontSize: 20,
        margin: "100px auto 0",
        textAlign: "center",
      },
    },
    link: {
      color: "rgb(63,101,227)",
      cursor: "pointer",
      fontSize: 20,
      textAlign: "center",
      marginTop: 0,
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Header label="住所登録" transitionLocation={"/?"} />
      <div className={classes.info}>
        <p>住所を削除しました</p>
      </div>

      <p className={classes.link} onClick={() => push("/?")}>
        TOPページへ
      </p>
    </div>
  );
};

export default DeliveryDeleteDone;
