import { makeStyles, Typography } from "@material-ui/core";

const ReserveDeliveryDate = () => {
  const useStyles = makeStyles({
    whiteArea: {
      width: 490,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: 365,
      },
      paddingTop: 15,
      paddingLeft: 20,
    },
    infoText: {
      fontSize: 14,
    },
    changeBtn: {
      border: "1px solid gray",
      width: 75,
      margin: "5px auto 10px",
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        backgroundColor: "rgb(234,234,234)",
      },
    },
  });
  const classes = useStyles();
  return (
    <>
      <div className={classes.whiteArea}>
        <Typography className={classes.infoText}>日付指定なし</Typography>
      </div>
      <div className={classes.changeBtn}>
        <Typography className={classes.infoText} align="center">
          変更する
        </Typography>
      </div>
    </>
  );
};

export default ReserveDeliveryDate;
