import { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { GrayBack } from "../MyPage/GrayBack";
import Radio from "@material-ui/core/Radio";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { CreditMainChange } from "./CreditMainChange";
import { CreditDeleteSelect } from "./CreditDeleteSelect";
import Visa from "./Visa";
import MasterCard from "./MasterCard";
import Discover from "./Discover";
import JCB from "./JCB";
import AmericanExpress from "./AmericanExpress";
import DinersClub from "./DinersClub";
import { stripeRetrievePaymentMethod } from "../../function/cloudFunctions";
import { Loading } from "../atoms/Loading/Loading";
import { Text } from "../atoms/Text/Text";
import { Credit } from "../../Types/paymentType";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Center } from "../atoms/Layout/Center";
import { logError } from "../../lib/logError";

export const CreditRegistered = (props: { customerID: string }) => {
  const { customerID } = props;
  const useStyles = makeStyles({
    creditInfoArea: {
      display: "flex",
      height: 66,
      backgroundColor: "white",
    },
    left: {
      flex: 1.5,
      "@media screen and (max-width: 499px)": {
        flex: 1,
      },
    },
    cardArea: {
      flex: 1.5,
      "@media screen and (max-width: 499px)": {
        flex: 1.3,
      },
    },
    center: {
      flex: 3,
      "@media screen and (max-width: 499px)": {
        flex: 2,
      },
    },
    right: {
      flex: 2,
    },
    centerTop: {
      height: 33,
    },
    centerBottom: {
      height: 33,
    },
    radio: {
      margin: "13px auto 0",
      display: "block",
    },
    btn: {
      margin: "15px auto 0",
      display: "block",
    },
    textTop: {
      lineHeight: 4,
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    textBottom: {
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    leftCardArea: {
      flex: 2,
    },
    icon: {
      width: 100,
      height: 100,
      objectFit: "cover",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  const [credits, setCredits] = useState<Credit[]>([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [indexNumber, setIndexNumber] = useState<number>();
  const [deleteCredit, setDeleteCredit] = useState<Credit>();
  const [isActive, setActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isUpdateActive, setUpdateActive] = useState({
    active: false,
    text: "",
  });

  // 住所のメイン切り替え用のモーダル
  const handleOpen = (index: number) => {
    setOpen(true);
    setIndexNumber(index);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // 削除確認のモーダル
  const handleDeleteClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = (index: number) => {
    setDeleteOpen(true);
    setDeleteCredit(credits[index]);
  };

  useEffect(() => {
    let isMounted: boolean = true;

    setLoading(true);
    if (customerID !== "") {
      stripeRetrievePaymentMethod({ customerID })
        .then((res) => {
          if (isMounted) {
            let cardDataBox: Credit[] = [];
            res.data.data.forEach((r: any, i: number) => {
              const data = res.data.data[i].card;
              const metaData = res.data.data[i];
              const pmID = res.data.data[i].id;
              const cardData = {
                last4: data?.last4 ?? "",
                brand: data?.brand ?? "",
                expMonth: data?.exp_month ?? 0,
                expYear: data?.exp_year ?? 0,
                primaryCard:
                  metaData?.metadata?.primary_card === "true" ? true : false,
                pmID: pmID,
              };
              cardDataBox.push(cardData);
            });
            setCredits(cardDataBox);
            setLoading(false);
          }
        })
        .catch((err) => {
          logError(err);
        });
    } else {
      setLoading(false);
      setActive(false);
    }

    return () => {
      isMounted = false;
    };
  }, [customerID]);

  return (
    <div>
      {isUpdateActive.active && <Loading text={isUpdateActive.text} />}
      {loading && <Loading text="取得中" />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <CreditMainChange
            customerID={customerID}
            setOpen={setOpen}
            indexNumber={indexNumber}
            setUpdateActive={setUpdateActive}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={deleteOpen}
        onClose={handleDeleteClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <CreditDeleteSelect
            setDeleteOpen={setDeleteOpen}
            credit={deleteCredit}
            setUpdateActive={setUpdateActive}
          />
        </Fade>
      </Modal>
      {isActive && <Loading text="読み込み中" />}
      {credits.length > 0 ? (
        <>
          {credits.map((c: any, index: number) => (
            <div key={index}>
              <GrayBack height={1} isDark />
              <div className={classes.creditInfoArea}>
                <div className={classes.left}>
                  <Radio
                    checked={c.primaryCard}
                    onChange={() => handleOpen(index)}
                    value={index}
                    color="default"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "1" }}
                    className={classes.radio}
                  />
                </div>
                <div className={classes.cardArea}>
                  {c.brand === "visa" && <Visa />}
                  {c.brand === "mastercard" && <MasterCard />}
                  {c.brand === "discover" && <Discover />}
                  {c.brand === "jcb" && <JCB />}
                  {c.brand === "amex" && <AmericanExpress />}
                  {c.brand === "diners" && <DinersClub />}
                </div>
                <div className={classes.center}>
                  <div className={classes.centerTop}>
                    <Typography
                      className={classes.textTop}
                    >{`****-****-****-${c.last4}`}</Typography>
                  </div>
                  <div className={classes.centerBottom}>
                    <Typography
                      className={classes.textBottom}
                    >{`有効期限 ${c.expYear}/${c.expMonth}`}</Typography>
                  </div>
                </div>
                <Center
                  className={{
                    alignItems: "center",
                    flex: 2,
                  }}
                >
                  <GrayButton
                    width={80}
                    onClick={() => handleDeleteOpen(index)}
                  >
                    削除
                  </GrayButton>
                </Center>
              </div>
              <GrayBack height={1} isDark />
            </div>
          ))}
        </>
      ) : (
        <>
          {!isActive && (
            <>
              <Text
                text="クレジットカードの登録がありません"
                align="center"
                className={{
                  margin: "20px 0",
                }}
              />
              <GrayBack height={1} isDark />
            </>
          )}
        </>
      )}
    </div>
  );
};
