import React from "react";
import Left from "../../assets/icon/leftArrow.png";
import { toggleModal } from "../../features/hamburgerMenu";
import Modal from "react-modal";
import { Typography, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { transitionFunc } from "../../function/common";
import { useDispatch, useSelector } from "react-redux";

const userStyles = makeStyles({
  myPageLeft: {
    width: 15,
    height: 30,
    marginTop: 12,
    cursor: "pointer",
    outline: "none",
    "@media screen and (max-width: 499px)": {
      marginTop: 8,
      marginLeft: 5,
      width: 12,
      height: 24,
    },
  },
  myPageHeader: {
    position: "fixed",
    top: 0,
    zIndex: 1,
    display: "flex",
    borderBottom: "0.5px solid black",
    borderWidth: "thin",
    width: 500,
    height: 54,
    backgroundColor: "white",
    "@media screen and (max-width: 499px)": {
      width: "100vw",
      height: 38,
    },
  },
  myPageReturn: {
    flex: 1,
    marginLeft: 10,
  },
  myPageTitle: {
    flex: 3,
    textAlign: "center",
    cursor: "default",
    "& p": {
      fontSize: 20,
      lineHeight: 3.3,
      "@media screen and (max-width: 499px)": {
        lineHeight: 2.8,
        fontSize: 18,
      },
    },
  },
  myPageDummy: {
    flex: 1,
  },
});

export const StoreResetHeader = (props: any) => {
  const classes = userStyles();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { transitionFunc, path, storeReset } = props;
  return (
    <div className={classes.myPageHeader}>
      <div className={classes.myPageReturn}>
        <img
          src={Left}
          className={classes.myPageLeft}
          onClick={() => dispatch(transitionFunc(path, push, storeReset))}
          alt="arrow"
        />
      </div>
      <div className={classes.myPageTitle}>
        <Typography>{props.label}</Typography>
      </div>
      <div className={classes.myPageDummy}></div>
    </div>
  );
};
