import { useEffect, useState } from "react";
import { fetchReserveProduct } from "../../../features/productOperation";
import { ReserveJoinData } from "../../../Types/productType";
import { ReserveProductList } from "./ReserveProductList";
import { toast } from "react-toastify";
import { Loading } from "../../../components/atoms/Loading/Loading";

export const ReserveProducts = () => {
  const [reserve, setReserve] = useState<ReserveJoinData[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        await fetchReserveProduct(setReserve, setLoading);
      } catch (error) {
        toast.error(
          "予約一覧の取得に失敗しました。\nリロードを試してみてください"
        );
        setLoading(false);
      }
    })();
  }, []);
  return (
    <div>
      {loading && <Loading text="取得中" />}
      {reserve.map((r: ReserveJoinData) => (
        <ReserveProductList reserveData={r} key={JSON.stringify(r.createdAt)} />
      ))}
    </div>
  );
};
