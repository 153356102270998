import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { GrayButton } from "../../atoms/GrayButton/GrayButton";

type Props = {
  isShow: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
};

const WantInfo = (props: Props) => {
  const { isShow, setState } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "fixed",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      top: 0,
      left: 0,
      backgroundColor: "#00000040",
    },
    box: {
      width: 380,
      height: 220,
      border: "1px solid #00000040",
      backgroundColor: "white",
      padding: "20px",
      "@media screen and (max-width: 499px)": {
        width: 300,
        height: 180,
      },
    },
    text: {
      marginTop: 40,
      fontSize: 18,
      whiteSpace: "pre-line",
      color: "rgb(100, 100, 100)",
      "@media screen and (max-width: 499px)": {
        fontSize: 14,
        marginTop: 20,
      },
    },
    buttonBox: {
      flexGrow: 1,
      marginTop: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      width: 150,
      color: "white",
      backgroundColor: "rgb(175, 175, 175)",
      borderRadius: 0,
    },
    centerContent: {
      display: "flex",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  if (isShow) {
    return (
      <div className={classes.root} onClick={() => setState(false)}>
        <div className={classes.box}>
          <Typography align="center" className={classes.text} noWrap={false}>
            {`まだほしいを押してないようです。\n気になる商品があればほしいを押してみてください。`}
          </Typography>

          <div className={classes.buttonBox}>
            <Grid container spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.centerContent}>
                  <GrayButton
                    variant="contained"
                    onClick={() => setState(false)}
                  >
                    閉じる
                  </GrayButton>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default WantInfo;
