import { Header } from "../../components/Header/Header";
import { Done } from "./Done";
const CartBuyDone = () => {
  return (
    <div>
      <Header label="カート内商品購入" transitionLocation={"/?"} />
      <Done
        info={`ありがとうございます！\n購入完了しました`}
        linkText="TOPページへ"
      />
    </div>
  );
};

export default CartBuyDone;
