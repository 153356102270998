import { useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { TopFooter } from "../../components/Footer/TopFooter";
import { useEffect } from "react";
import { Post, UserInfo, Comment } from "../../Types/userType";
import { selectUser } from "../../features/userSlice";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CommentSkeleton from "./PostUIKit/CommentSkeleton";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { ReplyComments } from "./ReplyComments";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { RouteComponentProps } from "react-router-dom";
import { sendComment } from "../../function/cloudFunctions";
import { LoginInfo } from "../../components/feature/LoginInfo/LoginInfo";
import { GrayButton } from "../../components/atoms/GrayButton/GrayButton";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { commentsCollection, getDocumentRef } from "../../firebasePaths";
import { toast } from "react-toastify";
import { logError } from "../../lib/logError";

export const CommentArea = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    commentContainer: {
      width: 500,
      margin: "0 auto",
      height: "100%",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    textArea: {
      marginTop: 20,
      width: "100%",
    },
    textInput: {
      padding: "10px 15px",
      width: 480,
      borderRadius: 10,
      border: "1px solid rgb(223, 223, 223)",
      resize: "none",
      "@media screen and (max-width: 499px)": {
        width: 353,
      },
      fontSize: 12,
      marginBottom: 15,
      color: "rgb(90,90,90)",
      "&::placeholder": {
        fontSize: 12,
        color: "gray",
      },
      outline: "none",
    },
    submitArea: {
      width: "100%",
      display: "grid",
      itemAlign: "center",
      justifyItems: "center",
    },
    validatesArea: {
      flex: 8,
      border: "1px solid black",
      borderRight: "none",
      borderTop: "none",
      borderRadius: "0 0 0 5px",
    },
    btnArea: {
      flex: 1,
    },
    submitBtn: {
      height: 20,
      margin: "0 auto",
    },
    btn: {
      display: "none",
    },
    errorText: {
      fontSize: 12,
      color: "red",
    },
    commentTextarea: {
      position: "fixed",
      bottom: 0,
      zIndex: 1,
      backgroundColor: "white",
    },
    circleArea: {
      position: "relative",
    },
    commentLoading: {
      position: "absolute",
      top: 0,
      zIndex: 10,
      height: window.innerHeight,
    },
    loadingArea: {
      position: "fixed", // または 'absolute'
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  });

  const classes = useStyles();
  const post = history.location.state as Post; // stateはPostのこと　名前あとっ
  const isSignedIn = useSelector(selectUser).isSignedIn;
  const [comments, setComments] = useState<Comment[]>([]);

  const [commentsPreview, setCommentsPreview] = useState(false);

  const [loading, setLoading] = useState(true);
  const user = useSelector(selectUser);

  const [commentActive, setCommentActive] = useState(true);
  const [commentLoading, setCommentLoading] = useState(false);

  const [show, setShow] = useState(false);
  
  const userInfo: UserInfo = {
    displayName: user.displayName,
    uid: user.uid,
    photoUrl: user.photoUrl,
  };

  const defaultValues = {
    comment: "",
  };

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;

  type CommentForm = {
    comment: string;
  };

  const commentApi = async (data: CommentForm) => {
    if (isSignedIn) {
      setCommentActive(true);
      setCommentLoading(true);
      setValue("comment", "");
      const comment = { post, comment: data.comment, userInfo };
      try {
        await sendComment(comment);
        setCommentLoading(false);
      } catch (error) {
        logError(error);
        toast.error("コメントの投稿に失敗しました。");
      }
    } else {
      setShow(true);
    }
  };

  const btnClick = () => {
    const realBtn = document.getElementById("noneBtn");
    realBtn?.click();
  };

  useEffect(() => {
    const postRef = post.projectPostRef
      ? query(
          collection(getDocumentRef(post.projectPostRef), "comments"),
          orderBy("createdAt", "asc")
        )
      : query(
          commentsCollection(post.uid, post.postID ?? ""),
          orderBy("createdAt", "asc")
        );

    onSnapshot(postRef, (snapshot) => {
      const comments: Comment[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data() as Comment;
        comments.push(data);
      });
      setComments(comments);
      setLoading(false);
      setCommentsPreview(true);
    });
  }, []);

  return (
    <div className={classes.commentContainer}>
      <LoginInfo isShow={show} setState={setShow} />
      {commentLoading && (
        <div className={classes.loadingArea}>
          <CircularProgress size={50} color="primary" thickness={2.5} />
        </div>
      )}
      <BrowserBackHeader label="コメント" browserBack={history} />
      <SpaceBox height={10} />
      {loading && <CommentSkeleton />}
      {commentsPreview && (
        <ReplyComments
          comments={comments}
          setCommentLoading={setCommentLoading}
        />
      )}

      <div className={classes.commentTextarea}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(commentApi)}>
            <div className={classes.textArea}>
              {errors.comment && (
                <Typography align="center" className={classes.errorText}>
                  {errors.comment.message}
                </Typography>
              )}
              <Controller
                name="comment"
                control={control}
                rules={{
                  required: "0文字でコメントはできません",
                }}
                render={({ field }) => (
                  <TextareaAutosize
                    maxRows={30}
                    placeholder="コメントを投稿する"
                    aria-label="maximum height"
                    className={classes.textInput}
                    {...field}
                  />
                )}
              />
              <button id="noneBtn" className={classes.btn}>
                submit
              </button>
            </div>
          </form>
        </FormProvider>
        <div className={classes.submitArea}>
          <GrayButton disabled={!commentActive} onClick={btnClick} width="50%">
            投稿する
          </GrayButton>
        </div>
        <SpaceBox height={70} />
      </div>
      <TopFooter />
    </div>
  );
};
