import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { addressEditModalClose } from "../../features/hamburgerMenu";
import { editAddress } from "../../features/userSlice";
import { deleteAddress } from "../../features/addressOperation";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { Text } from "../atoms/Text/Text";
import { toast } from "react-toastify";
import { Address } from "../../Types/userType";

type Props = {
  fetchAddressData: () => void;
  addressEdit: Omit<Address, "uid">;
  openEdit: boolean;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditModal = (props: Props) => {
  const { addressEdit, openEdit, setOpenEdit, fetchAddressData } = props;
  const useStyles = makeStyles({
    modalInfoEdit: {
      marginBottom: 20,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalArea: {
      padding: 20,
      backgroundColor: "white",
      zIndex: 100,
    },
    modalAddress: {},

    modalBtnArea: {
      marginTop: 20,
      alignItems: "center",
      display: "flex",
    },
    selectBtn: {
      margin: "0 auto",
      display: "block",
    },
    link: {
      textDecoration: "none",
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const deleteAddressData = async () => {
    try {
      await dispatch(deleteAddress(addressEdit.aid));
      toast.success("住所の削除をしました。");
      setOpenEdit(false);
      await fetchAddressData();
    } catch (error) {
      toast.success("住所の削除に失敗しました。");
    }
  };
  return (
    <div className={classes.modalArea}>
      <div className={classes.modalInfoEdit}>
        <Text
          text="編集か削除か選択してください"
          fontSize={14}
          align="center"
        />
      </div>
      <div className={classes.modalAddress}>
        <Text
          text={addressEdit.lastName + " " + addressEdit.firstName}
          fontSize={14}
        />
        <Text text={"〒" + addressEdit.zipcode} fontSize={14} />
        <Text
          text={
            addressEdit.prefecture +
            addressEdit.city +
            addressEdit.town +
            addressEdit.address
          }
          fontSize={14}
        />
        <Text text={addressEdit.buildingName} fontSize={14} />
        <Text text={"TEL" + " " + addressEdit.phoneNumber} fontSize={14} />
      </div>
      <div className={classes.modalBtnArea}>
        <Link to={"/editaddress/" + addressEdit.aid} className={classes.link}>
          <Center
            className={{
              flex: 1,
            }}
          >
            <GrayButton
              width={80}
              onClick={() =>
                dispatch(
                  editAddress({
                    lastName: addressEdit.lastName,
                    firstName: addressEdit.firstName,
                    lastNameKana: addressEdit.lastNameKana,
                    firstNameKana: addressEdit.firstNameKana,
                    prefecture: addressEdit.prefecture,
                    city: addressEdit.city,
                    town: addressEdit.town,
                    address: addressEdit.address,
                    buildingName: addressEdit.buildingName,
                    phoneNumber: addressEdit.phoneNumber,
                    mainAddress: addressEdit.mainAddress,
                    zipcode: addressEdit.zipcode,
                    aid: addressEdit.aid,
                  })
                )
              }
            >
              編集
            </GrayButton>
          </Center>
        </Link>
        <Center
          className={{
            flex: 1,
            margin: "0 20px",
          }}
        >
          <GrayButton width={80} onClick={() => deleteAddressData()}>
            削除
          </GrayButton>
        </Center>
        <Center
          className={{
            flex: 1,
          }}
        >
          <GrayButton
            width={80}
            onClick={() =>
              dispatch(addressEditModalClose(openEdit, setOpenEdit))
            }
          >
            戻る
          </GrayButton>
        </Center>
      </div>
    </div>
  );
};
