import { useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core";
import { switchNotice } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { ControllerRenderProps } from "react-hook-form";
import { UserProfileEditType } from "../../Types/userType";

type Props = {
  field: ControllerRenderProps<UserProfileEditType, "notice">;
  defaultValue: boolean;
};

const userStyles = makeStyles({
  infoCatchArea: {
    height: 40,
    display: "flex",
    width: "80%",
    borderTop: "0.5px solid rgb(130, 130, 130)",
    borderBottom: "0.5px solid rgb(130, 130, 130)",
    margin: "0 auto",
  },
  infoMessage: {
    flex: 5,
    "& p": {
      marginTop: 8.5,
      marginLeft: 25,
      fontSize: 15,
      "@media screen and (max-width: 499px)": {
        marginLeft: 15,
      },
    },
  },
  infoSwitch: {
    flex: 1,
  },
});

export const ProfileInfoCatch = (props: Props) => {
  const { field, defaultValue } = props;
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(switchNotice({ notice: user.notice }));
  });

  const classes = userStyles();
  return (
    <div className={classes.infoCatchArea}>
      <div className={classes.infoMessage}>
        <p>tomemeからのお知らせを受け取る</p>
      </div>
      <div className={classes.infoSwitch}>
        <Switch
          defaultChecked={defaultValue}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
          {...field}
        />
      </div>
    </div>
  );
};
