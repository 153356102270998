import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { UserIcon } from "../../../components/UIKit/UserIcon";
import { selectUser } from "../../../features/userSlice";
import { Name } from "../../../components/UIKit/Name";
import { ReturnComment } from "./ReturnComment";
import { ReplyComment } from "../../../Types/userType";
import { useCallback } from "react";
import { relativeDate } from "../../../function/common";
import LoadingOverlay from "react-loading-overlay-ts";
import styled from "styled-components";
import { Comment } from "../../../Types/userType";
import garbageBox from "../../../assets/icon/garbageBox2.png";
import { deleteComment } from "../../../function/cloudFunctions";
import { NormalModal } from "../../../components/feature/Modal/NormalModal";
import { toast } from "react-toastify";
import { LoginInfo } from "../../../components/feature/LoginInfo/LoginInfo";
import { collection, onSnapshot, query } from "firebase/firestore";
import { getDocumentRef } from "../../../firebasePaths";
import { logError } from "../../../lib/logError";

type Props = {
  comment: Comment;
  index: number;
  textToggle: boolean;
  commentsMoldingOpen: Function;
  commentsMoldingClose: Function;
  setModifyComments: Function;
  setCommentLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddComment = React.memo((props: Props) => {
  const {
    comment,
    index,
    textToggle,
    commentsMoldingOpen,
    commentsMoldingClose,
    setModifyComments,
    setCommentLoading,
  } = props;

  const useStyles = makeStyles({
    addCommentArea: {
      display: "flex",
    },
    addIcon: {
      flex: 1,
    },
    addComment: {
      flex: 7,
    },
    dateArea: {
      width: 54,
      "@media screen and (max-width: 499px)": {
        width: 45,
      },
    },
    returnArea: {
      flex: 1,
      "& p": {
        fontSize: 10,
        color: "rgb(112,112,112)",
        textAlign: "center",
        display: "block",
        "@media screen and (max-width: 499px)": {
          margin: "0 2px 0 ",
        },
      },
      "@media screen and (max-width: 499px)": {
        width: 56,
      },
    },
    userName: {
      margin: "10px 0 10px 10px",
      flex: 5,
    },
    Name: {
      lineHeight: 3,
      fontSize: 12,
      color: "gray",
    },

    replyColor: {
      color: "rgb(55,154,246)",
      marginBottom: 7,
      cursor: "pointer",
    },
    replyInfo: {
      fontSize: 12,
    },
    dummyBottom: {
      height: 5,
    },
    returnText: {
      cursor: "pointer",
      marginTop: 5,
    },
    forever: {
      width: 15,
      margin: "30px 15px 0 0",
      cursor: "pointer",
    },
    postCommentText: {
      fontSize: 8,
      color: "gray",
      marginTop: 5,
    },

    notForever: {
      marginRight: 20,
    },
    returnCommentArea: {
      display: "flex",
    },
    dummyArea: {
      flex: 1.5,
    },
    leftArea: {},
    time: {
      flex: 1,
    },
    etc: {
      width: 180,
      display: "flex",
    },
  });

  const isCommentedUser = comment.userInfo.uid;

  const [openComments, setOpenComments] = useState(false);

  const [isShow, setIsShow] = useState(false);
  const [loginInfo, setLoginInfo] = useState(false);

  const [replyComments, setReplyComments] = useState<ReplyComment[]>([]);

  const selector = useSelector(selectUser);

  const commentUser = {
    displayName: selector.displayName,
    uid: selector.uid,
    photoUrl: selector.photoUrl,
  };

  const classes = useStyles();
  const user = useSelector(selectUser);
  const isLogin = user.isSignedIn;
  const relativeDateText = relativeDate(comment.createdAt.seconds);

  const fetchReplyComment = (commentData: Comment) => {
    if (commentData.commentRef) {
      const replyCommentsRef = query(
        collection(getDocumentRef(commentData.commentRef), "replyComments")
      );
      try {
        onSnapshot(replyCommentsRef, (snapshot) => {
          const replys: any = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            replys.push(data);
          });
          setReplyComments(replys);
        });
      } catch (error) {
        logError(error);
      }
    }
  };

  const callbackOpenComments = useCallback(
    (comment) => {
      setOpenComments((prev) => !prev);
      fetchReplyComment(comment);
    },
    [setOpenComments]
  );

  const modalOpen = useCallback(() => {
    setIsShow(true);
  }, [setIsShow]);

  const modalClose = useCallback(() => {
    setIsShow(false);
  }, [setIsShow]);

  const postComment = comment.comment
    .split(/(\n)/)
    .map((item: string, index: number) => {
      return (
        <React.Fragment key={index}>
          {item.match(/\n/) ? <br /> : item}
        </React.Fragment>
      );
    });

  const StyledCircle = styled(LoadingOverlay)`
    .MyLoader_overlay {
      background: rgba(145, 145, 145, 0.9);
    }
  `;

  const deleteCommentApi = () => {
    const { commentRef } = comment;
    const targetComment = { user, comment: commentRef };
    setCommentLoading(true);
    deleteComment(targetComment)
      .then(() => {
        setIsShow(false);
        setCommentLoading(false);
        toast.success(`コメント削除に成功しました。`);
      })
      .catch((err) => {
        logError(err);
      });
  };

  return (
    <div>
      <LoginInfo isShow={loginInfo} setState={setLoginInfo} />
      <StyledCircle spinner text="コメント中" classNamePrefix="MyLoader_">
        <div className={classes.addCommentArea}>
          <div className={classes.addIcon}>
            <UserIcon
              photoUrl={comment.userInfo.photoUrl}
              width={50}
              height={50}
              margin="10px 0 0 10px"
            />
          </div>
          <div className={classes.addComment}>
            <Name
              displayName={comment.userInfo.displayName}
              fontSize={8}
              fontWeight="bold"
              margin="10px 0 0"
              textAlign={"left"}
              color="rgb(100, 100, 100)"
            />
            <Typography className={classes.postCommentText}>
              {postComment}
            </Typography>
            <div className={classes.etc}>
              <div className={classes.time}>
                <Typography className={classes.postCommentText}>
                  {relativeDateText}
                </Typography>
              </div>
              <div className={classes.returnArea}>
                {textToggle ? (
                  <p
                    className={classes.returnText}
                    onClick={() => setModifyComments(commentsMoldingClose())}
                  >
                    閉じる
                  </p>
                ) : (
                  <p
                    className={classes.returnText}
                    onClick={() => {
                      if (isLogin) {
                        setModifyComments(commentsMoldingOpen(index));
                      } else {
                        setLoginInfo(true);
                      }
                    }}
                  >
                    返信する
                  </p>
                )}
              </div>
            </div>
            <div
              className={classes.replyColor}
              onClick={() => callbackOpenComments(comment)}
            >
              {comment.replyCount >= 1 &&
                (openComments ? (
                  <Typography className={classes.replyInfo}>
                    コメントを閉じる
                  </Typography>
                ) : (
                  <Typography className={classes.replyInfo}>
                    {comment.replyCount} 件返信があります
                  </Typography>
                ))}
            </div>
          </div>
          {isCommentedUser === commentUser.uid ? (
            <div onClick={modalOpen}>
              <img
                src={garbageBox}
                alt="garbageBox"
                className={classes.forever}
              />
            </div>
          ) : (
            <div className={classes.notForever} />
          )}
        </div>
        {openComments && (
          <>
            {replyComments &&
              replyComments.map((reply, idx) => {
                return (
                  <div key={idx}>
                    <ReturnComment reply={reply} />
                    <div className={classes.dummyBottom} />
                  </div>
                );
              })}
          </>
        )}
        <NormalModal
          isShow={isShow}
          setState={setIsShow}
          leftButtonText="削除"
          rightButtonText="キャンセル"
          leftOnClick={deleteCommentApi}
          rightOnClick={modalClose}
          description="コメントを削除しますか？"
        />
      </StyledCircle>
    </div>
  );
});
