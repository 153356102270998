import { Header } from "../../components/Header/Header";
import { Done } from "../../templates/Done/Done";

export const ReviewDone = () => {
  return (
    <div>
      <Header label="レビュー完了" transitionLocation={"/?"} />
      <Done
        info={`レビューありがとうございます！\n引き続きtomemeをよろしくお願いします。`}
        linkText="TOPページへ"
      />
    </div>
  );
};
