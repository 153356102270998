export const posts = [
  {
    author: '',
    body: '',
    createdAt: {
      seconds: 0,
      nanoseconds: 0,
    },
    images: [
      {
        path: '',
        id: '',
      },
    ],
    likeCount: 0,
    mentionTo: [
      {
        displayName: '',
        photoUrl: '',
        selected: true,
        uid: '',
      },
    ],
    postID: '',
    title: '',
    uid: '',
    userFollowed: false,
    userInfo: {
      displayName: '',
      photoUrl: '',
      uid: '',
    },
    userLiked: false,
    userWanted: false,
    wantCount: 0,
    commentCount: 0,
    projectRef: '',
    projectPostRef: '',
  },
]

export const postId = ['']

export const createdAt = {
  seconds: 0,
  nanoseconds: 0,
}
