import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { db } from "../firebase";
import { doc, setDoc, serverTimestamp, collection } from "firebase/firestore";
import * as Path from "../refPath";
import {
  stripeAttachPaymentMethod,
  stripeCreateCustomerCard,
  stripeMainCardMainUpdateMethod,
  stripeMainCardUpdateMethod,
  stripeRetrievePaymentMethod,
} from "../function/cloudFunctions";
import { logError } from "../lib/logError";

// Set Header
const headers = new Headers();
headers.set("Content-type", "application/json");
headers.set("Access-Control-Allow-Origin", "*");

const createCustomer = async (
  email: string,
  paymentMethodID: string,
  uid: string,
  username: string
) => {
  try {
    const inputData = { email, paymentMethodID, uid, username };
    const response = await stripeCreateCustomerCard(inputData);
    const { data } = response;
    if (data.statusCode || !data.id) throw Error();
    return data.id;
  } catch (error) {
    logError(error);
    throw Error();
  }
};

const fetchCardList = async (customerID: string) => {
  const response = await stripeRetrievePaymentMethod({
    customerID: customerID,
  });
  const { data } = response;

  if (data.statusCode) throw Error();
  return data.data;
};

export const registerCard = async (
  stripe: Stripe | null,
  elements: StripeElements | null,
  customerID: string,
  isMain: boolean,
  cardHolderName: string,
  uid: string,
  username: string
) => {
  const email = "test@gmail.com"; // storeにメール足す

  try {
    if (!stripe || !elements) {
      console.error("Does not exist stripe or elements");
      throw Error();
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error("Does not exist cardElement");
      throw Error();
    }

    // メインカードかどうかのハンドリング用
    let registerMainCard = isMain;

    // 初めての登録だから強制でメインカードにする
    // メインにする項目がOFFだった場合に通る
    if (customerID === "" && !isMain) {
      registerMainCard = true;
    }

    let lengthCheckData: { id: string; customer: string }[] = [];
    if (customerID !== "") {
      const lengthCheck = await fetchCardList(customerID);
      lengthCheckData = lengthCheck;
      // カードの登録が内場合に強制でメインカードにする
      // カード登録から削除で0枚の登録時とかにここを通る
      if (lengthCheckData.length === 0) {
        registerMainCard = true;
      }
    }

    // 既にStripe登録済みで、今回登録したカードをメインカードにしたい場合
    // fetchCardListでデータ取得済みなので、
    if (customerID !== "" && isMain) {
      const cardDataList = lengthCheckData;
      await Promise.all(
        cardDataList.map(async (card: any) => {
          if (card.metadata.primary_card === "true") {
            await stripeMainCardUpdateMethod({ paymentMethodID: card.id });
          }
        })
      );
    }

    // Stripeへカードを登録
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: cardHolderName,
      },
      metadata: {
        primary_card: registerMainCard ? "true" : "false",
      },
    });

    if (error) {
      alert("カードの登録ができませんでした");
      throw Error();
    }

    const paymentMethodID = paymentMethod?.id;

    // Stripeのアカウントがない場合、アカウント作成する
    if (customerID === "") {
      const customerId = await createCustomer(
        email,
        paymentMethodID,
        uid,
        username
      );
      const docRef = doc(collection(db, Path.credits(uid)));
      await setDoc(docRef, {
        stripeCustomerID: customerId,
        createdAt: serverTimestamp(),
      });
      return { status: "success", customerId };
    } else {
      // 既にアカウントがある場合、カードだけを作る
      const attachData = {
        paymentMethodID,
        customerID,
      };

      const attachRes = await stripeAttachPaymentMethod(attachData);
      if (attachRes.data.statusCode) {
        alert("カードの登録ができませんでした。");
        throw Error();
      }
      return { status: "success", customerId: customerID };
    }
  } catch (error) {
    logError(error);
    return {
      status: "failed",
      customerId: customerID,
    };
  }
};

export const mainCardUpdate = async (
  customerID: string,
  currentIdx: number
) => {
  const cardList = await fetchCardList(customerID);

  await Promise.all(
    cardList.map(async (card) => {
      if (card.metadata.primary_card === "true") {
        await stripeMainCardUpdateMethod({ paymentMethodID: card.id });
      }
    })
  );

  await stripeMainCardMainUpdateMethod({
    paymentMethodID: cardList[currentIdx].id,
  });

  window.location.reload();
};
