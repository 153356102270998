import { MenuItem } from "@material-ui/core";

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = currentYear; i >= 1900; i--) {
    years.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }
  return years;
};

export const generateMonthOptions = () => {
  let months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }
  return months;
};

export const generateDayOptions = (month?: string) => {
  const monthLength = new Date(2020, Number(month ?? 1), 0).getDate(); // 2020年は閏年
  let days = [];
  for (let i = 1; i <= monthLength; i++) {
    days.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }
  return days;
};
export const dateOfBirthValidate = (
  year?: string,
  month?: string,
  day?: string
) => {
  if (year && month && day) {
    const formattedMonth = month.length === 1 ? `0${month}` : month;
    const formattedDay = day.length === 1 ? `0${day}` : day;
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  return "";
};
