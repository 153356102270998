import { makeStyles, Typography } from "@material-ui/core";

export const ReservePriceDetail = (props: any) => {
  const { label, price } = props;
  const useStyles = makeStyles({
    detail: {
      display: "flex",
      width: 320,
    },
    right: {
      flex: 1,
    },
    left: {
      flex: 1,
    },
    textLeft: {
      marginLeft: 10,
      fontSize: 14,
      // "@media screen and (max-width: 499px)": {
      //   fontSize: 12,
      // },
    },
    textRight: {
      marginRight: 10,
      fontSize: 14,
      // "@media screen and (max-width: 499px)": {
      //   fontSize: 12,
      // },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.detail}>
      <div className={classes.left}>
        <Typography align="left" className={classes.textLeft}>
          {label}
        </Typography>
      </div>
      <div className={classes.right}>
        <Typography align="right" className={classes.textRight}>
          {price}
        </Typography>
      </div>
    </div>
  );
};
