import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { address, creditList, useCreditUtil } from "../../features/paymentUtil";
import { selectChoiceProduct } from "../../features/productSlice";
import { fetchDeliveryAddress } from "../../features/addressOperation";
import { ReservePaymentHeader } from "./ReserveParts/ReservePaymentHeader";
import { ReserveProduct } from "./ReserveParts/ReserveProduct";
import { BrowserBackHeader } from "../../components/Header/BrowserBackHeader";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import { Credit } from "../../Types/paymentType";
import { Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { BuySubmitButton } from "./ReserveParts/BuySubmitButton";
import { PurchaseDetail } from "../Purchase/PurchaseDetail";
import { ProductSaleInfo } from "./ProductParts/ProductSaleInfo";
import { ProductShip } from "./ReserveParts/ProductShip";
import { Coupon, SalesProject } from "../../Types/productType";
import { stripeRetrievePaymentMethod } from "../../function/cloudFunctions";
import { Address } from "../../Types/deliveryType";
import { SpaceBox } from "../../components/UIKit/SpaceBox";
import { getStripePublicKey } from "../../function/common";
import { logError } from "../../lib/logError";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Loading } from "../../components/atoms/Loading/Loading";
import { selectUser } from "../../features/userSlice";
import { creditsCollection } from "../../firebasePaths";
import { getDocs } from "firebase/firestore";

const stripePromise = loadStripe(getStripePublicKey());
export const ReserveConfirm = (props: RouteComponentProps<{ id: string }>) => {
  const { history } = props;
  const {
    reservations,
    minimumProduction,
    reserveId,
    coupons,
    retailPrice,
    prevLocation,
    memer,
  } = history.location.state as SalesProject;

  const choiceProduct = useSelector(selectChoiceProduct);
  const { uid } = useSelector(selectUser);
  const dispatch = useDispatch();
  //住所を格納
  const [deliveryAddress, setDeliveryAddress] = useState<Address>(address);

  // StripeからcardListを取得して格納
  const [useCreditList, setUseCreditList] = useState<Credit[]>(creditList);
  // 選択しているcard
  const [useCredit, setUseCredit] = useState<Credit>(useCreditUtil);
  // 住所のLoading
  // クレジットカードのLoading
  const [creditActive, setCreditActive] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);

  // credit取得時のタイムオーバー時使う
  const [timeOver, setTimeOver] = useState(false);

  const [isNotExist, setIsNotExist] = useState<boolean>();

  const [paymentAmount, setPaymentAmount] = useState(retailPrice);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [customerId, setCustomerId] = useState("");

  const defaultValues = {
    couponCode: "",
  };

  const methods = useForm({ defaultValues });
  const { control, getValues } = methods;

  const discountApplicable = (coupon: Coupon) => {
    setIsNotExist(false);
    // 割引実行
    const discountAmount = Math.ceil(
      retailPrice *
        (coupon.discountRate / 100) *
        choiceProduct.selectedQuantityTotal
    );
    const discountedPrice =
      retailPrice - discountAmount / choiceProduct.selectedQuantityTotal;

    setPaymentAmount(discountedPrice);
    setDiscountedPrice(discountAmount);
    setDiscountRate(Number(coupon.discountRate));
  };

  const couponReset = () => {
    setIsNotExist(true);
    setPaymentAmount(retailPrice);
    setDiscountedPrice(0);
    setDiscountRate(0);
  };

  const couponExistCheck = (code: string) => {
    // 一旦初期化
    setIsNotExist(undefined);
    if (!coupons) {
      couponReset();
      return;
    }

    const matchedCouponCode = coupons.find((coupon) => coupon.code === code);
    if (!matchedCouponCode) {
      couponReset();
      return;
    }

    discountApplicable(matchedCouponCode);
  };

  const fetchCredit = async (userId: string) => {
    const customerIDRef = creditsCollection(userId);
    const snapshot = await getDocs(customerIDRef);
    let currentCustomerId = "";
    snapshot.forEach((cusData) => {
      const { stripeCustomerID } = cusData.data();
      currentCustomerId = stripeCustomerID;
      setCustomerId(stripeCustomerID);
    });
    setCreditActive(true);
    let fiveCount = () => {
      setTimeOver(true);
      setCreditActive(false);
    };

    let timeOverMethod = setTimeout(fiveCount, 5000);
    if (choiceProduct.toBeShipDate !== "") {
      dispatch(fetchDeliveryAddress(setDeliveryAddress));
    }

    const cardDataBox: Credit[] = [];
    if (currentCustomerId && currentCustomerId !== "") {
      stripeRetrievePaymentMethod({
        customerID: currentCustomerId ?? "",
      })
        .then((res) => {
          res.data.data.forEach((_, i) => {
            const data = res.data.data[i].card;
            const metaData = res.data.data[i];
            const pmID = res.data.data[i].id;
            const cardData = {
              last4: data?.last4 ?? "",
              brand: data?.brand ?? "",
              expMonth: data?.exp_month ?? 0,
              expYear: data?.exp_year ?? 0,
              primaryCard: metaData?.metadata?.primary_card === "true",
              pmID: pmID ?? "",
            };
            cardDataBox.push(cardData);
          });
          setCreditActive(false);
          setUseCreditList(cardDataBox);
          clearTimeout(timeOverMethod);
        })
        .catch((err) => {
          logError(err);
          toast.error(
            "カード情報の取得に失敗しました。\n商品選択からやり直してください"
          );
        });
    } else {
      setCreditActive(false);
      clearTimeout(timeOverMethod);
    }
  };

  useEffect(() => {
    let isMounted: boolean = true;
    window.scrollTo(0, 0);
    (async () => {
      if (isMounted) {
        try {
          await fetchCredit(uid);
        } catch (error) {
          toast.error("カード情報の取得に失敗しました。");
          console.error("Error fetching data:", error);
          // エラーハンドリングをここに追加（例：トースト通知など）
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      {choiceProduct.toBeShipDate === "" ? (
        <>
          <BrowserBackHeader label="商品予約" browserBack={history} />
          <SpaceBox height={100} />
          <Typography align="center">申し訳ありません。</Typography>
          <Typography align="center">
            商品選択からやり直してください。
          </Typography>
        </>
      ) : (
        <>
          <BrowserBackHeader label="商品予約" browserBack={history} />
          {submitLoading && <Loading text="予約処理中" />}
          {reservations < minimumProduction && <ProductSaleInfo />}
          <ReservePaymentHeader />
          <Elements stripe={stripePromise}>
            <PurchaseDetail
              address={deliveryAddress}
              setAddress={setDeliveryAddress}
              useCreditList={useCreditList}
              useCredit={useCredit}
              setUseCredit={setUseCredit}
              creditActive={creditActive}
              timeOver={timeOver}
              salesProject={history.location.state}
              fetchCredit={fetchCredit}
            />
          </Elements>
          {choiceProduct.reserveProducts.map((product) =>
            product.detail.map((detail) => {
              const productData = {
                color: product.color,
                value: product.value,
              };
              return (
                <ReserveProduct
                  key={`${detail.ref}${detail.size}`}
                  productData={productData}
                  productDetail={detail}
                  image={choiceProduct.image}
                  projectName={choiceProduct.projectName}
                  retailPrice={choiceProduct.retailPrice}
                  itemName={choiceProduct.itemName}
                />
              );
            })
          )}
          <ProductShip
            control={control}
            getValues={() => {
              const couponCode = getValues("couponCode");
              couponExistCheck(couponCode);
            }}
            isNotExist={isNotExist}
            paymentAmount={paymentAmount}
            discountedPrice={discountedPrice}
            discountRate={discountRate}
          />
          {/* {choiceProduct.salesStatus.sale && (
            <>
              <ReserveGrayBack label="お届け日時" />
              <ReserveDeliveryDate />
            </>
          )} */}
          <SpaceBox height={40} />
          <BuySubmitButton
            deliveryAddress={deliveryAddress}
            useCredit={useCredit}
            customerID={customerId}
            setSubmitLoading={setSubmitLoading}
            reserveId={reserveId}
            isNotExist={isNotExist}
            paymentAmount={paymentAmount}
            discountedPrice={discountedPrice}
            discountRate={discountRate}
            prevLocation={prevLocation}
            creatorInfo={memer}
          />
          <SpaceBox height={70} />
        </>
      )}
    </div>
  );
};
