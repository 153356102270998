import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export const projectPostSlice = createSlice({
  name: "projectPost",
  initialState: {
    projectPost: {
      title: "",
      body: "",
      images: [],
      path: "",
      id: "",
      mentionTos: [],
      twitterShare: "",
      facebookShare: "",
      instagramShare: "",
      tiktokShare: "",
      projectID: "",
      numberToShip: 0,
      reservations: 0,
      salesStatus: {
        production: false,
        underReservation: false,
        sale: false,
        endOfSale: false,
      },
      projectName: "",
    },
  },
  reducers: {
    addProjectID: (state, action) => {
      state.projectPost.projectID = action.payload.projectID;
      state.projectPost.numberToShip = action.payload.numberToShip;
      state.projectPost.reservations = action.payload.reservations;
      state.projectPost.salesStatus = action.payload.salesStatus;
      state.projectPost.projectName = action.payload.projectName;

      return state;
    },
    addProjectMentionTo: (state, action) => {
      state.projectPost.mentionTos = action.payload.mentionTos;
      return state;
    },
    addProjectImages: (state, action) => {
      state.projectPost.images = action.payload.images;
    },
    resetProjectState: (state) => {
      state.projectPost = {
        title: "",
        body: "",
        images: [],
        path: "",
        id: "",
        mentionTos: [],
        twitterShare: "",
        facebookShare: "",
        instagramShare: "",
        tiktokShare: "",
        projectID: "",
        numberToShip: 0,
        reservations: 0,
        salesStatus: {
          production: false,
          underReservation: false,
          sale: false,
          endOfSale: false,
        },
        projectName: "",
      };
    },
  },
});
export const {
  addProjectMentionTo,
  addProjectImages,
  resetProjectState,
  addProjectID,
} = projectPostSlice.actions;
export const selectProjectPost = (state: RootState) =>
  state.projectPost.projectPost;
// export const selectFetchPost = (state: RootState) => state.post.fetchPost
export default projectPostSlice.reducer;
