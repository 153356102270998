// export const statusFetchURL: string =
//   "https://asia-northeast1-meme-project-f3275.cloudfunctions.net/fetchDeliveryStatus";
export const statusFetchURL: string =
  "https://asia-northeast1-meme-project-f3275.cloudfunctions.net/fetchProdDeliveryStatus";

export interface Sagawa {
  okurijoList: {
    okurijoNo: string;
  }[];
  henkDataSyube: string;
}

export const isOneLengthArrayhValidation = (body: Sagawa): boolean => {
  const okurijoListLength = body.okurijoList.length;
  if (okurijoListLength != 1) {
    return true;
  } else {
    return false;
  }
};

export const isTextCountValidation = (body: Sagawa): boolean => {
  const okurijoNumTextCount = body.okurijoList[0].okurijoNo.length;
  const okurijoNumMustCount = 12;
  if (okurijoNumTextCount != okurijoNumMustCount) {
    return true;
  } else {
    return false;
  }
};
