import { makeStyles, Typography } from "@material-ui/core";
import { UserIcon } from "../UIKit/UserIcon";
import { Project } from "../../Types/projectType";
import NoImage from "../../assets/icon/no_image.png";
interface PROPS {
  project: Project;
}

export const ProjectCard = (props: PROPS) => {
  const useStyles = makeStyles({
    cardImg: {
      flex: 1,
    },
    cardText: {
      padding: 18,
      flex: 1,
      overflow: "auto",
      wordWrap: "break-word",
    },
    name: {
      "@media screen and (max-width: 499px)": {
        fontSize: 13,
      },
    },
  });
  const classes = useStyles();
  const project = props.project;
  const { underReservation, inProduction, sale, cancel, endOfSale } =
    project.salesStatus;
  const img = props.project.images ? props.project.images[0] : NoImage;
  return (
    <>
      <div className={classes.cardImg}>
        <UserIcon
          photoUrl={img}
          frameWidth={104}
          width={104}
          height={104}
          margin={18}
        />
      </div>
      <div className={classes.cardText}>
        <Typography align="center" style={{ marginTop: 10 }}>
          品番　{project.productNumber}
        </Typography>

        {inProduction && (
          <Typography align="center" style={{ marginTop: 10 }}>
            製作中の商品です
          </Typography>
        )}
        {underReservation && (
          <Typography align="center" style={{ marginTop: 10 }}>
            予約の商品です
          </Typography>
        )}
        {sale && (
          <Typography align="center" style={{ marginTop: 10 }}>
            販売中の商品です
          </Typography>
        )}
        {endOfSale && (
          <Typography align="center" style={{ marginTop: 10 }}>
            販売終了したの商品です
          </Typography>
        )}
        <Typography
          align="center"
          style={{ marginTop: 10 }}
          className={classes.name}
        >
          {project.projectName}
        </Typography>
      </div>
    </>
  );
};
