import { ProfileIconSelect } from "./ProfileIconSelect";
import { ProfileInfoCatch } from "./ProfileInfoCatch";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import {
  CircularProgress,
  makeStyles,
  TextareaAutosize,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { fetchUserPrivate, updateProfile } from "../../features/userOperation";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { SpaceBox } from "../UIKit/SpaceBox";
import { Center } from "../atoms/Layout/Center";
import { GrayButton } from "../atoms/GrayButton/GrayButton";
import { useEffect, useState } from "react";
import { Gender, UserPrivate, UserProfileEditType } from "../../Types/userType";
import {
  generateDayOptions,
  generateMonthOptions,
  generateYearOptions,
} from "../../lib/userHelper";
import { logError } from "../../lib/logError";

export const ProfileEdit = (
  props: RouteComponentProps<{ id: string; uid: string }>
) => {
  const { history } = props;
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const userStyles = makeStyles({
    profileEditArea: {
      width: 500,
      zIndex: 2,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },

    passwordEdit: {
      marginTop: 50,
      "& p": {
        borderBottom: "0.5px solid black",
        fontSize: 12,
        width: 96,
        margin: "0 auto",
      },
    },
    message: {
      color: "rgb(67,234,180)",
    },
    btn: {
      display: "none",
    },
    inputArea: {
      width: "80%",
      margin: "5px auto 15px",
    },
    introAndAccount: {
      marginTop: "30px",
      "& p": {
        margin: "0 0 0",
        fontSize: 15,
      },
    },
    introductionArea: {
      paddingRight: 20,
      marginBottom: 35,
      width: 400,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "90%",
      },
    },
    loadingArea: {
      position: "fixed", // または 'absolute'
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    profileText: {
      width: "100%",
      padding: 10,
      border: "1px solid #ccc",
      borderRadius: 5,
      resize: "none",
    },
    dateInputArea: {
      display: "flex",
      justifyContent: "space-between",
      width: "80%",
      margin: "0 auto 15px",
    },
    yearSelect: {
      width: "50%",
    },
    monthDaySelect: {
      width: "25%",
    },
    textField: {
      "& label.Mui-focused": {
        color: "rgb(130, 130, 130)", // フォーカス時のラベル色
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "rgb(130, 130, 130)", // フォーカス時のボーダー色
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "rgb(130, 130, 130)", // フォーカス時のボーダー色（アウトラインスタイル）
        },
      },
    },
  });

  const classes = userStyles();
  const [loading, setLoading] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [stateUser, setStateUser] = useState(user);
  const [userPrivate, setUserPrivate] = useState<UserPrivate>();
  const [birthYear, setBirthYear] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [gender, setGender] = useState<Gender>("empty");

  const birthDisassembly = (
    dateOfBirth: string | undefined,
    type: "year" | "month" | "day"
  ) => {
    if (!dateOfBirth) return "";
    const dateParts = dateOfBirth.split("-");
    switch (type) {
      case "year":
        return dateParts[0];
      case "month":
        return dateParts[1];
      case "day":
        return dateParts[2];
      default:
        return "";
    }
  };

  const defaultValues: UserProfileEditType = {
    displayName: user.displayName,
    notice: user.notice,
    selfIntroduction: user.selfIntroduction,
    birthYear: Number(birthYear),
    birthMonth: Number(birthMonth),
    birthDay: Number(birthDay),
    gender: userPrivate?.gender ?? "empty",
  };

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = methods;

  const selectedMonth = watch("birthMonth");
  const btnClick = () => {
    const realBtn = document.getElementById("noneBtn");
    realBtn?.click();
  };

  const selfIntroductionLength = watch("selfIntroduction")?.length || 0;

  const onSubmit = async (data: UserProfileEditType) => {
    try {
      await updateProfile(data, user.uid, dispatch);
      setValue("displayName", data.displayName);
      setValue("selfIntroduction", data.selfIntroduction);
      setValue("notice", data.notice);
      setValue("birthYear", data.birthYear);
      setValue("birthMonth", data.birthMonth);
      setValue("birthDay", data.birthDay);
      setStateUser((prev) => {
        return {
          ...prev,
          displayName: data.displayName,
          selfIntroduction: data.selfIntroduction,
          notice: data.notice,
        };
      });
      setUpdateCount((prev) => prev + 1);
      toast.success("プロフィールを更新しました");
    } catch (error) {
      console.error(error);
      toast.error("プロフィールの更新が失敗しました");
    }
  };

  useEffect(() => {
    if (updateCount === 0) {
      setValue("displayName", user.displayName);
      setValue("selfIntroduction", user.selfIntroduction);
      setValue("notice", user.notice);
      return;
    }

    setValue("displayName", stateUser.displayName);
    setValue("selfIntroduction", stateUser.selfIntroduction);
    setValue("notice", stateUser.notice);
  }, [user]);

  useEffect(() => {
    (async () => {
      if (user.isSignedIn) {
        try {
          const response = await fetchUserPrivate(user.uid);
          setUserPrivate(response);
        } catch (error) {
          logError(error);
          toast.error("情報の取得に失敗しました");
        }
      }
    })();
  }, [user]);

  useEffect(() => {
    setBirthYear(birthDisassembly(userPrivate?.dateOfBirth, "year"));
    setBirthMonth(birthDisassembly(userPrivate?.dateOfBirth, "month"));
    setBirthDay(birthDisassembly(userPrivate?.dateOfBirth, "day"));
    setGender(userPrivate?.gender ?? "empty");
  }, [userPrivate]);

  useEffect(() => {
    if (birthYear && birthMonth && birthDay) {
      const updatedDefaultValues = {
        ...defaultValues,
        birthYear: Number(birthYear),
        birthMonth: Number(birthMonth),
        birthDay: Number(birthDay),
      };
      methods.reset(updatedDefaultValues);
    }
    setValue("gender", gender);
  }, [birthYear, birthMonth, birthDay, gender]);

  return (
    <div className={classes.profileEditArea}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BrowserBackHeader label="プロフィール編集" browserBack={history} />
          <ProfileIconSelect setLoading={setLoading} />
          {loading && (
            <div className={classes.loadingArea}>
              <CircularProgress size={50} color="primary" thickness={2.5} />
            </div>
          )}
          <div className={classes.inputArea}>
            <Controller
              name="displayName"
              control={control}
              rules={{
                required: "必須です",
                minLength: {
                  value: 2,
                  message: "2文字以上で入力してください",
                },
                maxLength: {
                  value: 30,
                  message: "30文字以下で入力してください",
                },
              }}
              render={({ field }) => (
                <TextField
                  required
                  label="ユーザーネーム2文字以上30文字以内　※必須"
                  autoComplete="given-name"
                  fullWidth
                  {...field}
                />
              )}
            />
            {errors.displayName && (
              <Typography style={{ fontSize: 12, color: "red" }}>
                *{errors.displayName.message}
              </Typography>
            )}
          </div>
          <div className={classes.dateInputArea}>
            <Controller
              name="birthYear"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  className={`${classes.textField} ${classes.yearSelect}`}
                  label="生年"
                  variant="standard"
                  {...field}
                  value={field.value}
                >
                  {generateYearOptions()}
                </TextField>
              )}
            />
            <Controller
              name="birthMonth"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  className={`${classes.textField} ${classes.monthDaySelect}`}
                  label="月"
                  variant="standard"
                  {...field}
                  value={field.value}
                >
                  {generateMonthOptions()}
                </TextField>
              )}
            />
            <Controller
              name="birthDay"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  className={`${classes.textField} ${classes.monthDaySelect}`}
                  label="日"
                  variant="standard"
                  {...field}
                  value={field.value}
                >
                  {generateDayOptions(String(selectedMonth))}
                </TextField>
              )}
            />
          </div>

          <div className={classes.inputArea}>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  className={classes.textField}
                  label="性別"
                  fullWidth={true}
                  variant="standard"
                  {...field}
                >
                  <MenuItem value="empty">選択しない</MenuItem>
                  <MenuItem value="man">男性</MenuItem>
                  <MenuItem value="woman">女性</MenuItem>
                  <MenuItem value="other">その他</MenuItem>
                </TextField>
              )}
            />
          </div>
          <div className={classes.introAndAccount}>
            <div className={classes.introductionArea}>
              <Typography
                style={{
                  marginBottom: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                自己紹介
                <span
                  style={{
                    color: selfIntroductionLength > 1000 ? "red" : "inherit",
                  }}
                >
                  {selfIntroductionLength}/1000文字
                </span>
              </Typography>
              <Controller
                name="selfIntroduction"
                control={control}
                rules={{
                  maxLength: {
                    value: 1000,
                    message: "1000文字以下で入力してください",
                  },
                }}
                render={({ field }) => (
                  <TextareaAutosize
                    {...field}
                    minRows={7}
                    placeholder="自己紹介"
                    className={classes.profileText}
                  />
                )}
              />
              {errors.selfIntroduction && (
                <Typography style={{ fontSize: 12, color: "red" }}>
                  *{errors.selfIntroduction.message}
                </Typography>
              )}
            </div>
          </div>

          <Controller
            name="notice"
            control={control}
            render={({ field }) => (
              <ProfileInfoCatch
                defaultValue={defaultValues.notice}
                field={field}
              />
            )}
          />
          <SpaceBox height={50} />

          <Center>
            <GrayButton onClick={btnClick}>登録する</GrayButton>
          </Center>
          <SpaceBox height={50} />
          <button id="noneBtn" className={classes.btn}>
            submit
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
