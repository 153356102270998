import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { selectUser } from "../features/userSlice";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
const Home: React.FC = () => {
  const { push } = useHistory();
  const user = useSelector(selectUser);

  const useStyles = makeStyles({});
  const classes = useStyles();

  return (
    <div>
      Home画面
      <Button color="primary" onClick={() => push("/?")}>
        Topへ
      </Button>
      <Button color="primary" onClick={() => push("/signin")}>
        Loginへ
      </Button>
      <Button color="primary" onClick={() => signOut(auth)}>
        Logout
      </Button>
      <Button color="primary" onClick={() => push("/methodtest")}>
        メソッドテスト
      </Button>
      <Button color="primary" onClick={() => push("/post/mention-to")}>
        PostTo
      </Button>
      <Button color="primary" onClick={() => push("/signup/setprofileimage")}>
        setProfileImage
      </Button>
      <Button color="primary" onClick={() => push("/comment")}>
        comment
      </Button>
      <Button color="primary" onClick={() => push("/eeyan")}>
        うぃいいね
      </Button>
      <Button color="primary" onClick={() => push("/memerimageedit")}>
        memeredit
      </Button>
      <p>ログインしているユーザー名は{user.uid}です。</p>
      <p>roleは{user.role}です</p>
      <span className={"x"}></span>
    </div>
  );
};

export default Home;
