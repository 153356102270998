import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import { UserIcon } from "../UIKit/UserIcon";
import { Name } from "../UIKit/Name";
import { TopFooter } from "../Footer/TopFooter";
// import algoliasearch from "algoliasearch";
// import { MultipleQueriesQuery } from "@algolia/client-search";
import "instantsearch.css/themes/satellite-min.css";
import {
  fetchCreatorList,
  fetchFirstOrLastId,
} from "../../features/creatorOperation";
import { CreatorList as TypeCreatorList } from "../../Types/userType";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { selectUser } from "../../features/userSlice";
import { RouteComponentProps } from "react-router-dom";
import { SpaceBox } from "../UIKit/SpaceBox";
import { CreatorListSkeleton } from "./CreatorListSkeleton";
import { toast } from "react-toastify";
import { CreatorPagination } from "./CreatorPagination";

export const CreatorList = (props: RouteComponentProps) => {
  const { history } = props;
  // const app_id: string = process.env.REACT_APP_ALGOLIA_ID!;
  // const admin_id: string = process.env.REACT_APP_ALGOLIA_ADMIN_KEY!;
  // const algoliaClient = algoliasearch(app_id, admin_id);
  const useStyles = makeStyles({
    creatorListContainer: {
      width: 500,
      margin: "0 auto",
      zIndex: 5,

      "@media screen and (max-width: 499px)": {
        width: "100vw",
      },
    },
    creatorList: {
      marginTop: 30,
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "white",
    },
    creator: {
      width: "33.333333%",
    },
    creatorImage: {
      margin: "0 auto",
    },
    creatorCover: {
      backgroundColor: "white",
      height: 20,
    },
    paginationBox: {
      display: "flex",
    },
    prev: {
      flex: 1,
    },
    next: {
      flex: 1,
    },
    prevBtn: {},
    nextBtn: {},
    arrow: {
      width: 30,
      height: 30,
      display: "block",
      margin: "0 auto",
      cursor: "pointer",
    },
    center: {
      flex: 1,
    },
  });
  const classes = useStyles();
  const clientUser = useSelector(selectUser);
  const [usersList, setUsersList] = useState<TypeCreatorList[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // skeleton
  const [lastId, setLastId] = useState<string>(""); //creatorの最後のuidを格納している
  const [firstId, setFirstId] = useState<string>(""); //creatorの最後のuidを格納している
  const [readLastId, setReadLastId] = useState<string>(""); // 最新で読み込んでるcreatorの最後のuidを格納、lastIdと比較してnext矢印を消してる
  const [readFirstId, setReadFirstId] = useState<string>(""); // 最新で読み込んでるcreatorの最初のuidを格納、lastIdと比較してnext矢印を消してる
  const [arrowCount, setArrowCount] = useState<number>(1); //ページ管理
  const [lastReadCreator, setLastReadCreator] = useState<Date>(); //次のページに行く時に今読み込んでるcreatorの最後のcreatedAt
  const [firstReadCreator, setFirstReadCreator] = useState<Date>(); //次のページに行く時に今読み込んでるcreatorの最初のcreatedAt
  const skeletonLength: number[] = Array(9).fill(0);
  const { push } = useHistory();

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        if (isMounted) {
          const creatorListResponse = await fetchCreatorList();
          setLastReadCreator(creatorListResponse.lastLeadCreator);
          setFirstReadCreator(creatorListResponse.firstRadCreator);
          setReadLastId(creatorListResponse.lastId);
          setReadFirstId(creatorListResponse.firstId);
          setUsersList(creatorListResponse.creatorList);
          setLoading(false);
          setLastId(await fetchFirstOrLastId("asc"));
          setFirstId(await fetchFirstOrLastId("desc"));
        }
      } catch (error) {
        if (isMounted) {
          toast.error("データの取得に失敗しました。");
        }
      }
    })();
    return () => {
      isMounted = false; // コンポーネントがアンマウントされたらフラグをfalseに
    };
  }, []);

  // const mock = {
  //   hits: [],
  //   nbHits: 0,
  //   nbPages: 0,
  //   page: 0,
  //   processingTimeMS: 0,
  // };

  // const searchClient = {
  //   ...algoliaClient,
  //   search(requests: MultipleQueriesQuery[]) {
  //     if (requests.every(({ params }) => !params?.query)) {
  //       return Promise.resolve(mock);
  //     }
  //     return algoliaClient.search(requests);
  //   },
  // };

  return (
    <div className={classes.creatorListContainer}>
      <BrowserBackHeader label="Creator List" browserBack={history} />
      <TopFooter effect="creatorList" />
      {/* <InstantSearch searchClient={searchClient} indexName="tomeme">
        <Configure
          hitsPerPage={5}
          // analytics={false}
          // enablePersonalization={false}
          // distinct
        />
        <SearchBox />
        <SearchResult />
      </InstantSearch> */}
      <div className={classes.creatorList}>
        {skeletonLength.map(
          (_, index) => loading && <CreatorListSkeleton key={index} />
        )}
        {usersList.map((user, idx) => (
          <div key={idx} className={classes.creator}>
            <UserIcon
              photoUrl={user.photoUrl}
              width="98px"
              height="98px"
              margin="0 auto"
              frameWidth="98px"
              onClick={() => {
                push(`/profile/${user.uid}/${clientUser.uid}`);
              }}
            />
            <Name
              displayName={user.displayName}
              marginTop="5px"
              fontSize="12px"
            />
          </div>
        ))}
      </div>
      <SpaceBox height={20} />
      <CreatorPagination
        setUsersList={setUsersList}
        setArrowCount={setArrowCount}
        arrowCount={arrowCount}
        lastId={lastId!}
        firstId={firstId!}
        lastReadCreator={lastReadCreator!}
        setLastReadCreator={setLastReadCreator}
        firstReadCreator={firstReadCreator!}
        setFirstReadCreator={setFirstReadCreator}
        setLoading={setLoading}
        readLastId={readLastId}
        setReadLastId={setReadLastId}
        readFirstId={readFirstId}
        setReadFirstId={setReadFirstId}
      />
      <SpaceBox height={100} />
    </div>
  );
};
