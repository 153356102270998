import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { UserFollowInfo } from "../MyPage/UserFollowInfo";
import { SelfIntroduction } from "../MyPage/SelfIntroduction";
import { MyPageFollowBtn } from "../UIKit/MyPageFollowBtn";
import { MeMerImage } from "../MyPage/MeMerImage";
import { SortPost } from "../../templates/Post/SortPost";
import { MyFollowInfo } from "../MyPage/MyFollowInfo";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { TopFooter } from "../Footer/TopFooter";
import { colorConvert } from "../../features/helpers/profileHelper";
import { UserProfileType } from "../../Types/userType";
import { useHistory } from "react-router-dom";
import { SpaceBox } from "../UIKit/SpaceBox";

type Props = {
  userInfo: UserProfileType;
  uid: string;
};

export const CreatorProfile = (props: Props) => {
  const { userInfo, uid } = props;
  const history = useHistory();
  const [followCount, setFollowCount] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);
  const backColor = colorConvert(userInfo.profileColor!);
  const useStyles = makeStyles({
    creatorProfileBody: {
      width: 500,
      overflowX: "hidden",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    creatorTopIntroduction: {
      backgroundColor: backColor,
    },
    creatorName: {
      fontSize: 20,
      fontWeight: "bold",
      width: "85%",
      margin: "10px auto ",
      "& p": {
        margin: "0 0",
      },
    },
    creatorIntroduction: {
      fontSize: 13,
      width: "90%",
      margin: "0 auto",
    },
    creatorIconArea: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 230,
      height: 230,
      borderRadius: 15,
      backgroundColor: "white",
      "@media screen and (max-width: 499px)": {
        width: 200,
        height: 200,
      },
    },
    creatorIcon: {
      width: 180,
      height: 180,
      margin: "25px 0 0 25px",
      borderRadius: 15,
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: 150,
        height: 150,
      },
    },
    followArea: {
      margin: "0 auto",
      width: "60%",
    },
    creatorColorChangeArea: {
      height: 230,
      position: "relative",
    },
    imageBackground: {
      backgroundColor: backColor,
      height: 115,
    },
    whiteArea: {
      height: 115,
    },
    text: {
      fontSize: 12,
      lineHeight: 1.8,
    },
    nameText: {
      fontWeight: "bold",
      fontSize: 18,
    },
  });
  const classes = useStyles();
  const topProfile = userInfo.topProfile
    .split(/(\n)/)
    .map((item: string, index: number) => {
      return (
        <React.Fragment key={index}>
          {item.match(/\n/) ? <br /> : item}
        </React.Fragment>
      );
    });

  const bottomProfile = userInfo.bottomProfile
    .split(/(\n)/)
    .map((item: string, index: number) => {
      return (
        <React.Fragment key={index}>
          {item.match(/\n/) ? <br /> : item}
        </React.Fragment>
      );
    });

  const isFollow = (id?: string, myId?: string) => {
    if (!id || !myId) return false;
    if (myId !== id) return true;
    return false;
  };

  useEffect(() => {
    // setPostSwitch(true);
    setFollowCount(userInfo.followCount);
    setFollowerCount(userInfo.followerCount);
  }, []);

  return (
    <div className={classes.creatorProfileBody}>
      <BrowserBackHeader label={userInfo.displayName} browserBack={history} />
      <div className={classes.creatorTopIntroduction}>
        <SpaceBox height={10} />
        <div className={classes.creatorName}>
          <Typography className={classes.nameText}>
            {userInfo.displayName}
          </Typography>
        </div>
        <SpaceBox height={10} />
        <div className={classes.creatorIntroduction}>
          <Typography className={classes.text}>{topProfile}</Typography>
        </div>
      </div>
      <SpaceBox height={30} backgroundColor={backColor} />
      <div className={classes.creatorColorChangeArea}>
        <div className={classes.imageBackground} />
        <div className={classes.creatorIconArea}>
          <img
            src={userInfo.photoUrl}
            className={classes.creatorIcon}
            alt="creatorIcon"
          />
        </div>
        <div className={classes.whiteArea} />
      </div>
      <div className={classes.followArea}>
        {isFollow(userInfo.uid, uid) ? (
          <UserFollowInfo
            follow={followCount}
            follower={followerCount}
            post={userInfo.postCount}
            uid={userInfo.uid}
          />
        ) : (
          <MyFollowInfo uid={userInfo.uid} />
        )}
      </div>
      <SelfIntroduction bottomProfile={bottomProfile} />
      {/* {isFollow(userInfo.uid, uid) ? (
        <MyPageFollowBtn
          anotherUser={userInfo.uid}
          uid={uid}
          setFollowerCount={setFollowerCount}
        />
      ) : (
        <SpaceBox height={20} />
        )} */}
      <SpaceBox height={20} />
      <MeMerImage images={userInfo.images} color={backColor} />
      <SortPost uid={userInfo.uid} />
      <TopFooter uid={userInfo.uid} effect="mypage" />
    </div>
  );
};
