import { makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import "../../../vertical.css";
import "../../../assets/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { ArrayImages } from "./ArrayImages";
import { SpaceBox } from "../../../components/UIKit/SpaceBox";

type Props = {
  images: string[];
};

export const ProductSlider = (props: Props) => {
  const { images } = props;
  const useStyles = makeStyles({
    imageArea: {
      width: 500,
      height: 625,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
        height: 468.75,
      },
    },
    img: {
      width: 500,
      height: 625,
      display: "block",
      margin: "0 auto",
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: "100vw",
        height: 468.75,
      },
      outline: "none",
      pointerEvents: "none",
    },
    "slick-prev": {
      "& :before": {
        color: "rgba(106, 106, 106, 0.7)",
      },
    },
    imgMini: {
      width: 80,
      height: 100,
      margin: 2,
      objectFit: "contain",
      cursor: "pointer",
      "@media screen and (max-width: 499px)": {
        width: 60,
        height: 75,
        margin: 1,
      },
    },
    miniArea: {
      display: "inline-block",
    },
    imageModal: {
      width: 400,
      height: 500,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: 300,
        height: 375,
      },
      outline: "none",
    },
    modalImage: {
      width: 400,
      height: 500,
      margin: "0 auto",
      display: "block",
      objectFit: "cover",
      "@media screen and (max-width: 499px)": {
        width: 300,
        height: 375,
      },
      "&amp;:focus": {
        outline: "none",
      },
      "&:active": {
        outline: "none",
      },
    },
    closeBtn: {
      width: 50,
      height: 50,
      marginTop: 100,
      color: "rgb(151,151,151)",
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  const SliderWrapper = styled.div`
    .slick-prev {
      left: 5px;
      z-index: 1;
    }
    .slick-next {
      right: 5px;
    }
    .slick-prev:before,
    .slick-next:before {
      color: rgba(40, 40, 40, 0.7);
    }
  `;
  const setting = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div className={classes.imageArea}>
        <SliderWrapper>
          <Slider {...setting}>
            {images.map(
              (img: string, index: number) =>
                img !== "" && (
                  <div key={index}>
                    <img src={img} className={classes.img} alt="productImage" />
                  </div>
                )
            )}
          </Slider>
        </SliderWrapper>
      </div>
      <SpaceBox height={30} />
      <ArrayImages images={images} />
    </div>
  );
};
