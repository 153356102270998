import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import { WantProjects } from "../../templates/Want/WantProjects";

export const WantPage = (props: RouteComponentProps<{ uid: string }>) => {
  const { history } = props;
  return (
    <div>
      <BrowserBackHeader label="ほしいプロジェクト一覧" browserBack={history} />
      <WantProjects />
    </div>
  );
};
