import { Typography, makeStyles } from "@material-ui/core";
import { newLineConvertWithUrl } from "../../features/helpers/textHelper";

type Props = {
  label: string;
};

export const PostText = (props: Props) => {
  const { label } = props;
  const useStyles = makeStyles({
    postTextArea: {
      margin: "0 auto",
      padding: "10px 5px",
      "& p": {
        fontSize: 12,
        color: "black",
        wordBreak: "break-all",
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.postTextArea}>
      <Typography>{newLineConvertWithUrl(label)}</Typography>
    </div>
  );
};
