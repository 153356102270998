import React from 'react'
import { AppThunk } from '../app/store'

// オープン
export const toggleModal = (
  modalIsOpen: any,
  setModalIsOpen: any
): AppThunk => {
  return async (dispatch): Promise<void> => {
    setModalIsOpen(!modalIsOpen)
  }
}
// 戻る時に使ってる
export const toggleModal2 = (
  modalIsOpen2: any,
  setModalIsOpen2: any
): AppThunk => {
  return async (dispatch): Promise<void> => {
    setModalIsOpen2(!modalIsOpen2)
  }
}

export const memerListToggleModal = (
  modalIsOpen: any,
  setModalIsOpen: any
): AppThunk => {
  return async (dispatch): Promise<void> => {
    setModalIsOpen(!modalIsOpen)
  }
}

export const addressEditModalClose = (
  openEdit: any,
  setOpenEdit: any
): AppThunk => {
  return async (dispatch): Promise<void> => {
    setOpenEdit(false)
  }
}
