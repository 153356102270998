import { Header } from "../../Header/Header";
import { Done } from "../../../templates/Done/Done";
export const InquiryDone = () => {
  return (
    <div>
      <Header label="お問い合わせ" transitionLocation={"/?"} />
      <Done
        info={`ありがとうございました。\n返信までいましばらくお待ちください。`}
        linkText="TOPページへ"
      />
    </div>
  );
};
