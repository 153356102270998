import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { CartProduct } from "../../Types/productType";

interface PROPS {
  cartData: CartProduct[];
}

const TotalInCart = (props: PROPS) => {
  const useStyles = makeStyles({
    priceArea: {
      padding: "0 80px",
    },
    centeredBlock: {
      display: "flex",
    },
    left: {
      flex: 1,
    },
    right: {
      flex: 1,
    },
  });
  const classes = useStyles();

  const totalPriceCalculation = () => {
    let price = 0;
    props.cartData.map((cd: CartProduct, index: number) => {
      const singlePrice = cd.retailPrice * cd.quantity;
      price += singlePrice;
    });

    return price;
  };

  // 商品合計
  const totalPrice = totalPriceCalculation();
  // 送料
  const postage = 500;
  // 消費税込み合計金額
  const taxAndPostageInTotalPrice = Math.floor(totalPrice + postage);

  return (
    <div className={classes.priceArea}>
      {/* <div className={classes.centeredBlock}>
        <div className={classes.left}>商品合計</div>
        <div className={classes.right}>
          <Typography align="right">¥ {totalPrice.toLocaleString()}</Typography>
        </div>
      </div> */}
      <div className={classes.centeredBlock}>
        <div className={classes.left}>送料</div>
        <div className={classes.right}>
          <Typography align="right">¥ {postage.toLocaleString()}</Typography>
        </div>
      </div>
      {/* <div className={classes.centeredBlock}>
        <div className={classes.left}>消費税</div>
        <div className={classes.right}>
          <Typography align="right">¥ {tax.toLocaleString()}</Typography>
        </div>
      </div> */}
      <div className={classes.centeredBlock}>
        <div className={classes.left}>合計（税込）</div>
        <div className={classes.right}>
          <Typography align="right">
            ¥ {taxAndPostageInTotalPrice.toLocaleString()}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TotalInCart;
