import { useCallback, useEffect, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { follow, unFollow } from "../../features/socialOperation";
import { followsCollection } from "../../firebasePaths";
import { onSnapshot } from "firebase/firestore";

type Props = {
  anotherUser: string;
  setFollowerCount?: React.Dispatch<React.SetStateAction<number>>;
  uid?: string;
};

export const MyPageFollowBtn = (props: Props) => {
  const { anotherUser, uid, setFollowerCount } = props;
  const useStyles = makeStyles((Theme: any) =>
    createStyles({
      userFollow: {
        margin: "30px 10px",
        "@media screen and (max-width: 499px)": {},
      },
      followBtn: {
        border: "1px solid rgb(175, 175, 175)",
        borderRadius: 0,
        display: "block",
        margin: "0 auto",
        backgroundColor: "rgb(175, 175, 175)",
        color: "white",
        width: 130,
        fontSize: 13,
        textAlign: "center",
        paddingTop: 5,
        paddingBottom: 5,
        "&:hover": {
          color: "black",
        },
      },
      notFollowBtn: {
        borderRadius: 0,
        display: "block",
        margin: "0 auto",
        color: "black",
        width: 130,
        fontSize: 12,
        textAlign: "center",
        paddingTop: 5,
        paddingBottom: 5,
      },
      notHover: {},
    })
  );
  const classes = useStyles();
  const [isFollowed, setIsFollowed] = useState(false);
  const [followStates, setFollowStates] = useState("フォロー中");
  const isFollowedCallback = useCallback(
    (followed: boolean) => {
      setIsFollowed(followed);
    },
    [setFollowStates]
  );

  const changeText = (): void => {
    setFollowStates("フォローを外す");
  };
  const defaultText = () => {
    setFollowStates("フォロー中");
  };

  useEffect(() => {
    let isMounted: boolean = true;
    if (uid) {
      const followsRef = followsCollection(uid);
      onSnapshot(followsRef, (snapshot) => {
        snapshot.docs.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          // docのuidとpostのuidがあればフォローしていると判断
          if (data.followerUid === anotherUser && isMounted) {
            isFollowedCallback(true);
          }
        });
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={classes.userFollow}>
      {isFollowed ? (
        <Button
          variant="outlined"
          className={classes.followBtn}
          onClick={() => {
            unFollow(anotherUser, setIsFollowed);
            setFollowerCount && setFollowerCount((prev) => prev - 1);
          }}
          onMouseEnter={changeText}
          onMouseLeave={defaultText}
        >
          <span className={classes.notHover} id="changeFollow">
            {followStates}
          </span>
        </Button>
      ) : (
        <Button
          variant="outlined"
          className={classes.notFollowBtn}
          onClick={() => {
            follow(anotherUser, setIsFollowed);
            setFollowerCount && setFollowerCount((prev) => prev + 1);
          }}
        >
          フォローする
        </Button>
      )}
    </div>
  );
};
