import React from "react";
import { makeStyles } from "@material-ui/core";

import visa from "../../assets/icon/VISA.png";
import master from "../../assets/icon/Master.png";
import jcb from "../../assets/icon/JCB.png";
import discover from "../../assets/icon/discover.png";
import diners from "../../assets/icon/Diner.png";
import american from "../../assets/icon/American.png";

interface PROPS {
  creditBrand: string;
  margin?: string;
}

const CreditCardViesList = (props: PROPS) => {
  const useStyles = makeStyles({
    cardImage: {
      // width: 65.115,
      // height: 44.379,
      width: 52.092,
      height: 35.5,
      margin: props.margin,
    },
  });
  const classes = useStyles();

  const creditBrand = props.creditBrand;
  return (
    <div>
      {creditBrand === "visa" && (
        <img src={visa} alt="visaCard" className={classes.cardImage} />
      )}
      {creditBrand === "mastercard" && (
        <img src={master} alt="masterCard" className={classes.cardImage} />
      )}
      {creditBrand === "discover" && (
        <img src={discover} alt="discoverCard" className={classes.cardImage} />
      )}
      {creditBrand === "jcb" && (
        <img src={jcb} alt="jcbCard" className={classes.cardImage} />
      )}
      {creditBrand === "amex" && (
        <img src={american} alt="amexCard" className={classes.cardImage} />
      )}
      {creditBrand === "diners" && (
        <img src={diners} alt="dinersCard" className={classes.cardImage} />
      )}
    </div>
  );
};

export default CreditCardViesList;
