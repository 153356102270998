import React from "react";
import { makeStyles } from "@material-ui/core";
import airPlain from "../../assets/icon/airplain.png";
import { MentionUser } from "./MentionUser";
import { MentionUserData } from "../../Types/userType";

type Props = {
  users: MentionUserData[];
};
export const MentionPost = (props: Props) => {
  const { users } = props;
  const useStyles = makeStyles({
    mentionToArea: {
      marginTop: 15,
      display: "flex",
      width: "100%",
    },
    left: {
      flex: "0 0 50px",
      "@media screen and (max-width: 499px)": {
        marginRight: 5,
      },
    },
    right: {
      flex: "1 1 auto",
    },
    img: {
      width: 30,
      height: 30,
      marginLeft: 5,
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.mentionToArea}>
      <div className={classes.left}>
        <img src={airPlain} className={classes.img} alt="plain" />
      </div>
      <div className={classes.right}>
        <MentionUser users={users} />
      </div>
    </div>
  );
};
