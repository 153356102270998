import { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { animateScroll as scroll } from "react-scroll";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import * as textContents from "./supportUtils";
import { RouteComponentProps } from "react-router-dom";
import { SpaceBox } from "../UIKit/SpaceBox";

const TermsOfService = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    termsArea: {
      padding: "0 10px",
    },
    oneBlock: {
      marginBottom: 50,
    },
    textBold: {
      fontSize: 14,
      fontWeight: "bold",
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    text: {
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
  });
  const classes = useStyles();

  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 0 });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      {/* <Header label={'利用規約'} transitionLocation={'/mypage'} /> */}
      <BrowserBackHeader label="利用規約" browserBack={history} />
      <SpaceBox height={55} />
      <div className={classes.termsArea}>
        <div className={classes.oneBlock}>
          {textContents
            .termsOfServicetextContents()
            .map((tc: any, idx: number) => (
              <>
                <Typography className={classes.textBold}>
                  {tc.caption}
                </Typography>
                <Typography className={classes.text}>
                  {textContents.enterSplitTextContent(tc.contents)}
                </Typography>
                <br />
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
