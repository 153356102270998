import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { MentionUserData } from "../../Types/userType";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";

type Props = {
  users: MentionUserData[];
};

export const MentionUser = (props: Props) => {
  const { users } = props;
  const uid = useSelector(selectUser).uid;
  const useStyles = makeStyles({
    mentionArea: {
      width: 400,
      display: "flex",
      position: "relative",
      "@media screen and (max-width:@media screen and (max-width: 499px))": {
        width: 250,
      },
    },
    left: {
      flex: 1,
    },
    right: {
      flex: 2,
    },
    img: {
      width: 30,
      height: 30,
      marginLeft: 20,
      // '@media screen and (max-width:@media screen and (max-width: 499px))': {
      //   marginLeft: 5,
      // },
    },
    userName: {
      marginTop: 7,
      fontWeight: "bold",
      // cursor: 'pointer',
      fontSize: 12,
      "&:hover": {
        color: "rgb(175,175,175)",
      },
      "@media screen and (max-width: 499px)": {
        marginTop: 8,
        fontSize: 10,
      },
    },
    userNameNext: {
      display: "inline-block",
      marginTop: 5,
      fontWeight: "bold",
      // cursor: 'pointer',
      "&:hover": {
        color: "rgb(175,175,175)",
      },
      "@media screen and (max-width: 499px)": {
        marginTop: 6,
        fontSize: 14,
      },
    },
    nextUserLength: {
      marginTop: 5,
      fontWeight: "bold",
      cursor: "pointer",
      color: "rgb(146,200,226)",
      borderBottom: "1px solid rgb(146, 200, 226)",
      "@media screen and (max-width: 499px)": {
        marginTop: 6,
        fontSize: 14,
      },
    },
    hoverBlock: {
      display: "flex",
      position: "absolute",
      top: 40,
      left: 0,
      width: 380,
      height: 30,
      backgroundColor: "white",
      border: "1px solid gray",
      borderRadius: 10,
      overflowX: "auto",
      whiteSpace: "nowrap",
      padding: "20px",
      "@media screen and (max-width: 499px)": {
        width: 275,
      },
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    mentionBox: {
      display: "flex",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(175,175,175)",
      },
    },
    nextMentionBox: {
      display: "flex",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(175,175,175)",
      },
    },
    iconBox: {
      marginRight: 10,
    },
    iconBorder: {
      width: 30,
      height: 30,
    },
    icon: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 5,
      border: "1px solid gray",
    },
    test: {},
  });
  const classes = useStyles();
  const { push } = useHistory();
  const [initMention, setInitMention] = useState<MentionUserData[]>([]);
  const [nextMention, setNextMention] = useState<MentionUserData[]>([]);
  const [mentionList, setMentionList] = useState<boolean>(false);

  useEffect(() => {
    users.map((u: any, index: number) =>
      index < 2
        ? setInitMention((prev: any) => [...prev, u])
        : setNextMention((prev: any) => [...prev, u])
    );
  }, []);

  const change = () => {
    setMentionList(!mentionList);
  };

  return (
    <div className={classes.mentionArea}>
      {initMention.map((im, index) => (
        <div
          className={classes.mentionBox}
          onClick={() => push(`/profile/${im.uid}/${uid}`)}
          key={index}
        >
          <div className={classes.iconBox}>
            <div className={classes.iconBorder}>
              <img src={im.photoUrl} alt="" className={classes.icon} />
            </div>
          </div>
          <Typography className={classes.userName}>{`${
            im.displayName.length >= 8
              ? im.displayName.slice(0, 5) + `...　`
              : im.displayName
          }　`}</Typography>
        </div>
      ))}
      {nextMention.length > 0 && (
        <>
          <div className={classes.nextUserLength} onClick={() => change()}>
            {mentionList ? `閉じる` : `+${nextMention.length}`}
          </div>
        </>
      )}
      {mentionList && (
        <div className={classes.hoverBlock}>
          {nextMention.map((nm: any) => (
            <div
              className={classes.nextMentionBox}
              onClick={() => push(`/profile/${nm.uid}`)}
            >
              <div className={classes.iconBox}>
                <div className={classes.iconBorder}>
                  <img src={nm.photoUrl} alt="" className={classes.icon} />
                </div>
              </div>
              <Typography
                className={classes.userNameNext}
              >{`${nm.displayName}　`}</Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

