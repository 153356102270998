import { UnpaidReserve } from "../../Types/userType";

export type InquirySelectValue =
  | "app"
  | "coupon"
  | "productDeliveryChange"
  | "reservationSizeOrColorChange"
  | "other";

export const INQUIRY_KIND = [
  { label: "アプリについて", value: "app" },
  { label: "クーポンについて", value: "coupon" },
  { label: "商品発送の発送先変更", value: "productDeliveryChange" },
  {
    label: "予約商品のサイズまたはカラー変更",
    value: "reservationSizeOrColorChange",
  },
  { label: "その他お問合せ", value: "other" },
];

export const generateInquiryText = (
  data: UnpaidReserve,
  kind: InquirySelectValue
) => {
  if (kind === "coupon") return generateCouponText(data);
  if (kind === "productDeliveryChange") return generateDeliveryChangeText(data);
  if (kind === "reservationSizeOrColorChange")
    return generateSizeOrColorChangeText(data);
  return "";
};

const generateCouponText = (data: UnpaidReserve) => {
  return `---------------\n【件名】 クーポンについて\n【予約番号】 ${data.rpid}\n【商品名】 ${data.projectName}\n【型番】 ${data.productNumber}\n※適応するクーポンコードを入力してください\n【クーポンコード】 \n【備考】 \n---------------`;
};

const generateDeliveryChangeText = (data: UnpaidReserve) => {
  return `---------------\n【件名】 商品発送の発送先変更\n【予約番号】 ${data.rpid}\n【商品名】 ${data.projectName}\n【型番】 ${data.productNumber}\n【現在の発送予定住所】 ${data.address}\n※アプリ内の住所登録もお願いいたします。\n【変更後の郵便番号】 〒\n【変更後の住所】 \n【備考】 \n---------------`;
};

const generateSizeOrColorChangeText = (data: UnpaidReserve) => {
  const colors = data.reservedProducts.map((product) => {
    const { color } = product;
    const sizes = product.detail.map((detail) => detail.size);
    return `${color}(${sizes.join(", ")})`;
  });

  return `---------------\n【件名】 予約商品のサイズまたはカラー変更\n【予約番号】 ${
    data.rpid
  }\n【商品名】 ${data.projectName}\n【型番】 ${
    data.productNumber
  }\n【現在の予約されているサイズとカラー】 ${colors.join(
    ", "
  )}\n【変更後のサイズとカラー】 \n【備考】 \n---------------`;
};
