import { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { animateScroll as scroll } from "react-scroll";
import { BrowserBackHeader } from "../Header/BrowserBackHeader";
import * as textContents from "./supportUtils";
import { RouteComponentProps } from "react-router-dom";
import { SpaceBox } from "../UIKit/SpaceBox";

const PrivacyPolicy = (props: RouteComponentProps) => {
  const { history } = props;
  const useStyles = makeStyles({
    termsArea: {
      padding: "0 10px",
    },
    oneBlock: {
      marginBottom: 50,
    },
    textBold: {
      fontSize: 14,
      fontWeight: "bold",
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
    text: {
      fontSize: 14,
      "@media screen and (max-width: 499px)": {
        fontSize: 12,
      },
    },
  });
  const classes = useStyles();
  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 0 });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <BrowserBackHeader label="プライバシーポリシー" browserBack={history} />
      <SpaceBox height={55} />
      <div className={classes.termsArea}>
        <div className={classes.oneBlock}>
          <Typography className={classes.text}>
            株式会社bodaijyu（以下「当社」といいます）は、個人情報の保護に関する法律（以下「個人情報保護法」といいます）その他関連法令等を遵守し、個人情報保護法第２条第１項によって定義された個人情報を適正に取り扱うことが、企業の重要な社会的責務であるとの認識に立ち、以下の規定に従い、当社の提供するサービスにおける個人情報、ユーザーの登録情報その他ユーザーが当社に対して提供した情報（以下「個人情報等」といいます。）の適切な取扱い及び保護に努めます。
          </Typography>
        </div>
        <div className={classes.oneBlock}>
          {textContents.privacyPolicy().map((tc, idx) => (
            <div key={idx}>
              <Typography className={classes.textBold}>{tc.caption}</Typography>
              <Typography className={classes.text}>
                {textContents.enterSplitTextContent(tc.contents)}
              </Typography>
              <br />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
