import { makeStyles, TextareaAutosize } from "@material-ui/core";
import { SpaceBox } from "../../UIKit/SpaceBox";

interface PROPS {
  label: string;
  redLabel?: string;
  error?: string;
  field: object;
  place: string;
}

const TextArea = (props: PROPS) => {
  const useStyles = makeStyles({
    textAreaContainer: {
      padding: "0 10px 0 10px",
    },
    textInfo: {
      height: 20,
    },
    labelBlack: {
      color: "block",
      fontSize: 12,
    },
    labelRed: {
      color: "red",
      fontSize: 12,
    },
    textArea: {
      width: "96%",
      resize: "none",
      margin: "0 auto",
      display: "block",
      padding: 5,
      fontSize: 16,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.textAreaContainer}>
      <div className={classes.textInfo}>
        <p>
          <span className={classes.labelBlack}>{props.label}</span>
          <span className={classes.labelRed}>{props.redLabel}</span>
          {props.error && (
            <span className={classes.labelRed}>{props.error}</span>
          )}
        </p>
      </div>
      <SpaceBox height={10} />
      <TextareaAutosize
        className={classes.textArea}
        rows={8}
        placeholder={props.place}
        {...props.field}
      />
      <SpaceBox height={30} />
    </div>
  );
};

export default TextArea;
