import { Button, makeStyles } from "@material-ui/core";
import ReserveInfo from "../Product/ReserveParts/ReserveInfo";
import { CartProduct } from "../../Types/productType";
import CartItem from "../Cart/CartItem";
import TotalInCart from "../Cart/TotalInCart";
import { SpaceBox } from "../../components/UIKit/SpaceBox";

type Props = {
  buySubmit: Function;
  handleClose: Function;
  cartData: CartProduct[];
};

const CartWithPurchaseConfirm = (props: Props) => {
  const { buySubmit, handleClose, cartData } = props;
  const useStyles = makeStyles({
    grayBack: {
      backgroundColor: "rgb(234,234,234)",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    reserveBox: {
      width: 498,
      // height: 250,
      paddingTop: 10,
      border: "1px solid black",
      margin: "0 auto",
      backgroundColor: "white",
      "@media screen and (max-width: 499px)": {
        width: 373,
      },
    },
    reserveConfirm: {
      height: 170,
      display: "flex",
    },
    left: {
      width: 180,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 135,
      },
    },
    right: {
      width: 320,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 240,
      },
    },
    imageBox: {
      margin: "0 auto",
      width: 120,
    },
    image: {
      width: 120,
      height: 150,
      objectFit: "cover",
    },
    buttonArea: {
      height: 50,
      display: "flex",
    },
    leftBtn: {
      // height: 20,
      width: "50%",
      margin: "0 5px 0 10px",
    },
    rightBtn: {
      width: "50%",
      margin: "0 10px 0 5px",
    },
    reserveBtn: {},
  });
  const classes = useStyles();

  // const total = Number(cartData.retailPrice) * cartData.purchaseQuantity;
  // const price = cartData.retailPrice.toLocaleString();
  // const totalPrice = total.toLocaleString();

  return (
    <div className={classes.reserveBox}>
      {/* <div className={classes.reserveConfirm}>
        <div className={classes.left}>
          <div className={classes.imageBox}>
            <img
              src={cartData.image}
              className={classes.image}
              alt="productImage"
            />
          </div>
        </div>
        <div className={classes.right}>
          <ReserveSheif label={"アイテム名"} detail={cartData.projectName} />
          <ReserveSheif label={"サイズ"} detail={cartData.size} />
          <ReserveSheif label={"カラー"} detail={cartData.color} />
          <ReservePrice
            // price={`¥${price}`}
            // totalPrice={`¥${totalPrice}`}
            price={"10,000"}
            totalPrice={"10,000"}
            quantity={cartData.purchaseQuantity}
          />
        </div>
      </div> */}
      {cartData.map((cd: CartProduct, index: number) => (
        <div key={index}>
          <CartItem cartData={cd} deleteBoolLength={2} />
        </div>
      ))}
      <SpaceBox height={10} />
      <TotalInCart cartData={cartData} />
      <SpaceBox height={10} />
      <ReserveInfo bgc={"white"} border={"1px solid gray"} />
      <SpaceBox height={13} />

      <div className={classes.buttonArea}>
        <div className={classes.leftBtn}>
          <Button
            className={classes.reserveBtn}
            variant="outlined"
            fullWidth={true}
            onClick={() => buySubmit()}
          >
            購入する
          </Button>
        </div>
        <div className={classes.rightBtn}>
          <Button
            className={classes.reserveBtn}
            variant="outlined"
            fullWidth={true}
            onClick={() => handleClose()}
          >
            閉じる
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartWithPurchaseConfirm;
