export const users = "users";
// users/uid
export const user = (userID: string) => [users, userID].join("/"); // usersとuserIDを引数にとってpath化する

export const userPosts = (userID: string) => [user(userID), "posts"].join("/"); // userと'posts'をpath化する users/userID/posts
// users/uid/posts/postID
export const userPost = (userID: string, postID: string) =>
  [userPosts(userID), postID].join("/"); // userと'likedPosts'をpath化する users/userID/likedPosts

export const purchaseHistoryPath = (userID: string, aid: string) =>
  [users, userID, "purchaseInfos", userID, "addresses", aid].join("/"); // usersとuserIDを引数にとってpath化する

// users/uid/projects
export const projects = (userID: string) =>
  [user(userID), "projects"].join("/");
// users/uid/projects/pid
export const project = (userID: string, projectID: string) =>
  [projects(userID), projectID].join("/");

export const projectPosts = (userID: string, projectID: string) =>
  [project(userID, projectID), "posts"].join("/");
export const projectPost = (
  userID: string,
  projectID: string,
  projectPostID: string
) => [projectPosts(userID, projectID), projectPostID].join("/");

// users/uid/likedPosts
export const likedPosts = (userID: string) =>
  [user(userID), "likedPosts"].join("/");
// users/uid/likedPosts/postID
export const likedPost = (userID: string, postID: string) =>
  [likedPosts(userID), postID].join("/");
// users/uid/posts/postID/likedUsers
export const likedUsers = (userID: string, postID: string) =>
  [userPost(userID, postID), "likedUsers"].join("/");
// users/uid/posts/postID/likedUsers/likedUserID
export const likedUser = (
  userID: string,
  postID: string,
  likedUserID: string
) => [likedUsers(userID, postID), likedUserID].join("/");

// users/uid/wantedPosts
export const wantedPosts = (userID: string) =>
  [user(userID), "wantedPosts"].join("/");
// users/uid/wantedPosts/postID
export const wantedPost = (userID: string, postID: string) =>
  [wantedPosts(userID), postID].join("/");
export const wantedUsers = (userID: string, postID: string) =>
  [userPost(userID, postID), "wantedUsers"].join("/");
export const wantedUser = (
  userID: string,
  postID: string,
  wantedUser: string
) => [wantedUsers(userID, postID), wantedUser].join("/");
// users/uid/follows
export const follows = (userID: string) => [user(userID), "follows"].join("/");
// users/uid/followers
export const followers = (userID: string) =>
  [user(userID), "followers"].join("/");

export const comments = (userID: string, postID: string) =>
  [userPost(userID, postID), "comments"].join("/");
export const comment = (userID: string, postID: string, commentID: string) =>
  [comments(userID, postID), commentID].join("/");

// users/uid/commentedPosts
export const commentedPosts = (userID: string) =>
  [user(userID), "commentedPosts"].join("/");
// users/uid/commentedPosts/commentID
export const commentedPost = (userID: string, commentID: string) =>
  [commentedPosts(userID), commentID].join("/");
export const commentedUsers = (userID: string, postID: string) =>
  [userPost(userID, postID), "commentedUsers"].join("/");
export const commentedUser = (
  userID: string,
  postID: string,
  commentUser: string
) => [commentedUsers(userID, postID), commentUser].join("/");

// reply用のコメントパス
export const replyComments = (
  userID: string,
  postID: string,
  commentID: string
) => [comments(userID, postID), commentID, "replyComments"].join("/");

export const purchaseInfos = (userID: string) =>
  [user(userID), "purchaseInfos"].join("/");
export const addresses = (userID: string) =>
  [purchaseInfos(userID), userID, "addresses"].join("/");
export const address = (userID: string, addressID: string) =>
  [addresses(userID), addressID].join("/");
export const credits = (userID: string) =>
  [purchaseInfos(userID), userID, "credits"].join("/");
export const credit = (userID: string, creditsID: string) =>
  [credits(userID), creditsID].join("/");

export const purchaseInfo = (userID: string, purchaseInfoID: string) =>
  [purchaseInfos(userID), purchaseInfoID].join("/");

// users/uid/unpaidReservers
export const unpaidReserves = (userID: string) =>
  [user(userID), "unpaidReserves"].join("/");
export const unpaidReserve = (userID: string, unpaidReserveID: string) =>
  [unpaidReserves(userID), unpaidReserveID].join("/");

export const settledReserves = (userID: string) =>
  [user(userID), "settledReserves"].join("/");
export const settledReserve = (userID: string, settledReserveID: string) =>
  [settledReserves(userID), settledReserveID].join("/");
