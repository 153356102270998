import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { SpaceBox } from "../../../components/UIKit/SpaceBox";
import { threeDimages } from "../../../Types/productType";

export const ProductThreeDAnother = (props: any) => {
  const { project } = props;
  const useStyles = makeStyles({
    avatarArea: {
      paddingTop: 20,
    },
    avatarTitle: {
      width: 220,
    },
    title: {
      fontSize: 16,
    },
    threeDImages: {
      position: "relative",
      width: 460,
      height: 690,
      border: "1px solid rgb(230, 230, 230)",
      margin: "10px auto",
      "@media screen and (max-width: 499px)": {
        width: 340,
        height: 558,
      },
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    threeSize: {
      position: "absolute",
      backgroundColor: "rgba(110,110,110, 0.6)",
      borderRadius: 3,
      bottom: 20,
      left: "50%",
      transform: "translateX(-50%)",
      width: "90%",
      padding: "3px 0",
      "@media screen and (max-width: 499px)": {
        bottom: 18,
      },
    },
    threeSizeText: {
      color: "white",
      fontSize: 12,
    },
  });
  const classes = useStyles();
  const threeDimages: threeDimages[] = project.threeDimages;
  return (
    <div className={classes.avatarArea}>
      {threeDimages.map((threeDImage) => (
        <>
          {threeDImage.wearingData.map((wearingData) => (
            <>
              {wearingData.imageUrl !== "" && (
                <>
                  <div className={classes.avatarTitle}>
                    <Typography className={classes.title}>
                      　{threeDImage.size}サイズ 身長{wearingData.height}cm
                    </Typography>
                  </div>
                  <div className={classes.threeDImages}>
                    <img
                      src={wearingData.imageUrl}
                      className={classes.img}
                      alt="threeDImages"
                    />
                    <div className={classes.threeSize}>
                      <Typography
                        align="center"
                        className={classes.threeSizeText}
                      >
                        Avatar : B {wearingData.bust}cm W {wearingData.waist}cm
                        H {wearingData.hip}cm
                      </Typography>
                    </div>
                  </div>
                </>
              )}
            </>
          ))}
          <SpaceBox height={20} />
        </>
      ))}
    </div>
  );
};
