import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Skelton } from "../atoms/Skelton/Skelton";

type Props = {
  photoUrl: string;
  float?: "left" | "right";
  margin?: number | string;
  width: number | string;
  height: number | string;
  sWidth?: number | string;
  sHeight?: number | string;
  frameWidth?: number | string;
  onClick?: () => void;
};

export const UserIcon = (props: Props) => {
  const {
    frameWidth,
    margin,
    width,
    height,
    float,
    sWidth,
    sHeight,
    photoUrl,
    onClick,
  } = props;

  const useStyles = makeStyles({
    userIcon: {
      width: frameWidth ? frameWidth : 60,
      margin: margin,
    },
    iconBox: {
      width: width,
      height: height,
      "@media screen and (max-width: 499px)": {
        width: sWidth ?? width,
        height: sHeight ?? height,
      },
    },
    Icon: {
      float: float ? float : "right",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 15,
      // border: "1px solid gray",
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  const [imageLoading, setImageLoading] = useState(true);

  // useEffect(() => {
  //   const img = new Image();
  //   img.src = photoUrl;
  //   img.onload = () => setImageLoading(false); // 画像が読み込まれたらスケルトンを非表示に
  // }, [photoUrl]);

  return (
    <div className={classes.userIcon} onClick={onClick}>
      <div className={classes.iconBox}>
        {/* {imageLoading ? (
          <Skelton
            width={55}
            height={55}
            className={{
              borderRadius: 15,
            }}
          />
        ) : (
          )} */}
        <img src={photoUrl} className={classes.Icon} alt="icon" />
      </div>
    </div>
  );
};
