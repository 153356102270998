import { db, auth } from "../firebase/index";
import { push } from "connected-react-router";
import { AppThunk } from "../app/store";
import {
  addMentionTo,
  addImages,
  resetState,
  fetchPost,
  fetchLastPost,
  fetchWantLastPost,
  fetchOldestId,
  fetchCheckPostId,
  fetchProjectPost,
  fetchCheckProjectPostId,
  fetchProjectLastPost,
  fetchOldestProjectId,
  fetchCheckWantPostId,
  fetchOldestWantId,
  fetchWantPost,
} from "./postSlice";
import { Post, MentionUserData, Info } from "../Types/userType";
import {
  closePostChangeLoading,
  openPostChangeLoading,
} from "../features/controlBoolSlice";
import * as Path from "../refPath";
import { squeezeChange } from "./userSlice";
import { Images } from "../Types/imageType";
import {
  followsCollection,
  getDocumentRef,
  likedUsersCollection,
  postDocument,
  postsCollection,
  postsCollectionGroup,
  socialInfosCollection,
  userDocument,
  usersCollection,
  wantedProjectsCollection,
} from "../firebasePaths";
import {
  collection,
  getDoc,
  getDocs,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  where,
  writeBatch,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
const timestamp = serverTimestamp();

type RefBox = {
  createdAt: any;
  id: string;
  postRef: string;
};

export const sendPost = (
  body: string,
  images: string[],
  mentionTo: any
): AppThunk => {
  return async (dispatch, getState): Promise<void> => {
    const userState = getState().user.user;
    const batch = writeBatch(db);
    const userInfo = {
      displayName: userState.displayName,
      photoUrl: userState.photoUrl,
      uid: userState.uid,
    };
    const uid = auth.currentUser!.uid;
    const postsRef = postDocument(uid);
    const postNewData = {
      author: userDocument(uid).path,
      uid: uid,
      body: body,
      images: images,
      mentionTo: mentionTo,
      createdAt: timestamp,
      likeCount: 0,
      wantCount: 0,
      userInfo: userInfo,
      postRef: postsRef.path,
      isProject: false,
    };
    batch.set(postsRef, postNewData);
    const userPostCountRef = userDocument(uid);
    batch.set(userPostCountRef, { postCount: increment(1) }, { merge: true });

    batch.commit().then(() => {
      dispatch(resetState());
    });

    const squeezeObj = {
      Product: false,
      All: true,
      Project: false,
      // Reservation: false,
      // Sale: false,
      Want: false,
    };

    dispatch(squeezeChange(squeezeObj));
    dispatch(fetchFirstPosts());
    dispatch(push("/"));
  };
};

export const addStoreMentionTo = (mentionTos: MentionUserData[]): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(addMentionTo({ mentionTos }));
    dispatch(push("/post/select-image"));
  };
};

export const addImagesActions = (images: Images[]): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(addImages({ images }));
  };
};

// 一般投稿とプロジェクトの投稿を混ぜてfetchする
export const fetchPosts = (
  lastDate: any,
  postsData: any,
  oldestId: string
): AppThunk => {
  return async (dispatch, state): Promise<void> => {
    dispatch(openPostChangeLoading());
    let posts: any[] = [];
    let postIdBox: string[] = [];

    // let postsRef = db.collectionGroup("posts").orderBy("createdAt", "desc");
    // if (lastDate) {
    //   if (lastDate.createdAt) {
    //     if (oldestId === postsData[postsData.length - 1].postID) {
    //       return;
    //     }
    //     postsRef = postsRef.startAfter(lastDate.createdAt);
    //   }
    // }

    let postsQuery = query(
      postsCollectionGroup(),
      orderBy("createdAt", "desc"),
      where("isProject", "==", false),
      limit(5)
    );

    if (
      lastDate?.createdAt &&
      oldestId !== postsData[postsData.length - 1].postID
    ) {
      postsQuery = query(postsQuery, startAfter(lastDate.createdAt));
    }

    await getDocs(postsQuery).then(async (snapshot) => {
      snapshot.forEach(async (doc) => {
        const userLiked: boolean = false;
        const userWanted: boolean = false;
        const userFollowed: boolean = false;
        const postID = doc.id;
        const data = doc.data();
        posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
        postIdBox.push(postID);
      });
    });

    await Promise.all(
      posts.map(async (post: any) => {
        const userRef = userDocument(post.uid);

        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          const userInfo = {
            uid: data.uid,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            projectRef: data.projectRef ? data.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );

    const infiniteScroll = posts.reduce((acc: any, post: any): any[] => {
      return [...acc, post];
    }, postsData);

    dispatch(fetchCheckPostId(postIdBox));
    dispatch(fetchPost(infiniteScroll));
    dispatch(fetchLastPost(infiniteScroll[infiniteScroll.length - 1]));
    dispatch(closePostChangeLoading());
  };
};
export const fetchFirstPosts = (): AppThunk => {
  return async (dispatch, getState): Promise<void> => {
    if (getState().post.fetchPost[0].postID === "")
      dispatch(openPostChangeLoading());
    const posts: any = [];
    const postIdBox: string[] = [];

    const postQuery = query(
      postsCollectionGroup(),
      orderBy("createdAt", "desc"),
      where("isProject", "==", false),
      limit(5)
    );
    await getDocs(postQuery).then(async (snapshot) => {
      snapshot.forEach(async (doc) => {
        const userLiked: boolean = false;
        const userWanted: boolean = false;
        const userFollowed: boolean = false;
        const postID = doc.id;

        const data = doc.data();
        data.createdAt = doc.data({
          serverTimestamps: "estimate",
        }).createdAt;

        posts.push({
          userLiked,
          userWanted,
          userFollowed,
          postID,
          ...data,
        });
        postIdBox.push(postID);
      });
    });

    await Promise.all(
      posts.map(async (post: any) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          const userInfo = {
            uid: data.uid,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            projectRef: data.projectRef ? data.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );
    dispatch(fetchCheckPostId(postIdBox));
    dispatch(fetchPost(posts));
    dispatch(fetchLastPost(posts[posts.length - 1]));
    dispatch(closePostChangeLoading());
  };
};

// すべての投稿の最後のidをfetch
export const lastIdGet = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    const postQuery = query(
      postsCollectionGroup(),

      orderBy("createdAt", "asc"),
      limit(1)
    );
    getDocs(postQuery).then((snapshot) => {
      snapshot.forEach((doc) => {
        const id = doc.id;
        dispatch(fetchOldestId(id));
      });
    });
  };
};

// プロジェクトの投稿だけをfetch
export const fetchProjectsPosts = (
  lastDate: any,
  postsData: any,
  oldestId: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(openPostChangeLoading());
    let posts: any = [];
    let postIdBox: string[] = [];
    let postQuery = query(
      postsCollectionGroup(),
      orderBy("createdAt", "desc"),
      where("search", "==", true),
      limit(5)
    );
    if (lastDate) {
      if (lastDate.createdAt) {
        if (oldestId === postsData[postsData.length - 1].postID) {
          return;
        }
        postQuery = query(postQuery, startAfter(lastDate.createdAt));
      }
    }
    await getDocs(postQuery).then(async (snapshot) => {
      snapshot.forEach(async (doc) => {
        const userLiked: boolean = false;
        const userWanted: boolean = false;
        const userFollowed: boolean = false;
        const postID = doc.id;
        const data = doc.data();
        posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
        postIdBox.push(postID);
      });
    });
    await Promise.all(
      posts.map(async (post: any) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          const userInfo = {
            uid: data.uid,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            projectRef: data.projectRef ? data.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );

    const infiniteScroll = posts.reduce((acc: any, post: any): any[] => {
      return [...acc, post];
    }, postsData);

    // store
    dispatch(fetchCheckProjectPostId(postIdBox));
    dispatch(fetchProjectPost(infiniteScroll));
    dispatch(fetchProjectLastPost(infiniteScroll[infiniteScroll.length - 1]));
    // state
    // await setCheckPostId(postIdBox)
    // await setPostsData(infiniteScroll)
    // await setLastDate(infiniteScroll[infiniteScroll.length - 1])

    // await setLoading(false)
    dispatch(closePostChangeLoading());
  };
};
// ProjectのPostだけを最初にfetch
export const fetchFirstProjectsPosts = (): AppThunk => {
  return async (dispatch, getState): Promise<void> => {
    const posts: any[] = [];
    const postIdBox: string[] = [];
    const postQuery = query(
      postsCollectionGroup(),
      orderBy("createdAt", "desc"),
      where("search", "==", true),
      limit(1)
    );
    await getDocs(postQuery).then(async (snapshot) => {
      snapshot.forEach(async (doc) => {
        const userLiked: boolean = false;
        const userWanted: boolean = false;
        const userFollowed: boolean = false;
        const postID = doc.id;
        const data = doc.data();
        data.createdAt = doc.data({
          serverTimestamps: "estimate",
        }).createdAt; // GPT確認
        posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
        postIdBox.push(postID);
      });
    });

    await Promise.all(
      posts.map(async (post) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          const userInfo = {
            uid: data.uid,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            projectRef: data.projectRef ? data.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );
    dispatch(fetchProjectPost(posts));
    dispatch(fetchProjectLastPost(posts[posts.length - 1]));
    dispatch(fetchCheckProjectPostId(postIdBox));
    dispatch(closePostChangeLoading());
  };
};

// プロジェクトの投稿のidだけをfetch
export const projectsLastIdGet = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    const lastIdQuery = query(
      postsCollectionGroup(),
      orderBy("createdAt", "asc"),
      where("search", "==", true),
      limit(1)
    );
    getDocs(lastIdQuery).then((snapshot) => {
      snapshot.forEach((doc) => {
        const id = doc.id;
        dispatch(fetchOldestProjectId(id));
      });
    });
  };
};

// Wantのスクロール用fetch
export const fetchWantPosts = (
  wantLastDate: any,
  postsData: any,
  oldestId: string,
  uid: string
): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(openPostChangeLoading());
    let refBox: RefBox[] = [];
    let posts: any = [];
    let postIdBox: string[] = [];
    let wantedQuery = query(
      wantedProjectsCollection(uid),
      orderBy("createdAt", "desc"),
      limit(5)
    );
    if (wantLastDate) {
      if (wantLastDate.createdAt) {
        if (oldestId === postsData[postsData.length - 1].postID) {
          return;
        }
        wantedQuery = query(wantedQuery, startAfter(wantLastDate.createdAt));
      }
    }
    await getDocs(wantedQuery).then((snapshot: any) => {
      snapshot.forEach((doc: any) => {
        const data: RefBox = doc.data();
        refBox.push({ ...data });
      });
    });

    await Promise.all(
      refBox.map(async (rb: RefBox) => {
        const wantedPostsRef = getDocumentRef(rb.postRef);
        await getDoc(wantedPostsRef).then((doc) => {
          const userLiked: boolean = false;
          const userWanted: boolean = false;
          const userFollowed: boolean = false;
          const postID = doc.id;
          const data = doc.data();
          posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
          postIdBox.push(postID);
        });
      })
    );
    await Promise.all(
      posts.map(async (post: any) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          if (data) {
            const userInfo = {
              uid: data.uid,
              photoUrl: data.photoUrl,
              displayName: data.displayName,
              projectRef: data.projectRef ? data.projectRef : "",
            };
            post.userInfo = userInfo;
          }
        });
        return post;
      })
    );

    const infiniteScroll = posts.reduce((acc: any, post: any): any[] => {
      return [...acc, post];
    }, postsData);
    dispatch(fetchCheckWantPostId(postIdBox));
    dispatch(fetchWantPost(infiniteScroll));
    dispatch(fetchWantLastPost(refBox[refBox.length - 1]));
    dispatch(closePostChangeLoading());
  };
};

export const fetchFirstWantPosts = (uid: string): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(openPostChangeLoading());
    let refBox: RefBox[] = [];
    let posts: any = [];
    let postIdBox: string[] = [];

    if (!auth.currentUser) {
      return;
    }

    const wantedRef = wantedProjectsCollection(uid);
    const wantedQuery = query(
      wantedRef,
      orderBy("createdAt", "desc"),
      limit(5)
    );
    await getDocs(wantedQuery).then((snapshot) => {
      snapshot.forEach(async (doc) => {
        const data = doc.data() as RefBox;
        refBox.push(data);
      });
    });

    await Promise.all(
      refBox.map(async (rb: RefBox) => {
        const wantedPostsRef = getDocumentRef(rb.postRef);
        await getDoc(wantedPostsRef).then((doc) => {
          const userLiked: boolean = false;
          const userWanted: boolean = false;
          const userFollowed: boolean = false;
          const postID = doc.id;
          const data = doc.data();
          posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
          postIdBox.push(postID);
        });
      })
    );

    await Promise.all(
      posts.map(async (post: Post) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data();
          const userInfo = {
            uid: data?.uid,
            photoUrl: data?.photoUrl,
            displayName: data?.displayName,
            projectRef: data?.projectRef ? data?.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );
    const filetedPosts = posts.filter((post: any) => post.userInfo.uid);
    dispatch(fetchCheckWantPostId(postIdBox));
    dispatch(fetchWantPost(filetedPosts));
    dispatch(fetchWantLastPost(refBox[refBox.length - 1]));
    dispatch(closePostChangeLoading());
  };
};

export const wantLastIdGet = (uid: string): AppThunk => {
  return async (dispatch): Promise<void> => {
    if (!auth.currentUser) {
      return;
    }
    const lastIdRef = wantedProjectsCollection(uid);
    const lastIdQuery = query(lastIdRef, orderBy("createdAt", "asc"), limit(1));

    await getDocs(lastIdQuery).then((snapshot) => {
      snapshot.forEach((doc) => {
        const id = doc.id;
        dispatch(fetchOldestWantId(id));
      });
    });
  };
};

// ProjectPost!!
export const fetchFirstProjectPosts = (
  projectRef: string,
  setCheckPostId: Function,
  setPostsData: Function,
  setLastDate: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(openPostChangeLoading());
    const posts: any = [];
    const postIdBox: string[] = [];
    // const postsRef = db
    //   .collectionGroup("posts")
    //   .orderBy("createdAt", "desc")
    //   .where("projectRef", "==", projectRef);

    const postsQuery = query(
      postsCollectionGroup(),
      orderBy("createdAt", "desc"),
      where("projectRef", "==", projectRef),
      limit(5)
    );
    await getDocs(postsQuery).then((snapshot) => {
      snapshot.forEach((doc) => {
        const userLiked: boolean = false;
        const userWanted: boolean = false;
        const userFollowed: boolean = false;
        const postID = doc.id;
        const data = doc.data();
        posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
        postIdBox.push(postID);
      });
    });
    await Promise.all(
      posts.map(async (post: Post) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          const userInfo = {
            uid: data.uid,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            projectRef: data.projectRef ? data.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );
    setCheckPostId(postIdBox);
    setPostsData(posts);
    setLastDate(posts[posts.length - 1]);
    dispatch(closePostChangeLoading());
  };
};

export const projectLastIdGet = (
  projectRef: string,
  setOldestId: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    if (projectRef === "") return;
    const lastIdRef = collection(getDocumentRef(projectRef), "posts");
    const lastIdRefQuery = query(
      lastIdRef,
      orderBy("createdAt", "asc"),
      limit(1)
    );
    await getDocs(lastIdRefQuery).then((snapshot) => {
      snapshot.forEach((doc) => {
        const id = doc.id;
        setOldestId(id);
      });
    });
  };
};

export const fetchProjectPosts = (
  lastDate: Post | undefined,
  postsData: Post[],
  oldestId: string,
  projectRef: string,
  setCheckPostId: Function,
  setPostsData: Function,
  setLastDate: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(openPostChangeLoading());
    const posts: Post[] = [];
    const postIdBox: string[] = [];
    let postsRefQuery = query(
      postsCollectionGroup(),

      orderBy("createdAt", "desc"),
      where("projectRef", "==", projectRef),
      limit(5)
    );

    if (lastDate) {
      if (lastDate.createdAt) {
        if (oldestId === postsData[postsData.length - 1].postID) {
          return;
        }
        postsRefQuery = query(postsRefQuery, startAfter(lastDate.createdAt));
      }
    }

    await getDocs(postsRefQuery).then(async (snapshot) => {
      snapshot.forEach(async (doc) => {
        const userLiked: boolean = false;
        const userWanted: boolean = false;
        const userFollowed: boolean = false;
        const postID = doc.id;
        const data = doc.data() as Post;
        posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
        postIdBox.push(postID);
      });
    });

    await Promise.all(
      posts.map(async (post: any) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          const userInfo = {
            uid: data.uid,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            projectRef: data.projectRef ? data.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );

    const infiniteScroll = posts.reduce((acc: any, post: any): any[] => {
      return [...acc, post];
    }, postsData);
    setCheckPostId(postIdBox);
    setPostsData(infiniteScroll);
    setLastDate(infiniteScroll[infiniteScroll.length - 1]);
    dispatch(closePostChangeLoading());
  };
};

export const fetchFirstSortPosts = (
  uid: string,
  setCheckPostId: Function,
  setPostsData: Function,
  setLastDate: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(openPostChangeLoading());
    const posts: any = [];
    const postIdBox: string[] = [];
    const postsRefQuery = query(
      postsCollectionGroup(),
      orderBy("createdAt", "desc"),
      where("uid", "==", uid),
      limit(5)
    );
    await getDocs(postsRefQuery).then((snapshot) => {
      snapshot.forEach((doc) => {
        const userLiked: boolean = false;
        const userWanted: boolean = false;
        const userFollowed: boolean = false;
        const postID = doc.id;
        const data = doc.data();
        posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
        postIdBox.push(postID);
      });
    });
    await Promise.all(
      posts.map(async (post: Post) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          const userInfo = {
            uid: data.uid,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            projectRef: data.projectRef ? data.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );
    setCheckPostId(postIdBox);
    setPostsData(posts);
    setLastDate(posts[posts.length - 1]);
    dispatch(closePostChangeLoading());
  };
};

export const fetchSortPosts = (
  lastDate: Post | undefined,
  postsData: Post[],
  oldestId: string,
  uid: string,
  setCheckPostId: Function,
  setPostsData: Function,
  setLastDate: Function
): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(openPostChangeLoading());
    const posts: Post[] = [];
    const postIdBox: string[] = [];
    let postsRefQuery = query(
      postsCollectionGroup(),
      orderBy("createdAt", "desc"),
      where("uid", "==", uid),
      limit(5)
    );

    if (lastDate) {
      if (lastDate.createdAt) {
        if (oldestId === postsData[postsData.length - 1].postID) {
          return;
        }
        postsRefQuery = query(postsRefQuery, startAfter(lastDate.createdAt));
      }
    }
    await getDocs(postsRefQuery).then(async (snapshot) => {
      snapshot.forEach(async (doc) => {
        const userLiked: boolean = false;
        const userWanted: boolean = false;
        const userFollowed: boolean = false;
        const postID = doc.id;
        const data = doc.data() as Post;
        posts.push({ userLiked, userWanted, userFollowed, postID, ...data });
        postIdBox.push(postID);
      });
    });

    await Promise.all(
      posts.map(async (post: any) => {
        const userRef = userDocument(post.uid);
        await getDoc(userRef).then((doc) => {
          const data = doc.data()!;
          const userInfo = {
            uid: data.uid,
            photoUrl: data.photoUrl,
            displayName: data.displayName,
            projectRef: data.projectRef ? data.projectRef : "",
          };
          post.userInfo = userInfo;
        });
        return post;
      })
    );

    const infiniteScroll = posts.reduce((acc: any, post: any): any[] => {
      return [...acc, post];
    }, postsData);
    setCheckPostId(postIdBox);
    setPostsData(infiniteScroll);
    setLastDate(infiniteScroll[infiniteScroll.length - 1]);
    dispatch(closePostChangeLoading());
  };
};
export const sortLastIdGet = (uid: string, setOldestId: Function): AppThunk => {
  return async (dispatch): Promise<void> => {
    const lastIdRef = postsCollection(uid);
    const lastIdRefQuery = query(
      postsCollection(uid),

      orderBy("createdAt", "asc"),
      limit(1)
    );
    await getDocs(lastIdRefQuery).then((snapshot) => {
      snapshot.forEach((doc) => {
        const id = doc.id;
        setOldestId(id);
      });
    });
  };
};

export const updateUserFollowed = (post: Post, setFollowState: Function) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      const followsQuery = query(
        followsCollection(uid),
        where("followerUid", "==", post.uid)
      );

      const snapshotUnsubscribe = onSnapshot(followsQuery, (snapshot) => {
        snapshot.forEach((doc) => {
          setFollowState(true);
        });
      });

      // Clean up snapshot listener
      return snapshotUnsubscribe;
    }
  });
  return unsubscribe;

  // var unsubscribe = firebase.auth().onAuthStateChanged((user) => {
  //   if (user) {
  //     // ログインしていれば中通る
  //     const uid = auth.currentUser!.uid;
  //     const followsRef = db.collection(Path.follows(uid));
  //     followsRef.onSnapshot((snapshot) => {
  //       snapshot.forEach((doc) => {
  //         // docのuidとpostのuidがあればフォローしていると判断
  //         if (doc.data().followerUid === post.uid) {
  //           setFollowState(true);
  //         }
  //       });
  //     });
  //   }

  //   // 登録解除
  //   unsubscribe();
  // });
};

export const updateUserLiked = async (post: Post, setLikeState: Function) => {
  if (!auth.currentUser) {
    return;
  }
  const uid = auth.currentUser!.uid;
  if (post.projectPostRef) {
    const likedUsersRef = collection(
      getDocumentRef(post.projectPostRef),
      "likedUsers"
    );
    onSnapshot(likedUsersRef, (snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.data().id === uid) {
          setLikeState(true);
        }
      });
    });
  } else if (post.postID) {
    const likedUsersRef = likedUsersCollection(post.uid, post.postID);
    onSnapshot(likedUsersRef, (snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.data().id === uid) {
          setLikeState(true);
        }
      });
    });
  }
};

export const updateUserWant = (post: Post, setWantState: Function) => {
  if (!auth.currentUser) {
    return;
  }
  const uid = auth.currentUser!.uid;
  const projectPost: any = post.projectPostRef;
  const projectPostDocument = getDocumentRef(projectPost);
  const wantedUsersRef = collection(projectPostDocument, "wantedUsers");
  onSnapshot(wantedUsersRef, (snapshot) => {
    snapshot.forEach((doc) => {
      if (doc.data().id === uid) {
        setWantState(true);
      }
    });
  });
};

export const fetchInfo = (setInfo: Function): AppThunk => {
  return async (dispatch): Promise<void> => {
    let infoBox: Info[] = [];
    let unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const infoRefQuery = query(
          socialInfosCollection(user.uid),
          orderBy("createdAt", "desc"),
          limit(30)
        );

        getDocs(infoRefQuery)
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              const data = doc.data() as Info;
              // TODO: Type Fix
              infoBox.push(data);
            });
          })
          .then(() => {
            setInfo(infoBox);
          });
      }
      // 登録解除
      unsubscribe();
    });
  };
};

export const fetchInfoUnRead = (setNewInfo: Function): AppThunk => {
  return async (dispatch): Promise<void> => {
    let unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const infoRefQuery = query(
          socialInfosCollection(user.uid),
          orderBy("createdAt", "desc"),
          where("alreadyRead", "==", false),
          limit(21)
        );

        onSnapshot(infoRefQuery, (snapshot) => {
          setNewInfo(
            snapshot.docs.map((doc) => ({
              ...doc.data(),
            }))
          );
        });
      }
      // 登録解除
      unsubscribe();
    });
  };
};

// NOTE: 使わなくなったがいつか使うかも
// export const fetchSalePosts = (): AppThunk => {
//   return async (dispatch): Promise<void> => {
//     dispatch(openPostChangeLoading());
//     let posts: any = [];
//     let postIdBox: string[] = [];
//     let postsRef = db
//       .collectionGroup("projects")
//       .where("salesStatus.sale", "==", true)
//       .firestore.collectionGroup("posts");   // この書き方は出来ない
//     // .orderBy("createdAt", "desc");
//     await postsRef
//       .limit(5)
//       .get()
//       .then(async (snapshot) => {
//         snapshot.forEach(async (doc) => {
//           const userLiked: boolean = false;
//           const userWanted: boolean = false;
//           const userFollowed: boolean = false;
//           const postID = doc.id;

//           const data = doc.data();
//           // serverTimestampをestimateにしてnullを防ぐ
//           data.createdAt = doc.data({
//             serverTimestamps: "estimate",
//           }).createdAt;

//           posts.push({
//             userLiked,
//             userWanted,
//             userFollowed,
//             postID,
//             ...data,
//           });
//           postIdBox.push(postID);
//         });
//       });

//     const innerJoinData = await Promise.all(
//       posts.map(async (post: any) => {
//         // uid とる　　uidでGet そんでまーじ
//         const userRef = userDocument(post.uid);
//         await getDoc(userRef).then((doc) => {
//           const data = doc.data()!;
//           const userInfo = {
//             uid: data.uid,
//             photoUrl: data.photoUrl,
//             displayName: data.displayName,
//             projectRef: data.projectRef ? data.projectRef : "",
//           };
//           post.userInfo = userInfo;
//         });
//         return post;
//       })
//     );
//     await dispatch(fetchCheckPostId(postIdBox));
//     await dispatch(fetchPost(posts));
//     await dispatch(fetchLastPost(posts[posts.length - 1]));
//     // await setLoading(false)
//     dispatch(closePostChangeLoading());
//   };
// };
