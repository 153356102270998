import { Typography, makeStyles } from "@material-ui/core";
import { DeliveryStepper } from "./DeliveryStepper";

type Props = {
  sagawaStatus: { status: string[]; step: number };
  deliveryMessage: string;
  deliveryScheduledDate: string;
  orderNumber: string;
  url?: string;
};

const Status = (props: Props) => {
  const {
    sagawaStatus,
    deliveryMessage,
    deliveryScheduledDate,
    url,
    orderNumber,
  } = props;
  const useStyles = makeStyles({
    deliveryModalArea: {
      width: "100%",
    },
    centerBtn: {
      width: 100,
      height: 30,
      margin: "20px auto",
      display: "flex",
      cursor: "pointer",
    },
    titleBtn: {
      fontWeight: "bold",
      textAlign: "center",
      flex: 8,
    },
    icon: {
      width: 16,
      height: 16,
      marginTop: 10,
    },
    imgBtn: {
      paddingTop: 10,
      flex: 2,
    },
    stepperArea: {
      margin: "0 auto",
    },
    stepper: {
      margin: "0 auto",
      "& p": {
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    changeUrl: {
      textAlign: "center",
      fontSize: 12,
    },
    url: {
      color: "skyblue",
    },
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.deliveryModalArea}>
        <div className={classes.centerBtn}>
          <Typography className={classes.titleBtn}>配送状況</Typography>
        </div>
        <Typography
          className={classes.titleBtn}
        >{`お問い合わせ番号 ${orderNumber}`}</Typography>
      </div>
      <div className={classes.stepperArea}>
        <div className={classes.stepper}>
          <Typography>{deliveryScheduledDate}</Typography>
          <DeliveryStepper
            activeStep={sagawaStatus.step}
            deliveryStatusArray={sagawaStatus.status}
            deliveryMessage={deliveryMessage}
          />
        </div>
      </div>
      {url && (
        <div className={classes.changeUrl}>
          <a href={url} className={classes.url}>
            お届け予定日の変更はこちら
          </a>
        </div>
      )}
    </div>
  );
};

export default Status;
