import { Done } from "../../templates/Done/Done";
import { Header } from "../Header/Header";
export const CreatorImageDone = () => {
  return (
    <div>
      <Header label="CreatorProfile編集" transitionLocation="/?" />
      <Done info="編集完了しました" linkText="TOPページへ" />
    </div>
  );
};
