import { makeStyles } from "@material-ui/core";
import { GrayBack } from "../../../components/MyPage/GrayBack";
import { ReserveItemInfo } from "./ReserveItemInfo";
import { ReservePrice } from "./ReservePrice";
import { ReserveJoinData, ReserveProduct } from "../../../Types/productType";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TotalAmount } from "../../../components/History/TotalAmount";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

type Props = {
  reserveData: ReserveJoinData;
};

export const ReserveProductList = (props: Props) => {
  const { reserveData } = props;
  const useStyles = makeStyles({
    productDetail: {
      display: "flex",
      height: 150,
      marginBottom: 20,
    },
    left: {
      width: 180,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 135,
      },
    },
    right: {
      width: 320,
      height: 100,
      "@media screen and (max-width: 499px)": {
        width: 240,
      },
    },
    imageArea: {
      margin: "0 auto",
      width: 120,
    },
    image: {
      width: 120,
      height: 150,
      objectFit: "cover",
    },
    deleteBtn: {
      "@media screen and (max-width: 499px)": {
        fontSize: 8,
      },
    },
    editBtn: {
      "@media screen and (max-width: 499px)": {
        fontSize: 8,
      },
    },
    btnArea: {
      display: "flex",
    },
    btnLeft: {
      flex: 1,
      marginRight: 5,
    },
    btnRight: {
      flex: 1,
      marginRight: 5,
    },
    reserveText: {
      margin: "20px 40px",
      border: "1px solid gray",
    },
    text: {
      lineHeight: 2,
      fontSize: 14,
    },
    headerTextArea: {
      paddingLeft: 10,
      marginTop: 10,
      "@media screen and (max-width: 499px)": {
        paddingLeft: 20,
      },
      "& p": {
        fontSize: 14,
        "@media screen and (max-width: 499px)": {
          fontSize: 12,
        },
      },
    },
    border: {
      border: "0.5px solid gray",
    },
    reserveDateText: {
      margin: "20px 0 10px",
      "@media screen and (max-width: 499px)": {
        marginTop: 10,
      },
    },
    projectNameSignboard: {
      fontWeight: "bold",
    },
    projectName: {
      marginBottom: 10,
    },
    totalPriceArea: {
      marginLeft: 20,
    },
    grayBorder: {
      borderBottom: "1px solid gray",
      width: "50%",
      marginBottom: 10,
    },
  });
  const classes = useStyles();
  const [calculationAmount, setCalculationAmount] = useState({
    price: 0,
    totalPrice: 0,
    postage: 0,
  });
  const reservations = reserveData.minimumProduction - reserveData.reservations;

  const reserveStatus = () => {
    if (reserveData.salesStatus.cancel) return `販売中止となりました`;
    if (reserveData.isPaid) return "精算済み、注文履歴を確認してください";
    if (reserveData.salesStatus.inProduction) return "生産中";
    if (reserveData.salesStatus.underReservation) {
      if (reservations > 0) return `販売開始まであと ${reservations}着`;
      if (reservations <= 0) return "予約数が上限を超えました";
    }
    return "文言準備中(Official)";
  };

  const dateFormat = (seconds: number) => {
    const date = dayjs.unix(seconds);

    const formattedDate = date.format("YYYY年M月D日H時MM分  ");

    return formattedDate;
  };

  const totalAmountCalculation = () => {
    let total = 0;
    reserveData.reserveProducts.forEach((rp: ReserveProduct) => {
      const priceMultipliedByQuantity = Math.floor(rp.price) * rp.quantity;
      total += priceMultipliedByQuantity;
    });

    setCalculationAmount({
      price: total,
      totalPrice: total + 0, // キャンペーン中
      postage: 0, // キャンペーン中
    });
  };

  useEffect(() => {
    totalAmountCalculation();
  }, []);

  return (
    <div key={reserveData.id}>
      {reserveData.reserveProducts.map((product) => (
        <div key={JSON.stringify(product)}>
          <div className={classes.headerTextArea}>
            <Typography className={classes.reserveDateText}>
              注文番号:{reserveData.id}
              <Button
                variant="outlined"
                style={{ marginLeft: 10 }}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(reserveData.id);
                    alert("注文番号をコピーしました"); // ユーザーにフィードバックを提供
                  } catch (err) {
                    console.error(
                      "クリップボードへのコピーに失敗しました",
                      err
                    );
                    alert("クリップボードへのコピーに失敗しました"); // エラーメッセージを表示
                  }
                }}
              >
                <FileCopyOutlinedIcon
                  style={{
                    cursor: "pointer",
                    fontSize: "small",
                  }}
                />
              </Button>
            </Typography>
            <Typography className={classes.reserveDateText}>
              予約日時:{dateFormat(reserveData.createdAt.seconds)}
            </Typography>
            <Typography className={classes.projectNameSignboard}>
              プロジェクト名
            </Typography>
            <Typography className={classes.projectName}>
              {product.projectName}
            </Typography>
          </div>
          <div className={classes.productDetail}>
            <div className={classes.left}>
              <div className={classes.imageArea}>
                <img
                  src={product.image}
                  className={classes.image}
                  alt="productImage"
                />
              </div>
            </div>
            <div className={classes.right}>
              <ReserveItemInfo
                label="アイテム名"
                detail={product.itemCategory}
              />
              <ReserveItemInfo label="サイズ" detail={product.size} />
              <ReserveItemInfo label="カラー" detail={product.color} />
              <ReservePrice
                price={`¥${Math.floor(product.price).toLocaleString()}`}
                quantity={product.quantity}
              />
            </div>
          </div>
        </div>
      ))}
      <div className={classes.totalPriceArea}>
        <TotalAmount
          kind="商品合計(税込)"
          price={calculationAmount.price.toLocaleString()}
        />
        <TotalAmount
          kind="送料"
          price={calculationAmount.postage.toLocaleString()}
        />
        <div className={classes.grayBorder} />
        <TotalAmount
          kind="合計(税込)"
          price={calculationAmount.totalPrice.toLocaleString()}
        />
      </div>
      <div className={classes.reserveText}>
        <Typography className={classes.text} align="center">
          {reserveStatus()}
        </Typography>
      </div>
      <GrayBack height={5} />
    </div>
  );
};
