import React from "react";
import { makeStyles } from "@material-ui/core";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import { useSelector } from "react-redux";
import GridLayout from "react-grid-layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import { selectUser } from "../../features/userSlice";
import { useWindowDimensions } from "../../hooks/windowDimensions";
import { SalesStatus } from "../../Types/productType";
import {
  isProjectLink,
  projectRouterChoice,
} from "../../features/helpers/routerHelper";

type Props = {
  images: {
    path: string;
    id: string;
  }[];
  projectRef: string;
  salesStatus?: SalesStatus;
  currentWidth: number;
};

export const PropsQuiltedThreeImages = React.memo((props: Props) => {
  const { images, projectRef, salesStatus, currentWidth } = props;
  const useStyles = makeStyles((theme: any) => ({
    root: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      margin: "0 auto",
      "@media screen and (max-width: 499px)": {
        width: "100%",
      },
    },
    image: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
  }));

  const setting = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const classes = useStyles();

  const { push } = useHistory();
  const projectRefArray = projectRef && projectRef.split("/");
  const projectID = projectRef && projectRefArray[3];
  const memerUID = projectRef && projectRefArray[1];

  const user = useSelector(selectUser);
  const uid = user.uid;
  const { width } = useWindowDimensions();

  return (
    <div
      className={classes.root}
      onClick={() =>
        isProjectLink(salesStatus, projectRef) &&
        push(projectRouterChoice(salesStatus, projectRef), uid)
      }
    >
      <Slider {...setting}>
        <GridLayout
          className="layout"
          isDraggable={false}
          isResizable={false}
          cols={20}
          rowHeight={width > 499 ? 100 : 75}
          width={width > 499 ? 500 : currentWidth}
          margin={[1, 1]}
        >
          <div
            key={0}
            data-grid={{
              x: 0,
              y: 0,
              w: 14,
              h: 6,
              i: "0",
            }}
          >
            <img
              src={images[0].path}
              className={classes.image}
              alt="postImage"
            />
          </div>
          <div
            key={1}
            data-grid={{
              x: 14,
              y: 1,
              w: 6,
              h: 3,
              i: "2",
            }}
          >
            <img
              src={images[1].path}
              className={classes.image}
              alt="postImage"
            />
          </div>
          <div
            key={2}
            data-grid={{
              x: 14,
              y: 1,
              w: 6,
              h: 3,
              i: "2",
            }}
          >
            <img
              src={images[2].path}
              className={classes.image}
              alt="postImage"
            />
          </div>
        </GridLayout>
        {images.map(
          (images, index: number) =>
            images.path !== "" && (
              <div key={index}>
                <GridLayout
                  className="layout"
                  isDraggable={false}
                  isResizable={false}
                  cols={20}
                  rowHeight={width > 499 ? 100 : 75}
                  width={width > 499 ? 500 : currentWidth}
                  margin={[1, 1]}
                >
                  <div
                    key={0}
                    data-grid={{
                      x: 0,
                      y: 0,
                      w: 20,
                      h: 6,
                      i: "0",
                    }}
                  >
                    <img
                      src={images.path}
                      className={classes.image}
                      alt="postImage"
                    />
                  </div>
                </GridLayout>
              </div>
            )
        )}
      </Slider>
    </div>
  );
});
